import React from 'react'
import ScrollToTop from "react-scroll-to-top";
// import { FaArrowCircleUp } from 'react-icons/fa';

export default function ScrollTop() {
  return (
    <div>
      <ScrollToTop className={"scrollToUp"} smooth top={1000}>
      </ScrollToTop>
    </div>
  )
}
