import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import Ourservices from '../components/Ourservices';
import Ourprocess from '../components/Ourprocess';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Modal from 'react-bootstrap/Modal';
import CasestudySlider from '../components/CasestudySlider';
import TestimonialSlider from '../components/TestimonialSlider';
import BlogSlider from '../components/BlogSlider';
import WhyUsChoose from '../components/WhyUsChoose';
import { Col, Container, Row } from 'react-bootstrap'


export default function Home() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handlePointerMove = (ev) => {
    const featureEls = document.querySelectorAll(".feature");
    featureEls.forEach((featureEl) => {
      const rect = featureEl.getBoundingClientRect();
      featureEl.style.setProperty("--x", ev.clientX - rect.left);
      featureEl.style.setProperty("--y", ev.clientY - rect.top);
    });
  };
  useEffect(() => {
    // Get all <span> elements within <h2>
    const spans = document.querySelectorAll('h2 > span');

    // Loop through each <span> element
    spans.forEach(span => {
      // Replace <span> with its inner text
      span.outerHTML = span.innerHTML;
    });
  }, []);
  //home  slider
  const handleFocusManagement = () => {
    document.querySelectorAll('.slick-slide[aria-hidden="true"]').forEach(slide => {
      slide.setAttribute('tabindex', '-1');
      slide.querySelectorAll('a, button, input, select, textarea').forEach(elem => {
        elem.setAttribute('tabindex', '-1');
      });
    });
  };
  const settings1 = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: false, // Enable autoplay
    cssEase: 'linear', // Linear easing for fade effect
    autoplaySpeed: 10000, // Autoplay interval in milliseconds (10 second)
    afterChange: handleFocusManagement,
    beforeChange: handleFocusManagement

  };
  useEffect(() => {
    handleFocusManagement();
  }, []);
  return (
    <div>

      <Helmet>
        <title>Sensussoft: Innovating Solutions for Your Business Growth</title>
        <meta name="title" content="Sensussoft: Innovating Solutions for Your Business Growth" />
        <meta name="keywords" content="Sensussoft, Business Growth Solutions, Innovative Solutions, Software Development, IT Services, Technology Solutions, Digital Transformation, Custom Software Solutions, Business Consulting, IT Solutions Provider" />
        <meta name="description" content="Sensussoft Software has a wide range of knowledge about mobile and website development." />
      </Helmet>

      <main role='main'>
        <div className="home-page-main">
          <div className='stricky-social'>
            <ul className='social'>
              <li className='call'>
                <Link to="" onClick={handleShow} aria-label='schedule call'>
                  <div className='d-flex'>
                    <div className='shedule-ic align-self-center'>
                      <i className="fa fa-phone" aria-hidden="true"></i>
                    </div>
                    <div className='shedule-text align-self-center'>
                      <span>schedule call</span>
                    </div>
                  </div>
                </Link>
              </li>
              <li className='broucher'>

              </li>
            </ul>
          </div>
          {/* We Nurture Creativity that drives your business to infinity start */}
          <div className="ss-creativity-section">

            <div className="slider-container">
              <Slider {...settings1}>
                <div className='home-slidersame'>
                  <div className='homeslide1_img_main' style={{ backgroundImage: `url(${require('../assets/images/homeslide1.jpg')})` }}>
                    {/* <div className='homeslide1_img_main' style={{ backgroundImage: `url(${require('../assets/images/homeslideNew1.jpg')})` }}> */}

                    <div className='homeslide-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='homeslide1_text_main'>
                              <div className='home-text-box'>
                                <h1 className='homeslide1_text'>
                                  {/* We Nurture Creativity that drives your business to Infinity */}
                                    Empowering Innovation with AI-Driven Creativity
                                </h1>
                                <p >
                                  {/* At Sensussoft, we cultivate creativity to drive your business to new heights. Discover limitless potential with our innovative solutions */}
                                At Sensussoft, we blend creativity with AI to accelerate growth and unlock limitless possibilities. Experience the future of intelligent innovation.
                                </p>
                                <div className='home-btn pt-20'>
                                  <Link to="/our-services" aria-label='learn more' >
                                    <button className="learn-more btn-large arrow-btn-secondary " aria-label='learn more'>
                                      <span className="circle" aria-hidden="true">
                                        <span className="icon arrow">
                                          <img src={require('../assets/images/btn-arrow2.svg').default} className="img-fluid" alt='img' />
                                        </span>
                                      </span>
                                      <span className="button-text ">Explore Our Solutions</span>
                                    </button>
                                  </Link>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

                <div className='home-slidersame'>
                  <div className='homeslide1_img_main' style={{ backgroundImage: `url(${require('../assets/images/homeslide2.jpg')})` }}>

                    <div className='homeslide-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='homeslide1_text_main'>
                              <div className='home-text-box'>
                                <h2 className='homeslide1_text'>
                                  {/* Your Reliable Partner for a Successful Digital Journey */}
                                  Your Trusted Tech Partner in AI-Powered Healthcare Innovation
                                </h2>
                                <p >
                                  {/* Join us on a transformative journey. Sensussoft is your trusted partner in achieving digital excellence and business growth. */}
                                  Sensussoft empowers healthcare with smart, secure AI solutions — helping you grow, adapt, and deliver better patient care.
                                </p>
                                <div className='home-btn pt-20'>
                                  <Link to="/about-us" aria-label='learn more' >
                                    <button className="learn-more btn-large arrow-btn-secondary " aria-label='learn more'>
                                      <span className="circle" aria-hidden="true">
                                        <span className="icon arrow">
                                          <img src={require('../assets/images/btn-arrow2.svg').default} className="img-fluid" alt='img' />
                                        </span>
                                      </span>
                                      <span className="button-text ">Start Your Journey</span>
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

                <div className='home-slidersame'>
                  <div className='homeslide1_img_main' style={{ backgroundImage: `url(${require('../assets/images/homeslide3.jpg')})` }}>

                    <div className='homeslide-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='homeslide1_text_main'>
                              <div className='home-text-box'>
                                <h2 className='homeslide1_text'>
                                  Crafting Success Stories from Inception to Reality
                                </h2>
                                <p >Every success story starts with a vision. Sensussoft turns your ideas into reality, delivering exceptional digital products from inception.
                                </p>
                                <div className='home-btn pt-20'>
                                  <Link to="/case-study" aria-label='learn more' >
                                    <button className="learn-more btn-large arrow-btn-secondary " aria-label='learn more'>
                                      <span className="circle" aria-hidden="true">
                                        <span className="icon arrow">
                                          <img src={require('../assets/images/btn-arrow2.svg').default} className="img-fluid" alt='img' />
                                        </span>
                                      </span>
                                      <span className="button-text ">View Case Studies</span>
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

                <div className='home-slidersame'>
                  <div className='homeslide1_img_main' style={{ backgroundImage: `url(${require('../assets/images/homeslide4.jpg')})` }}>

                    <div className='homeslide-content'>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-lg-6'>
                            <div className='homeslide1_text_main'>
                              <div className='home-text-box'>
                                <h2 className='homeslide1_text'>
                                  Where Innovation Meets Excellence in Digital Solutions
                                </h2>
                                <p >Experience the synergy of innovation and excellence. Sensussoft is committed to providing cutting-edge solutions tailored to your needs
                                </p>
                                <div className='home-btn pt-20'>
                                  <Link to="/technologies" aria-label='learn more' >
                                    <button className="learn-more  arrow-btn-secondary " aria-label='learn more'>
                                      <span className="circle" aria-hidden="true">
                                        <span className="icon arrow">
                                          <img src={require('../assets/images/btn-arrow2.svg').default} className="img-fluid" alt='img' />
                                        </span>
                                      </span>
                                      <span className="button-text ">Learn More</span>
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </Slider>
            </div>
          </div>
          {/* We Nurture Creativity that drives your business to infinity end */}
          {/* delivering section */}
          <div className="we-are-delivering pt-50 pb-80">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="ss-heading">
                    <div className="bg-hover">
                      <h2>We are <span>Delivering</span></h2>
                    </div>
                  </div>
                </div>
              </div>
              <Ourservices />
            </div>
          </div>
          {/* delivering section end*/}
          {/* why choose use start */}
          <WhyUsChoose />
          {/* why choose use end */}
          <div className="our-industry ptb-100 bg-multi ptb-100">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      Industries We Serve as an IT Service Provider

                    </h2>
                    <p className="pt-10 text-regular">
                      At Sensussoft, we pride ourselves on delivering top-notch IT services across a diverse range of industries. Our expertise and tailored solutions help businesses achieve their technological goals and drive success. Here are some of the key industries we serve:
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="pt-50 justify-content-center ">
                <Col lg={12}>
                  <div className="experience-box-main d-flex flex-wrap justify-content-center">

                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className=''> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/experience-ic8.svg').default} className="img-fluid" alt='experience-ic1' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Restaurant
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/experience-ic9.svg').default} className="img-fluid" alt='experience-ic2' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Logistics
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/experience-ic10.svg').default} className="img-fluid" alt='experience-ic3' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Games & Sports
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/experience-ic5.svg').default} className="img-fluid" alt='experience-ic5' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Aviation
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/experience-ic11.svg').default} className="img-fluid" alt='experience-ic4' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Real Estate
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/experience-ic12.svg').default} className="img-fluid" alt='experience-ic6' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          On-Demand
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/experience-ic13.svg').default} className="img-fluid" alt='experience-ic7' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Government
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/experience-ic1.svg').default} className="img-fluid" alt='experience-ic1' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Social Media
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/experience-ic2.svg').default} className="img-fluid" alt='experience-ic2' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          E-commerce
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/experience-ic3.svg').default} className="img-fluid" alt='experience-ic3' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Entertainment
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>

                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}

                      <div className="experience-img">
                        <img src={require('../assets/images/experience-ic4.svg').default} className="img-fluid" alt='experience-ic4' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Healthcare
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/experience-ic5.svg').default} className="img-fluid" alt='experience-ic5' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Travel
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/experience-ic6.svg').default} className="img-fluid" alt='experience-ic6' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Fintech
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/experience-ic7.svg').default} className="img-fluid" alt='experience-ic7' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Education
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>

                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text pt-50">
                    <h3 style={{ fontWeight: 400 }}>
                      Additional Industries with a Strong Focus on Health & Medical Sector
                    </h3>

                    {/* <p className="pt-10 text-regular">
                      At Sensussoft, we pride ourselves on delivering top-notch IT services across a diverse range of industries. Our expertise and tailored solutions help businesses achieve their technological goals and drive success. Here are some of the key industries we serve:
                    </p> */}
                  </div>
                </Col>
              </Row>
              <Row className="pt-50 justify-content-center ">
                <Col lg={12}>
                  <div className="experience-box-main d-flex flex-wrap justify-content-center">

                    <div className="experience-box healthcare-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className=''> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/Healthcare-Medical.svg').default} className="img-fluid" alt='Healthcare & Medical' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Healthcare & Medical
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box healthcare-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/Telemedicine-Remote-Patient-Monitoring.svg').default} className="img-fluid" alt='Telemedicine & Remote Patient Monitoring' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Telemedicine & Remote Patient Monitoring
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box healthcare-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/Fitness-Wellness.svg').default} className="img-fluid" alt='Fitness & Wellness' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Fitness & Wellness
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box healthcare-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/Wearable-Health-Technology.svg').default} className="img-fluid" alt='Wearable Health Technology' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Wearable Health Technology
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box healthcare-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/AI-in-Healthcare.svg').default} className="img-fluid" alt='AI in Healthcare' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                        AI in Healthcare
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box healthcare-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/Digital-Health-mHealth.svg').default} className="img-fluid" alt='Digital Health & mHealth' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Digital Health & mHealth
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box healthcare-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/Medical-Devices-IoT-in-Healthcare.svg').default} className="img-fluid" alt='Medical Devices & IoT in Healthcare' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Medical Devices & IoT in Healthcare
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box healthcare-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/Healthcare-E-commerce-Online-Pharmacy.svg').default} className="img-fluid" alt='Healthcare E-commerce & Online Pharmacy' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Healthcare E-commerce & Online Pharmacy
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box healthcare-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/Mental-Health-Well-being.svg').default} className="img-fluid" alt='Mental Health & Well-being' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Mental Health & Well-being
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box healthcare-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/Nutrition-Diet-Management.svg').default} className="img-fluid" alt='Nutrition & Diet Management' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Nutrition & Diet Management
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>

                    <div className="experience-box healthcare-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}

                      <div className="experience-img">
                        <img src={require('../assets/images/Health-Data-Analytics-Predictive-Healthcare.svg').default} className="img-fluid" alt='Health Data Analytics & Predictive Healthcare' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Health Data Analytics & Predictive Healthcare
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box healthcare-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/Healthcare-Fintech-Insurance-Tech.svg').default} className="img-fluid" alt='Healthcare Fintech & Insurance Tech' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Healthcare Fintech & Insurance Tech
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box healthcare-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/On-Demand-Health-Medical-Services.svg').default} className="img-fluid" alt='On-Demand Health & Medical Services' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          On-Demand Health & Medical Services
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>


                    <div className="experience-box healthcare-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/EHR-Healthcare-Software-Solutions.svg').default} className="img-fluid" alt='EHR & Healthcare Software Solutions' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          EHR & Healthcare Software Solutions
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>

                    <div className="experience-box healthcare-box text-center mb-sm-20 flex-xl-fill mb-20">
                      {/* <Link to="/marketplace-apps" target='_blank' className='industry-box'> */}
                      <div className="experience-img">
                        <img src={require('../assets/images/Rehabilitation-Physical-Therapy-Tech.svg').default} className="img-fluid" alt='Rehabilitation & Physical Therapy Tech' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Rehabilitation & Physical Therapy Tech
                        </p>
                      </div>
                      {/* </Link> */}
                    </div>

                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* Our Case study start */}
          <div className='home-page-portfolio pt-80'>
            <CasestudySlider />
          </div>
          {/* Our Case study end */}
          {/* The Proof Is In The Numbers! */}
          <div className="proof-of-number character-effects pt-80">
            <div className="container">
              <div className="row pb-30">
                <div className="col-lg-6 align-self-center">
                  <div className="proof-text-main">
                    <div className="ss-heading proof-text">
                      <h2>The Proof Is In The </h2>
                      <div className="bg-hover">
                        <h2> <span>Numbers!</span></h2>
                        <p className="text-regular pt-20 pb-35">
                          When you consider our staff, our experience, our awards, our satisfied clients and much more… the answer is clear:
                          Working with Dedicated Developers is the ideal way to get the powerful, easy-to-use app you envision.
                        </p>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 align-self-center">
                  <div className="row justify-content-end">
                    <div className="col-lg-5 col-md-6 align-self-center">
                      <div className="features" onPointerMove={handlePointerMove}>
                        <div className="feature">
                          <div className="proof-box ">
                            <h2 className="ss-title">
                              3
                            </h2>
                            <p className="text-regular">
                              Global Offices
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="features" onPointerMove={handlePointerMove}>
                        <div className="feature">
                          <div className="proof-box">
                            <h2 className="ss-title">
                              40+
                            </h2>
                            <p className="text-regular">
                              Resources
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="features" onPointerMove={handlePointerMove}>
                        <div className="feature">
                          <div className="proof-box">
                            <h2 className="ss-title">
                              200+
                            </h2>
                            <p className="text-regular">
                              Clients
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5  col-md-6 align-self-center">
                      <div className="features" onPointerMove={handlePointerMove}>
                        <div className="feature">
                          <div className="proof-box">
                            <h2 className="ss-title">
                              565+
                            </h2>
                            <p className="text-regular">
                              Projects
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="features" onPointerMove={handlePointerMove}>
                        <div className="feature">
                          <div className="proof-box">
                            <h2 className="ss-title">
                              10+
                            </h2>
                            <p className="text-regular">
                              Years Of Experience
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="letter-effect">
                    <ul className="main_class1">
                      <li>
                        <h2>S</h2>
                      </li>
                      <li>
                        <h2>e</h2>
                      </li>
                      <li>
                        <h2>n</h2>
                      </li>
                      <li>
                        <h2>s</h2>
                      </li>
                      <li>
                        <h2>u</h2>
                      </li>
                      <li>
                        <h2>s</h2>
                      </li>
                      <li>
                        <h2>s</h2>
                      </li>
                      <li>
                        <h2>o</h2>
                      </li>
                      <li>
                        <h2>f</h2>
                      </li>
                      <li>
                        <h2>t</h2>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* The Proof Is In The Numbers! end */}
          {/* What Clients Say About Sensussoft start */}
          <TestimonialSlider />
          {/* What Clients Say About Sensussoft end */}
          <div className='brands_cmp ptb-80'>
            <div className='container'>
              <div className='row'>
                <div className='col-12 col-md-6 col-lg '>
                  <div className='brand_logo '>
                    <Link to="https://at.trustpilot.com/review/sensussoft.com" target='_blank'>
                      <div className='goodfirm-box'>
                        <img className='img-fluid' src={require('../assets/images/trustpilot.svg').default} alt='trustpilot'></img>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg'>
                  <div className='brand_logo '>
                    <Link to="https://www.goodfirms.co/company/sensussoft" target='_blank'>
                      <div className='goodfirm-box'>
                        <img className='img-fluid' src={require('../assets/images/goodfirms.svg').default} alt='goodfirms'></img>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg '>
                  <div className='brand_logo  '>
                    <Link to="https://clutch.co/profile/sensussoft-software-private#highlights" target='_blank'>
                      <div className='goodfirm-box'>
                        <img className='img-fluid' src={require('../assets/images/clutch.svg').default} alt='clutch'></img>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg '>
                  <div className='brand_logo  '>
                    <Link to="https://www.glassdoor.co.in/Overview/Working-at-Sensussoft-EI_IE2855986.11,21.htm" target='_blank'>
                      <div className='goodfirm-box'>
                        <img className='img-fluid' src={require('../assets/images/glassdoor.svg').default} alt='glassdoor'></img>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className='col-12 col-md-6 col-lg '>
                  <div className='brand_logo  '>
                    <Link to="https://www.google.com/search?q=sensussoft&rlz=1C1CHBF_enIN1081IN1081&oq=sensussoft+&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MggIARBFGCcYOzIGCAIQRRg8MgYIAxBFGEEyBggEEEUYPDIGCAUQRRhBMgYIBhBFGEEyBggHEEUYPdIBCDE4NDZqMGo3qAIIsAIB&sourceid=chrome&ie=UTF-8#lrd=0x3be04f3c44b264bb:0x6c07c74f4d575e34,1" target='_blank'>
                      <div className='goodfirm-box'>
                        <img className='img-fluid' src={require('../assets/images/google_rating.svg').default} alt='glassdoor'></img>
                      </div>
                    </Link>
                  </div>
                </div>


              </div>
            </div>
          </div>
          {/* <our process start> */}
          <div className="our_process_mainblog ptb-80">
            <div className="container">
              <div className="row pb-40">
                <div className="col-lg-12">
                  <div className="ss-heading">
                    <h2>Our Process For <span>Creating Software Solutions</span> Is <span>Agile, Flexible, And Tailored</span> To Your Needs.</h2>
                  </div>
                </div>
              </div>
              <Ourprocess />
            </div>
          </div>
          {/* <our process end> */}

          {/* Latest Blogs section */}
          <BlogSlider />
          {/* Latest Blogs section end*/}

          {/* shedule meeting modal */}
          <Modal size="lg"
            show={show}
            onHide={handleClose}

            keyboard={false} className="shedule-meeting-modal modal-common">
            <Modal.Header closeButton>
              <Modal.Title>
                <div className="modal-title-common">
                  <h4 className="lg-text-semibold">
                    Schedule Meeting
                  </h4>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="quote-form">
                <div className="calendly-inline-widget"  >
                  <iframe src="https://calendly.com/vinodkalathiya" title="Example Website"></iframe>
                </div>
              </div>
            </Modal.Body>

          </Modal>
          {/* shedule meeting modal end*/}
        </div>
      </main>
    </div>
  )
}

