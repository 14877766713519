


import React, { useEffect, useState, useRef } from 'react';


const Ourprocess = () => {
    const [activeItem, setActiveItem] = useState(0);

    const initialActiveItemRef = useRef(0); // Store the initial active item

    const handleClick = (index) => {
        setActiveItem(index);
    };

    useEffect(() => {
        //   setItems(data.items);

        // Set the initial active item on component mount
        setActiveItem(initialActiveItemRef.current);
    }, []);
    return (
        <>
            <div className='our-prrocess-blog'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className="custom-carousel">
                            <div data-step="1" className={`item ${activeItem === 0 ? 'active' : ''}`} onMouseEnter={() => handleClick(0)} style={{ backgroundImage: `url(${require('../assets/images/our-process/Conceive-your-ideas.jpg')})` }}>
                                <h4>Conceive your ideas</h4>
                                <div className="item-desc">
                                    <h3>Conceive your ideas</h3>
                                    <p>We begin the process by understanding what you need and want. We gather the business, technical requirements, challenges, market potential, and project vision. Our goal is to understand what problem the product will be solving and what end-users need. </p>
                                    <div className='line-croess'></div>
                                </div>
                            </div>
                            <div data-step="2" className={`item ${activeItem === 1 ? 'active' : ''}`} onMouseEnter={() => handleClick(1)} style={{ backgroundImage: `url(${require('../assets/images/our-process/Analyse-and-brainstorm.jpg')})` }}>
                                <h4>Analyse and brainstorm</h4>
                                <div className="item-desc">
                                    <h3>Analyse and brainstorm</h3>
                                    <p>We analyze requirements carefully. Our team develops use case diagrams to illustrate how the software will interact with users. We will illustrate every step in each interaction. We verify software objectives & use cases. These include guidelines, general requirements for software (Google Play Store, App Store, data protection, legal requirements, etc.), and more.   </p>
                                    <div className='line-croess'></div>
                                </div>
                            </div>
                            <div data-step="3" className={`item ${activeItem === 2 ? 'active' : ''}`} onMouseEnter={() => handleClick(2)} style={{ backgroundImage: `url(${require('../assets/images/our-process/Devise-a-wireframe.jpg')})` }}>
                                <h4>Devise a wireframe</h4>
                                <div className="item-desc">
                                    <h3>Devise a wireframe</h3>
                                    <p>We gather the full requirements and analyze them to create a visual design concept. We present a range of layouts and themes. To ensure that your project's usability matches your requirements, our UI/UX design process incorporates design patterns, dependencies, communications within the product, and data flows to the server.</p>
                                    <div className='line-croess'></div>

                                </div>
                            </div>
                            <div data-step="4" className={`item ${activeItem === 3 ? 'active' : ''}`} onMouseEnter={() => handleClick(3)} style={{ backgroundImage: `url(${require('../assets/images/our-process/Production-and-Project-initiation.jpg')})` }}>
                                <h4>Production and Project initiation</h4>
                                <div className="item-desc">
                                    <h3>Production and Project initiation</h3>
                                    <p>Using the latest tools and best practices, our tech experts create high-end solutions. Regardless of how diverse or complex your requirements are, we strive to streamline workflow and integrate functions into a seamless pattern. All of the work we do with you is aligned and coordinated regardless of whether we use Agile, Kanban, or another methodology. </p>
                                    <div className='line-croess'></div>
                                </div>
                            </div>
                            <div data-step="5" className={`item ${activeItem === 4 ? 'active' : ''}`} onMouseEnter={() => handleClick(4)} style={{ backgroundImage: `url(${require('../assets/images/our-process/Bug-Finding-&-Fixing.jpg')})` }}>
                                <h4>Bug Finding & Fixing</h4>
                                <div className="item-desc">

                                    <h3>Bug Finding & Fixing</h3>
                                    <p>We integrate our QA team into the development process when it begins. From day one, your app starts to get the attention it deserves. To ensure quality, we use the best quality assurance tools for acceptance testing (E2E testing). We assure you that the performance, security, and stability requirements of your software solution are met.</p>
                                    <div className='line-croess'></div>
                                </div>
                            </div>
                            <div data-step="6" className={`item ${activeItem === 5 ? 'active' : ''}`} onMouseEnter={() => handleClick(5)} style={{ backgroundImage: `url(${require('../assets/images/our-process/Product-launch.jpg')})` }}>
                                <h4>Product launch</h4>
                                <div className="item-desc">
                                    <h3>Product launch</h3>
                                    <p>Get! Set! Go! Launch your application and watch users flock to it. Let your users enjoy the best mobile experience. We deploy the developed solution in a live environment. [i.e. Apple App Store, Google Play Store.] Integration of meaningful processes (staging, CI/CD, Git Hub Branching, Automated E2E tests, etc.) renders the process more sustainable. </p>
                                    <div className='line-croess'></div>
                                </div>
                            </div>
                            <div data-step="7" className={`item ${activeItem === 6 ? 'active' : ''}`} onMouseEnter={() => handleClick(6)} style={{ backgroundImage: `url(${require('../assets/images/our-process/After-project-support-and-maintenance.jpg')})` }}>
                                <h4>After project support and maintenance</h4>
                                <div className="item-desc">
                                    <h3>After project support and maintenance</h3>
                                    <p>We're never far apart. We provide warranty and maintenance support after deployment. You can also rely on our team for assistance with common problems, whether it is cloud configuration or application review questions. Our 360° IT Support services include monitoring, bug fixing, consulting, ideation, and feature enhancement. </p>
                                    <div className='line-croess'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>

    );
};

export default Ourprocess;
