// import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Technologies from './pages/Technologies';
import Career from './pages/Career';
import Blog from './pages/Blog';
import Footer from './components/Footer';
import Services from './pages/Services';
import ProductIdeation from './pages/services/ProductIdeation';
import WebAppDevelopment from './pages/services/WebAppDevelopment';
import MobileAppDevelopment from './pages/services/MobileAppDevelopment';
import UiUxDesign from './pages/services/UiUxDesign';
import QualityAssurance from './pages/services/QualityAssurance';
import Maintenance from './pages/services/Maintenance';
import Casestudy from './pages/Casestudy';
import ScrollToTop from './components/ScrollToTop';
import Team from './pages/Team';
import Culture from './pages/Culture';
import Mettinglandscape from './pages/Mettinglandscape';
import ContactUs from './pages/ContactUs';
import Cultureheader from './components/Cultureheader';
import React, { useState, useEffect, useRef } from 'react';
// import { Col, Row } from 'react-bootstrap'
import { HelmetProvider } from 'react-helmet-async';
import ScrollTop from './components/ScrollTop';
import Testimonials from './pages/Testimonials';
import Blockchaindevelopment from './pages/services/Blockchaindevelopment';
import Marketplaceapps from './pages/services/Marketplaceapps';
import AlMldevelopment from './pages/services/AlMldevelopment';
// import Newsletter from './pages/Newsletter';
import BlogDetails from './pages/blog-details-pages/BlogDetails';
import Careerdetails from './pages/career-inside/Careerdetails';
import Casestudydetails from './pages/portfolio-details-page/Casestudydetails';
import PageNotFound from './pages/PageNotFound';
import HireAngularJSDevelopers from './pages/hire-devloper/HireAngularJSDevelopers';
import HireAndroidDevelopers from './pages/hire-devloper/HireAndroidDevelopers';
import HireCodeigniterDevelopers from './pages/hire-devloper/HireCodeigniterDevelopers';
import HireDotNetDevelopers from './pages/hire-devloper/HireDotNetDevelopers';
import HireFlutterDevelopers from './pages/hire-devloper/HireFlutterDevelopers ';
import HireiOSDevelopers from './pages/hire-devloper/HireiOSDevelopers';
import HireLaravelDevelopers from './pages/hire-devloper/HireLaravelDevelopers';
import HireMagentoDevelopers from './pages/hire-devloper/HireMagentoDevelopers';
import HireNextJSDevelopers from './pages/hire-devloper/HireNextJSDevelopers';
import HireNodeJSDevelopers from './pages/hire-devloper/HireNodeJSDevelopers';
import HirePHPDevelopers from './pages/hire-devloper/HirePHPDevelopers';
import HireReactJSDevelopers from './pages/hire-devloper/HireReactJSDevelopers';
import HireShopifyDevelopers from './pages/hire-devloper/HireShopifyDevelopers ';
import HireVueJSDevelopers from './pages/hire-devloper/HireVueJSDevelopers';
import HireWordPressDevelopers from './pages/hire-devloper/HireWordPressDevelopers';
import HireXamarinDevelopers from './pages/hire-devloper/HireXamarinDevelopers';
import HireUIUXDesigner from './pages/hire-devloper/HireUIUXDesigner';
import PrivacyPolicy from './pages/PrivacyPolicy';
import HireResources from './pages/HireResources';
import Newsletters from './pages/Newsletters';
import Cookies from './pages/Cookies';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {

    const [showBanner, setShowBanner] = useState(false);
    const [activeHeader, setActiveHeader] = useState(null);

    useEffect(() => {
        // const isBannerShown = sessionStorage.getItem('showBanner');
        // console.log("------------", isBannerShown);
        // setShowBanner(isBannerShown);
        // console.log("++++++++++++", showBanner);


        const storedActiveHeader = sessionStorage.getItem('activeHeader');
        setActiveHeader(storedActiveHeader === 'header' ? 'cultureheader' : 'header');
    }, []);

    const handleBannerClick = (side) => {
        setActiveHeader(side === 'left' ? 'cultureheader' : 'header');
        if (showBanner === null || showBanner) {
            setShowBanner(false);
            sessionStorage.setItem('showBanner', false);
        }
        sessionStorage.setItem('activeHeader', side === 'left' ? 'cultureheader' : 'header');

    };


    useEffect(() => {
        const isWorkRoute = (pathname) => {
            // Define a pattern that matches work-related routes
            // const workRoutePattern = /^\/(aboutus|what-we-do|technologies|our-services|testimonials|blog|casestudy|web-application-development|product-ideation-service|mobile-app-development|ui-ux-design|quality-assurance|maintanancesupport|blockchain-development|ai-ml-development|Marketplace-apps|casestudydetails\/[A-Za-z0-9-=]+|blog\/[a-zA-Z0-9-=]+)$/i;
            const workRoutePattern = /^\/(aboutus|whatwedo|technologies|our-services|testimonials|blog|casestudy|webapplicationdevelopment|productideationservice|mobileappdevelopment|uiuxdesign|qualityassurance|maintenancesupport|blockchaindevelopment|aimldevelopment|marketplaceapps|casestudydetails\/[A-Za-z0-9-]+|blog\/[A-Za-z0-9-]+)$/i;


            return workRoutePattern.test(pathname);
        };

        const isCultureRoute = (pathname) => {
            // Define a pattern that matches culture-related routes
            // const cultureRoutePattern = /^\/(careerdetails\/[A-Za-z0-9-=]+|team|culture|metting-landscape|contact-us|career|newsletter)$/i;
            const cultureRoutePattern = /^\/(careerdetails\/[A-Za-z0-9-=]+|team|culture|metting-landscape|contact-us|career|newsletter)\/?$/i;


            return cultureRoutePattern.test(pathname);
        };

        const pathname = window.location.pathname;

        if (isWorkRoute(pathname)) {
            if (showBanner === null || showBanner) {
                setActiveHeader('header');
                setShowBanner(false);
                sessionStorage.setItem('showBanner', false);
                sessionStorage.setItem('activeHeader', 'header');
            }
        } else if (isCultureRoute(pathname)) {
            if (showBanner === null || showBanner) {
                setActiveHeader('cultureheader');
                setShowBanner(false);
                sessionStorage.setItem('showBanner', false);
                sessionStorage.setItem('activeHeader', 'cultureheader');
            }
        }
    }, [showBanner]);




    return (
        <>
            <HelmetProvider>
                <BrowserRouter>
                    <Cookies></Cookies>
                    <ScrollToTop />
                    {/* <Banner/> */}
                    {/* <Banner onClick={handleBannerClick} /> */}
                    <div className="App">

                        {showBanner ? (
                            <Banner onClick={handleBannerClick} setShowBanner={setShowBanner} setActiveHeader={setActiveHeader} />
                        ) : (
                            <>

                                {activeHeader === 'header' && <Header setShowBanner={setShowBanner} />}
                                {activeHeader === 'cultureheader' && <Cultureheader setShowBanner={setShowBanner} />}
                                <ScrollTop></ScrollTop>
                                <Routes>
                                    {/* comman page  */}
                                    <Route path="/what-we-do" element={<Home />} />
                                    <Route path="/" element={<Home />} />
                                    {/* <Route path="/404" element={<PageNotFound />} /> */}
                                    <Route path="/cultureheader" element={< Cultureheader />} />
                                    <Route path="/about-us" element={<AboutUs />} />
                                    <Route path="/technologies" element={<Technologies />} />
                                    <Route path="/careers" element={<Career />} />
                                    <Route path="/blog" element={<Blog />} ></Route>
                                    <Route path="/our-services" element={<Services />} />
                                    <Route path="/testimonials" element={<Testimonials />} />
                                    <Route exact path='/case-study' element={<Casestudy />} />
                                    <Route path='/casestudydetails/:app_url' element={<Casestudydetails />} />
                                    <Route path='/hire-resources' element={<HireResources />} />
                                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                                    {/* <Route path='/privacy' element={<Privacy />} /> */}
                                    {/* <Route exact path='/blog/:slug' element={<Blogsubdetails />} /> */}

                                    {/* services  */}
                                    <Route path="/web-application-development" element={<WebAppDevelopment />} />
                                    <Route path="/product-ideation-services" element={<ProductIdeation />} />
                                    <Route path="/mobile-app-development" element={<MobileAppDevelopment />} />
                                    <Route path="/ui-ux-design" element={<UiUxDesign />} />
                                    <Route path="/quality-assurance" element={<QualityAssurance />} />
                                    <Route path="/maintenance-support" element={<Maintenance />} />
                                    <Route path="/blockchain-development" element={<Blockchaindevelopment />} />
                                    <Route path="/ai-ml-development" element={<AlMldevelopment />} />
                                    <Route path="/marketplace-apps" element={<Marketplaceapps />} />

                                    {/* our culture */}
                                    <Route path='/culture' element={<Culture />} />
                                    <Route path='/meeting-landscape' element={<Mettinglandscape />} />
                                    <Route path='/get-in-touch-with-us' element={<ContactUs />} />
                                    <Route path='/blog/:slug' element={<BlogDetails />} />
                                    <Route path='/careerdetails/:department_id' element={<Careerdetails />} />
                                    <Route exact path='/newsletter' element={<Newsletters />} />
                                    <Route path='/team' element={<Team />} />
                                    {/* <Route path='/career2' element={<CareerDetail/>} /> */}

                                    {/* Hire-Developers */}
                                    <Route path='/hire-angularjs-resources' element={<HireAngularJSDevelopers />} />
                                    <Route path='/hire-android-resources' element={<HireAndroidDevelopers />} />
                                    <Route path='/hire-codeigniter-resources' element={<HireCodeigniterDevelopers />} />
                                    <Route path='/hire-dotnet-resources' element={<HireDotNetDevelopers />} />
                                    <Route path='/hire-flutter-resources' element={<HireFlutterDevelopers />} />
                                    <Route path='/hire-ios-resources' element={<HireiOSDevelopers />} />
                                    <Route path='/hire-laravel-resources' element={<HireLaravelDevelopers />} />
                                    <Route path='/hire-magento-resources' element={<HireMagentoDevelopers />} />
                                    <Route path='/hire-nextjs-resources' element={<HireNextJSDevelopers />} />
                                    <Route path='/hire-nodejs-resources' element={<HireNodeJSDevelopers />} />
                                    <Route path='/hire-php-resources' element={<HirePHPDevelopers />} />
                                    <Route path='/hire-reactjs-resources' element={<HireReactJSDevelopers />} />
                                    <Route path='/hire-shopify-resources' element={<HireShopifyDevelopers />} />
                                    <Route path='/hire-vuejs-resources' element={<HireVueJSDevelopers />} />
                                    <Route path='/hire-wordpress-resources' element={<HireWordPressDevelopers />} />
                                    <Route path='/hire-xamrin-resources' element={<HireXamarinDevelopers />} />
                                    <Route path='/hire-ui-ux-designer' element={<HireUIUXDesigner />} />
                                    {/* <Route path="*" element={<Navigate to="/404" <PageNotFound />/>} /> */}
                                    <Route path="*" element={<PageNotFound />} />

                                </Routes>
                                <Footer />
                            </>
                        )}
                    </div>

                </BrowserRouter>
            </HelmetProvider >
        </>

    );
}

const Banner = (props) => {

    const [activeItem, setActiveItem] = useState(0);
    const initialActiveItemRef = useRef(0); // Store the initial active item
    // const handleClick = (index) => {
    //     setActiveItem(index);
    //     props.setActiveHeader(index === 0 ? 'cultureheader' : 'header')
    //     sessionStorage.setItem('activeHeader', index === 0 ? 'cultureheader' : 'header');
    // };
    useEffect(() => {
        setActiveItem(initialActiveItemRef.current);
    }, []);

    return (
        <>

            {activeItem === 0 ? (
                <Header setShowBanner={props.setShowBanner} />
            ) : activeItem === 1 ? (
                <Cultureheader setShowBanner={props.setShowBanner} />
            ) : null}
            <>
                <Home />
                <Footer />
            </>

        </>
    );
};

export default App;
