const Hirededicationtecdata = [
    {
        id: 1,
        tecLink: "/hire-angularjs-resources",
        tecname: "Hire AngularJS Resources",
        tectype: "Frontend Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-1.svg`).default
    },
    {
        id: 2,
        tecLink: "/hire-reactjs-resources",
        tecname: "Hire ReactJS Resources",
        tectype: "Frontend Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-2.svg`).default
    },
    {
        id: 3,
        tecLink: "/hire-vuejs-resources",
        tecname: "Hire VueJS Resources",
        tectype: "Frontend Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-3.svg`).default
    },
    {
        id: 4,
        tecLink: "/hire-nextjs-resources",
        tecname: "Hire NextJS Resources",
        tectype: "Frontend Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-4.svg`).default
    },
    {
        id: 5,
        tecLink: "/hire-nodejs-resources",
        tecname: "Hire NodeJS Resources",
        tectype: "Backend Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-5.svg`).default
    },
    {
        id: 6,
        tecLink: "/hire-php-resources",
        tecname: "Hire PHP Resources",
        tectype: "Backend Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-6.svg`).default
    },
    {
        id: 7,
        tecLink: "/hire-laravel-resources",
        tecname: "Hire Laravel Resources",
        tectype: "Backend Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-7.svg`).default
    },
    {
        id: 8,
        tecLink: "/hire-shopify-resources",
        tecname: "Hire Shopify Resources",
        tectype: "CMS Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-8.svg`).default
    },
    {
        id: 9,
        tecLink: "/hire-android-resources",
        tecname: "Hire Android Resources",
        tectype: "Mobile Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-9.svg`).default
    },
    {
        id: 10,
        tecLink: "/hire-ios-resources",
        tecname: "Hire IOS Resources",
        tectype: "Mobile Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-10.svg`).default
    },
    {
        id: 11,
        tecLink: "/hire-flutter-resources",
        tecname: "Hire Flutter Resources",
        tectype: "Mobile Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-11.svg`).default
    },
    {
        id: 12,
        tecLink: "/hire-xamrin-resources",
        tecname: "Hire Xamrin Resources",
        tectype: "Mobile Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-12.svg`).default
    },
    {
        id: 13,
        tecLink: "/hire-ui-ux-designer",
        tecname: "Hire UI/UX Designer",
        tectype: "Designer Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-13.svg`).default
    },
    {
        id: 14,
        tecLink: "/hire-wordpress-resources",
        tecname: "Hire WordPress Resources",
        tectype: "CMS Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-14.svg`).default
    },
    {
        id: 15,
        tecLink: "/hire-codeigniter-resources",
        tecname: "Hire CodeIgniter Resources",
        tectype: "Backend Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-15.svg`).default
    },
    {
        id: 16,
        tecLink: "/hire-magento-resources",
        tecname: "Hire Magento Resources",
        tectype: "Backend Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-16.svg`).default
    },
    {
        id: 17,
        tecLink: "/hire-dotnet-resources",
        tecname: "Hire DotNet  Resources",
        tectype: "Backend Resource",
        tecicon: require(`../assets/images/hire-devloper/hiretec-17.svg`).default
    },
]

export default Hirededicationtecdata;