import React from 'react'
import { Col, Container, Row, Stack } from 'react-bootstrap'
// import Nav from 'react-bootstrap/Nav';
// import Tab from 'react-bootstrap/Tab';
import Portfolio from '../../components/Portfolio';
import { Link } from 'react-router-dom';
import Faq from '../../components/Faq';
import Accordion from 'react-bootstrap/Accordion';
import ScheduleCall from '../../components/ScheduleCall';
import { Helmet } from 'react-helmet-async';

export default function UiUxDesign() {
  return (
    <>
      <Helmet>
        <title>UI & UX Design: Crafting Memorable Digital Experiences</title>
        <meta name="title" content="UI & UX Design: Crafting Memorable Digital Experiences" />
        <meta name="keywords" content="UI & UX Design, User Interface Design, User Experience Design, Digital Experience Design, Sensussoft UI/UX Solutions" />

        <meta name="description" content="Elevate your app and website experiences with Sensussoft's UI/UX design services. From prototyping to responsive design, we'll help bring your ideas to life with engaging interactions and intuitive interfaces." />
      </Helmet>
      <main>
        <div className="ui-ux-main">
          {/* section 1 */}
          <div className="ui-ux-design website-development ptb-80 bg-logo">
            <Container >
              <Row className='pb-80 justify-content-end'>
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className=" service-btn-mobile  next-prev-btn comm-btn ">
                    <Link aria-label='productideation' to='/web-application-development'>
                      <button className="service-previous-btn learn-more arrow-btn " aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className="service-btn-mobile next-prev-btn comm-btn text-end ">
                    <Link aria-label='mobileappdevelopment' to='/quality-assurance'>
                      <button className="learn-more arrow-btn" aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="align-self-center">
                  <div className="website-development-text proof-text-main">
                    <div className="ss-heading proof-text">
                      <h1><span>UI & UX Design </span>
                      </h1>
                      <p className="text-regular mt-20">
                        Let's create an app experience your clients won't forget. Outer appearances may not matter all that much, but we believe user interfaces matter most. A User Experience creates the interaction architecture of the design elements, while a User Interface determines the appearance and feel of the mobile app and website. Our digital transformation process depends so much on UI/UX design.
                      </p>
                      <div className="comm-btn mt-20">
                        <Link to='/get-in-touch-with-us' aria-label='contact us for detail'>
                          <button className="learn-more btn-large " aria-label='contact us for detail'>
                            <span className="circle" aria-hidden="true">
                              <span className="icon arrow">
                                <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                              </span>
                            </span>
                            <span className="button-text">CONTACT US FOR DETAILS</span>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="align-self-center text-center">
                  <div className="change_vision_img"><img className="img-fluid" src={require('../../assets/images/serviceimg4.png')} alt='service1' /></div>

                  <div className="website-development-img-main">
                    <div className="website-development-img mt-sm-20 mt-md-20">
                      <img className="img-fluid" src={require('../../assets/images/ui-ux-bg.jpg')} alt="ui-ux-bg"></img>
                    </div>
                    <div className="web-build-box">
                      <div className="like-round">
                        <img className="img-fluid " src={require('../../assets/images/blub.svg').default} alt="blub">
                        </img>
                      </div>
                      <div className="build-text">
                        <h2>
                          500+
                        </h2>
                        <p className="text-regular">
                          UI & UX Design
                        </p>
                      </div>
                    </div>
                    <div className="web-build-box2">
                      <p>
                        Simplifying Complexity through <br />Thoughtful Design
                      </p>
                      <div className="line">
                        <img className="img-fluid" src={require('../../assets/images/linefill.png')} alt="line"></img><br />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 1 end*/}
          {/* section 2 */}
          <div className="solve-the-unsolved ptb-80">
            <Container>
              <Row>
                <Col lg={6}>
                  <div className="solve-img">
                    <img src={require('../../assets/images/solve.jpg')} className="img-fluid" alt='solve' />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      We Inherit The Desire To <br /><span>Solve The Unsolved.</span>
                    </h2>
                    <div className="cms-needed pt-30" >
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>

                        </div>
                        <div className="right-text">
                          <p className="light-heading">
                            Together we think big, innovate, design smartly, and develop quickly. Our team will help you transform your dream idea into an awesome solution.
                          </p>
                        </div>
                      </div>
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>

                        </div>
                        <div className="right-text ">
                          <p className="light-heading">
                            We will deliver our best efforts to transform your ideas and thoughts ingeniously into a clear and coherent design. We analyze both the users' behavior and the core features of the system extensively.
                          </p>
                        </div>
                      </div>
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>

                        </div>
                        <div className="right-text ">
                          <p className="light-heading">
                            We create clear digital roadmaps and user-centered experiences. Let us be your partner for planning, design, development, maintenance, and evolution. Our team is young, energetic, and excited to build products that are relevant and destined for success.
                          </p>
                        </div>
                      </div>

                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 2 end*/}

          {/* section 4 */}
          <div className="website-desiging ptb-80 ">
            <Container>
              <Row className="pb-80">
                <Col lg={6} className="align-self-center">
                  <div className="designing-content">
                    <div className="our-vision-text-main d-flex">
                      <div className="bd-highlight vision-img-main">
                        <div className="our-vision-img overview-img">
                          <img className="img-fluid" src={require('../../assets/images/overview.svg').default} alt="vision-ic"></img>
                        </div>
                      </div>
                      <div className="our-vision-text">
                        <h2>
                          Designing
                        </h2>
                      </div>
                    </div>
                    <div className="ss-heading ss-portfolio-text">
                      <h2 className="ptb-15">
                        <span>Mobile App</span>  And <br /><span>Website Designing</span>
                      </h2>
                      <p className="text-regular">
                        Our team works together with clients to develop prototypes, websites, mobile apps, and user interfaces to provide a complete solution that will achieve the goal 100%. Our expertise gained from designing hundreds of apps and websites can help you refine your product or create a completely unique solution.

                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="align-self-center">
                  <div className="designing-image-main d-flex">
                    <div className="designing-img1">
                      <img src={require('../../assets/images/designing-img1.jpg')} className="img-fluid" alt='designing-img1' />
                    </div>
                    <div className="designing-img1 align-self-center">
                      <img src={require('../../assets/images/designing-img2.jpg')} className="img-fluid" alt='designing-img2' />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="">
                <Col lg={9} md={12}>
                  <div className="everything-is-responsive">
                    <div className="ss-heading ss-portfolio-text">
                      <h2 className="ptb-15">
                        Of course, <span>everything is responsive</span> to  screen-sizes. *
                      </h2>
                      <p className="text-regular">
                        We are your 360° Software Development Partner- promise to meet your customers' expectations and engage them through relevant interactions. We develop clickable prototypes that show what your product is capable of and allow us to test it with real users. We will help you touch your big ideas and transform them into reality.
                      </p>
                      <div className="comm-btn mt-20">
                        <Link to='/case-study' aria-label='see our case study'>
                          <button className="learn-more btn-large arrow-btn-secondary2" aria-label='see our case study'>
                            <span className="circle" aria-hidden="true">
                              <span className="icon arrow">
                                <img src={require('../../assets/images/btn-arrow3.svg').default} className="img-fluid" alt='img' />
                              </span>
                            </span>
                            <span className="button-text">SEE OUR CASE STUDY</span>
                          </button>
                        </Link>
                      </div>

                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 4 end*/}
          {/* section 5 */}
          <div className="attractive-main ptb-120">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="attractive-box">
                    <Stack direction="horizontal" className="d-mobile-block">
                      <div className="attractive-text flex-grow-1">
                        <h3 className="ss-title">
                          Design <span className="text-regular">Is The Heart Of Every <br /> Software Product,</span> do it <span className="text-regular">right with us,</span>
                        </h3>
                      </div>
                      <div className="attractive-btn">
                        <ScheduleCall></ScheduleCall>
                      </div>
                    </Stack>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 5 end */}
          {/* section 6 */}
          <div className="services-portfolio-main ptb-80">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      <span>Our Portfolio</span>
                    </h2>

                  </div>
                </Col>
              </Row>
            </Container>
            <Portfolio></Portfolio>
          </div>
          {/* section 6 end*/}
          {/* section 7 */}
          <div className="ss-faq-main">
            <div className="faq-main pt-120   ">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="faq-title proof-text-main text-center mb-30">
                      <div className="ss-heading proof-text">
                        <h2><span>Frequently Asked<br />
                          Questions </span></h2>
                      </div>
                    </div>

                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <div className="faq-accordion">
                      <Accordion defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Is UI/UX design important?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Yes, design is most significant whether it is a mobile app or website. UI/UX Design plays a crucial role in achieving app goals. UI/UX Design of an application enhances the user's experience and customer satisfaction, which in turn helps increase the number of users of the app or website. In other words, it aims to understand and map how the user navigates through a product cognitively. User Interface refers to the visual and interactive aspects of a product. It pertains to the look and feel of the product, its layout, and its accessibility.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>What is the cost of designing an app?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Without digging into your project specifications, it is impossible to tell the exact cost of app design. If your app has a lot of features, the production costs will be higher. Additionally, the price varies based on the number of iterations, A/B testing, and scope of the project. Drop us a line with your app idea and we will provide a precise estimate.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>I Have An Idea And Would Like To Create A Project From Scratch. How Do I Get Started?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                We have extensive experience working with startups and developing software from scratch. We identify the user problems and research how we can solve them with a software solution. We will ask you to describe the solution requirements, identify your business goals, and determine your target audience. Afterward, we will create a user journey map and provide you with wireframes, mockups, and prototypes to validate the concept.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>I have a running project, but I am not pleased with the design. Could you help me?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Yes, we can help by running a design audit on your project. We provide you with matchable solutions once we know your business goals, your audience, and their behavior.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <Faq></Faq>
          </div>
          {/* section 7 end*/}

        </div>
      </main>
    </>
  )
}
