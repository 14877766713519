import { Col, Container, Row, Stack } from 'react-bootstrap'
import Portfolio from '../../components/Portfolio';
import Faq from '../../components/Faq';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import React from 'react';
import ScheduleCall from '../../components/ScheduleCall';
import { Helmet } from 'react-helmet-async';

export default function ProductIdeation() {

  // Slider settings

  return (
    <>
      <Helmet>
        <title>Product Ideation Services | Inspiring Product Innovation</title>
        <meta name="title" content="Product Ideation Services | Inspiring Product Innovation" />
        <meta name="keywords" content="Product Ideation Services, Product Innovation, Product Design, Sensussoft Product Services" />
        <meta name="description" content="Discover how our strategic ideation services can enhance your product ideas. Our experienced team collaborates with you to identify, understand, and execute your vision, delivering fully customized software solutions tailored to your needs. Unlock innovation today!" />
      </Helmet>
      <main>
        <div className="Product-ideation-service">
          {/* section 1 */}
          <div className="Product-ideation-section  website-development ptb-80 bg-logo">
            <Container >
              <Row className='pb-80 justify-content-end'>
                {/* <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className=" service-btn-mobile  next-prev-btn comm-btn ">
                    <Link aria-label='casestudydetail'>
                      <button className="service-previous-btn learn-more arrow-btn " aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col> */}
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className="service-btn-mobile next-prev-btn comm-btn text-end ">
                    <Link aria-label='productideation' to='/mobile-app-development'>
                      <button className="learn-more arrow-btn" aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="align-self-center">
                  <div className="website-development-text proof-text-main">
                    <div className="ss-heading proof-text">
                      <h1><span>Product Ideation </span><br /> Services</h1>
                      <p className="text-regular mt-20">
                        We enjoy brainstorming about what the idea for a product is, who it is for, how it should work, and why. We don't force multi-step ideation on our clients because everyone has a different background, worldview, and value system. By leveraging our years of experience, we can provide a quick and effective solution.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="align-self-center text-center">
                  <div className="change_vision_img"><img className="img-fluid" src={require('../../assets/images/serviceimg1.png')} alt='service1' /></div>
                  <div className="website-development-img-main">

                    <div className="website-development-img mt-sm-20 mt-md-20">
                      <img className="img-fluid" src={require('../../assets/images/product-ideation.jpg')} alt="product-ideation"></img>
                    </div>
                    <div className="web-build-box">
                      <div className="like-round">
                        <img className="img-fluid " src={require('../../assets/images/blub.svg').default} alt="blub">
                        </img>
                      </div>
                      <div className="build-text">
                        <h2>
                          50+
                        </h2>
                        <p className="text-regular">
                          Product Ideation <br /> Services
                        </p>
                      </div>
                    </div>
                    <div className="web-build-box2">
                      <p>
                        Inspiring product innovation through <br /> strategic ideation.
                      </p>
                      <div className="line">
                        <img className="img-fluid" src={require('../../assets/images/linefill.png')} alt="line"></img><br />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 1  end*/}
          {/* section 2 */}
          <div className="idea-and-enhance bg-service ">
            <Container>

              <Row>
                <Col lg={6}>
                  <div className="ideation-services">
                    <div className="services-in-action-main mb-md-20">
                      <div className="services-in-action pt-100 pb-200">
                        <div className="our-vision-text-main d-flex">
                          <div className="bd-highlight vision-img-main">
                            <div className="our-vision-img overview-img">
                              <img className="img-fluid" src={require('../../assets/images/overview.svg').default} alt="vision-ic"></img>
                            </div>
                          </div>

                          <div className="our-vision-text">
                            <p>
                              Service Overview
                            </p>
                          </div>
                        </div>
                        <div className="ss-heading ss-portfolio-text">
                          <h2 className="ptb-15">
                            See <span>Product  <br /> Ideation Services  </span> <br />in action.
                          </h2>
                          <div className="comm-btn ">
                            <Link to='/get-in-touch-with-us' aria-label='contact us for detail'>
                              <button className="learn-more btn-large arrow-btn-secondary2" arial-label="contact us for detail">
                                <span className="circle" aria-hidden="true">
                                  <span className="icon arrow">
                                    <img src={require('../../assets/images/btn-arrow3.svg').default} className="img-fluid" alt='img' />
                                  </span>
                                </span>
                                <span className="button-text">CONTACT US FOR DETAILS</span>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={12} className='align-self-center'>
                  <div className="ideation-content ptb-100">
                    <div className="enhance-content-main" >
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          How Do We Collect Your <span className="lg-text-semibold">Idea And Enhance It?</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          An idea has a purpose; however, the most important thing is how to identify, understand, and execute it. Identify the right team to execute your idea, and how that idea will solve your user's problem.
                          <br></br>
                          <br />
                          'We aren't just executors who fulfill requirements. With a team of talented engineers, we solve highly complex problems with well-founded, high-end solutions that meet operational, economic and business requirements.
                        </p>
                      </div>
                    </div>
                    <div className="enhance-content-main ">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          What  <span className="lg-text-semibold">Software</span> Is Right For You?
                        </h3>
                        <p className="pt-20 light-heading">
                          What is the best software to use? How does it fit your needs?
                          <br /> <br />
                          If you need a hammer, it's pretty easy to find a nice one. However, the software is more subjective: the best tool for you may not be the best for someone else. You want to use the best software for your work—everyone does. We know you can’t afford to make a mistake, so we deliver fully customize software solutions to make your work more efficient and more fun.  </p>
                        <div className="sotfware-info pt-40">
                          <Row>
                            <Col lg={6}>
                              <div className="application-title">
                                <h4 className="pb-25">
                                  Web Application
                                </h4>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      One page website
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Fully customized website
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="application-title">
                                <h4 className="pb-25">
                                  A Mobile Application
                                </h4>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Responsive MVP
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Custom mobile APP
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Enterprise APP
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                    </div>
                    <div className="enhance-content-main ">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          How Do We   <span className="lg-text-semibold">Map Your Idea? </span>
                        </h3>
                        <p className="pt-20 light-heading">
                          We believe that a lot of thought and creativity must go into creating an overall technical design/architecture before even writing the first line of code.
                          <br /><br />
                          Developing software is the art of transferring manual processes of problems or ideas into a technically and visually designed solution that runs stably, meets security standards, is easily upgraded and maintained, and complies with technology standards.<br />
                          We develop a project canvas in collaboration with our clients, and all members of the team must fully understand the problem and goal 100%. The conclusion should be as technically and theoretically defined as possible to avoid confusion about logic and dependences.
                        </p>
                      </div>
                    </div>

                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 5 */}
          <div className="services-portfolio-main ptb-80">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      <span>Our Portfolio</span>
                    </h2>

                  </div>
                </Col>
              </Row>
            </Container>
            <Portfolio></Portfolio>
          </div>
          {/* section 5 end*/}
          {/* section 6 */}
          <div className="attractive-main ptb-120">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="attractive-box">
                    <Stack direction="horizontal" className="d-mobile-block">
                      <div className="attractive-text flex-grow-1">
                        <h3 className="ss-title">
                          <span className="text-regular">Get a</span> free consultation  <span className="text-regular">call from <br />our expert to kickstart your </span>dream <br />project
                        </h3>
                      </div>
                      <div className="attractive-btn">
                        <ScheduleCall></ScheduleCall>
                      </div>
                    </Stack>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 6 end*/}
          {/* section 7 */}
          <div className="ss-faq-main">
            <div className="faq-main pt-120   ">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="faq-title proof-text-main text-center mb-30">
                      <div className="ss-heading proof-text">
                        <h2><span>Frequently Asked<br />
                          Questions </span></h2>
                      </div>
                    </div>

                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <div className="faq-accordion">
                      <Accordion defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>I have an idea, but I don't know where to begin</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Well, then you are at the right place. Chill, it's okay if you're not aware. We analyze your website and app ideas to plan the mobile app and website journey of your business based on our unique strategic consulting services. We make your website or app more business-centric by considering the needs of your target customers and the current market conditions.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Can you tell me more about your mobile app development services?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Yes definitely! Our mobile app development services include strategy consultation, documenting concepts, project management, UI/UX design, web and backend coding, testing, publishing apps on stores, and maintenance services. We make sure that our client's intellectual property is in safe hands.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>How long does it take to build a mobile app or website?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                There are several factors that determine how long it takes to develop software - the complexity of the software solution, logic, technology, platforms (mobile or web), and back-ends - APIs, databases, servers, etc. Initially, a concept is created and then resources, schedules, communication sprints, and support and maintenance are planned.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        {/* <Accordion.Item eventKey="3">
                    <Accordion.Header>Eu egestas sed sed posuere ultrices ?</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                      minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                      aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item> */}
                      </Accordion>
                    </div>
                  </Col>
                </Row>



              </Container>
            </div>
            <Faq></Faq>
          </div>
          {/* section 7 end*/}
        </div>
      </main>
    </>
  )
}
