import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import HiringDedication from '../../components/HiringDedication';
import PricingHire from '../../components/PricingHire';
import HireDedicationContect from '../../components/HireDedicationContect';
import { Helmet } from 'react-helmet-async';
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function HireAngularJSDevelopers() {
  const scrollToSection = () => {
    // Scroll to the section containing HireDedicationContect
    const section = document.getElementById('hireDedicationSection');
    if (section) {
      const topOffset = section.offsetTop - 200;
      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
  };
  const CustomArrow = ({ direction, onClick }) => {
    const icon = direction === 'next' ? faChevronRight : faChevronLeft;

    return (
      <div
        className={`custom-arrow ${direction}`}
        style={{ zIndex: '1', display: 'block' }}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  };

  const hireresource = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <Helmet>
        <title>	Hire Dedicated Angular Developers | Sensussoft</title>
        <meta name="title" content="Hire Dedicated Angular Developers | Sensussoft" />
        <meta name="keywords" content="Hire Angular Developers, Dedicated Angular Developers, Angular Development Services, Angular Development Company, Angular Experts, Angular Development Solutions, Sensussoft Angular Developers" />
        <meta name="description" content="Explore top-notch Angular development services for e-commerce, healthcare & education sectors. Custom solutions, API integration, maintenance & support."></meta>
      </Helmet>
      <main>
        <div className='hiring_dedication_section'>
          <section>
            <div className="mobile-app-development website-development ptb-80 bg-logo">
              <Container >
                <Row>
                  <Col lg={6} className="align-self-center">
                    <div className="website-development-text proof-text-main">
                      <div className="ss-heading proof-text">
                        <h1><span>Angular Development </span> <br />
                          Company </h1>
                        <p className="text-regular mt-20">
                          Delivering compelling, on-the-go mobile experiences across a variety of platforms. We have dedicated teams for native iOS, native Android, Cross-Platform, and Flutter development to support our partners' mobile app initiatives. We are a 360° Software Development Partner providing custom mobile app development services, so you can blow your users' minds.
                        </p>
                      </div>

                      <button className="learn-more btn-large mt-25" onClick={scrollToSection} aria-label='contact us for detail'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                        <span className="button-text">CONTACT US FOR DETAILS</span>
                      </button>

                    </div>
                  </Col>
                  <Col lg={6} className="align-self-center text-center">
                    <div className="change_vision_img"><img className="img-fluid" src={require('../../assets/images/hire-devloper/angular_img1.png')} alt='service1' /></div>

                    <div className="website-development-img-main">

                      <div className="website-development-img mt-sm-20 mt-md-20">
                        <img className="img-fluid" src={require('../../assets/images/hire-devloper/angular_img1.png')} alt="mobile-app-development"></img>
                      </div>

                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <div className="idea-and-enhance bg-service ">
              <Container>
                <Row>
                  <Col lg={6}>
                    <div className="ideation-services">
                      <div className="services-in-action-main mb-md-20">
                        <div className="services-in-action pt-100 pb-200">
                          <div className="our-vision-text-main d-flex">
                            <div className="bd-highlight vision-img-main">
                              <div className="our-vision-img overview-img">
                                <img className="img-fluid" src={require('../../assets/images/overview.svg').default} alt="vision-ic"></img>
                              </div>
                            </div>
                            <div className="our-vision-text">
                              <p>
                                Angular Development Services
                              </p>
                            </div>
                          </div>
                          <div className="ss-heading ss-portfolio-text">
                            <h2 className="ptb-15">
                              {/* See <span>Product  <br /> Ideation Services  </span> <br />in action. */}
                              Our AngularJS development harnesses mobile app innovation for scalable, secure applications, translating your business goals into robust technical solutions.
                            </h2>

                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={12} className='align-self-center'>
                    <div className="ideation-content ptb-100">
                      <div className="enhance-content-main " >
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">AngularJS Web Development</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Tailored solutions for unique business KPIs, ensuring extraordinary outcomes.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Plugin Development</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Enterprise-oriented Angular plugins for dynamic, data-driven applications.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Maintenance & Support</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Continuous, reliable support for native and cross-platform AngularJS applications.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">API Integration and Development</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Custom API solutions for seamless business operations and migrations.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Migration and Upgradation</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Expert upgrades and framework transitions, enhancing your AngularJS applications.
                          </p>
                        </div>
                      </div>

                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <div className="tecnology_plus_slider  testimonials ptb-80 mtb-80">
              <div className="container">
                <div className="col-lg-12">
                  <div className="testimonials-section">
                    <div className="ss-heading">
                      <h2>Most-Preferred<br /><span>Angular</span> Combinations We Cater</h2>
                    </div>
                    <div className="testimonials-box-main pt-35" >

                      <Slider {...hireresource} className='ss-testimonial'>
                        <div className='tecnology_hirededicate'>

                          <h3>Angular + .NET</h3>
                          <p>Achieve seamless integration with Angular frontend and robust .NET backend for dynamic web applications.</p>
                        </div>
                        <div className='tecnology_hirededicate'>

                          <h3>Angular + Node.js</h3>
                          <p>Experience agility and scalability by pairing Angular frontend with Node.js backend for modern web development.</p>
                        </div>
                        <div className='tecnology_hirededicate'>

                          <h3>Angular + AWS</h3>
                          <p>Unleash the power of AngularJS on AWS cloud infrastructure for flexible and scalable web solutions.</p>
                        </div>
                        <div className='tecnology_hirededicate'>

                          <h3>Angular + .PHP</h3>
                          <p>Enhance web applications with AngularJS frontend and PHP backend for dynamic and interactive user experiences.</p>
                        </div>
                        <div className='tecnology_hirededicate'>

                          <h3>Angular + Microsoft Azure</h3>
                          <p>Harness the potential of AngularJS on Microsoft Azure for secure, reliable, and scalable web solutions.</p>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <Container>
              <Row>
                <Col lg={12} md={12} className='align-self-center'>
                  <div className="ideation-content pb-80">
                    <div className="enhance-content-main ">
                      <div className="enhance-content">
                        <h3 className="text-regular text-center">
                          <span className="lg-text-semibold">Why Choose Angular Development Service From Sensussoft</span>
                        </h3>
                        <p className="pt-20 light-heading text-center">
                          Delivering client-centric web solutions that drive tangible results in the digital world.
                        </p>
                        <div className="sotfware-info pt-40">
                          <Row>
                            <Col lg={6}>
                              <div className="application-title">
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Advanced Development Approach
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Flexible Engagement Model
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      User-Oriented Ideology
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Comprehensive Services from Development to Hosting
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="application-title">
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Performance Optimization Guarantee
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Rigorous Security Compliance
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Continuous Support & Maintenance Post-Launch
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Ready-to-Launch Custom Solutions
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section>
            <div className="solve-the-unsolved">
              <Container>
                <Row>
                  <Col lg={6} className='align-self-center'>
                    <div className="solve-img text-center">
                      <img src={require('../../assets/images/hire-devloper/angular_img2.png')} className="img-fluid" alt='solve' />
                    </div>
                  </Col>
                  <Col lg={6} className='align-self-center'>
                    <div className="ss-heading ss-portfolio-text">
                      <h2>
                        Reasons to Opt for Our <br /><span> Dedicated Resources</span>
                      </h2>
                      <ul className='dedicated_resources'>
                        <li>Extensive pool of skilled professionals spanning various fields.</li>
                        <li>Customizable hiring models to suit your specific requirements.</li>
                        <li>Superior infrastructure and access to cutting-edge tools.</li>
                        <li>Adherence to non-disclosure agreements to ensure confidentiality.</li>
                        <li>Timely submission of projects, sticking to scheduled deadlines.</li>
                        <li>No hidden costs, ensuring project costs remain as estimated.</li>
                        <li>Fostering an open and communicative environment with developers.</li>
                        <li>Regular reports on project progression to keep you informed.</li>
                        <li>Continuous technical support and maintenance are available around the clock.</li>
                        <li>Money-back guarantee if the results fail to meet satisfaction.</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <PricingHire tecnology="AngularJs Developers" click={scrollToSection} />
          </section>
          <section>
            <div className='hiring_process_blog pt-80 pb-80' style={{ backgroundColor: '#F4F5F9' }}>
              <Container>
                <Row>
                  <Col lg="12">
                    <div className='hiring_heading'>
                      <div className="nurture-creativity-title pb-20">
                        <div className="our-vision-text-main d-flex">
                          <div className="bd-highlight vision-img-main">
                            <div className="our-vision-img">
                              <img className="img-fluid" src={require('../../assets/images/vision-ic.svg').default} alt="vision-ic"></img>
                            </div>
                          </div>
                          <div className="our-vision-text">
                            <h2>
                              Our Procedure
                            </h2>
                          </div>
                        </div>
                        <div className="our-vision-heading">
                          <h2 className="lg-text-light ptb-15">
                            Discover Your Future
                            <span> Join Us Today!</span>
                          </h2>
                          <p style={{ fontWeight: '300' }}>We have simple and smart procedures to get you the best team that gives the best result. To know more about how we build & maintain an exclusive offshore team for you, get in touch with us now.</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <slider of hiring process start  */}
                  <HiringDedication />
                  {/* <slider of hiring process end */}
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <div className="ss-faq-main mb-80">
              <div className="faq-main pt-80 pb-80">
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className="faq-title proof-text-main text-center mb-30">
                        <div className="ss-heading proof-text">
                          <h2><span>Frequently Asked<br />
                            Questions </span></h2>
                        </div>
                      </div>

                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      <div className="faq-accordion">
                        <Accordion defaultActiveKey="0" flush>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Why Choose Angular for Web Development?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Angular offers dynamic, responsive, and powerful web application capabilities ideal for modern web solutions.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>What's the Cost of Angular Web Application Development?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  The cost varies based on complexity and specific requirements. Contact us for a personalized quote.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>Project Duration for Angular Web Applications?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Timeframes depend on project scope but we aim for efficient delivery without compromising quality.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>Do You Offer Post-Development Support?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  No, we are your 360° software development partners. You can count on us, our team to make your current app compatible with the latest version of the iPhone.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>What Pricing Models Do You Offer?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  We have flexible pricing models including fixed, hourly, and project-based.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>

            </div>
          </section>
          <section id="hireDedicationSection">
            <HireDedicationContect />
          </section>
        </div>
      </main>
    </>
  )
}

export default HireAngularJSDevelopers
