import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";



const options = [
    { value: 'Mobile development', label: 'Mobile development' },
    { value: 'Ideation and planning', label: 'Ideation and planning' },
    { value: 'Website Development', label: 'Website Development' },
    { value: 'UI & UX Design', label: 'UI & UX Design' },
    { value: 'Quality Assurance', label: 'Quality Assurance' },
    { value: 'Maintenance and support', label: 'Maintenance and support' },
];

const option2 = [
    { value: '<$5k', label: '<$5k' },
    { value: '$10k', label: '$10k' },
    { value: '$25k', label: '$25k' },
    { value: '$50k', label: '$50k' },
    { value: '$100k', label: '$100k' },
    { value: '>$100k', label: '>$100k' },
    { value: 'Not Sure', label: 'Not Sure' },
];

const option3 = [
    { value: 'Right Now', label: 'Right Now' },
    { value: 'In Few Weeks', label: 'In Few Weeks' },
    { value: 'In Few Months', label: 'In Few Months' },
    { value: 'Not Sure', label: 'Not Sure' },
];

const ContactUs = (props) => {
    const [firstnm, setFirstNm] = useState('');
    const [last_name, setLastNm] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [service, setService] = useState(null);
    const [budget, setBudget] = useState(null);
    const [timeline, setTimeline] = useState(null);
    const [message, setMessage] = useState('');
    const [attach_file, setAttachFile] = useState(null);
    const [fileName, setFileName] = useState('Drag & Drop files here or Browse Files');
    const [errorMessage, setErrorMessage] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [serviceValidated, setServiceValidated] = useState(false);
    const [budgetValidated, setBudgetValidated] = useState(false);
    const [timelineValidated, setTimelineValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const handlePhoneChange = (phone, country) => {
        if (typeof phone === 'string' && phone && typeof country.dialCode === 'string') {
            const reducedPhone = phone.replace(country.dialCode, '').trim();
            setMobile(country.dialCode + ' ' + reducedPhone);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size <= 10485760) { // 10MB limit (10 * 1024 * 1024)
                setFileName(file.name);
                setErrorMessage('');
                setAttachFile(file);
            } else {
                setFileName('Attach File');
                setErrorMessage('File size exceeds the limit of 10MB.');
                setAttachFile(null);
            }
        }
    };


    const [isValid, setIsValid] = useState(true);

    const handleChange = (event) => {
        const inputValue = event.target.value;
        setEmail(inputValue);
        validateEmail(inputValue);
    };
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValid(emailRegex.test(email));
    };

    const [validated, setValidated] = useState(false);
    // const [showErrors, setShowErrors] = useState(false);


    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        // Check if all required fields are filled
        const isFormComplete = firstnm && last_name && email && mobile && service && budget && timeline && message;

        if (!service) {
            setServiceValidated(true);
        } else {
            setServiceValidated(false);
        }

        if (!budget) {
            setBudgetValidated(true);
        } else {
            setBudgetValidated(false);
        }

        if (!timeline) {
            setTimelineValidated(true);
        } else {
            setTimelineValidated(false);
        }

        // If the form is not complete, don't show the loader
        if (!isFormComplete || serviceValidated || budgetValidated || timelineValidated) {
            setLoading(false);
            setValidated(true);
            return;
        }

        // If the form is complete, proceed with form submission
        const formData = new FormData();
        formData.append('first_name', firstnm);
        formData.append('last_name', last_name);
        formData.append('mobile', mobile);
        formData.append('email', email);
        formData.append('messages', message);
        formData.append('service', service.value);
        formData.append('budget', budget.value);
        formData.append('timeline', timeline.value);
        formData.append('attach_file', attach_file);

        axios
            .post('https://api.sensussoft.com/api/contactus', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                reset();
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
                setShowSuccessModal(true);
            });
    };

    const reset = () => {
        setFirstNm('');
        setLastNm('');
        setEmail('');
        setMobile('');
        setService(null);
        setBudget(null);
        setTimeline(null);
        setMessage('');
        setAttachFile(null);
        setFileName('Drag & Drop files here or Browse Files');
        setErrorMessage('');
        setServiceValidated(false);
        setBudgetValidated(false);
        setTimelineValidated(false);
        // setShowErrors(false);
        setValidated(false);
        setShowSuccessModal(true);
    }

    const handleCloseModal = () => {
        setShowSuccessModal(false);
    };

    return (
        <>
            <Helmet>
                <title>Contact Sensussoft: Start Your Project Now!</title>
                <meta name="title" content="Contact Sensussoft: Start Your Project Now!" />
                <meta name="keywords" content="Contact Sensussoft, Start Your Project, Get in Touch, Contact Us, Project Consultation, Business Inquiry, Request a Quote, Contact Information, Project Collaboration, Reach Out to Sensussoft" />
                <meta name="description" content="Ready to begin your next project or launch a new website? Contact Sensussoft now to discuss your requirements and kickstart your digital advancement journey!" />
            </Helmet>
            <main>
                <div className='contactus-page-main'>
                    {/* section 1 */}
                    <div className='get-in-touch'>
                        <Container>
                            <Row className='ptb-40'>
                                <Col lg={7} className='align-self-center'>
                                    <div className="ss-heading ss-white-heading proof-text">
                                        <div className="bg-hover">
                                            <h1><span> Get in touch </span>  with us</h1>
                                        </div>
                                        <p className="text-regular ptb-20">Want to get started on your next project? Maybe a new website?
                                            Let's get started!</p>
                                    </div>
                                </Col>
                                <Col lg={5} className='align-self-center'>
                                    <Row>
                                        <Col lg={5} md={6}>
                                            <div className="contact-us-detail pb-50">
                                                <div className='contact-us-box d-flex'>
                                                    <div className='bd-highlight align-self-center'>
                                                        <div className='contact-detailimg'>
                                                            <img src={require('../assets/images/contact-ic1.svg').default} className="img-fluid" alt='contact-ic1' />
                                                        </div>
                                                    </div>
                                                    <div className='contact-detail-text align-self-center'>
                                                        <p>
                                                            Open Hours
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className='text-regular'>
                                                    Monday - Friday:<br />
                                                    8AM - 7PM
                                                </p>
                                            </div>
                                        </Col>
                                        <Col lg={5} md={6}>
                                            <div className="contact-us-detail pb-50">
                                                <div className='contact-us-box d-flex'>
                                                    <div className='bd-highlight align-self-center'>
                                                        <div className='contact-detailimg'>
                                                            <img src={require('../assets/images/contact-ic2.svg').default} className="img-fluid" alt='contact-ic2' />
                                                        </div>
                                                    </div>
                                                    <div className='contact-detail-text align-self-center'>
                                                        <p>
                                                            Email Us
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className='text-regular'>
                                                    Send your query<br />
                                                    info@sensussoft.com
                                                </p>
                                            </div>
                                        </Col>
                                        <Col lg={5} md={6}>
                                            <div className="contact-us-detail">
                                                <div className='contact-us-box d-flex'>
                                                    <div className='bd-highlight align-self-center'>
                                                        <div className='contact-detailimg'>
                                                            <img src={require('../assets/images/contact-ic3.svg').default} className="img-fluid" alt='contact-ic3' />
                                                        </div>
                                                    </div>
                                                    <div className='contact-detail-text align-self-center'>
                                                        <p>
                                                            Call us
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className='text-regular'>
                                                    Call Us Now<br />
                                                    +91 9558189523
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* section 1 end*/}
                    {/* section 2 */}
                    <div className='contact-us-form-main pb-80'>
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className='contact-us-form-box'>
                                        <div className='contact-us-title'>
                                            <h2>
                                                Tell Us A Bit About Yourself
                                            </h2>
                                            <p className='text-regular pt-5'>
                                                Give your business digital advancement with us
                                            </p>
                                        </div>
                                        <div className='contact-form-main'>
                                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                                <Row className="mt-30">
                                                    <Col lg={6}>
                                                        <div className="custom-input mb-20">
                                                            <Form.Group className="mb-3" controlId="validationCustom01">
                                                                <Form.Label>First Name<span style={{ color: '#FA483C' }}>*</span></Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    value={firstnm}
                                                                    onChange={(e) => setFirstNm(e.target.value)}
                                                                    placeholder="First Name"

                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please enter your first name.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="custom-input mb-20">
                                                            <Form.Group className="mb-3" controlId="validationCustom02">
                                                                <Form.Label>Last Name<span style={{ color: '#FA483C' }}>*</span></Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    type="text"
                                                                    value={last_name}
                                                                    onChange={(e) => setLastNm(e.target.value)}
                                                                    placeholder="Last Name"

                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please enter your last name.
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="custom-input mb-20">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword">
                                                                <Form.Label>Email Address<span style={{ color: '#FA483C' }}>*</span></Form.Label>
                                                                <Form.Control
                                                                    type="email"
                                                                    value={email}
                                                                    onChange={handleChange} isInvalid={!isValid}
                                                                    placeholder="Email Address"
                                                                    required

                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please enter your email address
                                                                </Form.Control.Feedback>

                                                            </Form.Group>


                                                        </div>
                                                    </Col>

                                                    <Col lg={6}>
                                                        <div className="custom-input mb-20">
                                                            <Form.Group className="mb-3" controlId="formGroupPassword1">
                                                                <Form.Label>Phone Number<span style={{ color: '#FA483C' }}>*</span></Form.Label>
                                                                <PhoneInput
                                                                    value={mobile}
                                                                    country={"in"}
                                                                    enableSearch={true}
                                                                    onChange={handlePhoneChange}
                                                                    placeholder="Phone Number"
                                                                    required
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please enter your phone number
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className='custom-input mb-20'>
                                                            <Form.Group controlId='service'>
                                                                <Form.Label>Select a Service<span style={{ color: '#FA483C' }}>*</span></Form.Label>
                                                                <div className='custom-select mb-20'>
                                                                    <Select
                                                                        placeholder='Select..'
                                                                        className={serviceValidated && !service ? 'is-invalid' : ''}
                                                                        value={service}
                                                                        onChange={(selectedOption) => {
                                                                            setService(selectedOption);
                                                                            setServiceValidated(false);
                                                                        }}
                                                                        options={options}
                                                                    />
                                                                    {serviceValidated && !service && (
                                                                        <div className='invalid-feedback'>Please select a service.</div>
                                                                    )}
                                                                </div>
                                                            </Form.Group>
                                                        </div>

                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className='custom-input mb-20'>
                                                            <Form.Group className='mb-3' controlId='budget'>
                                                                <Form.Label>Tell us About Your Budget<span style={{ color: '#FA483C' }}>*</span></Form.Label>
                                                                <div className='custom-select mb-20'>
                                                                    <Select
                                                                        placeholder='Select..'
                                                                        className={budgetValidated && !budget ? 'is-invalid' : ''}
                                                                        value={budget}
                                                                        onChange={(selectedOption) => {
                                                                            setBudget(selectedOption);
                                                                            setBudgetValidated(false);
                                                                        }}
                                                                        options={option2}
                                                                    />
                                                                    {budgetValidated && !budget && (
                                                                        <div className='invalid-feedback'>Please select your budget.</div>
                                                                    )}
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className='custom-input mb-20'>
                                                            <Form.Group className='mb-3' controlId='timeline'>
                                                                <Form.Label>How soon you want to start?<span style={{ color: '#FA483C' }}>*</span></Form.Label>
                                                                <div className='custom-select mb-20'>
                                                                    <Select
                                                                        placeholder='Select..'
                                                                        className={timelineValidated && !timeline ? 'is-invalid' : ''}
                                                                        value={timeline}
                                                                        onChange={(selectedOption) => {
                                                                            setTimeline(selectedOption);
                                                                            setTimelineValidated(false);
                                                                        }}
                                                                        options={option3}
                                                                    />
                                                                    {timelineValidated && !timeline && (
                                                                        <div className='invalid-feedback'>Please select your timeline.</div>
                                                                    )}
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="custom-input mb-20">
                                                            <Form.Group controlId="validationCustom01">
                                                                <Form.Label>Brief About The Project <span style={{ color: '#FA483C' }}>*</span></Form.Label>
                                                                <Form.Control
                                                                    required
                                                                    as="textarea"
                                                                    rows={3}
                                                                    placeholder="Your Message"
                                                                    value={message}
                                                                    onChange={(e) => setMessage(e.target.value)}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    Please give brief about the project
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="attach_file_btn">
                                                            <div className="file-drop-area">
                                                                <label htmlFor="inputDoc" className="form-label"></label>
                                                                <span className="file-msg">
                                                                    <img className="img-fluid file_img" src={require('../assets/images/file-ic.svg').default} alt="Attach File" />
                                                                    <br /> {fileName}
                                                                </span>
                                                                <input
                                                                    className="file-input"
                                                                    type="file"
                                                                    accept=".docx, .doc, .pdf"
                                                                    onChange={handleFileChange}
                                                                />
                                                                {errorMessage && <p className="error-message">{errorMessage}</p>}
                                                                <p className="attach-file-text">
                                                                    .pdf/.doc/.docx 10MB max.
                                                                </p>

                                                            </div>

                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="form-submit-btn text-end pt-50 ">

                                                            <Button type="submit" className="btn-wrapper btn-wrapper-blue" disabled={loading} aria-label='submit'>
                                                                {loading ? <span> Loading <i className="fa fa-spinner fa-spin"></i> </span> : 'Submit'}
                                                            </Button>
                                                        </div>
                                                    </Col>

                                                    <Col lg={12}>
                                                        <div className="sucess-modal">
                                                            <Modal className='sucess-modal-main' centered show={showSuccessModal} onHide={handleCloseModal}>

                                                                <Modal.Body className='sucess-modal-info'>
                                                                    <div className='sucessfully-content text-center'>

                                                                        <div className='sucess-img'>
                                                                            <img className="img-fluid " src={require('../assets/images/sucess.gif')} alt="Attach File" />
                                                                        </div>
                                                                        <p className='pt-10'>Form submitted successfully!</p>
                                                                    </div>
                                                                    <div className='sucess-close text-center pt-20'>
                                                                        <Button className="btn-wrapper btn-wrapper-blue" onClick={handleCloseModal} aria-label='ok'>
                                                                            Ok
                                                                        </Button>
                                                                    </div>

                                                                </Modal.Body>



                                                            </Modal>

                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* section 2 end*/}

                    {/* section 3 */}
                    <div className="look-forward pb-80">
                        <Container>
                            <Row>
                                <Col lg={8} className='align-self-center'>
                                    <div className="ss-heading ss-portfolio-text"><h2> We look forward to
                                        <sup> <img src={require('../assets/images/sparcle.svg').default} className="img-fluid" alt='img' /><br /></sup>
                                        <span>hearing from you</span></h2></div>
                                    <Row className='pt-50'>
                                        <Col lg={4}>
                                            <div className='look-forward-box'>
                                                <h3>
                                                    Surat, India
                                                </h3>
                                                <h4>
                                                    402 To 406, Angel Square,  Utran(Digital Valley), Surat, Gujarat 394105
                                                </h4>
                                                <p className='pt-20'>
                                                    info@Sensussoft.Com
                                                </p>
                                                <p >
                                                    +91 955 818 9523
                                                </p>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className='look-forward-box'>
                                                <h3>
                                                    Navsari, India
                                                </h3>
                                                <h4>
                                                    344, 3rd Floor, Central Bazzar, Navsari, Gujarat 396445
                                                </h4>
                                                <p className='pt-20'>
                                                    info@Sensussoft.Com
                                                </p>
                                                <p >
                                                    +91 955 818 9523
                                                </p>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className='look-forward-box us-map'>
                                                <h3>
                                                    New York, USA
                                                </h3>
                                                <h4>
                                                    1458 Altamont Ave, Schenectady, NY-12189
                                                </h4>
                                                <p className='pt-20'>
                                                    info@Sensussoft.Com
                                                </p>
                                                <p >
                                                    +1 518 772 2058
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={4}>
                                    <div className="contact-img2 mt-sm-20 mt-md-20">
                                        <img src={require('../assets/images/contact-img2.png')} className="img-fluid" alt='contact-img2' />
                                    </div>

                                </Col>
                            </Row>

                        </Container>

                    </div>
                    {/* section 3 */}


                </div>
            </main>
        </>
    )
}
export default ContactUs








