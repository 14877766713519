
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';

function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const tag = new URLSearchParams(location.search).get("tag");



  useEffect(() => {
    let apiUrl = 'https://api.sensussoft.com/api/blog';

    if (tag) {
      // If a tag is specified, add it to the API URL for tag-based filtering
      apiUrl = `https://api.sensussoft.com/api/blog?tag=${tag}`;
    }

    axios.get(apiUrl)
      .then(response => {
        // console.log('API Response:', response.data);
        if (response.data && response.data.success) {
          const blogData = response.data.data || []; // Handle empty or invalid data
          setBlogs(blogData);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching blogs:', error);
        setLoading(false);
      });
  }, [tag]);

  if (loading) {
    return <div className='blog-loader-main'>
      <div className='blog-loader'>
        <div className='loader-img'>
          <Container>
            <Row className='justify-content-center ptb-100 text-center'>
              <Col lg={12}>
                <div className='loading-content'>
                  <div style={{ position: 'relative', width: '250px', height: '150px' }}>
                    {/* SVG Markup */}
                    <svg width="100%" height="100%" viewBox="0 0 187.3 93.7" preserveAspectRatio="xMidYMid meet" className="svg-animation">
                      <path
                        stroke="#001B50"
                        id="outline"
                        fill="none"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                      />
                      <path
                        id="outline-bg"
                        opacity="0.05"
                        fill="none"
                        stroke="#001B50"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                      />
                    </svg>

                    {/* Custom text or content below the SVG */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container></div>
      </div>
    </div>
  }
  return (
    <>
      <Helmet>
        <title>Tech Blog: Insights, Innovations & Trends</title>
        <meta name="title" content="Tech Blog: Insights, Innovations & Trends" />
        <meta name="keywords" content="Tech Blog, Technology Insights, Tech Innovations, Tech Trends, Latest Tech News, Technology Blog, Tech Updates, Tech Articles, Tech Analysis, Technology Trends" />
        <meta name="description" content="Dive into our blog for the latest trends, industry insights, and expert perspectives on software development, technology innovations, and digital solutions. Stay informed and unlock the power of software with Sensussoft." />
      </Helmet>
      {/* section 1 */}
      <main>
        <div className="Product-ideation-section  website-development ptb-80 ">
          <Container >
            <Row>
              <Col lg={10} className="align-self-center">
                <div className="website-development-text proof-text-main">
                  <div className="ss-heading proof-text">
                    <h1><span>News & Updates</span> </h1>
                    <p className="text-regular mt-20">
                      Unlocking the Power of Software: Explore our insightful blog for the latest trends, industry insights, and expert perspectives on software development, technology innovations, and digital solutions.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* section 1  end*/}
        {/* Latest Blogs section */}
        <div className='specific_blog_page ptb-80'>
          <Container>
            {blogs.length === 0 ? (
              <div className="text-center">
                <p>No blogs found for the selected tag.</p>
              </div>
            ) : (
              <Row>
                {blogs.map(blog => (
                  <Col lg={4} md={6} key={blog.id} className="mb-20">
                    <Link aria-label='blog' to={`/blog/${blog.slug}`} >
                      <div className="ss-blog-box-main d-flex flex-column">
                        <div className="ss-blog-box-img">
                          <img className="img-fluid" src={blog.image} alt="blog1" />
                        </div>
                        <div className="ss-blog-box-title flex-grow-1">
                          <p className="mb-10 text-regular">
                            {blog.category_name}
                          </p>
                          <p>  <span
                            style={{
                              color: "#003adc",
                              fontSize: "16px",
                              fontWeight: 400,
                              lineHeight: "24px"
                            }}
                            className="text-regular ss-blog-title"
                          >
                            {blog.title}  </span></p>

                          <p className="blog-title-info text-regular pt-5">
                            {blog.preview_text}
                          </p>
                        </div>
                        <div className="ss-blog-author-main d-flex ">
                          <div className="bd-highlight align-self-center">
                            <div className="ss-author-img">
                              <img className="img-fluid" src={blog.author_profile} alt={blog.alt_text} />
                            </div>
                          </div>
                          <div className="ss-author-name align-self-center">
                            <p className="text-regular ss-blog-author">
                              {blog.author_name}
                            </p>
                            <p className="ss-blog-date text-regular">
                              {blog.published_date} <span> • {blog.reading_time} Min</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>
            )}
          </Container>
        </div>
      </main>
      {/* Latest Blogs section end*/}
    </>

  );
}

export default Blog;
