
import Ourservices from '../components/Ourservices';
import Ourprocess from '../components/Ourprocess';
import { Col, Container, Row, Stack } from 'react-bootstrap'
import MultiStep from '../components/MultiStep';
import CasestudySlider from '../components/CasestudySlider';
import { Helmet } from 'react-helmet-async';

export default function Services() {


  return (
    <>
      <Helmet>
        <title>Sensussoft Services: Exploring Our Wide Range of Offerings</title>
        <meta name="title" content="Sensussoft Services: Exploring Our Wide Range of Offerings" />
        <meta name="keywords" content="Sensussoft Services, Service Offerings, Software Services, IT Solutions, Technology Services, Business Solutions, Consulting Services, Digital Services, Service Portfolio, IT Services Provider" />
        <meta name="description" content="Explore our comprehensive range of services including web design, development, and digital marketing. Contact us for custom solutions tailored to your business needs."></meta>
      </Helmet>
      <main>
        <div className="ss-service-main">
          {/* section 1 */}
          <div className="elevating-software">

            <Container className="pt-50">
              <Row >
                <Col lg={6} className="align-self-center">
                  <div className="website-development-text proof-text-main">
                    <div className="ss-heading proof-text">
                      <div className="bg-hover">
                        <h2> Elevating<span> Software</span> </h2>
                      </div>
                      <h2><span>Excellence,</span> Globally</h2>
                      <p className="text-regular ptb-20">We are a global Software Development Company making success stories for over 8 Years. We create meaningful, high-quality, and performant software products that inspire. We want to inspire you, your customers, and ourselves every day.</p>
                    </div>
                    <div className="ss-rating ">
                      <img className="img-fluid" src={require('../assets/images/rating.svg').default} alt="rating"></img>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="ss-service-img text-center mt-sm-20 mt-md-20">

                    <img src={require('../assets/images/service-img.png')} className="img-fluid" alt='service-img' />
                  </div>
                </Col>
              </Row>
            </Container>

          </div>
          {/* section 1 end*/}
          {/* section2 */}
          <div className="we-are-delivering ptb-40">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="ss-heading ">
                    <div className="bg-hover">
                      <h1>We are <span>Delivering</span></h1>
                    </div>
                  </div>
                </div>
              </div>
              <Ourservices />
            </div>
          </div>
          {/* section2 end*/}
          {/* section 3 */}
          {/* <our process start> */}
          <div className="our_process_mainblog ptb-80">
            <div className="container">
              <div className="row pb-40">
                <div className="col-lg-12">
                  <div className="ss-heading">
                    <h2>Our <span>Work Process</span></h2>
                  </div>
                </div>
              </div>
              <Ourprocess />
            </div>
          </div>
          {/* <our process end> */}
          {/* section 3 */}
          {/* section 4 */}
          <div className="ss-from-step  service-multistep ptb-80 ">
            <Container>
              <Row className="justify-content-center">
                <Col lg={10}>
                  <div className="dream-project-box">
                    <div className="dream-project-middle">
                      <Stack direction="horizontal" className="d-mobile-block">
                        <div className="dream-project-text ss-heading w-30 align-self-center">
                          <h2 className="text-regular">
                            Tell us bit <br />
                            about your <br />
                            <span>Dream Project!</span>
                          </h2>

                        </div>
                        <div className="dream-project-border"></div>
                        <div className="dream-project-form w-50 align-self-center ">
                          <div className="form-title">

                            <MultiStep></MultiStep>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 5 */}
          {/* section 5 */}
          {/* Our Case study start */}

          <CasestudySlider />

          {/* Our Case study end */}
          {/* section 5 end*/}

        </div>
      </main>
    </>
  )
}



