import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
// import Cookies from './Cookies';

export default function PrivacyPolicy() {
  const [activeSection, setActiveSection] = useState('section1');
  const headerHeight = 70;

  const handleScroll = () => {
    const scrollPos = window.scrollY + headerHeight + 50; // 50 is an additional offset for better UX

    const section1 = document.getElementById('section1').offsetTop - headerHeight;
    const section2 = document.getElementById('section2').offsetTop - headerHeight;
    const section3 = document.getElementById('section3').offsetTop - headerHeight;
    const section4 = document.getElementById('section4').offsetTop - headerHeight;

    if (scrollPos >= section4) {
      setActiveSection('section4');
    } else if (scrollPos >= section3) {
      setActiveSection('section3');
    } else if (scrollPos >= section2) {
      setActiveSection('section2');
    } else if (scrollPos >= section1) {
      setActiveSection('section1');
    }
  };

  const handleClick = (section) => {
    setActiveSection(section);
    const element = document.getElementById(section);
    const offsetPosition = element.offsetTop - headerHeight;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
      <Helmet>
        <title>Privacy Policy | Sensussoft's Commitment to Data Protection.</title>
        <meta name="title" content="Privacy Policy | Sensussoft's Commitment to Data Protection." />
        <meta name="keywords" content="Privacy Policy, Data Protection, Data Privacy, Privacy Statement, GDPR Compliance, Privacy Regulations, Personal Data Protection, Data Security, Privacy Commitment, User Data Privacy" />
        <meta name="description" content="Learn about our commitment to privacy and how we handle personal information at Sensussoft Software Pvt. Ltd. " />
      </Helmet>
      <main>
        <div className="Product-ideation-section  website-development ptb-80 ">
          <Container >
            <Row>
              <Col lg={10} className="align-self-center">
                <div className="website-development-text proof-text-main ">
                  <div className="ss-heading proof-text">
                    <h1><span>Privacy Policy</span> </h1>
                    <p className="text-regular mt-20">
                      Sensussoft recognizes that privacy is of great importance to individuals everywhere – our customers, website visitors, product users… everyone. This is why the responsible use and protection of personal and other information under our care is a core value of Sensussoft.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>



        <div className='privacy-main' data-spy="scroll" data-target="#myScrollspy" data-offset="15">
          <div className="container">
            <div className="row">
              <nav className="col-sm-3" id="myScrollspy">
                <ul className="policy-nav nav">
                  <li
                    className={activeSection === 'section1' ? 'active' : ''}
                    onClick={() => handleClick('section1')}
                  ><i class="fa fa-angle-double-right" style={{ fontSize: 20, marginRight: 5 }}></i>

                    <span>Privacy Policy</span>
                  </li>
                  <li
                    className={activeSection === 'section2' ? 'active' : ''}
                    onClick={() => handleClick('section2')}
                  ><i class="fa fa-angle-double-right" style={{ fontSize: 20, marginRight: 5 }}></i>
                    <span>Terms and conditions</span>
                  </li>
                  <li
                    className={activeSection === 'section3' ? 'active' : ''}
                    onClick={() => handleClick('section3')}
                  ><i class="fa fa-angle-double-right" style={{ fontSize: 20, marginRight: 5 }}></i>
                    <span>Data Policy</span>
                  </li>
                  <li
                    className={activeSection === 'section4' ? 'active' : ''}
                    onClick={() => handleClick('section4')}
                  ><i class="fa fa-angle-double-right" style={{ fontSize: 20, marginRight: 5 }}></i>
                    <span>Cookies</span>
                  </li>
                </ul>
              </nav>
              <div className="col-sm-9">
                <div id="section1" className="section">
                  <div className="privacy-policy-page-main ">
                    <div className="privacy-policy-main pb-50">
                      <Container>
                        <Row>
                          <Col lg={12}>
                            <div className="privacy-policy-intro">
                              <div className="our-vision-heading">
                                <h2 className="lg-text-light pt-15" style={{ fontWeight: 500, fontSize: 30 }}>Privacy Policy</h2>
                                <img className="img-fluid" src={require('../assets/images/line2.svg').default} alt='line'></img>
                              </div>

                            </div>
                          </Col>
                        </Row>
                        <Row>

                          <Col lg={12}>
                            <div className="privacy-policy-main-text ptb-10">
                              <p className="light-heading">
                                Sensussoft recognizes that privacy is of great importance to
                                individuals everywhere – our customers, website visitors,
                                product users… everyone. This is why the responsible use and
                                protection of personal and other information under our care is
                                a core value of Sensussoft. To learn more about our privacy
                                practices, please review the policy below. If you have any
                                further questions or concerns, please feel free to reach us at info@sensussoft.com
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="privacy-policy-main-text ptb-10">
                              <p className="light-heading">
                                The privacy statement was last updated on 05/02/2024
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="privacy-policy-intro">
                              <div className="our-vision-heading">
                                <h2 className="lg-text-light ptb-15">1. Introduction</h2>
                              </div>
                              <div className="policy-intro-text ptb-10">
                                <p className="light-heading">
                                  1.1 Sensussoft Software Pvt. Ltd. is committed to
                                  safeguarding the privacy of https://sensussoft.com visitors.
                                  In this policy Sensussoft Software Pvt. Ltd. explains how
                                  Sensussoft Software Pvt. Ltd. will treat your personal
                                  information.
                                </p>
                              </div>
                              <div className="policy-intro-text ptb-10">
                                <p className="light-heading">
                                  1.2 Sensussoft Software Pvt. Ltd. will ask you to consent to
                                  our use of cookies in accordance with the terms of this
                                  policy when you first visit https://sensussoft.com By using
                                  https://sensussoft.com and agreeing to this policy, you
                                  consent to our use of cookies in accordance with the terms
                                  of this policy.
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="policy-collect-info">
                              <div className="our-vision-heading">
                                <h2 className="lg-text-light ptb-15">
                                  2. Collecting personal information
                                </h2>
                              </div>
                              <div className='policy-personal-info '>
                                <div className="enhance-content ptb-10 ">
                                  <h3 className="text-regular">
                                    <span className="lg-text-semibold">
                                      2.1 Sensussoft Software Pvt. Ltd. may collect, store and
                                      use the following kinds of personal information:
                                    </span>
                                  </h3>
                                </div>
                              </div>
                              <div className='declaration-text'>
                                <ul>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (a) information about your computer and about your visits
                                      to and use of this website (including your IP address,
                                      geographical location, browser type and version, operating
                                      system, referral source, length of visit, page views and
                                      website navigation paths)
                                    </p>
                                  </li>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (b) information that you provide to us when using the
                                      services on https://sensussoft.com or that is generated in
                                      the course of the use of those services (including the
                                      timing, frequency and pattern of service use.
                                    </p>
                                  </li>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (c) information contained in or relating to any
                                      communications that you send to us or send through
                                      https://sensussoft.com (including the communication
                                      content and meta data associated with the communication)
                                    </p>
                                  </li>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (d) any other personal information that you choose to send
                                      to us
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="policy-personal-info">
                              <div className="our-vision-heading">
                                <h2 className="lg-text-light ptb-15">
                                  3. Using your personal information
                                </h2>
                              </div>

                              <div className="enhance-content ptb-10">
                                <p className="text-regular">

                                  3.1 Personal information submitted to us through
                                  https://sensussoft.com will be used for the purposes
                                  specified in this policy or on the relevant pages of the
                                  website.

                                </p>
                              </div>
                              <div className="enhance-content ptb-10">
                                <h4 className="text-regular">
                                  <span className="lg-text-semibold">
                                    3.2 Sensussoft Software Pvt. Ltd. may use your personal
                                    information to:
                                  </span>
                                </h4>
                              </div>
                              <div className='declaration-text'>

                                <ul>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (a) administer https://sensussoft.com and business
                                    </p>
                                  </li>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (b) personalise https://sensussoft.com for you
                                    </p>
                                  </li>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (c) enable your use of the services available on
                                      https://sensussoft.com{" "}
                                    </p>
                                  </li>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (d) send you non-marketing commercial communications
                                    </p>
                                  </li>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (e) send you marketing communications relating to our
                                      business or the businesses of carefully selected third
                                      parties that Sensussoft Software Pvt. Ltd. think may be
                                      of interest to you, by post or, where you have
                                      specifically agreed to this, by email or similar
                                      technology (you can inform us at any time if you no
                                      longer require marketing communications)
                                    </p>
                                  </li>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (f) provide third parties with statistical information
                                      about our users (but those third parties will not be
                                      able to identify any individual user from that
                                      information)
                                    </p>
                                  </li>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (g) deal with enquiries and complaints made by or about
                                      you relating to https://sensussoft.com{" "}
                                    </p>
                                  </li>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (h) keep https://sensussoft.com secure and prevent fraud
                                    </p>
                                  </li>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (l) verify compliance with the terms and conditions
                                      governing the use of https://sensussoft.com (including
                                      monitoring private messages sent through
                                      https://sensussoft.com private messaging service)
                                    </p>
                                  </li>
                                </ul>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  3.3 If you submit personal information for publication on
                                  https://sensussoft.com, Sensussoft Software Pvt. Ltd. will
                                  publish and otherwise use that information in accordance
                                  with the license you grant to us.
                                </p>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  3.4 Sensussoft Software Pvt. Ltd. will not, without your
                                  express consent, supply your personal information to any
                                  third party for the purpose of their or any other third
                                  party’s direct marketing.
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="disclose-info-main">
                              <div className="our-vision-heading">
                                <h2 className="lg-text-light ptb-15">
                                  4. Disclosing personal information
                                </h2>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  4.1 Sensussoft Software Pvt. Ltd. may disclose your personal
                                  information to any of our employees, officers, insurers,
                                  professional advisers, agents, suppliers or subcontractors
                                  insofar as reasonably necessary for the purposes set out in
                                  this policy.
                                </p>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  4.2 Sensussoft Software Pvt. Ltd. may disclose your personal
                                  information to any member of our group of companies (this
                                  means our subsidiaries, our ultimate holding company and all
                                  its subsidiaries) insofar as reasonably necessary for the
                                  purposes set out in this policy.
                                </p>
                              </div>
                              <div>
                                <div className='policy-personal-info '>
                                  <div className="enhance-content ptb-10">
                                    <h3 className="text-regular">
                                      <span className="lg-text-semibold">
                                        4.3 Sensussoft Software Pvt. Ltd. may disclose your
                                        personal information:
                                      </span>
                                    </h3>
                                  </div>
                                </div>
                                <div className='declaration-text'>
                                  <ul>
                                    <li className="ptb-10" style={{ listStyle: "none" }}>
                                      <p className="light-heading">
                                        (a) to the extent that Sensussoft Software Pvt. Ltd. is
                                        required to do so by law
                                      </p>
                                    </li>
                                    <li className="ptb-10" style={{ listStyle: "none" }}>
                                      <p className="light-heading">
                                        (b) in connection with any on-going or prospective legal
                                        proceedings
                                      </p>
                                    </li>
                                    <li className="ptb-10" style={{ listStyle: "none" }}>
                                      <p className="light-heading">
                                        (c) in order to establish, exercise or defend our legal
                                        rights (including providing information to others for the
                                        purposes of fraud prevention and reducing credit risk)
                                      </p>
                                    </li>
                                    <li className="ptb-10" style={{ listStyle: "none" }}>
                                      <p className="light-heading">
                                        (d) to the purchaser (or prospective purchaser) of any
                                        business or asset that Sensussoft Software Pvt. Ltd. are
                                        (or are contemplating) selling and
                                      </p>
                                    </li>
                                    <li className="ptb-10" style={{ listStyle: "none" }}>
                                      <p className="light-heading">
                                        (e) to any person who Sensussoft Software Pvt. Ltd.
                                        reasonably believes may apply to a court or other
                                        competent authority for disclosure of that personal
                                        information where, in our reasonable opinion, such court
                                        or authority would be reasonably likely to order
                                        disclosure of that personal information.
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  4.4 Except as provided in this policy, Sensussoft Software
                                  Pvt. Ltd. will not provide your personal information to
                                  third parties.
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="data-transfer-main">
                              <div className="our-vision-heading">
                                <h2 className="lg-text-light ptb-15">
                                  5. International data transfers
                                </h2>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  5.1 Information that Sensussoft Software Pvt. Ltd. collects
                                  may be stored and processed in and transferred between any
                                  of the countries in which Sensussoft Software Pvt. Ltd.
                                  operate in order to enable us to use the information in
                                  accordance with this policy.
                                </p>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  5.2 Personal information that you publish on
                                  https://sensussoft.com or submit for publication on
                                  https://sensussoft.com may be available, via the internet,
                                  around the world. Sensussoft Software Pvt. Ltd. cannot
                                  prevent the use or misuse of such information by others.
                                </p>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  5.3 You expressly agree to the transfers of personal
                                  information described in this Section 5.
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="retaining-info-main declaration-text">
                              <div className="our-vision-heading">
                                <h2 className="lg-text-light ptb-15">
                                  6. Retaining personal information
                                </h2>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  6.1 This Section 6 sets out our data retention policies and
                                  procedure, which are designed to help ensure that Sensussoft
                                  Software Pvt. Ltd. comply with our legal obligations in
                                  relation to the retention and deletion of personal
                                  information.
                                </p>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  6.2 Personal information that Sensussoft Software Pvt. Ltd.
                                  process for any purpose or purposes shall not be kept for
                                  longer than is necessary for that purpose or those purposes.
                                </p>
                              </div>
                              <div>
                                <div className='policy-personal-info '>
                                  <div className="enhance-content ptb-10">
                                    <h3 className="text-regular">
                                      <span className="lg-text-semibold">
                                        6.3 Without prejudice to Section 6.2, Sensussoft
                                        Software Pvt. Ltd. will usually delete personal data
                                        falling within the categories set out below at the
                                        date/time set out below:
                                      </span>
                                    </h3>
                                  </div>
                                </div>
                                <div className='declaration-text'>
                                  <ul>
                                    <li className="ptb-10" style={{ listStyle: "none" }}>
                                      <p className="light-heading">
                                        (a) personal data will be deleted after 6 months
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div>
                                <div className="enhance-content ptb-10">
                                  <h4 className="text-regular">
                                    <span className="lg-text-semibold">
                                      6.4 Notwithstanding the other provisions of this Section
                                      6, Sensussoft Software Pvt. Ltd. will retain documents
                                      (including electronic documents) containing personal
                                      data:
                                    </span>
                                  </h4>
                                </div>
                                <ul>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (a) to the extent that Sensussoft Software Pvt. Ltd. is
                                      required to do so by law
                                    </p>
                                  </li>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (b) if Sensussoft Software Pvt. Ltd. believe that the
                                      documents may be relevant to any on-going or prospective
                                      legal proceedings and
                                    </p>
                                  </li>
                                  <li className="ptb-10" style={{ listStyle: "none" }}>
                                    <p className="light-heading">
                                      (c) in order to establish, exercise or defend our legal
                                      rights (including providing information to others for
                                      the purposes of fraud prevention and reducing credit
                                      risk).
                                    </p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="security-of-info-main">
                              <div className="our-vision-heading">
                                <h2 className="lg-text-light ptb-15">
                                  7. Security of your personal information
                                </h2>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  7.1 Sensussoft Software Pvt. Ltd. will take reasonable
                                  technical and organizational precautions to prevent the
                                  loss, misuse or alteration of your personal information.
                                </p>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  7.2 Sensussoft Software Pvt. Ltd. will store all the
                                  personal information you provide on our secure (password-
                                  and firewall-protected) servers.
                                </p>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  7.3 You acknowledge that the transmission of information
                                  over the internet is inherently insecure, and Sensussoft
                                  Software Pvt. Ltd. cannot guarantee the security of data
                                  sent over the internet.
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="amendments-main">
                              <div className="our-vision-heading">
                                <h2 className="lg-text-light ptb-15">
                                  8. Amendments
                                </h2>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  8.1 Sensussoft Software Pvt. Ltd. may update this policy from time to time by publishing a new version on https://sensussoft.com.
                                </p>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  8.2 You should check this page occasionally to ensure you are happy with any changes to this policy.
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="rights-main">
                              <div className="our-vision-heading">
                                <h2 className="lg-text-light ptb-15">
                                  9. Your rights
                                </h2>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  9.1 Sensussoft Software Pvt. Ltd. may withhold personal information that you request to the extent permitted by law.
                                </p>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  9.2 You may instruct us at any time not to process your personal information for marketing purposes.
                                </p>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  9.3 In practice, you will usually either expressly agree in advance to our use of your personal information for marketing purposes, or Sensussoft Software Pvt. Ltd. will provide you with an opportunity to opt out of the use of your personal information for marketing purposes.
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="policy-third-party-main">
                              <div className="our-vision-heading">
                                <h2 className="lg-text-light ptb-15">
                                  10. Third party websites
                                </h2>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  10.1 https://sensussoft.com includes hyperlinks to, and details of, third-party websites.
                                </p>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  10.2 Sensussoft Software Pvt. Ltd. has no control over, and is not responsible for, the privacy policies and practices of third parties.
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="policy-third-party-main">
                              <div className="our-vision-heading">
                                <h2 className="lg-text-light ptb-15">
                                  11. Updating information
                                </h2>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  11.1 Please let us know if the personal information that Sensussoft Software Pvt. Ltd. holds about you needs to be corrected or updated.
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <div className="our-details-main">
                              <div className="our-vision-heading">
                                <h2 className="lg-text-light ptb-15">
                                  12. Our details
                                </h2>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  12.1 This website is owned and operated by Sensussoft Software Pvt. Ltd.
                                </p>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  12.2 Our principal place of business is at Surat, Gujarat, India.
                                </p>
                              </div>
                              <div className="ptb-10">
                                <p className="light-heading">
                                  12.3 You can contact us by writing to the business address given above, by using the https://sensussoft.com contact form, by email to
                                  info@sensussoft.com or by telephone on
                                  +91 9558189523
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                </div>
                <div id="section2" className="section pb-50">
                  <div className="conditions-main pb-20">
                    <Container>
                      <Row>
                        <Col lg={12}>
                          <div className="privacy-policy-intro ">
                            <div className="our-vision-heading">
                              <h2 className="lg-text-light " style={{ fontWeight: 500, fontSize: 30 }}>Terms & Conditions</h2>
                              <img className="img-fluid" src={require('../assets/images/line2.svg').default} alt='line'></img>
                            </div>

                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="declaration-main">
                            <div className="our-vision-heading">
                              <h2 className="lg-text-light ptb-15">
                                Declarations and Disclaimers
                              </h2>
                            </div>
                            <div className="declaration-text">
                              <ul>
                                <li>
                                  <p className="light-heading ptb-10">
                                    Following are the set of terms and conditions which will
                                    be assumed to have been accepted by the Users on the
                                    usage of business website of Sensussoft Software Pvt.
                                    Ltd, sensussoft.com. Users are requested to read the
                                    terms carefully before getting engaged for services of
                                    the site.
                                  </p>
                                </li>
                                <li>
                                  <p className="light-heading ptb-10">
                                    Following are the set of terms and conditions which will
                                    be assumed to have been accepted by the Users on the
                                    usage of business website of Sensussoft Software Pvt.
                                    Ltd, sensussoft.com. Users are requested to read the
                                    terms carefully before getting engaged for services of
                                    the site.
                                  </p>
                                </li>
                                <li>
                                  <p className="light-heading ptb-10">
                                    User is referred as the one browsing the site while
                                    Sensussoft shall refer to the company under this
                                    section. Site further referred will be sensussoft.com
                                    proprietary and monitored by Sensussoft Software Pvt.
                                    Ltd.
                                  </p>
                                </li>
                                <li>
                                  <p className="light-heading ptb-10">
                                    By using the Site, user agrees to pursue and obligate
                                    the following terms and conditions concerning usage of
                                    Site. These terms and conditions may be changed at any
                                    time without prior notice by Sensussoft Software Pvt.
                                    Ltd.
                                  </p>
                                </li>
                                <li>
                                  <p className="light-heading ptb-10">
                                    Sensussoft may terminate any User to access the services
                                    and site at times for a valid offensive or fraud
                                    implicative reasons and hence Sensussoft may monitor
                                    every access to the Site.
                                  </p>
                                </li>
                                <li>
                                  <p className="light-heading ptb-10">
                                    All contents of this site are only property of
                                    Sensussoft. The software information, texts, graphics,
                                    videos and audios used on this site are proprietary
                                    material of Sensussoft. No one is allowed to copy,
                                    modify, reproduce, republish or distribute this content
                                    for any purposes other than benefiting and crediting
                                    Sensussoft unless prior permitted in written by
                                    Sensussoft itself. Rights not expressly granted here
                                    shall all be considered reserved.
                                  </p>
                                </li>
                                <li>
                                  <p className="light-heading ptb-10">
                                    Any unauthorized use of materials of the Site may
                                    violate proprietary copyrights, trademarks and relevant
                                    laws applicable which can force civil penalties.
                                  </p>
                                </li>
                                <li>
                                  <p className="light-heading ptb-10">
                                    Sensussoft is not responsible and liable for any damages
                                    including without limitation, direct, accidental or
                                    substantial damages including, but not limited to, the
                                    User’s system, loss of data, lost profits, business
                                    interruption, loss of informational rights that might
                                    result from downloading and deploying any developed
                                    software, content, materials, information from the
                                    website and allocated links to Users.
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="intellectual-property-main">
                            <div className="our-vision-heading">
                              <h2 className="lg-text-light ptb-15">
                                Intellectual Property
                              </h2>
                            </div>
                            <div className="enhance-content pt-10">
                              <h3 className="text-regular">
                                <span className="lg-text-semibold">Firm Side</span>
                              </h3>
                            </div>
                            <div>
                              <p className="light-heading ptb-10">
                                Sensussoft’s website may directly or indirectly show the
                                business ideas of its own functioning and operations which
                                are due rights of Sensussoft itself, any copyrights,
                                trademarks or information misuse may force criminal
                                punishments or civil penalties.
                              </p>
                            </div>
                            <div className="enhance-content pt-10">
                              <h3 className="text-regular">
                                <span className="lg-text-semibold">Client Side</span>
                              </h3>
                            </div>
                            <div>
                              <p className="light-heading ptb-10">
                                All the information acquired regarding the service needs and
                                requirements from the clients before and after the
                                engagement upon SLA below will be kept confidential as per
                                our Privacy Policy.
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="service-level-agreement-main mt-15">
                            <div className="our-vision-heading">
                              <h3 className="lg-text-light ptb-15">
                                Service Level Agreement
                              </h3>
                            </div>
                            <div className="enhance-content pt-10">
                              <p className="text-regular">
                                <span className="lg-text-semibold">
                                  Specification Documents
                                </span>
                              </p>
                            </div>
                            <div>
                              <p className="light-heading ptb-10">
                                Any typographical, clerical or other accidental errors or
                                omissions in Sales literature, quotation, price list,
                                acceptance of offer, invoice or other document or
                                information issued by the Sensussoft shall be subject to
                                correction without any liability on the part of the
                                Sensussoft. The specification for the deliverables shall be
                                those set out in the prior documentation before the
                                commencement of project. Sensussoft reserves the right to
                                make changes in the specification documents which will be
                                notified to the engaged client for that particular project.
                              </p>
                            </div>
                            <div className="enhance-content pt-10">
                              <h3 className="text-regular">
                                <span className="lg-text-semibold">Payment Terms</span>
                              </h3>
                            </div>
                            <div>
                              <p className="light-heading ptb-10">
                                Payment terms shall be agreed before and on signing the
                                contract between the Sensussoft and the client getting
                                engaged for any service. Subject to any special terms agreed
                                in writing or published on the website of Sensussoft, we
                                shall invoice the client for the net sum due to the
                                deliverables on or at any time after dispatch of the
                                project. Clients should make the payments as agreed in
                                contract documents or within seven permitted days as
                                applicable considering Sensussoft’s in general contract
                                terms declared and mentioned here. Any delays or impotency
                                to make payments shall or may seize the pursuing work on
                                respective tasks. Reminding of the dates as notices will not
                                be a responsibility of Sensussoft and hence the engaged
                                client shall take the sole responsibility of contract terms
                                while finding the assistance from the firm Sensussoft in
                                asked matters.
                              </p>
                            </div>
                            <div className="enhance-content pt-10">
                              <h3 className="text-regular">
                                <span className="lg-text-semibold">
                                  Refund Policy & Cancellation
                                </span>
                              </h3>
                            </div>
                            <div>
                              <p className="light-heading ptb-10">
                                All deliverables and services by Sensussoft are intangible
                                goods that are digitally delivered, we therefore follow a
                                strict refund policy on agreed terms, depending upon the
                                type of project or engagement. All the custom development
                                project works won’t have any refund policies and hence
                                clients are requested to read all specification documents
                                and formal agreement documents well before getting engaged
                                for services. Any other than development services don’t have
                                any refund policies either as on service charges are
                                applicable upon signing and assigning of contract. Any
                                unavoidable changes required to this policy should be
                                permitted under changes discussing with Sensussoft before
                                getting engaged for that specific service. Any disagreement
                                or detainment of payment after the agreement is signed and
                                agreed may turn to cancellation of the project in any nature
                                of services included as decided by the Sensussoft. Upon
                                cancellation, Sensussoft will be responsible to deliver the
                                work that is already paid for. We understand that
                                exceptional circumstance may occur due to the nature of the
                                services we provide. So please, get acquainted with our
                                Refund Policy before you order any of our services to avoid
                                dissatisfaction after the purchase.
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
                <div id="section3" className="section pb-50">
                  <div className="conditions-main">
                    <Container>
                      <Row>
                        <Col lg={12}>
                          <div className="privacy-policy-intro">
                            <div className="our-vision-heading">
                              <h2 className="lg-text-light " style={{ fontWeight: 500, fontSize: 30 }}>Data Policy
                              </h2>
                              <img className="img-fluid" src={require('../assets/images/line2.svg').default} alt='line'></img>

                            </div>

                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className=' policy-personal-info  privacy-policy-main-text ptb-10'>
                            <div className='enhance-content'>
                              <p className='light-heading'>
                                At Sensussoft, we are committed to protecting your privacy and ensuring the security of your personal data. This Data Policy outlines how we collect, use, store, and protect your information in compliance with the General Data Protection Regulation (GDPR) and other applicable data protection laws.


                              </p>
                              <p className='light-heading ptb-10'>
                                1. Data Controller
                                Sensussoft 403 Angel Square, Utran Surat, Gujarat, India Pin: 394105 Email: info@sensussoft.com Contact Number: +91 9558189523

                              </p>
                              <h3>
                                <span>
                                  2. Information We Collect

                                </span>
                              </h3>
                              <p className='light-heading ptb-2' style={{ paddingTop: 5, paddingBottom: 5 }}>
                                We may collect and process the following types of personal data:

                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Contact Information:</span>Name, email address, phone number, and mailing address.
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Professional Information:</span>Job title, company name, and industry.
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Technical Data:</span>IP address, browser type, operating system, and other technical information collected through cookies and similar technologies.
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Service Data:</span>Details about your interactions with our services, including support requests and feedback.
                              </p>
                              <h3 className='ptb-10'>
                                <span>
                                  3. How We Use Your Data
                                </span>
                              </h3>
                              <p className='light-heading pb-10'>
                                We use your personal data for the following purposes:
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Service Delivery:</span>To provide, manage, and improve our IT services.
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Communication:</span>To respond to your inquiries, provide customer support, and send updates about our services.
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Marketing::</span>To send promotional materials and newsletters, subject to your consent.

                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Compliance:</span>To comply with legal obligations and protect our legal rights.
                              </p>
                              <h3 className='pt-10'>
                                <span>
                                  4. Legal Basis for Processing

                                </span>
                              </h3>
                              <p className='light-heading ptb-2' style={{ paddingTop: 5, paddingBottom: 5 }}>
                                We process your personal data based on the following legal grounds:
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Contractual Necessity:</span>Processing is necessary for the performance of a contract with you or to take steps at your request before entering into a contract.
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Consent:</span>  You have given consent for specific purposes, such as receiving marketing communications.
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Legitimate Interests:</span>Processing is necessary for our legitimate interests, such as improving our services, provided your interests and fundamental rights do not override those interests.
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Legal Obligation:</span>Processing is necessary to comply with legal obligations.
                              </p>
                              <h3 className='pt-10'>
                                <span>
                                  5. Data Sharing and Transfers
                                </span>
                              </h3>
                              <p className='light-heading ptb-2' style={{ paddingTop: 5, paddingBottom: 5 }}>
                                We do not sell, trade, or rent your personal data to third parties. We may share your data with:
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Service Providers:</span>Third-party vendors who perform services on our behalf, such as hosting, data analysis, and customer support.

                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Legal Authorities:</span>  If required by law or to protect our legal rights.
                              </p>
                              <p className='light-heading ptb-2' style={{ paddingTop: 5, paddingBottom: 5 }}>
                                If we transfer your personal data outside the European Economic Area (EEA), we will ensure appropriate safeguards are in place to protect your data, such as Standard Contractual Clauses or equivalent measures.
                              </p>
                              <h3 className='pt-10'>
                                <span>
                                  6. Data Security
                                </span>
                              </h3>
                              <p className='light-heading ptb-2' style={{ paddingTop: 5, paddingBottom: 5 }}>
                                We implement appropriate technical and organizational measures to safeguard your personal data against unauthorized access, disclosure, alteration, and destruction. These measures include encryption, access controls, and regular security assessments.
                              </p>
                              <h3 className='pt-10'>
                                <span>
                                  7. Data Retention
                                </span>
                              </h3>
                              <p className='light-heading ptb-2' style={{ paddingTop: 5, paddingBottom: 5 }}>
                                We retain your personal data only for as long as necessary to fulfill the purposes for which it was collected and to comply with legal obligations. The retention period may vary depending on the type of data and the specific legal or business requirements.

                              </p>
                              <h3 className='pt-10'>
                                <span>
                                  8. Your Rights

                                </span>
                              </h3>
                              <p className='light-heading ptb-2' style={{ paddingTop: 5, paddingBottom: 5 }}>
                                Under the GDPR, you have the following rights regarding your personal data:
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Access:</span> You can request correction of inaccurate or incomplete data.

                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Rectification:</span>  You can request access to your personal data and obtain a copy.
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Erasure:</span>You can request the deletion of your data under certain conditions.
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Restriction:</span>You can request the restriction of processing under certain conditions.
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Objection:</span>You can object to processing based on legitimate interests or for direct marketing purposes
                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Portability:</span>You can request the transfer of your data to another data controller.
                              </p>
                              <p className='light-heading ptb-2' style={{ paddingTop: 5, paddingBottom: 5 }}>
                                To exercise your rights, please contact us at info@sensussoft.com.
                              </p>
                              <h3 className='pt-10'>
                                <span>
                                  9. Cookies and Tracking Technologies

                                </span>
                              </h3>
                              <p className='light-heading ptb-2'>
                                We use cookies and similar technologies to collect technical data and enhance your experience on our website. You can manage your cookie preferences through your browser settings.

                              </p>
                              <h3 className='pt-10'>
                                <span>
                                  10. Changes to This Data Policy
                                </span>
                              </h3>
                              <p className='light-heading ptb-2'>
                                We may update this Data Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated policy on our website and indicating the effective date.
                              </p>
                              <h3 className='pt-10'>
                                <span>
                                  11. Contact Us

                                </span>
                              </h3>
                              <p className='light-heading ptb-2'>
                                If you have any questions or concerns about this Data Policy or our data practices, please contact us at:
                                <br></br>
                                Sensussoft 403 Angel Square, Utran Surat, Gujarat, India Pin: 394105 Email: info@sensussoft.com Contact Number: +91 9558189523
                              </p>
                            </div>
                          </div>

                        </Col>
                      </Row>

                    </Container>
                  </div>
                </div>
                <div id="section4" className="section pb-50">
                  <div className="conditions-main pb-80">
                    <Container>
                      <Row>
                        <Col lg={12}>
                          <div className="privacy-policy-intro">
                            <div className="our-vision-heading">
                              <h2 className="lg-text-light " style={{ fontWeight: 500, fontSize: 30 }}>Cookies
                              </h2>
                              <img className="img-fluid" src={require('../assets/images/line2.svg').default} alt='line'></img>

                            </div>

                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className=' policy-personal-info  privacy-policy-main-text ptb-10'>
                            <div className='enhance-content'>
                              <p className='light-heading'>
                                At Sensussoft, we use cookies and similar tracking technologies to enhance your browsing experience, provide personalized services, and understand how our website is used. This Cookie Policy explains what cookies are, how we use them, and your choices regarding their use.


                              </p>

                              <h3>
                                <span>
                                  What are Cookies?


                                </span>
                              </h3>
                              <p className='light-heading ptb-2' style={{ paddingTop: 5, paddingBottom: 5 }}>
                                Cookies are small text files that are placed on your device (computer, smartphone, or other devices) when you visit a website. They are widely used to make websites work more efficiently and to provide information to the website owners.

                              </p>

                              <h3>
                                <span>
                                  Types of Cookies We Use
                                </span>
                              </h3>
                              <p className='light-heading ptb-2'>
                                We use the following types of cookies on our website:
                              </p>

                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Essential Cookies: </span>These cookies are necessary for the proper functioning of our website. They enable core functionalities such as security, network management, and accessibility. Without these cookies, certain parts of the website may not function properly.

                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Performance Cookies:</span>These cookies collect information about how visitors use our website, such as which pages are visited most often and any error messages received. The information collected is used to improve the performance and functionality of our website.

                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Functional Cookies:</span>These cookies allow our website to remember choices you make (such as your username, language, or region) and provide enhanced, more personalized features. They may also be used to provide services you have asked for, such as watching a video or commenting on a blog.

                              </p>
                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Targeting/Advertising Cookies:</span>These cookies are used to deliver advertisements more relevant to you and your interests. They remember that you have visited a website and share this information with other organizations, such as advertisers.

                              </p>
                              <h3 className='ptb-10'>
                                <span>
                                  How We Use Cookies

                                </span>
                              </h3>
                              <p className='light-heading'>
                                We use cookies to:
                              </p>
                              <div className='declaration-text'>
                                <ul>
                                  <li>
                                    <p className='light-heading'>
                                      Ensure the proper functioning of our website.

                                    </p>
                                  </li>
                                  <li>
                                    <p className='light-heading'>
                                      Personalize your experience on our website.

                                    </p>
                                  </li>
                                  <li>
                                    <p className='light-heading'>
                                      Analyze website traffic and usage patterns.

                                    </p>
                                  </li>
                                  <li>
                                    <p className='light-heading'>
                                      Provide relevant advertising and marketing messages.

                                    </p>
                                  </li>
                                </ul>
                              </div>
                              <h3 className='pb-10'>
                                <span>
                                  Your Cookie Choices

                                </span>
                              </h3>

                              <p className='light-heading ptb-2'>
                                You can manage your cookie preferences through your browser settings. Most web browsers allow you to control cookies through their settings preferences. You can choose to accept, reject, or delete cookies. However, if you choose to disable cookies, some parts of our website may not function properly.

                              </p>

                              <p className='light-heading ptb-2'>
                                <span style={{ fontWeight: 500 }}>Communication:</span> Managing Cookies on Different Browsers

                              </p>
                              <div className='declaration-text'>
                                <ul>
                                  <li>
                                    <p className='light-heading'>
                                      Google Chrome
                                    </p>
                                  </li>
                                  <li>
                                    <p className='light-heading'>
                                      Mozilla Firefox
                                    </p>
                                  </li>
                                  <li>
                                    <p className='light-heading'>
                                      Microsoft Edge
                                    </p>
                                  </li>
                                  <li>
                                    <p className='light-heading'>
                                      Safari
                                    </p>
                                  </li>
                                </ul>
                              </div>

                              <h3 >
                                <span>
                                  Third-Party Cookies

                                </span>
                              </h3>
                              <p className='light-heading ptb-2' style={{ paddingTop: 5, paddingBottom: 5 }}>
                                In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the website, deliver advertisements, and provide other relevant services.
                                Changes to This Cookie Policy
                              </p>
                              <p className='light-heading ptb-2' style={{ paddingTop: 5, paddingBottom: 5 }}>
                                We may update this Cookie Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this policy periodically to stay informed about our use of cookies.

                              </p>
                              <h3 className='pt-10'>
                                <span>
                                  Contact Us


                                </span>
                              </h3>
                              <p className='light-heading ptb-2'>
                                If you have any questions or concerns about this Cookie Policy or our data practices, please contact us at:

                              </p>
                              <p className='light-heading ptb-2'>
                                Sensussoft 403 Angel Square, Utran Surat, Gujarat, India Pin: 394105 Email: info@sensussoft.com Contact Number: +91 9558189523

                              </p>
                              <p className='light-heading ptb-2'>
                                This Cookie Policy was last updated on 10 June 2024. Please review it periodically for any updates or changes.
                              </p>


                            </div>
                          </div>

                        </Col>
                      </Row>

                    </Container>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </main>
    </div>
  )
}
