
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Col, Container, Row, Tab, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
function Casestudy() {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [mainportfolioData, setMainportfolioData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const CustomArrow = ({ direction, onClick }) => {
        const icon = direction === 'next' ? faChevronRight : faChevronLeft;

        return (
            <div
                className={`custom-arrow ${direction}`}
                style={{ zIndex: '1', display: 'block' }}
                onClick={onClick}
            >
                <FontAwesomeIcon icon={icon} />
            </div>
        );
    };
    const categoryslider = {
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        variableWidth: true,
        autoplay: false,
        margin: 10,
        nextArrow: <CustomArrow direction="next" />,
        prevArrow: <CustomArrow direction="prev" />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
        ],
    };
    useEffect(() => {
        // Fetch portfolio data from the API endpoint
        axios.get('https://api.sensussoft.com/api/casestudy')
            .then(response => {
                setMainportfolioData(response.data.data || []);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching portfolio data:', error);
                setLoading(false);
                setError("Error fetching data");
            });
    }, []);


    const splitCategories = (categories) => {
        return categories.split(',').map((category) => category.trim());
    };

    const filteredPortfolios = mainportfolioData.filter(portfolio =>
        selectedCategory === 'all' || splitCategories(portfolio.category).includes(selectedCategory)
    );

    const categories = ['all', 'UI/UX', 'Mobile Development', 'Web Development', 'Theme Development', 'Logo Design']; // Add more categories as needed

    if (loading) {
        return <div className='blog-loader-main'>
            <div className='blog-loader'>
                <div className='loader-img'>
                    <Container>
                        <Row className='justify-content-center ptb-100 text-center'>
                            <Col lg={12}>
                                <div className='loading-content'>
                                    <div style={{ position: 'relative', width: '250px', height: '150px' }}>
                                        {/* SVG Markup */}
                                        <svg width="100%" height="100%" viewBox="0 0 187.3 93.7" preserveAspectRatio="xMidYMid meet" className="svg-animation">
                                            <path
                                                stroke="#001B50"
                                                id="outline"
                                                fill="none"
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeMiterlimit="10"
                                                d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                                            />
                                            <path
                                                id="outline-bg"
                                                opacity="0.05"
                                                fill="none"
                                                stroke="#001B50"
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeMiterlimit="10"
                                                d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                                            />
                                        </svg>

                                        {/* Custom text or content below the SVG */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container></div>
            </div>
        </div>
    }

    if (error) {
        return <p>{error}</p>; // Show error message if there's an error while fetching data
    }


    return (
        <>
            <Helmet>
                <title>Explore Our Portfolio: Sensussoft's Success Stories</title>
                <meta name="title" content="Explore Our Portfolio: Sensussoft's Success Stories" />
                <meta name="keywords" content="Sensussoft Portfolio, Success Stories, Project Case Studies, Our Work, Client Success, Sensussoft Projects, Portfolio Showcase, Client Testimonials, Business Solutions, Our Achievements" />
                <meta name="description" content="Explore our case studies covering UI/UX design, mobile development, web development, theme development, and logo design projects. See how we've helped clients achieve their goals."></meta>
            </Helmet>
            <main>
                <div className='our_com_casestudy'>

                    <div className="mobile-app-development website-development ptb-80 bg-logo">

                    </div>
                    <Container>
                        <Row>
                            <Col lg={12} className="align-self-center">
                                <div className='main_box_of_casestudy'>
                                    <div className='case_study_c1-main d-flex justify-content-between'>
                                        <div className='case_study_c2-main d-flex justify-content-between w-100'>
                                            <div className='case_study_c1_wrapper d-flex d-mobile-block ' style={{ columnGap: '10px' }}>
                                                <div className='bd-highlight'>
                                                    <div className='images_of_casestudy_logo align-self-center'>
                                                        <img src={require('../assets/images/sensussoft-ic.png')} className="img-fluid" alt='img' />
                                                    </div>
                                                </div>
                                                <div className='text-of-sensussoft align-self-center'>
                                                    <h4>Sensussoft Software Private Limited</h4>
                                                    <p>We Nurture Creativity that drives your business to infinity</p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='specifiy_protfolio_page ptb-80'>
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className='heading_of_casestudy text-text-lg-center'>
                                    <div className="ss-heading proof-text">
                                        <h1>Our <span> Case study</span></h1>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="ss-case-study">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="all">
                                        <div className="container">
                                            <Row className="justify-content-center pt-30">
                                                <Col lg='12'>

                                                    <Nav
                                                        id="tecnology_scroll"
                                                        variant="pills"
                                                        className="flex-row"
                                                        activeKey={selectedCategory}
                                                        onSelect={(category) => setSelectedCategory(category)}
                                                    >
                                                        <Slider {...categoryslider} className="ss_tecnology_blog">
                                                            {categories.map((category) => (
                                                                <Nav.Item key={category}>
                                                                    <Nav.Link eventKey={category} aria-label="category">
                                                                        {category.toUpperCase()}
                                                                    </Nav.Link>
                                                                </Nav.Item>
                                                            ))}
                                                        </Slider>
                                                    </Nav>
                                                </Col>
                                                <Col lg={12}>

                                                    <Nav variant='pills' className="flex-row ss_casestudu-setab" activeKey={selectedCategory} onSelect={(category) => setSelectedCategory(category)}>
                                                        {categories.map(category => (
                                                            <Nav.Item key={category}>
                                                                <Nav.Link eventKey={category}>{category.toUpperCase()}</Nav.Link>
                                                            </Nav.Item>
                                                        ))}
                                                    </Nav>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row className='casestudy_portfolio_description'>
                                            <Col sm={12}>
                                                <Tab.Content>
                                                    {/* <Tab.Pane > */}
                                                    <div className="main_casestudy_after">
                                                        <Row>
                                                            {filteredPortfolios.length > 0 ? (
                                                                filteredPortfolios.map(portfolio => (

                                                                    <Col lg='4' md='6' key={portfolio.id} className={`${portfolio.category === 'Logo Design' ? ' logo_design_portfolio' : ''} main_portfolio_cell`}>

                                                                        <div className="portfolio-box-main d-flex" style={{ backgroundColor: portfolio.backimg_color_code, flexDirection: 'column' }}>
                                                                            {/* <Link
                                                                                to={portfolio.category !== 'UI/UX' ? `/casestudydetails/${portfolio.app_url}` : portfolio.url}
                                                                                target={portfolio.category !== 'UI/UX' ? '' : '_blank'}
                                                                                className={`d-flex flex-column flex-fill justify-content-end ${portfolio.category === 'UI/UX' ? 'ui-ux-category' : ''}`}
                                                                            > */}
                                                                            <Link
                                                                                to={(portfolio.category === 'UI/UX' || portfolio.category === 'Logo Design') ? portfolio.url : `/casestudydetails/${portfolio.app_url}`}
                                                                                target={(portfolio.category === 'UI/UX' || portfolio.category === 'Logo Design') ? '_blank' : ''}
                                                                                className={`d-flex flex-column flex-fill justify-content-end ${portfolio.category === 'UI/UX' ? 'ui-ux-category' : ''} ${portfolio.category === 'Logo Design' ? 'logo-design-category' : ''}`}
                                                                                aria-label='UI/UX'>
                                                                                <div className="portfolio-img mt-40 mx-auto">
                                                                                    <img className="img-fluid" src={portfolio.thumbnail} alt="portfolio-img" />
                                                                                </div>
                                                                            </Link>


                                                                            <div className="d-flex bd-highlight position_absolute_header">
                                                                                <div className=" flex-grow-1 bd-highlight">
                                                                                    {/* <Link
                                                                                        to={portfolio.category !== 'UI/UX' ? `/casestudydetails/${portfolio.app_url}` : portfolio.url}
                                                                                        target={portfolio.category !== 'UI/UX' ? '' : '_blank'}
                                                                                   
                                                                                    > */}
                                                                                    <Link a
                                                                                        to={(portfolio.category === 'UI/UX' || portfolio.category === 'Logo Design') ? portfolio.url : `/casestudydetails/${portfolio.app_url}`}
                                                                                        target={(portfolio.category === 'UI/UX' || portfolio.category === 'Logo Design') ? '_blank' : ''} aria-label='UI/UX'
                                                                                    >
                                                                                        {portfolio.category !== 'Logo Design' && (
                                                                                            <div className="portfolio-text">
                                                                                                <h2>
                                                                                                    {portfolio.title}
                                                                                                </h2>
                                                                                                <h3>
                                                                                                    {portfolio.subtitle}
                                                                                                </h3>
                                                                                            </div>
                                                                                        )}                                                                            </Link>
                                                                                </div>
                                                                                <div className=" bd-highlight">
                                                                                    <div className='ss_link_casestudy_main d-flex justify-content-end' style={{ columnGap: '5px' }}>
                                                                                        {portfolio.web_link && (
                                                                                            <div className='web_link_icon1'>
                                                                                                <Link to={portfolio.web_link} target='_blank' className='link-icon-1' aria-label='web url'>
                                                                                                    <img className='img-fluid' src={require('../assets/images/weblink.svg').default} alt='arrow' />
                                                                                                </Link>
                                                                                            </div>
                                                                                        )}
                                                                                        {portfolio.apple_link && (
                                                                                            <div className='ios_link_icon1'>
                                                                                                <Link to={portfolio.apple_link} target='_blank' className='link-icon-1' aria-label='appstore url'>
                                                                                                    <img className='img-fluid' src={require('../assets/images/ioslink.svg').default} alt='arrow' />
                                                                                                </Link>
                                                                                            </div>
                                                                                        )}
                                                                                        {portfolio.google_link && (
                                                                                            <div className='play_link_icon1'>
                                                                                                <Link to={portfolio.google_link} target='_blank' className='link-icon-1' aria-label='appstore'>
                                                                                                    <img className='img-fluid' src={require('../assets/images/applink.svg').default} alt='arrow' />
                                                                                                </Link>
                                                                                            </div>
                                                                                        )}
                                                                                        {portfolio.url && (
                                                                                            <div className='play_link_icon1'>
                                                                                                <Link to={portfolio.url} target='_blank' className='link-icon-1' aria-label='behance'>
                                                                                                    <img className='img-fluid ms-0' src={require('../assets/images/behance-case.svg').default} alt='arrow' />
                                                                                                </Link>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </Col>

                                                                ))
                                                            ) : (
                                                                <Col>
                                                                    <p className='pb-10 pt-50 text-center' style={{ color: "#7b7b7b" }}>No portfolios found for the selected category.</p>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </div>
                                                    {/* </Tab.Pane> */}
                                                </Tab.Content>

                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </main>

        </>
    );
}


export default Casestudy;
