import React from 'react'
import { Col, Container, Row, Stack, } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ScheduleCall from '../../components/ScheduleCall';
import Faq from '../../components/Faq';
import Accordion from 'react-bootstrap/Accordion';
import Portfolio from '../../components/Portfolio';
import { Helmet } from 'react-helmet-async';

function Marketplaceapps() {
  return (
    <>
      <Helmet>
        <title>Enhanced Marketplace Connectivity by Sensussoft</title>
        <meta name="title" content="Enhanced Marketplace Connectivity by Sensussoft" />
        <meta name="keywords" content="Marketplace Connectivity, Enhanced Connectivity Solutions, Sensussoft Marketplace Solutions" />
        <meta name="description" content="Partner with us for highly profitable and scalable marketplace apps that connect buyers, sellers, and service providers seamlessly. Access a wide range of services with multiple payment options." />
      </Helmet>
      <main>
        <div className='aimi-serive-main'>

          {/* section 1 */}
          <div className="maintanace-main website-development ptb-80 bg-logo">
            <Container>
              <Row className='pb-80'>
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className=" service-btn-mobile  next-prev-btn comm-btn ">
                    <Link aria-label='productideation' to='/blockchain-development'>
                      <button className="service-previous-btn learn-more arrow-btn " aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>

              </Row>
              <Row>
                <Col lg={6} className="align-self-center">
                  <div className="website-development-text proof-text-main">
                    <div className="ss-heading proof-text">
                      <h1><span>An Online Marketplace Connects Buyers And Sellers Seamlessly</span></h1>
                      <p className="text-regular mt-20">
                        At Sensussoft, we specialize in developing highly profitable and scalable marketplace apps that bring together buyers, sellers, service providers, and customers. Our custom marketplace application solutions ensure that clients can access a wide range of services anytime, anywhere, with the added convenience of multiple payment options. With our expertise in online marketplace development, we deliver cutting-edge technology and features, creating user-friendly platforms tailored to your specific industry and business needs.

                      </p>

                      <Link to='/get-in-touch-with-us' aria-label='contact us for detail'>
                        <button className="learn-more btn-large mt-25" aria-label='contact us for detail'>
                          <span className="circle" aria-hidden="true" >
                            <span className="icon arrow">
                              <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                            </span>
                          </span>
                          <span className="button-text">CONTACT US FOR DETAILS</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="align-self-center text-center">
                  <div className="change_vision_img"><img className="img-fluid" src={require('../../assets/images/marketplacebg.png')} alt='service1' /></div>

                  <div className="website-development-img-main">

                    <div className="website-development-img mt-sm-20 mt-md-20">
                      <img className="img-fluid" src={require('../../assets/images/marketplace.png')} alt="ai-service"></img>
                    </div>
                    <div className="web-build-box maitanance-box">
                      <div className="like-round">
                        <img className="img-fluid " src={require('../../assets/images/blub.svg').default} alt="blub">
                        </img>
                      </div>
                      <div className="build-text">
                        <h2>
                          50+
                        </h2>
                        <p className="text-regular">
                          360° services <br />support
                        </p>
                      </div>
                    </div>
                    <div className="web-build-box2 maitanance-box2">
                      <p>
                        Unmatched Maintenance Support:<br></br> Covering Every Dimension of Your Needs.
                      </p>
                      <div className="line">
                        <img className="img-fluid" src={require('../../assets/images/linefill.png')} alt="line"></img><br />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 1 end*/}
          {/* section 2 */}
          <div className="solve-the-unsolved ptb-80">
            <Container>
              <Row>
                <Col lg={6}>
                  <div className="solve-img">
                    <img src={require('../../assets/images/solve.jpg')} className="img-fluid" alt='solve' />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      Custom Marketplace Apps Designed for Different Sectors
                    </h2>
                    <div className="cms-needed pt-30" >
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>

                        </div>
                        <div className="right-text">
                          <p className="light-heading">
                            If you have a great concept for a new marketplace but don't know where to start, get in touch with us and we'll develop it from the bottom up, whether it's a B2B, B2C, C2C, or social media marketplace solution. We assist our clients in developing unique applications that people like.
                          </p>
                        </div>
                      </div>
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>

                        </div>
                        <div className="right-text ">
                          <p className="light-heading">
                            Business-to-Business (B2B) - a business model that focuses on selling products and services to other companies.


                          </p>
                        </div>
                      </div>
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>

                        </div>
                        <div className="right-text ">
                          <p className="light-heading">
                            Business-to-Customer (B2C) - a business model that opens doors for companies whose customers are people purchasing products or services.


                          </p>
                        </div>
                      </div>
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>
                        </div>
                        <div className="right-text ">
                          <p className="light-heading">
                            Customer-to-Customer (C2C) - a business model that facilitates commerce between independent individuals.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 2 end*/}
          {/* section 3 */}
          <div className="idea-and-enhance bg-service ">
            <Container>
              <Row>
                <Col lg={6}>
                  <div className="ai-service-list  ideation-services">
                    <div className="services-in-action-main mb-md-20">
                      <div className="services-in-action pt-100 pb-200">
                        <div className="our-vision-text-main d-flex">
                          <div className="bd-highlight vision-img-main">
                            <div className="our-vision-img overview-img">
                              <img className="img-fluid" src={require('../../assets/images/overview.svg').default} alt="vision-ic"></img>
                            </div>
                          </div>
                          <div className="our-vision-text">
                            <p>
                              Service Overview
                            </p>
                          </div>
                        </div>
                        <div className="ss-heading ss-portfolio-text">
                          <h2 className="ptb-15">
                            Our <span>Marketplace</span> App Development Services
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={12} className='align-self-center'>
                  <div className="ideation-content ptb-100">
                    <div className="enhance-content-main " >
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Goods and Products Selling Marketplaces:</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Facilitate seamless online transactions for a wide range of products, from groceries and consumer electronics to health and beauty items, with our customizable goods and products selling marketplace app development.
                          <br></br>
                          Example Solutions: eBay, Amazon, Etsy, Asos Marketplace, Steam Marketplace [Suggestion: Icons would look good]


                        </p>

                      </div>
                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Information Marketplaces:
                          </span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Create a dynamic platform where users can access and share valuable information across various domains, including e-books, research papers, tutorials, and more, through our innovative information marketplace app development.<br></br>
                          Example Solutions: Twitch, YouTube, SoundCloud, Yelp, Spotify [Suggestion: Icons would look good]

                        </p>

                      </div>

                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Education Marketplaces:

                          </span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Transform the way people learn by building an interactive education marketplace app connecting students and educators, offering a diverse array of courses and learning materials.<br></br>
                          Example Solutions: Udemy, Coursera, Skillshare, Codecademy, edX, Mimo  [Suggestion: Icons would look good]
                        </p>

                      </div>

                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Investment and Crowdfunding Marketplaces:
                          </span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Empower entrepreneurs and investors with our investment and crowdfunding marketplace app, providing a platform for crowdfunding campaigns and investment opportunities in startups and projects.<br></br>
                          Example Solutions: Robinhood, CircleUp, Xignite Market Data Cloud, IndieGoGo, Ulule [Suggestion: Icons would look good]
                        </p>
                      </div>

                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Services Providing Marketplaces:

                          </span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Enable service providers to connect with potential clients in various fields such as freelance writing, graphic design, web development, and more, through our services providing marketplace app development.
                          Example Solutions: Uber.Eats, Lyft, Couchsurfing, UpWork, 99designs [Suggestion: Icons would look good]
                        </p>
                      </div>

                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Telehealth Marketplaces:

                          </span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Enhance healthcare accessibility by developing a telehealth marketplace app connecting patients with healthcare professionals for virtual consultations, medical advice, and health services.<br></br>
                          Example Solutions: Medtronic, Yandex Health, HeadSpace [Suggestion: Icons would look good]
                        </p>
                      </div>

                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Real Estate & Rental Marketplaces:</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Simplify property transactions and rental services with our real estate and rental marketplace app, connecting property owners, buyers, and renters in one user-friendly platform. <br></br>
                          Example Solutions: AirBnB, Realtor.com, Redfin, Pad [Suggestion: Icons would look good]

                        </p>
                      </div>

                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 3 end*/}
          {/* section 4 */}
          <div className="solve-the-unsolved ptb-80">
            <Container>
              <Row>
                <Col lg={6} className="align-self-center">
                  <div className="solve-img">
                    <img src={require('../../assets/images/solve.jpg')} className="img-fluid" alt='solve' />
                  </div>
                </Col>
                <Col lg={6} className="align-self-center">
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      KEY FEATURES FOR MARKETPLACE APP DEVELOPMENT
                    </h2>
                    <div className="cms-needed pt-30" >
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>

                        </div>
                        <div className="right-text">
                          <p className="light-heading">
                            The basic feature set required to build a marketplace app




                          </p>
                        </div>
                      </div>
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>

                        </div>
                        <div className="right-text ">
                          <p className="light-heading">
                            Personal user accounts

                          </p>
                        </div>
                      </div>
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>

                        </div>
                        <div className="right-text ">
                          <p className="light-heading">
                            Wish lists


                          </p>
                        </div>
                      </div>
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>

                        </div>
                        <div className="right-text ">
                          <p className="light-heading">
                            Product search by categories</p>
                        </div>
                      </div>
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>

                        </div>
                        <div className="right-text ">
                          <p className="light-heading">
                            Shipment tracking</p>
                        </div>
                      </div>
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>

                        </div>
                        <div className="right-text ">
                          <p className="light-heading">
                            Live chat for seller-buyer communication</p>
                        </div>
                      </div>
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>

                        </div>
                        <div className="right-text ">
                          <p className="light-heading">
                            Checkout and payment processing</p>
                        </div>
                      </div>
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>

                        </div>
                        <div className="right-text ">
                          <p className="light-heading">
                            Two-sided ratings and reviews</p>
                        </div>
                      </div>
                      <div className="right-box d-flex mb-20">
                        <div className="bd-highlight ">
                          <div className="right-img">
                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                          </div>

                        </div>
                        <div className="right-text ">
                          <p className="light-heading">
                            Dashboard for tracking statistics

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 4 end*/}

          <div className="our-industry ptb-100 bg-multi ptb-100">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      Our Industry <span> Specific Experience </span>
                    </h2>
                    <p className="pt-10 text-regular">
                      Sensussoft has been dedicated to providing mobile app and website solutions with industry specific experience. Whether it is lifestyle, health, fitness, booking, or e-commerce, Sensussoft has worked on various projects tailored to individual businesses' needs.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="pt-50 justify-content-center ">
                <Col lg={12}>
                  <div className="experience-box-main d-flex flex-wrap justify-content-center">
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic8.svg').default} className="img-fluid" alt='experience-ic1' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Restaurant
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic9.svg').default} className="img-fluid" alt='experience-ic2' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Logistics
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic10.svg').default} className="img-fluid" alt='experience-ic3' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Games & Sports
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic5.svg').default} className="img-fluid" alt='experience-ic5' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Aviation
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic11.svg').default} className="img-fluid" alt='experience-ic4' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Real Estate
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic12.svg').default} className="img-fluid" alt='experience-ic6' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          On-Demand
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic13.svg').default} className="img-fluid" alt='experience-ic7' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Government
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic1.svg').default} className="img-fluid" alt='experience-ic1' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Social Media
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic2.svg').default} className="img-fluid" alt='experience-ic2' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          E-commerce
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic3.svg').default} className="img-fluid" alt='experience-ic3' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Entertainment
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic4.svg').default} className="img-fluid" alt='experience-ic4' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Healthcare
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic5.svg').default} className="img-fluid" alt='experience-ic5' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Travel
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic6.svg').default} className="img-fluid" alt='experience-ic6' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Fintech
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic7.svg').default} className="img-fluid" alt='experience-ic7' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Education
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>


          {/* section  5*/}
          <div className="attractive-main ptb-120">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="attractive-box">
                    <Stack direction="horizontal" className="d-mobile-block">
                      <div className="attractive-text flex-grow-1">
                        <h3 className="ss-title">
                          <span className="text-regular">We Have Rolled Up Our Sleeves To <br></br>Provide You With 360-degree It Support.</span>
                        </h3>
                      </div>
                      <div className="attractive-btn">
                        <ScheduleCall></ScheduleCall>
                      </div>
                    </Stack>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 5 end */}
          {/* sectoion 5 */}
          <div className="services-portfolio-main ptb-80">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      <span>Our Portfolio</span>
                    </h2>

                  </div>
                </Col>
              </Row>
            </Container>
            <Portfolio></Portfolio>
          </div>
          {/* sectoion 5 end*/}

          {/* section 7 */}
          <div className="ss-faq-main">
            <div className="faq-main pt-120">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="faq-title proof-text-main text-center mb-30">
                      <div className="ss-heading proof-text">
                        <h2><span>Frequently Asked<br />
                          Questions </span></h2>
                      </div>
                    </div>

                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <div className="faq-accordion">
                      <Accordion defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Why should I make my own marketplace app?
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Marketplace applications are the driver of the mobile commerce industry. They open up many benefits to companies that develop online marketplace apps: significant earnings, growth potential, and a swift start. Here are some reasons why you should think about developing marketplace apps.

                                <ul>
                                  <li><p>You Want to Run a Business Effortlessly
                                  </p></li>

                                  <li><p>You Want to Attract a Wider Audience
                                  </p></li>

                                  <li><p>You Are Focused on Continuous Growth
                                  </p></li>


                                </ul>
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>What is the Marketplace App Development?
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Marketplace App Development is the process of creating mobile or web applications that facilitate buying, selling, or trading goods and services among multiple users in a virtual marketplace.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>What are the key factors to consider when developing a marketplace mobile app?
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                The primary factor for successful marketplace development is to find a truly experienced software development partner. Your software development partner should have sufficient industry-specific experience to deliver a high-quality marketplace. We at Sensussoft have a range of marketplace creation project cases you can view in our portfolio.

                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>

                      </Accordion>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <Faq></Faq>
          </div>
          {/* section 7 end*/}
        </div>
      </main>
    </>
  )
}

export default Marketplaceapps
