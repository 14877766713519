import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'


export default function PageNotFound() {
    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <div className='error_page_notfound'>
                            {/* <img className="img-fluid float-image right" src={require('../assets/images/404page.jpg')} alt="ceo" /> */}
                            <h1>404</h1>
                            <h4>Page Not Found</h4>
                            <h5 className='pt-10'>
                                Oops! It seems you've taken a detour.<br /> Let's get you back on track. Home awaits!
                            </h5>
                            <div className="get-touch-btn mt-10">
                                <Link to="/what-we-do" className="btn-wrapper-get" aria-label='go to home'>
                                    Go to Home
                                </Link>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

