import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Portfolio from '../components/Portfolio';
import Marquee from "react-fast-marquee";
import { Helmet } from 'react-helmet-async';
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

// import Faq from '../components/Faq';

export default function Technologies() {
  const CustomArrow = ({ direction, onClick }) => {
    const icon = direction === 'next' ? faChevronRight : faChevronLeft;

    return (
      <div
        className={`custom-arrow ${direction}`}
        style={{ zIndex: '1', display: 'block' }}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  };
  const handleFocusManagement = () => {
    document.querySelectorAll('.slick-slide[aria-hidden="true"]').forEach(slide => {
      slide.setAttribute('tabindex', '-1');
      slide.querySelectorAll('a, button, input, select, textarea').forEach(elem => {
        elem.setAttribute('tabindex', '-1');
      });
    });
  };
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: false,
    margin: 10,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    afterChange: handleFocusManagement,
    beforeChange: handleFocusManagement,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
  useEffect(() => {
    handleFocusManagement();
  }, []);
  return (
    <>

      <Helmet>
        <title>Exploring Advanced Technologies at Sensussoft</title>
        <meta name="title" content="Exploring Advanced Technologies at Sensussoft" />
        <meta name="keywords" content="Advanced Technologies, Sensussoft Technologies, Technology Exploration, Innovation in Technology, Cutting-edge Technologies, Tech Solutions, Technology Development, Future Technologies, IT Innovations" />
        <meta name="description" content="everage our expertise in web development, mobile development, design, communication, cloud services, database management, enterprise solutions, project management, version control, testing, documentation, and product monitoring to bring your dream project to life with unparalleled innovation and efficiency." />
      </Helmet>
      {/* section 1 */}


      <main>
        <div className="technology-main">
          <div className="technology-next-main  ptb-120">
            <Container>
              <Row className="justify-content-center">
                <Col lg={10}>
                  <div className="cutting-edge">
                    <div className="cutting-img">

                      <div className="cutting-img1 ss-story d-flex">
                        <Marquee speed={25} >
                          <span>
                            <div className="technology-img1 ">
                              <img src={require('../assets/images/technology-img1.svg').default} className="img-fluid" alt='technology-img1' />
                            </div>
                          </span>
                          <span>
                            <div className="technology-img1 ">
                              <img src={require('../assets/images/technology-img1.svg').default} className="img-fluid" alt='technology-img1' />
                            </div>
                          </span>
                          <span>
                            <div className="technology-img1">
                              <img src={require('../assets/images/technology-img1.svg').default} className="img-fluid" alt='technology-img1' />
                            </div>
                          </span>
                          <span>
                            <div className="technology-img1 ">
                              <img src={require('../assets/images/technology-img1.svg').default} className="img-fluid" alt='technology-img1' />
                            </div>
                          </span>
                        </Marquee>
                      </div>

                      <div className="cutting-img2 ss-story d-flex">
                        <Marquee speed={35} >
                          <span>
                            <div className="technology-img1  ">
                              <img src={require('../assets/images/technology-img2.svg').default} className="img-fluid" alt='technology-img1' />
                            </div>
                          </span>
                          <span>
                            <div className="technology-img1 ">
                              <img src={require('../assets/images/technology-img2.svg').default} className="img-fluid" alt='technology-img1' />
                            </div>
                          </span>
                          <span>
                            <div className="technology-img1  ">
                              <img src={require('../assets/images/technology-img2.svg').default} className="img-fluid" alt='technology-img1' />
                            </div>
                          </span>
                          <span>
                            <div className="technology-img1  ">
                              <img src={require('../assets/images/technology-img2.svg').default} className="img-fluid" alt='technology-img1' />
                            </div>
                          </span>
                        </Marquee>
                      </div>

                      <div className="cutting-img3 ss-story d-flex">
                        <Marquee speed={25} >
                          <span>
                            <div className="technology-img1  ">
                              <img src={require('../assets/images/technology-img3.svg').default} className="img-fluid" alt='technology-img3' />
                            </div>
                          </span>
                          <span>
                            <div className="technology-img1 ">
                              <img src={require('../assets/images/technology-img3.svg').default} className="img-fluid" alt='technology-img3' />
                            </div>
                          </span>
                          <span>
                            <div className="technology-img1  ">
                              <img src={require('../assets/images/technology-img3.svg').default} className="img-fluid" alt='technology-img3' />
                            </div>
                          </span>
                          <span>
                            <div className="technology-img1  ">
                              <img src={require('../assets/images/technology-img3.svg').default} className="img-fluid" alt='technology-img3' />
                            </div>
                          </span>
                        </Marquee>
                      </div>

                      <div className="cutting-img4 ss-story d-flex">
                        <Marquee speed={35} >
                          <span>
                            <div className="technology-img1  ">
                              <img src={require('../assets/images/technology-img4.svg').default} className="img-fluid" alt='technology-img4' />
                            </div>
                          </span>
                          <span>
                            <div className="technology-img1 ">
                              <img src={require('../assets/images/technology-img4.svg').default} className="img-fluid" alt='technology-img4' />
                            </div>
                          </span>
                          <span>
                            <div className="technology-img1  ">
                              <img src={require('../assets/images/technology-img4.svg').default} className="img-fluid" alt='technology-img4' />
                            </div>
                          </span>
                          <span>
                            <div className="technology-img1  ">
                              <img src={require('../assets/images/technology-img4.svg').default} className="img-fluid" alt='technology-img4' />
                            </div>
                          </span>
                        </Marquee>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row >
                <Col lg={9} md={12}>
                  <div className="cutting-text mt-50">
                    <div className="ss-heading proof-text"><h1>Cutting edge technology  <br />
                      <span>for your Dream project</span></h1>
                      <p className="text-regular pt-10">we leverage the latest advancements to bring your dream project to life with unparalleled innovation and efficiency. Stay ahead of the curve and unlock limitless possibilities with our state-of-the-art solutions.</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 1 end*/}
          {/* section 2 */}
          <div className="Technology-we-use">
            <div className="ss-case-study pb-80">
              <Tab.Container defaultActiveKey="ai-technologies">
                <div className="container">
                  <Row>
                    <Col lg="12">
                      <div className="tecnology_header pt-80">
                        <div className="ss-heading ss-portfolio-text">
                          <h2>
                            <span>Technology</span> we use
                          </h2>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="pt-30">
                    <Col lg={12}>
                      <Nav variant="pills" className="flex-row" defaultActiveKey="ai-technologies" id="tecnology_scroll">
                        <Slider {...settings} className="ss_tecnology_blog">
                        <Nav.Item>
                            <Nav.Link eventKey="ai-technologies" aria-label="AI Technologies">
                              AI Technologies
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="web-development" aria-label="Web Development">
                              Web Development
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="mobile-development" aria-label="Mobile Development">
                              Mobile Development
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="design" aria-label="Design">
                              Design
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="communication" aria-label="Communication">
                              Communication
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="cloud" aria-label="Cloud">
                              Cloud
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="database" aria-label="Database">
                              Database
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="enterprise" aria-label="Enterprise">
                              Enterprise
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="project-management" aria-label="Project Management">
                              Project Management
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="version-control" aria-label="Version Control">
                              Version Control
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="testing" aria-label="Testing">
                              Testing
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="documentation" aria-label="Documentation">
                              Documentation
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="product-monitoring" aria-label="Product Monitoring">
                              Product Monitoring
                            </Nav.Link>
                          </Nav.Item>
                        </Slider>
                      </Nav>
                    </Col>
                    <Col lg={11}>
                      <Nav variant="pills" className="flex-row" defaultActiveKey="ai-technologies" id='tecnology_scroll_secound'>

                      <Nav.Item>
                          <Nav.Link eventKey="ai-technologies" aria-label='AI Technologies'>AI Technologies</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="web-development" aria-label='Web Development'>Web Development</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="mobile-development" aria-label='Mobile Development'>Mobile Development</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="design" aria-label='Design'>Design</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="communication" aria-label='Communication'>Communication</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="cloud" aria-label='Cloud'>Cloud</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="database" aria-label='Database'>Database</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="enterprise" aria-label='Enterprise'>Enterprise</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="project-management" aria-label='Project Management'>Project Management</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="version-control" aria-label='Version Control'>Version Control</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="testing" aria-label='Testing'>Testing</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="documentation" aria-label='Documentation'>Documentation</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="product-monitoring" aria-label='Product Monitoring'>Product Monitoring</Nav.Link>
                        </Nav.Item>



                      </Nav>
                    </Col>
                  </Row>
                </div>
                <div className="container">
                  <Row className="justify-content-center">
                    <Col sm={12} className="pt-50">
                      <Tab.Content>
                      <Tab.Pane eventKey="ai-technologies">
                          <Row className="technology-info">
                            <Col lg={3} md={6}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Machine Learning & Deep Learning
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Supervised-Learning.svg').default} className="img-fluid  red" alt='img' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Supervised Learning
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Neural-Networks.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Neural Networks
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Reinforcement-AI.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Reinforcement AI
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Anomaly-Detection.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Anomaly Detection
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Predictive-AI.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Predictive AI
                                    </p>
                                  </div>
                                </div>


                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  NLP & Conversational AI
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Chatbots.svg').default} className="img-fluid svg-img" alt='Chatbots' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Chatbots
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Sentiment-AI.svg').default} className="img-fluid" alt='Sentiment-AI' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Sentiment AI
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Speech-AI.svg').default} className="img-fluid" alt='Speech-AI' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Speech AI
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/AI-Search.svg').default} className="img-fluid" alt='AI-Search' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      AI Search
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Text-AI.svg').default} className="img-fluid" alt='Text-AI' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                    Text AI
                                    </p>
                                  </div>

                                </div>


                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Computer Vision & Generative AI
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Image-AI.svg').default} className="img-fluid svg-img" alt='Image-AI' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Image AI
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Face-AI.svg').default} className="img-fluid" alt='Face-AI' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Face AI
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/AI-Art.svg').default} className="img-fluid" alt='AI-Art' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      AI Art
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/AI-Video.svg').default} className="img-fluid" alt='AI-Video' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      AI Video
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  AI in Automation & Industries
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Cyber-AI.svg').default} className="img-fluid svg-img" alt='Cyber-AI' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Cyber AI
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/E-com-AI.svg').default} className="img-fluid" alt='E-com-AI' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      E-com AI
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Manufacturing-AI.svg').default} className="img-fluid" alt='Manufacturing-AI' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Manufacturing AI
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Education-AI.svg').default} className="img-fluid" alt='Education-AI' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Education AI
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Gaming-AI.svg').default} className="img-fluid" alt='Gaming-AI' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Gaming AI
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img ai-technology-img">
                                        <img src={require('../assets/images/technology/Autonomous-AI.svg').default} className="img-fluid" alt='Autonomous-AI' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Autonomous AI
                                    </p>
                                  </div>

                                </div>


                              </div>
                            </Col>
                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="web-development">
                          <Row className="technology-info">
                            <Col lg={3} md={6}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Framework
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/web-ic1.svg').default} className="img-fluid  red" alt='img' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      NodeJS
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/web-ic2.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      jQuery
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/web-ic3.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Angular
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/web-ic4.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      ASP.net
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/web-ic5.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Express.js
                                    </p>
                                  </div>
                                </div>


                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Language
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/web-ic6.svg').default} className="img-fluid svg-img" alt='web-ic6' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      php
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/web-ic7.svg').default} className="img-fluid" alt='web-ic7' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      HTML
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/web-ic8.svg').default} className="img-fluid" alt='web-ic8' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      C#
                                    </p>
                                  </div>

                                </div>


                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  E-commerce
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/web-ic9.svg').default} className="img-fluid svg-img" alt='web-ic9' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Woocomerce
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/web-ic10.svg').default} className="img-fluid" alt='web-ic10' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Wordpress
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </Col>
                            <Col lg={3} md={6}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  CMS System
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/web-ic11.svg').default} className="img-fluid svg-img" alt='web-ic11' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      CodeIgniter
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/web-ic12.svg').default} className="img-fluid" alt='web-ic12' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Laravel
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/web-ic13.svg').default} className="img-fluid" alt='web-ic13' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      CakePHP
                                    </p>
                                  </div>

                                </div>


                              </div>
                            </Col>
                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="mobile-development">
                          <Row className="technology-info">
                            <Col lg={6} md={6}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Programming language
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/mobile-ic1.svg').default} className="img-fluid" alt='mobile-ic1' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Kotlin
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/mobile-ic2.svg').default} className="img-fluid" alt='mobile-ic2' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Swift
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/mobile-ic3.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Java
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/mobile-ic7.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Objective-C
                                    </p>
                                  </div>
                                </div>


                              </div>
                            </Col>
                            <Col lg={6} md={6}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Cross-Platform
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/mobile-ic4.svg').default} className="img-fluid svg-img" alt='mobile-ic4' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Xamarin
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/mobile-ic5.svg').default} className="img-fluid" alt='mobile-ic5' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Flutter
                                    </p>
                                  </div>

                                </div>
                              </div>
                            </Col>

                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="design">
                          <Row className="technology-info">
                            <Col lg={4} md={4}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Graphic Design
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/design-ic1.svg').default} className="img-fluid" alt='design-ic1' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Photoshop
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/design-ic2.svg').default} className="img-fluid" alt='design-ic2' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Illustration
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/design-ic3.svg').default} className="img-fluid" alt='design-ic3' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Invison
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/design-ic4.svg').default} className="img-fluid" alt='design-ic4' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Corel
                                    </p>
                                  </div>
                                </div>


                              </div>
                            </Col>
                            <Col lg={4} md={4}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  UI Design
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/design-ic5.svg').default} className="img-fluid svg-img" alt='design-ic5' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Adobe XD
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/design-ic6.svg').default} className="img-fluid" alt='design-ic6' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Figma
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/design-ic7.svg').default} className="img-fluid" alt='design-ic7' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Sketch
                                    </p>
                                  </div>

                                </div>


                              </div>
                            </Col>
                            <Col lg={4} md={4}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Wireframing
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/design-ic8.svg').default} className="img-fluid svg-img" alt='design-ic8' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Miro
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/design-ic9.svg').default} className="img-fluid" alt='web-ic9' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Zeplin
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </Col>

                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="communication">
                          <Row className="technology-info">
                            <Col lg={6} md={12}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Comunication Tools
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/communication-ic1.svg').default} className="img-fluid" alt='communication-ic1' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Zoom
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/communication-ic2.svg').default} className="img-fluid" alt='communication-ic2' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Skype
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/communication-ic3.svg').default} className="img-fluid" alt='communication-ic3' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Slack
                                    </p>
                                  </div>

                                </div>


                              </div>
                            </Col>


                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="cloud">
                          <Row className="technology-info">
                            <Col lg={6} md={12}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  CLOUD Technology
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/cloud-ic1.svg').default} className="img-fluid" alt='cloud-ic1' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      AWS
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/cloud-ic2.svg').default} className="img-fluid" alt='cloud-ic2' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Google Cloud
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/cloud-ic3.svg').default} className="img-fluid" alt='cloud-ic3' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Microsoft Azure
                                    </p>
                                  </div>

                                </div>


                              </div>
                            </Col>


                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="database">
                          <Row className="technology-info">
                            <Col lg={6} md={12}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Database
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/database-ic1.svg').default} className="img-fluid" alt='database-ic1' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Mongo DB
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/database-ic2.svg').default} className="img-fluid" alt='database-ic2' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      MySQL
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/database-ic3.svg').default} className="img-fluid" alt='communication-ic3' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      postgre SQL
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/database-ic4.svg').default} className="img-fluid" alt='communication-ic4' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Oracle
                                    </p>
                                  </div>
                                </div>


                              </div>
                            </Col>


                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="enterprise">
                          <Row className="technology-info">
                            <Col lg={6} md={12}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Enterprise Technology
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/enterprice-ic1.svg').default} className="img-fluid" alt='enterprice-ic1' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      office 365
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/enterprice-ic2.svg').default} className="img-fluid" alt='enterprice-ic2' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      SharePoint
                                    </p>
                                  </div>


                                </div>


                              </div>
                            </Col>


                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="project-management">
                          <Row className="technology-info">
                            <Col lg={6} md={12}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Project Management Software Tools
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/project-manage-ic1.svg').default} className="img-fluid" alt='project-manage-ic1' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Jira
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/project-manage-ic2.svg').default} className="img-fluid" alt='project-manage-ic2' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Trello
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/project-manage-ic3.svg').default} className="img-fluid" alt='project-manage-ic3' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Asana
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/project-manage-ic4.svg').default} className="img-fluid" alt='communication-ic4' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Teamwork
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/project-manage-ic5.svg').default} className="img-fluid" alt='project-manage-ic5' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Monday
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="version-control">
                          <Row className="technology-info">
                            <Col lg={6} md={12}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Version Control
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/version-ic1.svg').default} className="img-fluid" alt='version-ic1' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Git
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/version-ic2.svg').default} className="img-fluid" alt='version-ic2' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      GitHub
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/version-ic3.svg').default} className="img-fluid" alt='version-ic3' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Bitbucket
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/version-ic4.svg').default} className="img-fluid" alt='version-ic4' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Gitlab
                                    </p>
                                  </div>
                                </div>


                              </div>
                            </Col>


                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="testing">
                          <Row className="technology-info">
                            <Col lg={6} md={12}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Testing tools
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/testing-ic1.svg').default} className="img-fluid" alt='testing-ic1' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      LambdaTest
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/testing-ic2.svg').default} className="img-fluid" alt='testing-ic2' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Browser Stack
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/testing-ic3.svg').default} className="img-fluid" alt='testing-ic3' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Katalon Studio
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/testing-ic4.svg').default} className="img-fluid" alt='testing-ic4' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      TestProject.io
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/testing-ic5.svg').default} className="img-fluid" alt='testing-ic5' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Selenium
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="documentation">
                          <Row className="technology-info">
                            <Col lg={6} md={12}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Documentation
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/documentation-ic1.svg').default} className="img-fluid" alt='documentation-ic1' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Confluence
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/documentation-ic2.svg').default} className="img-fluid" alt='documentation-ic2' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Draw.io
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/documentation-ic3.svg').default} className="img-fluid" alt='documentation-ic3' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      One Drive
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/documentation-ic4.svg').default} className="img-fluid" alt='documentation-ic4' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Google Drive
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/documentation-ic5.svg').default} className="img-fluid" alt='documentation-ic5' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Notion
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/documentation-ic6.svg').default} className="img-fluid" alt='documentation-ic6' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Google Doc
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="product-monitoring">
                          <Row className="technology-info">
                            <Col lg={6} md={12}>
                              <div className="technology-box-main">
                                <h3 className="text-regular mb-25">
                                  Product Monitoring Tools
                                </h3>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/monitoring-ic1.svg').default} className="img-fluid" alt='monitoring-ic1' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Google analytics
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/monitoring-ic2.svg').default} className="img-fluid" alt='monitoring-ic2' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Firebase
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../assets/images/technology/monitoring-ic3.svg').default} className="img-fluid" alt='monitoring-ic3' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Datadog
                                    </p>
                                  </div>

                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </div>
              </Tab.Container>
            </div>
          </div>
          {/* section 2 end*/}
          {/* section 3 */}
          <div className="services-portfolio-main ptb-80">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      <span>Our portfolio</span>
                    </h2>

                  </div>
                </Col>
              </Row>

            </Container>
            <Portfolio></Portfolio>
          </div>
          {/* section 3 end*/}

        </div>
      </main>
    </>
  )
}
