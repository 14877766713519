import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import BuidTogether from '../sliderdata/BuildTogether.json';
import Marquee from "react-fast-marquee";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { Helmet } from 'react-helmet-async';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function Culture() {
    const CustomArrow = ({ direction, onClick }) => {
        const icon = direction === 'next' ? faChevronRight : faChevronLeft;

        return (
            <div
                className={`custom-arrow ${direction}`}
                style={{ zIndex: '1', display: 'block' }}
                onClick={onClick}
            >
                <FontAwesomeIcon icon={icon} />
            </div>
        );
    };

    const buildslider = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false, // Set to true if you want autoplay
        autoplaySpeed: 2000,
        nextArrow: <CustomArrow direction="next" />,
        prevArrow: <CustomArrow direction="prev" />,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                },
            },
        ],
    };


    const settings = {
        slidesToShow: 11,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 10000,
        cssEase: "linear",
        Infinity: false,
        responsive: [

            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }

        ]
    };
    const certification2020 = {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        Infinity: true,
        responsive: [

            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const certification2021 = {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        Infinity: true,
        responsive: [

            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const certification2022 = {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        Infinity: true,
        responsive: [

            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    // 
    const culture = {
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 50000,
        cssEase: "linear",
        Infinity: false,
        responsive: [

            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 5500,
                }
            }
        ]
    };
    const imageList = [
        'eventimg1.jpg',
        'eventimg2.jpg',
        'eventimg3.jpg',
        'eventimg4.jpg',
        'eventimg5.jpg',
        'eventimg6.jpg',
        'eventimg7.jpg',
        'eventimg8.jpg',
        'eventimg9.jpg',
        'eventimg10.jpg',
        'eventimg22.jpg',
        'eventimg23.jpg',
        'eventimg24.jpg',
        'eventimg25.jpg'
    ];
    const culture2 = {
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 50000,
        cssEase: "linear",
        rtl: true,
        Infinity: false,
        responsive: [

            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 5500
                }
            }
        ]
    };
    const imageList2 = [
        'eventimg11.jpg',
        'eventimg12.jpg',
        'eventimg13.jpg',
        'eventimg14.jpg',
        'eventimg15.jpg',
        'eventimg16.jpg',
        'eventimg17.jpg',
        'eventimg18.jpg',
        'eventimg19.jpg',
        'eventimg20.jpg',
        'eventimg21.jpg',
        'eventimg26.jpg',
        'eventimg27.jpg'
    ];
    return (
        <>
            <Helmet>
                <title>One Team, Countless Talents: Cultivating Collaboration</title>
                <meta name="title" content="One Team, Countless Talents: Cultivating Collaboration" />
                <meta name="keywords" content="Team Collaboration, Diverse Talents, Cultivating Teamwork, One Team, Employee Collaboration, Team Synergy, Company Culture, Collaborative Work Environment, Team Success, Talent Development" />
                <meta name="description" content="Events At Sensussoft " />
            </Helmet>
            <main>
                <div className='culture-main-page'>
                    {/* section  1*/}
                    <div className="we-are-sensussoft-section pt-40  ">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="we-are-text-main">
                                        <div className="ss-heading proof-text">
                                            <div className="bg-hover">
                                                <h1> Hey, we're <span> Sensussoft.    <img className="img-fluid" src={require('../assets/images/hand.svg').default} alt="about-skype" /></span><span></span> </h1>
                                            </div>
                                            <p className="text-regular ptb-20">We are a global Software Development Company making success stories for over 8 Years. We create meaningful, high-quality, and performant software products that inspire. We want to inspire you, your customers, and ourselves every day.</p>
                                        </div>
                                        <div className="we-are-img pt-40">
                                            <img className="img-fluid" src={require('../assets/images/culture/who-we.jpg')} alt="about-skype" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* section  1 end*/}
                    {/* section 2 */}
                    <div className='learning-opportunities-main ptb-80'>
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="ss-heading ss-portfolio-text">
                                        <h2>Our Learning Opportunities <span> For your Growth  </span></h2>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pt-50">
                                <Col lg={6} md={6} className="mb-sm-20 mb-md-20">
                                    <div className="learningbox-main learningbox1 mb-sm-20 mb-md-20" style={{ backgroundColor: '#003ADC' }}>
                                        <div className="learningbox-ic">
                                            <img className="img-fluid" src={require('../assets/images/culture/learning-ic1.svg').default} alt="learning-ic1" />

                                        </div>
                                        <div className="learning-text pt-100">
                                            <h2>
                                                Training Programs
                                            </h2>
                                            <p className="pt-10 text-regular">
                                                Sensussoft supports up to one month's salary annually for participation in eligible program fees/tuition, classes, courses, certificates, mentoring programs, etc.. <br /> <br />Join us in our commitment to fostering a culture of continuous learning and development.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} className="mb-sm-20 mb-md-20">
                                    <div className="learningbox-main learningbox2" style={{ backgroundColor: '#001B50' }}>
                                        <div className="learningbox-ic">
                                            <img className="img-fluid" src={require('../assets/images/culture/learning-ic2.svg').default} alt="learning-ic2" />
                                        </div>
                                        <div className="learning-text pt-100">
                                            <h2>
                                                Udemy Courses
                                            </h2>
                                            <p className="pt-10 text-regular">
                                                At Sensussoft, we believe in the power of continuous learning and knowledge sharing.
                                                <br /> <br />Whether it's technology or soft skills, our employees have the opportunity to enhance their personal growth by taking online courses any day of the week, including weekends.
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* section 2 end*/}
                    {/* section 3 */}
                    <div className="countless-talents ptb-80">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="countless-title-main">
                                        <div className="countless-title our-vision-text-main d-flex ">
                                            <div className=" vision-img-main">
                                                <div className="countless-title-img">
                                                    <img className="img-fluid" src={require('../assets/images/culture/coutless-title-img.svg').default} alt="vision-ic"></img>
                                                </div>
                                            </div>
                                            <div className="our-vision-text ">
                                                <h2>
                                                    Why We’re Different
                                                </h2>
                                            </div>

                                        </div>
                                        <div className="ss-heading proof-text pt-10">
                                            <h2> One Team,  <span> Countless Talents </span>   <sup><img className="img-fluid" src={require('../assets/images/culture/star.svg').default} alt="vision-ic"></img></sup>  </h2>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                        <Row className='pt-50'>
                            <Col lg={12}>
                                <Slider {...settings} className='one-team-main'>

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img1.jpg')} alt="vision-ic"></img>
                                    </div>


                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img2.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img4.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img5.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img6.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img talent-logo'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img7.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img8.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img9.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img10.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img11.jpg')} alt="vision-ic"></img>
                                    </div>


                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img12.jpg')} alt="vision-ic"></img>
                                    </div>


                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img13.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img1.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img2.jpg')} alt="vision-ic"></img>
                                    </div>

                                    {/* <div className='talents-img'>
                                    <img className="img-fluid" src={require('../assets/images/culture/talent-img3.png')} alt="vision-ic"></img>
                                </div> */}

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img4.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img5.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img talent-logo'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img7.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img8.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img9.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img10.jpg')} alt="vision-ic"></img>
                                    </div>

                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img11.jpg')} alt="vision-ic"></img>
                                    </div>


                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img12.jpg')} alt="vision-ic"></img>
                                    </div>


                                    <div className='talents-img'>
                                        <img className="img-fluid" src={require('../assets/images/culture/talent-img13.jpg')} alt="vision-ic"></img>
                                    </div>



                                </Slider>
                            </Col>
                        </Row>
                    </div>
                    {/* section 3 end*/}
                    {/* section 4  */}
                    <div className="build-section ptb-80">
                        <Container>
                            <Row >
                                <Col lg={12}>
                                    <div className="ss-heading ss-portfolio-text">
                                        <h2>We scratch,  <span> build and
                                            play together.  </span></h2>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="bulid-togther-slider pt-50">

                                <Col lg={12}>
                                    <div className="buildtogether-slider testimonials-box-main ">

                                        <Slider {...buildslider}>
                                            {BuidTogether.map((build) => (
                                                <div className="buildtogether-slider-main d-flex">
                                                    <div key={build.id} className="build-box-main" style={{
                                                        backgroundImage: `url(${require('../assets/images/culture/' + build.buildbgimg)})`
                                                    }}>
                                                        <div className="build-box-content">
                                                            <h3 className='text-regular ss-title' style={{ whiteSpace: 'pre-line' }}>
                                                                {build.buildtext}
                                                            </h3>
                                                            <p className='text-regular pt-10'>
                                                                {build.buildsubtext}
                                                            </p>
                                                        </div>
                                                        <div className="bulid-ic">
                                                            <img className="img-fluid" src={require(`../assets/images/culture/${build.buildic}`)} alt="build-ic1" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>


                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* section 4  end*/}
                    {/* section5 */}
                    <div className="friday-evenings ptb-80">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="ss-heading ss-portfolio-text">
                                        <h2>We go crazy on    <span> Friday Evenings </span></h2>
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                        <Container fluid>
                            <Row className='pt-80'>
                                <Col lg={12}>
                                    <div className="fridayevent1-slider">
                                        <Slider {...culture}>
                                            {/* Map over imageList and render each image */}
                                            {imageList.map((image, index) => (
                                                <div key={index} className='com_culture_memories friday-events'>
                                                    <img className='img-fluid' src={require(`../assets/images/event-images/${image}`)} alt={`eventimg${index + 1}`} />
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                    <div className='fridayevent2-slider'>
                                        <Slider {...culture2}>
                                            {/* Map over imageList and render each image */}
                                            {imageList2.map((image, index) => (
                                                <div key={index} className='com_culture_memories friday-events'>
                                                    <img className='img-fluid' src={require(`../assets/images/event-images/${image}`)} alt={`eventimg${index + 11}`} />
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* section 6 */}
                    <div className='workshops-main ptb-80'>
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="ss-heading ss-portfolio-text">
                                        <h2>Our Noteworthy   <span> workshops. </span></h2>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pt-50'>
                                <Col lg={12} >
                                    <div className="workshops">
                                        <ul id="cards ">
                                            <li className="card" id="card_1">
                                                <div className="card__content" style={{ backgroundColor: '#001B50' }}>
                                                    <div className='workshops-cards'>
                                                        <Row>
                                                            <Col lg={6} className="align-self-center">
                                                                <div className='workshops-title'>
                                                                    <h3 className='pb-25'>
                                                                        Internal knowledge sharing sessions
                                                                    </h3>
                                                                    <div className="workshops-point ">
                                                                        <div className="workshops-point-box mb-15">
                                                                            <h4>
                                                                                &#8226; 💻 For a day we learned & tested together. 💪 Our workshop started with ice breakers to create a fun atmosphere.
                                                                            </h4>
                                                                        </div>
                                                                        <div className="workshops-point-box mb-15">
                                                                            <h4>
                                                                                &#8226; We got to know the art of engineering and profession of Software Engineering.
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col lg={6} className="align-self-center text-center">
                                                                <div className='workshopimg-main'>
                                                                    <img className="img-fluid" src={require('../assets/images/culture/workshopimg1.jpg')} alt="about-skype" />
                                                                    <div className="web-build-box">
                                                                        <div className="build-text">
                                                                            <h5>
                                                                                500+ <span>Attendees</span>
                                                                            </h5>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="card" id="card_2">
                                                <div className="card__content" style={{ backgroundColor: ' #003ADC' }}>
                                                    <div className='workshops-cards'>
                                                        <Row>
                                                            <Col lg={6} className="align-self-center">
                                                                <div className='workshops-title'>
                                                                    <h3 className='pb-25'>
                                                                        Seminars
                                                                    </h3>
                                                                    <div className="workshops-point ">
                                                                        <div className="workshops-point-box mb-15">
                                                                            <h4>
                                                                                &#8226;  Our CEO, presented at an event, shared valuable insights from our playbook & systems.
                                                                            </h4>
                                                                        </div>
                                                                        <div className="workshops-point-box mb-15">
                                                                            <h4>
                                                                                &#8226;    Attendees gained knowledge on policies,
                                                                                procedures, and our journey from a small
                                                                                office to a team of 60+ people.                                                                </h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col lg={6} className="align-self-center text-center">
                                                                <div className='workshopimg-main'>
                                                                    <img className="img-fluid" src={require('../assets/images/culture/workshopimg2.jpg')} alt="workshopimg2" />
                                                                    <div className="web-build-box">
                                                                        <div className="build-text">
                                                                            <h5 style={{ color: '#003ADC' }}>
                                                                                500+ <span>Attendees</span>
                                                                            </h5>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="card" id="card_3">
                                                <div className="card__content" style={{ backgroundColor: ' #26AFFB' }}>
                                                    <div className='workshops-cards'>
                                                        <Row>
                                                            <Col lg={6} className="align-self-center">
                                                                <div className='workshops-title'>
                                                                    <h3 className='pb-25'>
                                                                        Anniversary Celebration
                                                                    </h3>
                                                                    <div className="workshops-point ">
                                                                        <div className="workshops-point-box mb-15">
                                                                            <h4>
                                                                                &#8226; On 12th June, the Sensussoft family celebrated
                                                                                the 7th-anniversary annual function.                                                                </h4>
                                                                        </div>
                                                                        <div className="workshops-point-box mb-15">
                                                                            <h4>
                                                                                &#8226;  We rewarded our goal-getters with well-deserved tokens of appreciation. 🏆                                                                </h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col lg={6} className="align-self-center text-center">
                                                                <div className='workshopimg-main'>
                                                                    <img className="img-fluid" src={require('../assets/images/culture/workshopimg3.jpg')} alt="workshopimg3" />
                                                                    <div className="web-build-box">
                                                                        <div className="build-text">
                                                                            <h5 style={{ color: '#7B7B7B' }}>
                                                                                500+ <span>Attendees</span>
                                                                            </h5>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="card" id="card_4">
                                                <div className="card__content" style={{ backgroundColor: '#F9D14B' }}>
                                                    <div className='workshops-cards'>
                                                        <Row>
                                                            <Col lg={6} className="align-self-center">
                                                                <div className='workshops-title'>
                                                                    <h3 className='pb-25' style={{ color: '#001B50' }}>
                                                                        Company outing
                                                                    </h3>
                                                                    <div className="workshops-point ">
                                                                        <div className="workshops-point-box workshops-point-black mb-15">
                                                                            <h4>
                                                                                &#8226; Sensussoft organized a movie outing to foster team bonding and create a fun experience for participants.                                          </h4>
                                                                        </div>
                                                                        <div className="workshops-point-box workshops-point-black mb-15">
                                                                            <h4>
                                                                                &#8226;   It was the perfect opportunity to unwind, have fun, and connect with a team outside of the office walls.
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>

                                                            <Col lg={6} className="align-self-center text-center">
                                                                <div className='workshopimg-main'>
                                                                    <img className="img-fluid" src={require('../assets/images/culture/workshopimg4.jpg')} alt="workshopimg4" />
                                                                    <div className="web-build-box">
                                                                        <div className="build-text">
                                                                            <h5 style={{ color: '#001B50' }}>
                                                                                200+ <span>Attendees</span>
                                                                            </h5>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* section 6 end*/}
                    {/* section5 end*/}
                    {/*section 7 */}
                    <div className="achivement-main">
                        <div className="achivement-text ss-story  container-fluid p-0 pt-20 d-flex">
                            <Marquee speed={50} >
                                <span>
                                    Our Achievement
                                    <img className="img-fluid" src={require('../assets/images/culture/star2.svg').default} alt="star2"></img>
                                </span>
                                <span>
                                    Our Achievement   <img className="img-fluid" src={require('../assets/images/culture/star2.svg').default} alt="star2"></img>
                                </span>
                                <span>
                                    Our Achievement    <img className="img-fluid" src={require('../assets/images/culture/star2.svg').default} alt="star2"></img>
                                </span>
                                <span>
                                    Our Achievement    <img className="img-fluid" src={require('../assets/images/culture/star2.svg').default} alt="star2"></img>
                                </span>
                                <span>
                                    Our Achievement    <img className="img-fluid" src={require('../assets/images/culture/star2.svg').default} alt="star2"></img>
                                </span>
                                <span>
                                    Our Achievement    <img className="img-fluid" src={require('../assets/images/culture/star2.svg').default} alt="star2"></img>
                                </span>
                                <span>
                                    Our Achievement    <img className="img-fluid" src={require('../assets/images/culture/star2.svg').default} alt="star2"></img>
                                </span>
                                <span>
                                    Our Achievement    <img className="img-fluid" src={require('../assets/images/culture/star2.svg').default} alt="star2"></img>
                                </span>
                                <span>
                                    Our Achievement    <img className="img-fluid" src={require('../assets/images/culture/star2.svg').default} alt="star2"></img>
                                </span>
                                <span>
                                    Our Achievement    <img className="img-fluid" src={require('../assets/images/culture/star2.svg').default} alt="star2"></img>
                                </span>
                            </Marquee>
                        </div>

                        <div className="award-section ptb-80">
                            <Container>
                                <Row>
                                    <Col lg={3} md={6} className='mb-sm-20 mb-md-20'>
                                        <div className="award-box">
                                            <div className='award-img text-center'>
                                                <img className='img-fluid m-auto' src={require('../assets/images/culture/award1.jpg')} alt="award1"></img>
                                            </div>
                                            <div className='award-content mt-25'>
                                                <div className='award-name-main d-flex'>
                                                    <div className='award-ic align-self-center'>
                                                        <img className='img-fluid m-auto' src={require('../assets/images/culture/award-ic.svg').default} alt="award1"></img>
                                                    </div>
                                                    <div className='award-name align-self-center'>
                                                        <p className='sm-text-semibold'>
                                                            LEADERSHIP INTEGRITY PROGRAM

                                                        </p>
                                                    </div>
                                                </div>

                                                <p className='award-text sm-text-semibold text-regular pt-15'>
                                                    We are proud to have been recognized with the LIP Award - Leadership Integrity Program. This prestigious award highlights our dedication to upholding the values of integrity in our leadership practices.

                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6} className='mb-sm-20 mb-md-20'>
                                        <div className="award-box">
                                            <div className='award-img text-center'>
                                                <img className='img-fluid m-auto' src={require('../assets/images/culture/award2.jpg')} alt="award1"></img>
                                            </div>
                                            <div className='award-content mt-25'>
                                                <div className='award-name-main d-flex'>
                                                    <div className='award-ic align-self-center'>
                                                        <img className='img-fluid m-auto' src={require('../assets/images/culture/award-ic.svg').default} alt="award1"></img>
                                                    </div>
                                                    <div className='award-name align-self-center'>
                                                        <p className='sm-text-semibold'>
                                                            PLAYFIE COLLABORATION AWARD

                                                        </p>
                                                    </div>
                                                </div>

                                                <p className='award-text sm-text-semibold text-regular pt-15'>
                                                    We are humbled and delighted to be bestowed with the prestigious PLAYFIE COLLABORATION AWARD. This recognition is a reflection of the strong partnership we have established with Playfie and the trust they have placed in us.

                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6} className='mb-sm-20 mb-md-20'>
                                        <div className="award-box">
                                            <div className='award-img text-center'>
                                                <img className='img-fluid m-auto' src={require('../assets/images/culture/award3.jpg')} alt="award1"></img>
                                            </div>
                                            <div className='award-content mt-25'>
                                                <div className='award-name-main d-flex'>
                                                    <div className='award-ic align-self-center'>
                                                        <img className='img-fluid m-auto' src={require('../assets/images/culture/award-ic.svg').default} alt="award1"></img>
                                                    </div>
                                                    <div className='award-name align-self-center'>
                                                        <p className='sm-text-semibold'>
                                                            VALUED PARTNER AWARD
                                                        </p>
                                                    </div>
                                                </div>

                                                <p className='award-text sm-text-semibold text-regular pt-15'>
                                                    We feel honored and privileged to receive the VALUED PARTNER AWARD. We are grateful for the trust and support that our partner has placed in us. This award is a testament to our ongoing commitment to delivering the highest quality of service and products to our customers.

                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={3} md={6} className='mb-sm-20 mb-md-20'>
                                        <div className="award-box">
                                            <div className='award-img text-center'>
                                                <img className='img-fluid m-auto' src={require('../assets/images/culture/award4.jpg')} alt="award1"></img>
                                            </div>
                                            <div className='award-content mt-25'>
                                                <div className='award-name-main d-flex'>
                                                    <div className='award-ic align-self-center'>
                                                        <img className='img-fluid m-auto' src={require('../assets/images/culture/award-ic.svg').default} alt="award1"></img>
                                                    </div>
                                                    <div className='award-name align-self-center'>
                                                        <p className='sm-text-semibold'>
                                                            ITCA MEMBERSHIP CERTIFICATE

                                                        </p>
                                                    </div>
                                                </div>

                                                <p className='award-text sm-text-semibold text-regular pt-15'>
                                                    We are delighted to be awarded the ITCA MEMBERSHIP CERTIFICATE, signifying our affiliation with the esteemed IT Companies Association. This recognition highlights our commitment to upholding the highest standards of professionalism and innovation.

                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    {/*section 7 end*/}
                    {/* section 8 */}
                    <div className="certification ptb-80">
                        <Tab.Container id="certification-tabs" defaultActiveKey="2022">
                            <Container fluid >
                                <Container>
                                    <Row className="ss-case-study pb-50">
                                        <Col lg={8} className='align-self-center'>
                                            <div className="ss-heading ss-portfolio-text"><h2>Appreciation<span> & Certification</span></h2></div>
                                        </Col>
                                        <Col lg={4}>
                                            <Nav variant="pills" className="flex-row">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="2020" aria-label='year'>2020</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="2021" aria-label='year'>2021</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="2022" aria-label='year'>2022</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                    </Row>

                                </Container>
                                <Row className='certification-tab-content'>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="2020">
                                                <Slider {...certification2020} className='certification-slider'>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img1.jpg')} alt="certification-img1"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img2.jpg')} alt="certification-img2"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img3.jpg')} alt="certification-img3"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img4.jpg')} alt="certification-img4"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img5.jpg')} alt="certification-img5"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img6.jpg')} alt="certification-img6"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img7.jpg')} alt="certification-img7"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img8.jpg')} alt="certification-img8"></img>
                                                        </div>
                                                    </div>
                                                </Slider>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="2021">
                                                <Slider {...certification2021} className='certification-slider'>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img25.jpg')} alt="certification-img1"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img26.jpg')} alt="certification-img2"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img27.jpg')} alt="certification-img3"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img28.jpg')} alt="certification-img4"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img29.jpg')} alt="certification-img5"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img30.jpg')} alt="certification-img6"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img31.jpg')} alt="certification-img7"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img32.jpg')} alt="certification-img8"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img33.jpg')} alt="certification-img8"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img34.jpg')} alt="certification-img8"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img35.jpg')} alt="certification-img8"></img>
                                                        </div>
                                                    </div>
                                                </Slider>

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="2022">
                                                <Slider {...certification2022} className='certification-slider'>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img9.jpg')} alt="certification-img9"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Creative game changer
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img10.jpg')} alt="certification-img11"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Role model of Integrity
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img11.jpg')} alt="certification-img11"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Quick Learner
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img12.jpg')} alt="certification-img4"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img13.jpg')} alt="certification-img4"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Employee of the Year
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img14.jpg')} alt="certification-img4"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                The One Man Army
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img15.jpg')} alt="certification-img4"></img>
                                                        </div>
                                                    </div>

                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                The Moral Hero
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img17.jpg')} alt="certification-img4"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Best Learner
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img18.jpg')} alt="certification-img4"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Beyond Grateful
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img19.jpg')} alt="certification-img4"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                The Silent Soldier
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img20.jpg')} alt="certification-img4"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Beyond Grateful
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img21.jpg')} alt="certification-img4"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Super Star Award
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img22.jpg')} alt="certification-img4"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                The One Man Army
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img23.jpg')} alt="certification-img4"></img>
                                                        </div>
                                                    </div>
                                                    <div className='certification2021-box'>
                                                        <div className='certification-title'>
                                                            <h3>
                                                                Quick Bug Fixer
                                                            </h3>
                                                        </div>
                                                        <div className="certification-img">
                                                            <img className="img-fluid" src={require('../assets/images/culture/certification-img24.jpg')} alt="certification-img4"></img>
                                                        </div>
                                                    </div>
                                                </Slider>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab.Container>
                    </div>
                    {/* section 8 end*/}

                </div>
            </main>
        </>
    )
}
