import React from 'react';
import { Col, Container, Row, Stack } from 'react-bootstrap';
import Hiringproslider from '../components/Hiringproslider';
import Ourculture from '../sliderdata/Ourculture.json';
import Ourculture2 from '../sliderdata/Ourculture2.json';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Slider from 'react-slick';
import Requirementjiob from '../components/Requirementjiob';
import Applyjob from '../components/Applyjob';
import { Helmet } from 'react-helmet-async';
// import { Link } from 'react-router-dom';



const Career = () => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 50000,
    cssEase: "linear",
    Infinity: false,

  };
  const culture = {
    dots: false,
    arrows: false,
    Infinity: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,

        }
      },
    ]
  };

  return (
    <>

      <Helmet>
        <title> Join Our Team: Careers at Sensussoft Software</title>
        <meta name="title" content="Join Our Team: Careers at Sensussoft Software" />
        <meta name="keywords" content="Sensussoft careers, Join our team, Job openings, Software jobs, IT careers, Developer positions, Work at Sensussoft, Tech jobs, Career opportunities, Sensussoft hiring" />
        <meta name="description" content="Sensussoft is a company that values innovation, diversity, and success within a collaborative work environment." />
      </Helmet>
      <main>
        <div className='career_page_main'>
          {/* Do you have the talents and the dream to do something exceptional? start */}
          <div className="our_talent_and_dream pt-80">
            <Container>
              <Row>
                <Col>
                  <div className="ss-heading">
                    <h1>
                      Do you have the talents and the dream to do <span>something exceptional?</span>
                    </h1>

                  </div>
                </Col>
              </Row>
              <Row className='pt-150 pb-120'>
                <Col>
                  <div className='exception_com_culture'>
                    <Slider  {...culture} >

                      <div className="careere-main-images section-c1-box1 flex-fill">
                        <div className='sans-culture-mem'>
                          <img className='img-fluid' src={require('../assets/images/career-c1.jpg')} alt='career' />
                        </div>
                        <p className='sm-text-semibold'>5 days working</p>
                      </div>
                      <div className="careere-main-images section-c1-box2 flex-fill">
                        <div className='sans-culture-mem'>
                          <img className='img-fluid' src={require('../assets/images/career-c2.jpg')} alt='career' />
                        </div>
                        <p className='sm-text-semibold'>Friendly culture</p>
                      </div>


                      <div className="careere-main-images section-c1-box3 flex-fill">
                        <div className='sans-culture-mem'>
                          <img className='img-fluid' src={require('../assets/images/career-c3.jpg')} alt='career' />
                        </div>
                        <p className='sm-text-semibold'>Performance Bonus</p>
                      </div>


                      <div className="careere-main-images section-c1-box4 flex-fill">
                        <div className='sans-culture-mem'>
                          <img className='img-fluid' src={require('../assets/images/career-c4.jpg')} alt='career' />
                        </div>
                        <p className='sm-text-semibold'>Flexible Timing</p>
                      </div>

                      <div className="careere-main-images section-c1-box5 flex-fill">
                        <div className='sans-culture-mem'>
                          <img className='img-fluid' src={require('../assets/images/career-c5.jpg')} alt='career' />
                        </div>
                        <p className='sm-text-semibold'>Engagement activities</p>
                      </div>

                      {/* </Stack> */}
                    </Slider>
                  </div>
                </Col>
              </Row>
              {/* <Row className='pt-150 pb-120'>
              <Col>
                <Stack direction="horizontal" gap={5} className='ss_sens_culture_main justify-content-between' >
                  <div className="careere-main-images section-c1-box1 flex-fill">
                    <div className='sans-culture-mem'>
                      <img className='img-fluid' src={require('../assets/images/career-c1.png')} alt='career' />
                    </div>
                    <p className='sm-text-semibold'>Work Life Balance</p>
                  </div>
                  <div className="careere-main-images section-c1-box2 flex-fill">
                    <div className='sans-culture-mem'>
                      <img className='img-fluid' src={require('../assets/images/career-c1.png')} alt='career' />
                    </div>
                    <p className='sm-text-semibold'>Competitive Salary</p>
                  </div>
                  <div className="careere-main-images section-c1-box3 flex-fill">
                    <div className='sans-culture-mem'>
                      <img className='img-fluid' src={require('../assets/images/career-c1.png')} alt='career' />
                    </div>
                    <p className='sm-text-semibold'>Work Life Balance</p>
                  </div>
                  <div className="careere-main-images section-c1-box4 flex-fill">
                    <div className='sans-culture-mem'>
                      <img className='img-fluid' src={require('../assets/images/career-c1.png')} alt='career' />
                    </div>
                    <p className='sm-text-semibold'>Friendly Office Culture</p>
                  </div>
                  <div className="careere-main-images section-c1-box5 flex-fill">
                    <div className='sans-culture-mem'>
                      <img className='img-fluid' src={require('../assets/images/career-c1.png')} alt='career' />
                    </div>
                    <p className='sm-text-semibold'>Work Life Balance</p>
                  </div>
                </Stack>
              </Col>
            </Row> */}
            </Container>
          </div>
          {/* Do you have the talents and the dream to do something exceptional? end */}
          {/* <A home for dreamers, designers, engineers, idealists, managers & thinkers.> */}
          <div className='our_homes_for_dreams ptb-80' style={{ background: 'linear-gradient(180deg, #F4F5F9 0%, rgba(244, 245, 249, 0) 100%)' }}>
            <Container>
              <Row className="justify-content-center">
                <Col lg="10">
                  <div className='our_cluture_video mb-40'>
                    <div className='our_cluture_video_head'>
                      <h2>A home for dreamers, designers, engineers, idealists, managers & thinkers.</h2>
                      <p>We are Sensussoft, a company full of motivated goal-getters who make the dreams of people all around the globe a reality with innovation,
                        out-of-the-box thinking, dedication, and talent.</p>
                    </div>
                  </div>
                </Col>
                <Col lg="12">
                  <div className='our_cluture_video_img'>
                    <video className="career-video w-100" controls poster={require('../assets/images/career-vframe.png')} >
                      <source src={require("../assets/images/career-video.mp4")} type="video/mp4" />
                    </video>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* </A home for dreamers, designers, engineers, idealists, managers & thinkers. */}
          {/* <Job Openings start */}
          <div id='joiningcomp'>
            <Requirementjiob />
          </div>
          {/* <Job Openings end */}
          {/* <Hiring Process strat> */}
          <div className='hiring_process_blog pt-80 pb-150' style={{ backgroundColor: '#F4F5F9' }}>
            <Container>
              <Row>
                <Col lg="12">
                  <div className='hiring_heading'>
                    <div className="nurture-creativity-title pb-20">
                      <div className="our-vision-text-main d-flex">
                        <div className="bd-highlight vision-img-main">
                          <div className="our-vision-img">
                            <img className="img-fluid" src={require('../assets/images/vision-ic.svg').default} alt="vision-ic"></img>
                          </div>
                        </div>
                        <div className="our-vision-text">
                          <h5>
                            Hiring Process
                          </h5>
                        </div>
                      </div>
                      <div className="our-vision-heading">
                        <h2 className="lg-text-light ptb-15">
                          Discover Your Future<br />
                          <span>Join Us Today!</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                </Col>
                {/* <slider of hiring process start  */}
                <Hiringproslider />
                {/* <slider of hiring process end */}
              </Row>
            </Container>

          </div>
          {/* <Hiring Process end> */}
          {/* <Didn’t found what you been looking for? start> */}
          <div className='our_looking_process'>
            <Container>
              <Row>
                <Col>
                  <Stack className='looking_all_design' direction="horizontal">
                    <div className="loogin_design1 align-items-center">
                      <h2 className='ss-tittle'>
                        Didn’t found what you been<br />
                        <span>looking for?</span>
                      </h2>
                      <p>If you have what it takes to become a goal-getter and your talent was not listed above, come and prove yourself.</p>
                    </div>

                    <div className="loogin_design1 align-items-center ms-auto">
                      <div className="comm-btn ">
                        <button className="learn-more" aria-label='apply job'>
                          <Applyjob image={require('../assets/images/btn-arrow.svg').default} />

                        </button>
                      </div>
                      {/* <div className="comm-btn ">
                      <button className="learn-more arrow-btn">
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </div> */}
                    </div>
                  </Stack>
                </Col>
              </Row>
            </Container>
          </div>
          {/* <Didn’t found what you been looking for? end> */}
          {/* <Join us in our journey To the Infinity  start.> */}
          <div className='join_our_journey ptb-80'>
            <Container>
              <Row>
                <Col>
                  <div className="our-vision-heading">
                    <h2 className="lg-text-light">
                      Discover Your Future<br />To the
                      <span> Infinity</span>
                    </h2>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container fluid>
              <Row>
                <Col>
                  <Slider {...settings}>
                    <div>
                      <div className='our_culture_memories pt-30'>
                        <ImageList variant="masonry" sx={{
                          columnCount: {
                            xs: '2 !important',
                            sm: '5 !important',
                            md: '5 !important',
                            lg: '5 !important',
                            xl: '5 !important',
                          },
                        }} gap={15}>
                          {Ourculture.map((items1, i) => (
                            <ImageListItem className='com_culture_memories' key={items1 + i}>
                              <img className='img-fluid' src={require(`../assets/images/our-culture/${items1.cultureimg}`)} alt={items1.tittle} />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </div>
                    </div>
                    <div>
                      <div className='our_culture_memories pt-30'>
                        <ImageList variant="masonry" sx={{
                          columnCount: {
                            xs: '2 !important',
                            sm: '5 !important',
                            md: '5 !important',
                            lg: '5 !important',
                            xl: '5 !important',
                          },
                        }} gap={15}>
                          {Ourculture2.map((items1, i) => (
                            <ImageListItem className='com_culture_memories' key={items1 + i}>
                              <img className='img-fluid' src={require(`../assets/images/our-culture/${items1.cultureimg}`)} alt={items1.tittle} />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </div>
                    </div>
                  </Slider>
                </Col>
              </Row>
            </Container>
          </div>
          {/* <Join us in our journey To the Infinity end.> */}
        </div>
      </main>
    </>
  );
};

export default Career;