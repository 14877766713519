import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Col, Container, Row, Stack } from 'react-bootstrap'
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Portfolio() {
  const baseurl = "https://api.sensussoft.com/api/casestudy";
  const [portfolios, setportfolio] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(baseurl)
      .then(response => {
        //   console.log('API Response:', response.data);
        const portfoliodata = response.data.data || []; // Handle empty or invalid data
        setportfolio(portfoliodata);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching blogs:', error);
        setLoading(false);
      });
  }, []);
  const handleFocusManagement = () => {
    // Ensure aria-hidden slides are not focusable
    document.querySelectorAll('.slick-slide[aria-hidden="true"]').forEach(slide => {
      slide.setAttribute('tabindex', '-1');
      slide.querySelectorAll('a, button, input, select, textarea').forEach(elem => {
        elem.setAttribute('tabindex', '-1');
      });
    });
  };

  useEffect(() => {
    handleFocusManagement();
  }, [portfolios]); // Reapply focus management when blogs change

  if (loading) {
    return <div className='blog-loader-main'>
      <div className='blog-loader'>
        <div className='loader-img'>
          <Container>
            <Row className='justify-content-center ptb-100 text-center'>
              <Col lg={12}>
                <div className='loading-content'>
                  <div style={{ position: 'relative', width: '250px', height: '150px' }}>
                    {/* SVG Markup */}
                    <svg width="100%" height="100%" viewBox="0 0 187.3 93.7" preserveAspectRatio="xMidYMid meet" className="svg-animation">
                      <path
                        stroke="#001B50"
                        id="outline"
                        fill="none"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                      />
                      <path
                        id="outline-bg"
                        opacity="0.05"
                        fill="none"
                        stroke="#001B50"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                      />
                    </svg>

                    {/* Custom text or content below the SVG */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container></div>
      </div>
    </div>
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    className: "center",
    centerMode: true,
    arrows: false,
    centerPadding: '350px', // Adjust the padding as needed
    afterChange: handleFocusManagement,
    beforeChange: handleFocusManagement,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          dots: false,
          centerPadding: '250px',
          centerMode: true,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          dots: false,
          centerMode: true,
          centerPadding: '150px',
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: false,
          centerMode: false,
          centerPadding: '50px',
          margin: 0,
        }
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          dots: false,
          centerMode: false,
          centerPadding: '0px',
        }
      }
    ],
  };
  return (

    <div className="service-portfolio-slider  testimonials-box-main pt-50">

      <Slider {...settings}>
        {/* .filter(technology => technology.category !== 'UI/UX') */}
        {portfolios
          .filter(technology => technology.category !== 'UI/UX' && technology.category !== 'Logo Design')
          .map((technology, index) => (
            <div key={index} >
              <div className="ss-portfolio-box-main" style={{ backgroundColor: technology.backimg_detailcolor_code }}>
                <Stack direction="horizontal" className="ss-portfolio-box d-mobile-block">
                  <div className="service-portfolio-text w-50 align-self-center">
                    <h3 className="">
                      {technology.title}
                    </h3>
                    <p className="lg-text-light ptb-10">
                      {technology.description}
                    </p>
                    <div className="comm-btn mb-sm-20 mb-md-20  pt-5">
                      <Link aria-label='casestudydetail' to={`/casestudydetails/${technology.app_url}`}>
                        <button className="learn-more arrow-btn" aria-label='learn more'>
                          <span className="circle" aria-hidden="true">
                            <span className="icon arrow">
                              <img src={require('../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                            </span>
                          </span>
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="service-portfolio-main  m-auto">
                    {/* <Tilt> */}
                    <div className="service-portfolio-img">
                      {/* <img className="img-fluid" src={require(`../assets/images/${portfolios.portfolioimg}`)} alt="portfolio-img1"></img> */}
                      <img src={technology.main_image} className="img-fluid" alt='img' />
                    </div>

                    {/* </Tilt> */}
                  </div>

                </Stack>
              </div>
            </div>
          ))}

      </Slider>

    </div>


  )
}
