import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import HiringDedication from '../../components/HiringDedication';
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import PricingHire from '../../components/PricingHire';
import HireDedicationContect from '../../components/HireDedicationContect';
import { Helmet } from 'react-helmet-async';

export default function HireWordPressDevelopers() {
  const scrollToSection = () => {
    // Scroll to the section containing HireDedicationContect
    const section = document.getElementById('hireDedicationSection');
    if (section) {
      const topOffset = section.offsetTop - 200;
      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
  };
  const CustomArrow = ({ direction, onClick }) => {
    const icon = direction === 'next' ? faChevronRight : faChevronLeft;

    return (
      <div
        className={`custom-arrow ${direction}`}
        style={{ zIndex: '1', display: 'block' }}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  };

  const hireresource = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <Helmet>
        <title>	Custom WordPress Development Services | Sensussoft</title>
        <meta name="title" content="Custom WordPress Development Services | Sensussoft" />
        <meta name="keywords" content="Custom WordPress Development, WordPress Development Services, WordPress Development Company, WordPress Web Development, Sensussoft WordPress Solutions" />
        <meta name="description" content="Explore our specialized WordPress development services tailored for e-commerce, blogging, and business websites. Get custom solutions, theme customization, plugin development, and ongoing support from experienced WordPress developers."></meta>
      </Helmet>
      <main>
        <div className='hiring_dedication_section'>
          <section>
            <div className="mobile-app-development website-development ptb-80 bg-logo">
              <Container >
                <Row>
                  <Col lg={6} className="align-self-center">
                    <div className="website-development-text proof-text-main">
                      <div className="ss-heading proof-text">
                        <h1><span>WordPress Development </span> <br />
                          Company </h1>
                        <p className="text-regular mt-20">
                          At Sensussoft, we specialize in WordPress, a leading content management system (CMS) that powers millions of websites worldwide. Our WordPress development services cater to various industries, including e-commerce, blogging, and business, delivering versatile and scalable solutions.
                        </p>
                      </div>
                      {/* <Link to='/get-in-touch-with-us'> */}
                      <button className="learn-more btn-large mt-25" onClick={scrollToSection} aria-label='contact us for detail'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                        <span className="button-text">CONTACT US FOR DETAILS</span>
                      </button>
                      {/* </Link> */}
                    </div>
                  </Col>
                  <Col lg={6} className="align-self-center text-center">
                    <div className="change_vision_img"><img className="img-fluid" src={require('../../assets/images/hire-devloper/wordpress_img1.png')} alt='service1' /></div>

                    <div className="website-development-img-main">

                      <div className="website-development-img mt-sm-20 mt-md-20">
                        <img className="img-fluid" src={require('../../assets/images/hire-devloper/wordpress_img1.png')} alt="mobile-app-development"></img>
                      </div>


                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <div className="idea-and-enhance bg-service ">
              <Container>
                <Row>
                  <Col lg={6}>
                    <div className="ideation-services">
                      <div className="services-in-action-main mb-md-20">
                        <div className="services-in-action pt-100 pb-200">
                          <div className="our-vision-text-main d-flex">
                            <div className="bd-highlight vision-img-main">
                              <div className="our-vision-img overview-img">
                                <img className="img-fluid" src={require('../../assets/images/overview.svg').default} alt="vision-ic"></img>
                              </div>
                            </div>
                            <div className="our-vision-text">
                              <p>
                                WordPress Development Services
                              </p>
                            </div>
                          </div>
                          <div className="ss-heading ss-portfolio-text">
                            <h2 className="ptb-15">
                              {/* See <span>Product  <br /> Ideation Services  </span> <br />in action. */}
                              Our expert team leverages WordPress to build dynamic, user-friendly, and customized websites that align seamlessly with your business objectives.
                            </h2>

                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={12} className='align-self-center'>
                    <div className="ideation-content ptb-100">
                      <div className="enhance-content-main " >
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Custom WordPress Website Development</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Tailored solutions to meet your unique business requirements.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">WordPress Theme Development & Customization</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Crafting visually appealing and responsive themes for your website
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">WordPress Plugin Development & Integration</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Creating and integrating plugins to enhance functionality and user experience.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">E-commerce Solutions with WooCommerce</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Building robust online stores with the powerful WooCommerce plugin.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Support and Maintenance</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Providing ongoing support to ensure your WordPress website runs smoothly.
                          </p>
                        </div>
                      </div>

                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <div className="tecnology_plus_slider tecnology_plus_slider_nav testimonials ptb-80 mtb-80">
              <div className="container">
                <div className="col-lg-12">
                  <div className="testimonials-section">
                    <div className="ss-heading">
                      <h2>Most-Preferred<br /><span>WordPress</span> Combinations We Cater</h2>
                    </div>
                    <div className='pt-10'>
                      <p className='light-heading'>We provide a range of WordPress combinations to meet diverse project requirements.</p>
                    </div>

                    <div className="testimonials-box-main pt-35" >
                      <Slider {...hireresource} className='ss-testimonial'>
                        <div className='tecnology_hirededicate'>

                          <h3>WordPress  + Elementor</h3>
                          <p>Customize WordPress websites easily with Elementor for stunning visual designs</p>
                        </div>
                        <div className='tecnology_hirededicate'>

                          <h3>WordPress + SEO Optimization</h3>
                          <p>Optimize WordPress websites for better search engine visibility and rankings.</p>
                        </div>
                        <div className='tecnology_hirededicate'>

                          <h4>WordPress + Membership Platforms</h4>
                          <p> Create membership websites on WordPress for exclusive content access.</p>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <Container>
              <Row>
                <Col lg={12} md={12} className='align-self-center'>
                  <div className="ideation-content pb-80">
                    <div className="enhance-content-main ">
                      <div className="enhance-content">
                        <h3 className="text-regular text-center">
                          <span className="lg-text-semibold">Why Choose WordPress Development Service From Sensussoft</span>
                        </h3>
                        <p className="pt-20 light-heading text-center">
                          Our commitment is to deliver cutting-edge WordPress solutions that enhance business growth and efficiency.
                        </p>
                        <div className="sotfware-info pt-40">
                          <Row>
                            <Col lg={6}>
                              <div className="application-title">
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Proactive and Advanced Development Strategies
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Flexible Engagement Models to Suit Client Needs
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Emphasis on User Experience and Performance
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Comprehensive Services from Conceptualization to Deployment
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="application-title">
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Guaranteed Performance and Speed Optimization
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Adherence to High Security and Code Standards
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Ongoing Support and Maintenance Post-Deployment
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Rapid Deployment Capabilities
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section>
            <div className="solve-the-unsolved">
              <Container>
                <Row>
                  <Col lg={6} className='align-self-center'>
                    <div className="solve-img text-center">
                      <img src={require('../../assets/images/hire-devloper/wordpress_img2.png')} className="img-fluid" alt='solve' />
                    </div>
                  </Col>
                  <Col lg={6} className='align-self-center'>
                    <div className="ss-heading ss-portfolio-text">
                      <h2>
                        Reasons to Opt for Our <br /><span> Dedicated Resources</span>
                      </h2>
                      <ul className='dedicated_resources'>
                        <li>Specialized pool of WordPress developers with varied domain expertise.</li>
                        <li>Customized hiring models to align with your project needs.</li>
                        <li>Access to modern infrastructure and advanced development tools.</li>
                        <li>Strong commitment to confidentiality and project security.</li>
                        <li>Adherence to timelines for on-time project delivery.</li>
                        <li>Transparent pricing with no hidden fees.</li>
                        <li>Collaborative work environment ensuring seamless communication.</li>
                        <li>Regular project updates for full transparency.</li>
                        <li>24/7 post-launch support and maintenance services.</li>
                        <li>Satisfaction guarantee with a refund policy if expectations are not met.</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <PricingHire tecnology="Wordpress Developers" click={scrollToSection} />
          </section>
          <section>
            <div className='hiring_process_blog pt-80 pb-80' style={{ backgroundColor: '#F4F5F9' }}>
              <Container>
                <Row>
                  <Col lg="12">
                    <div className='hiring_heading'>
                      <div className="nurture-creativity-title pb-20">
                        <div className="our-vision-text-main d-flex">
                          <div className="bd-highlight vision-img-main">
                            <div className="our-vision-img">
                              <img className="img-fluid" src={require('../../assets/images/vision-ic.svg').default} alt="vision-ic"></img>
                            </div>
                          </div>
                          <div className="our-vision-text">
                            <p>
                              Our Procedure
                            </p>
                          </div>
                        </div>
                        <div className="our-vision-heading">
                          <h2 className="lg-text-light ptb-15">
                            Discover Your Future
                            <span> Join Us Today!</span>
                          </h2>
                          <p style={{ fontWeight: '300' }}>We have simple and smart procedures to get you the best team that gives the best result. To know more about how we build & maintain an exclusive offshore team for you, get in touch with us now.</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <slider of hiring process start  */}
                  <HiringDedication />
                  {/* <slider of hiring process end */}
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <div className="ss-faq-main mb-80">
              <div className="faq-main pt-80 pb-80">
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className="faq-title proof-text-main text-center mb-30">
                        <div className="ss-heading proof-text">
                          <h2><span>Frequently Asked<br />
                            Questions </span></h2>
                        </div>
                      </div>

                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      <div className="faq-accordion">
                        <Accordion defaultActiveKey="0" flush>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Why Choose WordPress for Website Development?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  WordPress offers ease of use, versatility, and a vast ecosystem of themes and plugins.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>What’s the Cost of WordPress Website Development?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Costs vary based on project complexity; contact us for a custom quote.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>How Long Does It Take to Develop a WordPress Website?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Timelines depend on project scope, but we prioritize efficient and quality delivery.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>How Can I Track My WordPress Project’s Progress?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  We provide regular updates and use project management tools for transparency.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>Do You Offer Post-Development Support for WordPress?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Yes, we offer comprehensive after-launch support and maintenance services.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="5">
                            <Accordion.Header>What Pricing Models Do You Offer for WordPress Development?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  We offer flexible pricing models, including fixed, hourly, and dedicated hiring.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>

            </div>
          </section>
          <section id="hireDedicationSection">
            <HireDedicationContect />
          </section>
        </div>
      </main>
    </>
  )
}
