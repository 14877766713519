import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { Col, Container, Row } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Accordion from 'react-bootstrap/Accordion';
// import Tilt from 'react-parallax-tilt';
import { Helmet } from 'react-helmet-async';
import PageNotFound from '../PageNotFound';
import Slider from "react-slick";


export default function Casestudydetails() {

    const [activeTab, setActiveTab] = useState('accordion-1'); // Default to the first accordion

    const handleTabClick = (eventKey) => {
        setActiveTab(eventKey);
    };

    const { app_url } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [singleportfoilo, setsingleportfoilo] = useState(null);
    const [casefunctionalities, setFunctionalities] = useState([]); // Initialize as an empty array
    const [portfoliomainfun, setPortfoliomainfun] = useState([]); // Initialize as an empty array
    const [prevCaseStudyId, setPrevCaseStudyId] = useState(null);
    const [nextCaseStudyId, setNextCaseStudyId] = useState(null);
    const [techLength, setTechLength] = useState(4); // Default value
    // useEffect hook to fetch the data when the component mounts
    useEffect(() => {
        // Fetch blog post details and comments using the API
        // Axios.get(`https://api.sensussoft.com/api/casestudy/${id}`)
        Axios.get(`https://api.sensussoft.com/api/casestudy-demo/${app_url}`)
            .then((response) => {
                // console.log("API Response:", response.data);
                const data = response.data.data;
                const portfolioData = data.singlePortfolioData?.[0] || {};

                setsingleportfoilo(portfolioData);
                setFunctionalities(data.portfolioFunctionality || []);
                setPortfoliomainfun(data.portfolioMainFunctionality || []);
                setPrevCaseStudyId(data.getPrevPortfolio);
                setNextCaseStudyId(data.getNextPortfolio);
                setLoading(false);

                // Count only non-empty tech titles
                const techTitles = [
                    portfolioData.tech_title1,
                    portfolioData.tech_title2,
                    portfolioData.tech_title3,
                    portfolioData.tech_title4,
                ].filter(title => title?.trim() !== ""); // Remove empty values

                setTechLength(techTitles.length || 1); // Ensure at least 1 item
            })
            .catch((error) => {
                setLoading(false);
                navigate('*');
            });
    }, [app_url, navigate]);

    const technology = {
        dots: false,
        infinite: techLength >= 4, // Enable infinite loop only if more than 4 slides
        slidesToShow: Math.min(4, techLength), // Show 4 slides max, otherwise total slides // Show available or max 4
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: techLength < 3 ? techLength : 3,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: techLength < 2 ? techLength : 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                },
            },
        ],
    };
    // Conditional rendering based on the loading status, error, and blog post details
    if (loading) {
        return <div className='blog-loader-main'>
            <div className='blog-loader'>
                <div className='loader-img'>
                    <Container>
                        <Row className='justify-content-center ptb-100 text-center'>
                            <Col lg={12}>
                                <div className='loading-content'>
                                    <div style={{ position: 'relative', width: '250px', height: '150px' }}>
                                        {/* SVG Markup */}
                                        <svg width="100%" height="100%" viewBox="0 0 187.3 93.7" preserveAspectRatio="xMidYMid meet" className="svg-animation">
                                            <path
                                                stroke="#001B50"
                                                id="outline"
                                                fill="none"
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeMiterlimit="10"
                                                d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                                            />
                                            <path
                                                id="outline-bg"
                                                opacity="0.05"
                                                fill="none"
                                                stroke="#001B50"
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeMiterlimit="10"
                                                d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                                            />
                                        </svg>

                                        {/* Custom text or content below the SVG */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container></div>
            </div>
        </div>
    }

    // Handle case when singleportfoilo is null or not found
    if (!singleportfoilo || !singleportfoilo.caseStudyDescription) {
        return (
            <div>
                <PageNotFound></PageNotFound>
            </div>
        );
    }
    return (
        <>
            <Helmet>
                <title>{singleportfoilo.head_title}</title>
                <meta name="title" content={singleportfoilo.meta_title} />
                <meta name="keywords" content={singleportfoilo.meta_tag} />
                <meta name="description" content={singleportfoilo.meta_desc} />
            </Helmet>
            <main>
                <div className="protfolio-detail-main">
                    {/* section 1 */}
                    <div className="protfolio-detail-bg c-visitenkarte-page pt-80 pb-250">
                        <Container>
                            <Row className='next-prev-btn casestudy-next-prve'>
                                <Col lg={6} className='col-6'>
                                    <div>

                                        {prevCaseStudyId && (
                                            <div className="blog-next-btn">
                                                <Link to={`/casestudydetails/${prevCaseStudyId}`} aria-label='casestudy detail'>

                                                    <button className="learn-more arrow-btn" aria-label='casestudy detail'>
                                                        <span className="circle" aria-hidden="true">
                                                            <span className="icon arrow">
                                                                <img src={require('../../assets/images/btn-arrow2.svg').default} className="img-fluid" alt='img' />
                                                            </span>
                                                        </span>
                                                    </button>

                                                </Link>
                                            </div>
                                        )}

                                    </div>
                                </Col>
                                <Col lg={6} className='col-6'>
                                    <div className='text-end'>
                                        {nextCaseStudyId && (
                                            <Link to={`/casestudydetails/${nextCaseStudyId}`} aria-label='next casestudy'>

                                                <button className="learn-more arrow-btn" aria-label='next button'>
                                                    <span className="circle" aria-hidden="true">
                                                        <span className="icon arrow">
                                                            <img src={require('../../assets/images/btn-arrow2.svg').default} className="img-fluid" alt='img' />
                                                        </span>
                                                    </span>
                                                </button>

                                            </Link>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={9}>
                                    <div className="portfolio-heading studym8-portfolio">
                                        <div className="ss-heading ss-portfolio-text text-center">
                                            <h1>
                                                <span>{singleportfoilo.caseStudyTitle}</span>

                                            </h1>
                                            <p className="pt-10 text-regular">
                                                {singleportfoilo.caseStudyDescription}
                                            </p>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                    {/* section 1 end*/}
                    {/* section 2 */}
                    <div className="portfolio-img-main pb-80">
                        <Container>
                            <Row >
                                <Col lg={12}>
                                    <div className='portfolio-grid-box'>
                                        <div className="portfolio-mockup" style={{ backgroundColor: singleportfoilo.caseStudyDetailBackgroundColor }}>
                                            <div className="portfolio-grid">
                                                <div className='portfolio-grid-img'>
                                                    <img src={singleportfoilo.main_image} className="img-fluid " alt='portfolio5-img1' />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <div className='social_all_links d-flex justify-content-center'>
                                        {singleportfoilo.google_icon && (
                                            <Link to={singleportfoilo.google_link} target="_blank" aria-label='playstore'>
                                                <div className="portfolio-detail-img pt-80">
                                                    <img src={singleportfoilo.google_icon} className="img-fluid" alt="Google Play Icon" />
                                                </div>
                                            </Link>
                                        )}

                                        {singleportfoilo.apple_icon && (
                                            <Link to={singleportfoilo.apple_link} target="_blank" aria-label='appstore'>
                                                <div className="portfolio-detail-img  pt-80">
                                                    <img src={singleportfoilo.apple_icon} className="img-fluid" alt="Apple App Store Icon" />
                                                </div>
                                            </Link>
                                        )}

                                        {singleportfoilo.web_icon && (
                                            <Link to={singleportfoilo.web_link} target="_blank" aria-label='web url'>
                                                <div className="portfolio-detail-img  pt-80">
                                                    <img src={singleportfoilo.web_icon} className="img-fluid" alt="Web Icon" />
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                </Col>

                            </Row>

                        </Container>
                    </div>
                    {/* section 2 end*/}
                    {/* section 3 */}
                    {singleportfoilo && singleportfoilo.tab_title1 && singleportfoilo.tab_title2 && singleportfoilo.tab_title3 && singleportfoilo.tab_desc1 && singleportfoilo.tab_desc2 && singleportfoilo.tab_desc3 ? (
                        <div className="tap-to-scan ptb-100">
                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                            <Row className="justify-content-between ">
                                                <Col sm={3} className="align-self-center">
                                                    <div className="portfolio-tab">
                                                        <Nav variant="pills" className="flex-column " role="tablist">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="first">{singleportfoilo.tab_title1}</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="second">{singleportfoilo.tab_title2}</Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="third">{singleportfoilo.tab_title3}</Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </div>
                                                </Col>
                                                <Col sm={8} className="align-self-center">
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="first">
                                                            <div className="portfolio-content">
                                                                <h2 className="light-heading pb-20">
                                                                    The idea of the client
                                                                </h2>
                                                                <div className="portfolio-text">
                                                                    {singleportfoilo.tab_desc1.map((text, index) => (
                                                                        <p className='light-heading mb-20' key={index}>{text.split(',').join('\n')}</p>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="second">
                                                            <div className="portfolio-content">
                                                                <h2 className="light-heading pb-20">
                                                                    About Project
                                                                </h2>
                                                                <div className="portfolio-text">
                                                                    {singleportfoilo.tab_desc2.map((text, index) => (
                                                                        <p className='light-heading mb-20' key={index}>{text.split(',').join('\n')}</p>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="third">
                                                            <div className="portfolio-content">
                                                                <h2 className="light-heading">
                                                                    Function of the app?
                                                                </h2>
                                                                <div className="portfolio-text">
                                                                    {singleportfoilo.tab_desc3.map((text, index) => (
                                                                        <p className='light-heading mb-20' key={index}>{text.split(',').join('\n')}</p>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    ) : null}
                    {/* section 3 end*/}
                    {/* section 4*/}
                    {singleportfoilo && singleportfoilo.challanges_description && singleportfoilo.challanges_image ? (
                        <div className="main-challenges ptb-80">
                            <Container>
                                <Row>
                                    <Col lg={6} className="align-self-center">
                                        <div className="main-challenges">
                                            <div className="ss-heading ss-portfolio-text ">
                                                <h2>
                                                    Main <span>challenges</span>
                                                </h2>

                                                <div className="cms-needed">
                                                    <div className="right-box mb-20 mt-30">
                                                        <div className="right-text">
                                                            {singleportfoilo.challanges_description.map((text, index) => (
                                                                <p className='light-heading mb-20' key={index}>{text.split(',').join('\n')}</p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="align-self-center">

                                        <div className="main-challenges-img text-center">
                                            <img src={singleportfoilo.challanges_image} className="img-fluid " alt='portfolio1-img2' />
                                        </div>

                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    ) : null}
                    {/* section 4 end*/}
                    {/* section 5 */}
                    {casefunctionalities && casefunctionalities.length > 0 ? (
                        <div className="functionalities-main  our_company_benifits ptb-80">
                            <div className='ss_benififts_section'>
                                <Container>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="ss-heading ss-portfolio-text pb-50">
                                                <h2>
                                                    <span>Functionalities</span>
                                                </h2>

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="g-0">
                                        {
                                            casefunctionalities.map((functionalities, index) => (
                                                <Col lg={4} md={6} key={index}>
                                                    <div className="items">
                                                        <div className='hiring_description_s1'>
                                                            <div className="our-hiring-text-dese_s1 align-self-center">
                                                                <h3>
                                                                    {functionalities.title}
                                                                </h3>
                                                                <p>{functionalities.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))
                                        }

                                    </Row>
                                </Container>
                            </div>
                        </div>
                    ) : null}
                    {/* section 5 end*/}
                    {/* section 6 */}
                    {singleportfoilo && singleportfoilo.process_title1 && singleportfoilo.process_title2 && singleportfoilo.process_title3 && singleportfoilo.process_title4 && singleportfoilo.process_title5 && singleportfoilo.process_desc1 && singleportfoilo.process_desc2 && singleportfoilo.process_desc3 && singleportfoilo.process_desc4 && singleportfoilo.process_desc5 ? (
                        <div className="process-main ptb-80">
                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        <div className="ss-heading ss-portfolio-text">
                                            <h2>
                                                <span>Project Development </span> Process
                                            </h2>

                                        </div>
                                    </Col>
                                </Row>
                                <Row className="pt-50">
                                    <Col lg={12}>
                                        <div className="ss-process-tab">
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                                <Row className="justify-content-between">
                                                    <Col lg={4} md={12}>
                                                        <Nav variant="pills" className="flex-column">
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="first">{singleportfoilo.process_title1}<span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="second">{singleportfoilo.process_title2}  <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="third">{singleportfoilo.process_title3} <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="fourth">{singleportfoilo.process_title4}<span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item>
                                                                <Nav.Link eventKey="fifth">{singleportfoilo.process_title5}<span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                                                            </Nav.Item>
                                                        </Nav>
                                                    </Col>
                                                    <Col lg={7} md={12}>
                                                        <Tab.Content className="pt-50">
                                                            <Tab.Pane eventKey="first">
                                                                <div className="ss-process-content">
                                                                    <div className="core-value-img">
                                                                        <img className="img-fluid" src={require('../../assets/images/portfolio-images/process-ic1.svg').default} alt="process-ic1" />
                                                                    </div>

                                                                    <p className="light-heading pt-20">
                                                                        {singleportfoilo.process_desc1}
                                                                    </p>

                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="second">
                                                                <div className="ss-process-content">
                                                                    <div className="core-value-img">
                                                                        <img className="img-fluid" src={require('../../assets/images/portfolio-images/process-ic1.svg').default} alt="process-ic1" />
                                                                    </div>
                                                                    <p className="light-heading pt-20">
                                                                        {singleportfoilo.process_desc2}
                                                                    </p>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="third">
                                                                <div className="ss-process-content">
                                                                    <div className="core-value-img">
                                                                        <img className="img-fluid" src={require('../../assets/images/portfolio-images/process-ic1.svg').default} alt="process-ic1" />
                                                                    </div>
                                                                    <p className="light-heading pt-20">
                                                                        {singleportfoilo.process_desc3}
                                                                    </p>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="fourth">
                                                                <div className="ss-process-content">
                                                                    <div className="core-value-img">
                                                                        <img className="img-fluid" src={require('../../assets/images/portfolio-images/process-ic1.svg').default} alt="process-ic1" />
                                                                    </div>
                                                                    <p className="light-heading pt-20">
                                                                        {singleportfoilo.process_desc4}
                                                                    </p>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="fifth">
                                                                <div className="ss-process-content">
                                                                    <div className="core-value-img">
                                                                        <img className="img-fluid" src={require('../../assets/images/portfolio-images/process-ic1.svg').default} alt="process-ic1" />
                                                                    </div>
                                                                    <p className="light-heading pt-20">
                                                                        {singleportfoilo.process_desc5}
                                                                    </p>
                                                                </div>
                                                            </Tab.Pane>


                                                        </Tab.Content>

                                                    </Col>
                                                </Row>
                                            </Tab.Container>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    ) : null}
                    {/* section 6 end*/}
                    {/* section 7 */}
                    <div className="technology-we-used ptb-80">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="ss-heading ss-portfolio-text">
                                        <h2>
                                            <span>Technologies Used</span>
                                        </h2>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pt-50">
                                <div className="col-12">
                                    <div className="testimonials-box-main">
                                        <Slider {...technology} className='ss-testimonial'>
                                            {singleportfoilo.tech_title1 && (
                                                <div className="ss-technology-box-main">
                                                    <div className="tech-img">
                                                        <img className="img-fluid" src={require('../../assets/images/portfolio-images/portfolio1-technology-ic1.svg').default} alt="process-ic1" />
                                                    </div>
                                                    <div className="ss-technology-content">
                                                        <p className="ptb-30">
                                                            {singleportfoilo.tech_title1}
                                                        </p>
                                                        <div className="technology-title d-block">
                                                            {singleportfoilo.tech_desc1.split(',').map((tech, index) => (
                                                                <span className="technology-tag sm-text-semibold text-regular mb-10 me-2" key={index}>
                                                                    {tech.trim()}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {singleportfoilo.tech_title2 && (
                                                <div className="ss-technology-box-main">
                                                    <div className="tech-img">
                                                        <img className="img-fluid" src={require('../../assets/images/portfolio-images/portfolio1-technology-ic1.svg').default} alt="process-ic1" />
                                                    </div>
                                                    <div className="ss-technology-content">
                                                        <p className="ptb-30">
                                                            {singleportfoilo.tech_title2}
                                                        </p>
                                                        <div className="technology-title d-block">
                                                            {singleportfoilo.tech_desc2.split(',').map((tech, index) => (
                                                                <span className="technology-tag sm-text-semibold text-regular mb-10 me-2" key={index}>
                                                                    {tech.trim()}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {singleportfoilo.tech_title3 && (
                                                <div className="ss-technology-box-main">
                                                    <div className="tech-img">
                                                        <img className="img-fluid" src={require('../../assets/images/portfolio-images/portfolio1-technology-ic1.svg').default} alt="process-ic1" />
                                                    </div>
                                                    <div className="ss-technology-content">
                                                        <p className="ptb-30">
                                                            {singleportfoilo.tech_title3}
                                                        </p>
                                                        <div className="technology-title d-block">
                                                            {singleportfoilo.tech_desc3.split(',').map((tech, index) => (
                                                                <span className="technology-tag sm-text-semibold text-regular mb-10 me-2" key={index}>
                                                                    {tech.trim()}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {singleportfoilo.tech_title4 && (
                                                <div className="ss-technology-box-main">
                                                    <div className="tech-img">
                                                        <img className="img-fluid" src={require('../../assets/images/portfolio-images/portfolio1-technology-ic1.svg').default} alt="process-ic1" />
                                                    </div>
                                                    <div className="ss-technology-content">
                                                        <p className="ptb-30">
                                                            {singleportfoilo.tech_title4}
                                                        </p>
                                                        <div className="technology-title d-block">
                                                            {singleportfoilo.tech_desc4.split(',').map((tech, index) => (
                                                                <span className="technology-tag sm-text-semibold text-regular mb-10 me-2" key={index}>
                                                                    {tech.trim()}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Slider>
                                    </div>
                                </div>
                            </Row>
                        </Container>
                    </div>

                    {/* section 7 end*/}
                    {/* main functionality */}
                    {portfoliomainfun && portfoliomainfun.length > 0 ? (
                        <div className="main-functinality-main ptb-80">
                            <Container>
                                <Row>
                                    <Col lg={12}>
                                        <div className="ss-heading proof-text">
                                            <h2>
                                                <span>Main Functionalities
                                                </span>

                                            </h2>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="pt-20">
                                    <Col lg={6} className="align-self-center">
                                        <div className="main-functionality-accordion">
                                            <Accordion flush className="d-block nav nav-tabs" role="tablist" defaultActiveKey="accordion-1">
                                                {portfoliomainfun.map((functionality, index) => (
                                                    <Accordion.Item key={index} eventKey={`accordion-${index + 1}`}>
                                                        <Accordion.Header onClick={() => handleTabClick(`accordion-${index + 1}`)} >{functionality.title}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="functionality-content-main">
                                                                <ul className="main-functionality-content">

                                                                    {functionality.description.map((text, index) => (
                                                                        <li>
                                                                            <p className='light-heading' key={index}>{text.split(',').join('\n')}</p>
                                                                        </li>
                                                                    ))}

                                                                </ul>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                        </div>
                                    </Col>

                                    <Col lg={6} className="align-self-center">
                                        <div className="tab-content d-flex justify-content-center align-items-center" id="nav-tabContent">
                                            {portfoliomainfun.map((functionality, index) => {
                                                const isWebImageOnly = singleportfoilo.web_icon !== "" && singleportfoilo.web_link !== ""
                                                    && singleportfoilo.google_icon === "" && singleportfoilo.google_link === ""
                                                    && singleportfoilo.apple_icon === "" && singleportfoilo.apple_link === "";
                                                return (
                                                    <div key={index} className={`tab-pane fade ${activeTab === `accordion-${index + 1}` ? 'show active' : ''}`} role="tabpanel">
                                                        <div className={`functionality-images ${isWebImageOnly ? 'functionality-img-web' : ''}`}>
                                                            <img src={functionality.mainFuncImage} className="img-fluid" alt={`Portfolio ${index + 1}`} />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Col>




                                </Row>
                            </Container>
                        </div>
                    ) : null}
                    {/* main functionality end*/}
                    {/* section 8 */}
                    {singleportfoilo && singleportfoilo.special_funct_background && singleportfoilo.special_desc ? (
                        <div className="our-journey-main">
                            <Container>
                                <Row className="ptb-50">
                                    <Col lg={12}>
                                        <div className="ss-heading ss-portfolio-text">
                                            <h2>
                                                <span>The most special functionalities</span>
                                            </h2>
                                        </div>
                                    </Col>
                                </Row>

                            </Container>
                            <div className="journey-bg">
                                <img src={singleportfoilo.special_funct_background} className="img-fluid" alt='img' />
                            </div>
                            <div className="journey-content-main">
                                <Container>
                                    <Row >
                                        <Col lg={12}>
                                            <div className="journey-content ">
                                                <div className="cms-needed">
                                                    <div className="right-box">
                                                        <div className="right-text">
                                                            {singleportfoilo.special_desc.map((text, index) => (
                                                                <p className='light-heading mb-20' key={index}>{text.split(',').join('\n')}</p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </Col>
                                    </Row>

                                </Container>

                            </div>
                        </div>
                    ) : null}
                    {/* section 8 end*/}
                    {/* all links start   */}
                    <div className='web_app_link_wrapper pb-80' style={{ backgroundColor: '#F4F5F9' }}>
                        <Container>
                            <Row>
                                <Col className="text-center">
                                    <div className='social_all_links d-flex justify-content-center'>
                                        {singleportfoilo.google_icon && (
                                            <Link to={singleportfoilo.google_link} target="_blank">
                                                <div className="portfolio-detail-img pt-80">
                                                    <img src={singleportfoilo.google_icon} className="img-fluid" alt="Google Play Icon" />
                                                </div>
                                            </Link>
                                        )}

                                        {singleportfoilo.apple_icon && (
                                            <Link to={singleportfoilo.apple_link} target="_blank">
                                                <div className="portfolio-detail-img  pt-80">
                                                    <img src={singleportfoilo.apple_icon} className="img-fluid" alt="Apple App Store Icon" />
                                                </div>
                                            </Link>
                                        )}

                                        {singleportfoilo.web_icon && (
                                            <Link to={singleportfoilo.web_link} target="_blank">
                                                <div className="portfolio-detail-img  pt-80">
                                                    <img src={singleportfoilo.web_icon} className="img-fluid" alt="Web Icon" />
                                                </div>
                                            </Link>
                                        )}
                                    </div>
                                </Col>

                            </Row>
                        </Container>

                    </div>
                    {/* all links end  */}
                </div>
            </main>
        </>



    )

}
