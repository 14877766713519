import { Col, Container, Row, Stack } from 'react-bootstrap'
import Portfolio from '../../components/Portfolio';
import Faq from '../../components/Faq';
import Accordion from 'react-bootstrap/Accordion';
import React from 'react';
import { Link } from 'react-router-dom';
import ScheduleCall from '../../components/ScheduleCall';
import { Helmet } from 'react-helmet-async';


export default function WebAppDevelopment() {

  return (
    <>
      <Helmet>
        <title>360° Web Solutions: Crafting Robust, Secure Websites</title>
        <meta name="title" content="360° Web Solutions: Crafting Robust, Secure Websites" />
        <meta name="keywords" content="360° Web Solutions, Web Development Services, Secure Websites, Robust Web Solutions, Sensussoft Web Development" />
        <meta name="description" content="Reach your ideal customers on the world's largest professional network." />
      </Helmet>
      <main>
        <div className="web-app-development">

          {/* section 1 */}
          <div className="web-app-development website-development ptb-80 bg-logo">
            <Container >
              <Row className='pb-80 justify-content-end'>
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className=" service-btn-mobile  next-prev-btn comm-btn ">
                    <Link aria-label='mobileappdevelopment' to='/mobile-app-development'>
                      <button className="service-previous-btn learn-more arrow-btn " aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className="service-btn-mobile next-prev-btn comm-btn text-end ">
                    <Link aria-label='mobileappdevelopment' to='/ui-ux-design'>
                      <button className="learn-more arrow-btn" aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="align-self-center">
                  <div className="website-development-text proof-text-main">
                    <div className="ss-heading proof-text">
                      <h2><span>Web Application Development </span></h2>
                      <p className="text-regular mt-20">
                        We are committed to building 360° high-quality web solutions that are robust, scalable, and secure. Give us your idea and we'll make it a reality. We develop a custom, responsive website that captures the essence of your business. We take a 360-degree approach to software development, so your website will always be up-to-date and fully optimized. Since 2014, we have successfully delivered over 100+ websites, loved and browsed by millions.                  </p>

                    </div>
                  </div>
                </Col>
                <Col lg={6} className="align-self-center text-center">
                  <div className="change_vision_img"><img className="img-fluid" src={require('../../assets/images/serviceimg2.png')} alt='service1' /></div>

                  <div className="website-development-img-main pt-100">
                    <div className="website-development-img mt-sm-20 mt-md-20">
                      <img className="img-fluid" src={require('../../assets/images/website-development.jpg')} alt="product-ideation"></img>
                    </div>
                    <div className="web-build-box">
                      <div className="like-round">
                        <img className="img-fluid " src={require('../../assets/images/blub.svg').default} alt="blub">
                        </img>
                      </div>
                      <div className="build-text">
                        <h2>
                          100+
                        </h2>
                        <p className="text-regular">
                          Web Application <br />Development

                        </p>
                      </div>
                    </div>
                    <div className="web-build-box2">
                      <p>
                        Crafting seamless web experiences <br /> for a connected world.
                      </p>
                      <div className="line">
                        <img className="img-fluid" src={require('../../assets/images/linefill.png')} alt="line"></img><br />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 1 end*/}
          {/* section 2 */}
          <div className="cms-website bg-service">
            <Container>
              <Row>
                <Col lg={6} >
                  <div className="ideation-services">
                    <div className="services-in-action-main ">
                      <div className="services-in-action ptb-200">
                        <div className="our-vision-text-main d-flex">
                          <div className="bd-highlight vision-img-main">
                            <div className="our-vision-img overview-img">
                              <img className="img-fluid" src={require('../../assets/images/overview.svg').default} alt="vision-ic"></img>
                            </div>
                          </div>
                          <div className="our-vision-text">
                            <p>
                              Service Overview
                            </p>
                          </div>
                        </div>
                        <div className="ss-heading ss-portfolio-text">
                          <h2 className="ptb-15">
                            See   <span>Web Application  <br /> Development  </span> <br /> service in action.
                          </h2>
                          <div className="comm-btn ">
                            <Link to='/get-in-touch-with-us' aria-label='contact us for detail'>
                              <button className="learn-more btn-large arrow-btn-secondary2" aria-label='contact us for detail'>
                                <span className="circle" aria-hidden="true">
                                  <span className="icon arrow">
                                    <img src={require('../../assets/images/btn-arrow3.svg').default} className="img-fluid" alt='img' />
                                  </span>
                                </span>
                                <span className="button-text">CONTACT US FOR DETAILS</span>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={12}>
                  <div className="ideation-content ptb-100">

                    <div className="enhance-content-main ">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">CMS Website Development</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          We develop a CMS (Content Management System), which allows you to create, manage, and edit your website without the need for specialized technical skills. We develop a custom admin panel that gives you the control to post new content or create categories.
                        </p>
                        <h4 className="ptb-20">
                          Why A Strong CMS Software Is Needed!
                        </h4>
                        <div className="cms-needed">
                          <div className="right-box d-flex mb-20 d-mobile-block">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Easier to customize your CMS website as per your requirements and preferences
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                Does not require any coding knowledge
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                Simplifies the redesigning of the website
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Provides multiple access and collaborations
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                Cost-effective and time-saving maintenance
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="enhance-content-main  ">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Ecommerce Web Development</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          An eCommerce site is more than just HTML and CSS. You have to accept credit card payments and make sure your site is easy to search and navigate.
                        </p>
                        <h4 className="ptb-20">
                          Solution We Provide For Ecom.
                        </h4>
                        <div className="cms-needed">
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Custom e-commerce Integration
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                Multiple Payment Gateway Integration
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                Security Enhancement and removal of vulnerabilities.
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Malware Scan
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Third-party API’s
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Dynamic Shopping cart
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Versions & Plugins Upgrades
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Solving Server Issues
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Performance Tuning
                              </p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="enhance-content-main ">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Custom Website Development</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Every website we work on is custom-designed, mobile-ready, user-friendly, and search engine optimized. We build! And you own it! Unlike "off-the-shelf" software, custom software can boost your business, increase performance, and enhance your IP. Outrank your competitors by partnering with us.
                        </p>
                        <h4 className="ptb-20">
                          Why Custom Web Is Best Choice For You?
                        </h4>
                        <div className="cms-needed">
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Identify Unique & Innovative Products.
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                Scale Your Business Better.
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                Boost Productivity & Employee Motivation.
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Boost ROI.
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                It controls the narrative
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Enhances its credibility
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Communicate in a more constructive way
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Competition with Other Industry Goliaths
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </Col>
              </Row>
            </Container>
          </div>
          {/* section 2 end*/}
          {/* section 3 */}
          <div className="services-portfolio-main ptb-80">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      <span>Our Portfolio</span>
                    </h2>

                  </div>
                </Col>
              </Row>
            </Container>
            <Portfolio></Portfolio>
          </div>
          {/* section 3 end */}
          {/* section  4*/}
          <div className="attractive-main ptb-120">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="attractive-box">
                    <Stack direction="horizontal" className="d-mobile-block">
                      <div className="attractive-text flex-grow-1">
                        <h3 className="ss-title">
                          <span className="text-regular">Which </span> Website Development  <span className="text-regular">Is Right <br />For Your   </span> 360° Digital <br />Transformation?
                        </h3>
                      </div>
                      <div className="attractive-btn">
                        <ScheduleCall></ScheduleCall>
                      </div>
                    </Stack>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 4 end */}
          {/* section 5 */}
          <div className="ss-faq-main">
            <div className="faq-main pt-120   ">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="faq-title proof-text-main text-center mb-30">
                      <div className="ss-heading proof-text">
                        <h1><span>Frequently Asked<br />
                          Questions </span></h1>
                      </div>
                    </div>

                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <div className="faq-accordion">
                      <Accordion defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Got A Burning Question? </Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                We will answer pretty much all of them. Feel free to tap on the chat icon.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>I'm not sure what kind of website I need. Can you help me figure that out?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Sure. There are a few things to consider when deciding on the right website for your business. Firstly, what is the purpose of your website? Do you want to sell products or services online, or simply provide information about your business? Depending on your business goals, objectives, and target audience, we will recommend the right type of website for you.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>What is your process for designing a new website?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Our website building process in a nutshell:
                                <ul>


                                  <li><p>Free consultation and ideation</p></li>

                                  <li><p>Requirement and solution engineering of your vision</p></li>

                                  <li><p>UI/UX creation</p></li>

                                  <li><p>Software development</p></li>

                                  <li><p>Quality Assurance</p></li>

                                  <li> <p>Deployment</p></li>

                                  <li><p>Long-term IT support</p></li> </ul>
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            I don't have a payment gateway in my website. Will you help me with that?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Yes, we help you set up a payment gateway in your website. We are experienced in integrating different payment gateways for rapid processing. Simply share the payment gateways you want to use and we will do the rest.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>
                            How will I be informed about the project status during development?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                We are currently using Jira for project management. By using this tool, you can see the current status of a project, as well as all completed and outstanding tasks. We will add you as a collaborator, so you can leave comments and questions for the development team on specific issues. But If you want to use some other tool then we are open to use that.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                          <Accordion.Header>
                            How long will it take and how much will it cost to build a website?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                It depends on the website. A small website with a few pages and basic functionality could be built in less time and less money. However, a large, more complex website may take months and cost thousands of dollars.<br /><br />There are many factors that go into building a website, including the design, functionality, and hosting. Therefore, it is difficult to provide an accurate estimate without knowing more about the project. Let us know your concept and we will provide you with a timeframe and cost estimate.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                          <Accordion.Header>
                            What kind of aftercare/support do you offer clients who have launched their website with you?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                As a committed 360° Software Development Partner, we strive to enhance your product each day.<br /><br /> Our 360° software service support includes, but is not limited to:<br /><br /> Ensuring Design Consistency across All Pages, Fixing Broken Links, Search Engine Optimization, Backing up Files, Fixing HTML Errors, Improving Website Speed, Updating Website Software
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Col>
                </Row>



              </Container>
            </div>
            <Faq></Faq>
          </div>
          {/* section 5 end*/}
        </div>
      </main>
    </>
  )
}
