import React from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet-async';

function Mettinglandscape() {
    return (
        <>
            <Helmet>
                <title>Meeting Landscape | Sensussoft - Enhancing Communication and Collaboration</title>
                <meta name="title" content="Meeting Landscape | Sensussoft - Enhancing Communication and Collaboration" />
                <meta name="keywords" content="Meeting Landscape, Communication Solutions, Collaboration Tools, Business Meetings, Virtual Meetings, Meeting Software, Communication Platforms, Team Collaboration, Business Communication, Online Meetings" />
                <meta name="description" content="Explore Sensussoft's structured meeting landscape, fostering communication, collaboration, and progress across teams and projects." />
            </Helmet>
            <main>
                <div className='metting_landscapr_main_blog'>
                    <div className='meeting_section_of_main1'>

                        {/* <section2> */}
                        <div className='meeting_section_of_main'>
                            <div className='meeting_land_why pt-40'>
                                {/* <div className='main_why_section'> */}
                                <Container>
                                    <Row>
                                        <Col lg='12'>
                                            <div className='head_of_main_metting pt-20'>
                                                <h1>Our Meeting Landscape</h1>
                                            </div>
                                        </Col>
                                        <Col lg='6' className='align-self-center'>
                                            <div className='after_add_com_logo'>
                                                <div className='head_of_title_why'>
                                                    <h2>Why?</h2>
                                                </div>
                                                <div className='why_us_points'>
                                                    <ul className=" blog_point">
                                                        <li>To ensure regular communication between team members, project teams, and business units, a meeting landscape has been established.</li>
                                                        <li>Communication is key to our success and with these meetings, we ensure that we are all on the same page and that all relevant people have access to and can contribute to relevant topics of Sensussoft family.</li>
                                                        <li>It is designed to describe the flow of communication of the different types of information relevant to the success of our vision.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg={6} className="align-self-center text-center">
                                            <div className="our_metting_memories website-development-img-main">
                                                <div className="website-development-img mt-sm-20 mt-md-20">
                                                    <img className="img-fluid" src={require('../assets/images/metting-land1.jpg')} alt="maintanance"></img>
                                                </div>
                                                <div className="web-build-box2 maitanance-box2">
                                                    <p>
                                                        Connecting Minds, Unleashing Success:<br />The Meeting Landscape of Sensussoft
                                                    </p>
                                                    <div className="line">
                                                        <img className="img-fluid" src={require('../assets/images/linefill.png')} alt="line"></img><br />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='website-development-img-mb mt-sm-20 mt-md-20'>
                                                <img className="img-fluid" src={require('../assets/images/metting-land2-mb.png')} alt="product-ideation"></img>
                                            </div>
                                        </Col>

                                    </Row>
                                </Container>
                                {/* </div> */}
                            </div>
                            {/* </section2> */}
                            {/* <section3> */}
                            <div className='metting_yellow_card pt-40'>
                                <Container>
                                    <Row>
                                        <Col lg='12'>
                                            <div className=' head_of_main_metting pt-20 head_of_main_metting_yellow'>
                                                <h2>Our Meeting Landscape</h2>
                                            </div>
                                        </Col>
                                        <Col lg='6' className='align-self-center'>
                                            <div className='after_add_com_logo'>
                                                <div className='head_of_title_why'>
                                                    <h2>How?</h2>
                                                </div>
                                                <div className='why_us_points'>
                                                    <ul className=" blog_point">
                                                        <li>Recurring meetings have been convened for various purposes.  Each meeting has a clear purpose, participants, a schedule, and a responsible person.</li>
                                                        <li>Each meeting requires proactive input from all participants.</li>
                                                        <li>All participants actively contribute during the meeting, providing valuable input.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Col>


                                        <Col lg={6} className="align-self-center text-center">
                                            <div className="website-development-img-main">
                                                <div className="website-development-img mt-sm-20 mt-md-20">
                                                    <img className="img-fluid" src={require('../assets/images/metting-land2.jpg')} alt="product-ideation"></img>
                                                </div>

                                                <div className="web-build-box2">
                                                    <p>
                                                        Power Meetings: Fueling <br />Collaboration, Igniting Progress!
                                                    </p>
                                                    <div className="line">
                                                        <img className="img-fluid" src={require('../assets/images/linefill.png')} alt="line"></img><br />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='website-development-img-mb mt-sm-20 mt-md-20'>
                                                <img className="img-fluid" src={require('../assets/images/metting2-land2-mb.png')} alt="product-ideation"></img>
                                            </div>
                                        </Col>

                                    </Row>
                                </Container>
                            </div>
                        </div>
                    </div>
                    {/* </section3> */}
                    {/* <section4 4> */}
                    <div className='our_team_metting_bolg ptb-100'>
                        <Container>
                            <Row>
                                <Col lg='12'>
                                    <div className='innvoation_ideas_about'>
                                        <div className='out_team_metting_point'>
                                            <div className="ss-heading solution-title">
                                                <h2> <span>Team Meetings</span> </h2>
                                            </div>
                                        </div>
                                        <div className='tables_of_accordion accordion_section_1 pt-40 pb-80'>
                                            <Accordion defaultActiveKey="0" >
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header><h3>Team Meeting</h3><h4>Organized By <span>Team Leader</span></h4></Accordion.Header>
                                                    {/* style={{ backgroundImage: `url(${require('../assets/images/our-process/Conceive-your-ideas.jpg')})` }} */}
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Share organization topics with the team.</li>
                                                                <li>Discuss progress of team issues.</li>
                                                                <li>Introduce new team methods/concepts/principles to all team members.</li>
                                                                <li>Gather team member feedback on the organization to share with management.</li>
                                                                <li>Gather team member feedback on the projects that can be addressed within the team to improve it.</li>
                                                                <li>Strengthen your "one for all and all for one" team spirit and team environment(transparency, trust, and accountability) through team time.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header><h3>1:1 Team Member Meeting</h3><h4>Organized By <span>Team Leader</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Share personal issues such as mutual feedback</li>
                                                                <li>Discuss the individual's growth process</li>
                                                                <li>Discuss performance delivery based on feedback from project or team members and align with annual agreements</li>
                                                                <li>Identify the problems/needs of the individual</li>
                                                                <li>Identify the individual's happy moments</li>
                                                                <li>Identify strengths/weaknesses and improve both</li>
                                                                <li>Strengthen the relationship between team leader and member</li>
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header><h3>Team Ad-Hoc Meeting</h3><h4>Depends on the <span>meeting</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Discussion of very urgent topics that cannot wait until the other two regular meetings between x team members (including team leader).</li>
                                                                <li>
                                                                    Urgent topics:
                                                                    <ul className='Sub_points_blogs'>
                                                                        <li>Critical problems of the organization</li>
                                                                        <li>Critical team problems</li>
                                                                        <li>Critical problems of the team members</li>
                                                                        <li>Critical project issues that may require the support of multiple team members</li>
                                                                    </ul>
                                                                </li>
                                                                <li>Mentor meetings</li>
                                                                <li>Learning meetings</li>
                                                                <li>Sync meeting with Project Managers if required</li>
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                            </Accordion>
                                        </div>
                                        <div className='out_team_metting_point'>
                                            <div className="ss-heading solution-title">
                                                <h2> <span>Project Team Meetings</span> </h2>
                                            </div>
                                        </div>
                                        <div className='tables_of_accordion accodion_section_2 pt-40 pb-80'>
                                            <Accordion defaultActiveKey="3">
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header><h3>Project Set Up Meeting</h3><h4>Organized By <span>Project Manager</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Define project goals, objectives, and scope.</li>
                                                                <li>Identify project stakeholders and establish communication channels.</li>
                                                                <li>Allocate resources, assign roles and responsibilities, and create a project plan.</li>
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header><h3>Project StandUp Meeting</h3><h4>Organized By <span>Project Manager</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li> Provide a brief update on project progress</li>
                                                                <li>Discuss any obstacles or challenges encountered</li>
                                                                <li>Coordinate tasks and ensure team alignment and collaboration.</li>
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="5">
                                                    <Accordion.Header><h3>Project Refinement Meeting</h3><h4>Organized By <span>Project Manager</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li> Evaluate and refine project requirements, deliverables, or specifications.</li>
                                                                <li>  Identify potential areas for improvement and propose adjustments.</li>
                                                                <li>  Assess and address any changes in project scope or priorities.</li>
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="6">
                                                    <Accordion.Header><h3>Project Review Meeting</h3><h4>Organized By <span>Project Manager</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>  Evaluate project performance against predefined metrics and goals.</li>
                                                                <li>Assess the completion of project milestones and deliverables.</li>
                                                                <li> Discuss lessons learned, successes, challenges, and any necessary corrective actions.</li>
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="7">
                                                    <Accordion.Header><h3>Project Retrospective Meeting</h3><h4>Organized By <span>Project Manager</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Reflect on the project's overall performance and outcomes</li>
                                                                <li>  Identify and document successes, challenges, and lessons learned.</li>
                                                                <li>  Discuss ways to improve future project execution and enhance team effectiveness.</li>
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="21">
                                                    <Accordion.Header><h3>Project Lessons Learned Meeting</h3><h4>Organized By <span>Project Manager</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Document and share key takeaways and best practices from the project.</li>
                                                                <li>Identify areas of improvement for future projects.</li>
                                                                <li>Discuss strategies to implement lessons learned and drive continuous improvement.</li>

                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="8">
                                                    <Accordion.Header><h3>360° Partner Meeting</h3><h4>Organized By <span>Project Manager</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Inform about the status of progress (completed, next step, blockages, etc.)</li>
                                                                <li>Removal of misunderstandings between partner and family</li>
                                                                <li>Strengthen the relationship between our family and the partner through 360° support and inspiration</li>
                                                                <li>Plan next activities together to be on the same page</li>

                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="9">
                                                    <Accordion.Header><h3>Project TeamAd-Hoc Meeting</h3><h4>Depends on the <span>meeting</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Discuss issues that cannot wait until other regular meetings between x project team members and others as required

                                                                    <ul className='Sub_points_blogs'>
                                                                        <li>Critical project issues that may require the support of multiple team members</li>
                                                                        <li>Critical bugs from ongoing operations</li>
                                                                    </ul>
                                                                </li>


                                                                <li>Discuss issues that are not relevant to the other meetings between x project team
                                                                    members and other required people
                                                                    <ul className='Sub_points_blogs'>
                                                                        <li>Design thinking E2E to collaboratively solve a problem in a strong solution
                                                                            through all domains in a quick and satisfactory manner</li>
                                                                        <li>Analyze and understand the requirements</li>
                                                                        <li> Code review meeting to discuss the code with peers</li>
                                                                        <li> Clarification of issues that are not understood or questions that cannot be
                                                                            answered in the chat</li>
                                                                        <li> Any other issues that require a meeting to ensure project success</li>
                                                                    </ul>
                                                                </li>

                                                                <li>Motivational meetings to strengthen the bond between team members, between
                                                                    team members and the project vision, etc. to make the impossible possible or simply
                                                                    to create a smile in a difficult time</li>
                                                                <li>Clarify deadlines and calling for accountability to deliver on our promises to our
                                                                    partners in a timely manner</li>
                                                                <li>  Alignment of project or personal topics between Team Leader and Project Manager</li>
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                        <div className='out_team_metting_point'>
                                            <div className="ss-heading solution-title">
                                                <h2> <span>Organization Meetings</span></h2>
                                            </div>
                                        </div>
                                        <div className='tables_of_accordion accodion_section_3 pt-40 '>
                                            <Accordion defaultActiveKey="10">
                                                <Accordion.Item eventKey="10">
                                                    <Accordion.Header><h3>Assisting Team Leader Meeting</h3><h4>Organized By <span>Team Leader</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Deligating some task or responsibility to Assisting Team Leaders</li>
                                                                <li>Sharing issues of the organization with the Assisting Team Leaders</li>
                                                                <li>Discuss general issues that contribute to the growth of the organization and each individual</li>
                                                                <li>Share topics from your team members that are relevant to the organization and need to be discussed at the top management level</li>
                                                                <li>Discuss strategic issues to help achieve our company vision</li>
                                                                <li>Strengthen the bond between C-level Management, team leaders, and Family Members</li>
                                                                <li>Identify organizational or team specific tasks and plan, monitor, and resolve them</li>
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="11">
                                                    <Accordion.Header><h3>Team Leader Meeting</h3><h4> <span>C-Level Management</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Sharing issues of the organization with the Team Leaders</li>
                                                                <li>Discuss general issues that contribute to the growth of the organization and each
                                                                    individual</li>
                                                                <li>Share topics from your team members that are relevant to the organization and need
                                                                    to be discussed at the top management level</li>
                                                                <li>Discuss strategic issues to help achieve our company vision
                                                                </li>
                                                                <li>Strengthen the bond between C-level Management, team leaders, and Family
                                                                    Members</li>
                                                                <li>Identify organizational or team specific tasks and plan, monitor and resolve them</li>
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="12">
                                                    <Accordion.Header><h3>360° Innovation Meeting</h3><h4>Organized By <span>HR Team Leade</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Interdisciplinary knowledge exchange in a competitive environment</li>
                                                                <li>360° growth</li>
                                                                <li>See “More Info”</li>
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="13">
                                                    <Accordion.Header><h3>360° Recruitment Meeting</h3><h4>Organized By <span>HR Team Leader</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Present/discuss the status of all hiring activities over the past 2 weeks</li>
                                                                <li>Discuss of needs and job descriptions needed by the family</li>
                                                                <li>Discuss and monitor of ideas/suggestions for improving recruitment efforts</li>
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="14">
                                                    <Accordion.Header><h3>360° Quality Meeting</h3><h4>Organized By <span>QA Team Leader</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Present/discuss of the status of initiatives to improve our product quality</li>
                                                                <li>Present/discuss of the bug status of projects according to relevant KPIs
                                                                    <ul className='Sub_points_blogs'>
                                                                        <li>Number of bugs</li>
                                                                        <li>Number of tickets returned to development after QA.</li>
                                                                    </ul>
                                                                </li>
                                                                <li>Discuss ideas/suggestions to improve our overall quality with the appropriate teams</li>

                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="15">
                                                    <Accordion.Header><h3>360° Culture Meeting</h3><h4>Organized By <span>HR Team Leader</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Present/discuss the status of initiatives that contribute to our culture
                                                                    <ul className='Sub_points_blogs'>
                                                                        <li>Company events</li>
                                                                        <li>Special activities</li>
                                                                        <li>New come-together events</li>
                                                                        <li>Adaptation of the office</li>
                                                                        <li>Policies, etc.</li>
                                                                    </ul>
                                                                </li>
                                                                <li>Coordinate activities for upcoming events</li>
                                                                <li>Discuss of ideas/suggestions to improve our culture</li>
                                                                <li>Monitore relevant and agreed upon actions and their completion</li>

                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="16">
                                                    <Accordion.Header><h3>360° Project Report Meeting</h3><h4>Organized By <span>Project Lead , Team Leader</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Present/discuss project status of all relevant projects and provide information on:
                                                                    <ul className='Sub_points_blogs'>
                                                                        <li>Plan vs. actual progress</li>
                                                                        <li>Initiatives</li>
                                                                        <li>Roadblocks</li>
                                                                        <li>Expected delivery</li>
                                                                        <li>and other information relevant to the project status</li>
                                                                    </ul>
                                                                </li>
                                                                <li>Discuss of ideas/suggestions to improve operations and project delivery process</li>
                                                                <li>Monitor relevant and agreed upon actions and their completion</li>

                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="17">
                                                    <Accordion.Header><h3>360° Leads Planning Meeting</h3><h4>Organized By <span>Business Development Lead</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Present/discuss progress of leads:
                                                                    <ul className='Sub_points_blogs'>
                                                                        <li>Describe the nature of the project
                                                                            <ul className='Sub_points_blogs'>
                                                                                <li>Platform</li>
                                                                                <li>Technical stack</li>
                                                                                <li>Size</li>
                                                                            </ul>
                                                                        </li>
                                                                        <li>Duration</li>
                                                                        <li>Status of the line
                                                                            <ul className='Sub_points_blogs'>
                                                                                <li>New request</li>
                                                                                <li>Schedule created</li>
                                                                                <li>Offer sheet shared</li>
                                                                                <li>Contract signed → Planned start date?</li>
                                                                                <li>Contract failed → reason?</li>
                                                                                <li>In Progress</li>
                                                                                <li>Completed</li>
                                                                            </ul>
                                                                        </li>
                                                                        <li>Resources needed</li>
                                                                        <li>Possible start date</li>
                                                                        <li>End Date</li>
                                                                        <li>Other relevant information</li>
                                                                    </ul>
                                                                </li>
                                                                <li>Discuss ideas/suggestions to improve lead generation and conversion.</li>
                                                                <li>Monitore relevant and agreed actions and their completion.</li>
                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="18">
                                                    <Accordion.Header><h3>360° Engineering Round</h3><h4>Organized By <span>Operations Lead</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Present trendy Software Engineering topics of this month
                                                                    <ul className='Sub_points_blogs'>
                                                                        <li>Topics can be taken from the “Our 360° Tech Innovation Newsletter”</li>

                                                                    </ul>
                                                                </li>
                                                                <li>Dialog of all engineers about how we can improve our operations together</li>
                                                                <li>Monitore relevant and agreed actions and their completion</li>

                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="19">
                                                    <Accordion.Header><h3>360° Budget Planning</h3><h4>Organized By <span>Accounting Lead</span></h4></Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='why_us_points'>
                                                            <ul className=" blog_point">
                                                                <li>Present/Discuss budget plans for each domain</li>
                                                                <li>Discuss budget needs for each domain</li>
                                                                <li>Discuss project over- /undertime costs of completed projects in this quarter</li>
                                                                <li>Monitore relevant and agreed action and their completion</li>

                                                            </ul>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* </section4> */}
                </div>
            </main>
        </>
    )
}

export default Mettinglandscape
