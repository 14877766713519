import React, { Component } from 'react';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

class MultiStepForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      description: '',
      budget: '',
      timeline: '',
      one_last_thing: '',
      attach_file: null,
      validated: false,
      errors: {},
      successModalOpen: false,
      isLoading: false,

    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  handlePhoneChange = (phone, country) => {
    if (typeof phone === 'string' && phone && typeof country.dialCode === 'string') {
      const reducedPhone = phone.replace(country.dialCode, '').trim();
      this.setState({ phone_number: country.dialCode + ' ' + reducedPhone });
    }
  };

  handleFileChange = (event) => {
    this.setState({
      attach_file: event.target.files[0],
    });
  };

  nextStep = () => {
    const { step, first_name, last_name, email, phone_number, description, budget, timeline } = this.state;

    // Perform form validation based on the current step
    switch (step) {
      case 1:
        if (!first_name || !last_name || !email || !phone_number) {
          this.setState({ validated: true });
          return; // Stop proceeding to the next step if fields are not filled
        }
        if (!this.isValidEmail(email)) {
          this.setState({ validated: true });
          return; // Stop proceeding to the next step if email is not valid
        }
        break;
      case 2:
        if (!description) {
          this.setState({ validated: true });
          return; // Stop proceeding to the next step if field is not filled
        }
        break;
      case 3:
        if (!budget || !timeline) {
          this.setState({ validated: true });
          return; // Stop proceeding to the next step if fields are not filled
        }
        break;
      // Add validation for other steps if needed

      default:
        break;
    }

    this.setState((prevState) => ({
      step: prevState.step + 1,
      validated: false,
      errors: {},
    }));
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
      validated: false,
      errors: {},
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    if (!this.state.one_last_thing) {
      this.setState({ validated: true });
      return; // Stop form submission if the option is not selected
    }

    this.setState({ validated: true, isLoading: true }); // Start loading

    if (form.checkValidity()) {
      const {
        first_name,
        last_name,
        email,
        phone_number,
        description,
        attach_file,
        budget,
        timeline,
        one_last_thing,
      } = this.state;

      // Create form data to send files along with other data
      const formData = new FormData();
      formData.append('first_name', first_name);
      formData.append('last_name', last_name);
      formData.append('email', email);
      formData.append('phone_number', phone_number);
      formData.append('description', description);
      formData.append('budget', budget.value);
      formData.append('timeline', timeline.value);
      formData.append('one_last_thing', one_last_thing.value);
      formData.append('attach_file', attach_file);

      // Make the API call using Axios
      axios
        .post('https://api.sensussoft.com/api/about', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          // Handle API response
          // console.log(response.data);
          this.setState({ successModalOpen: true, isLoading: false }); // Stop loading on success
        })
        .catch((error) => {
          // Handle API error
          console.error(error);
          window.alert('Error occurred while submitting the form');
          this.setState({ isLoading: false }); // Stop loading on error
        });
    }
  };

  handleSuccessModalClose = () => {
    this.setState({ successModalOpen: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      step: 1,
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      description: '',
      budget: '',
      timeline: '',
      one_last_thing: '',
      attach_file: null,
      validated: false,
      errors: {},
    });
  };

  isValidEmail(email) {
    // Regular expression for email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  render() {
    const { validated, successModalOpen, isLoading } = this.state;


    const {
      step,
      first_name,
      last_name,
      email,
      phone_number,
      description,
      attach_file,
      budget,
      timeline,
      one_last_thing,
    } = this.state;

    // select options
    const options = [
      { value: '< $500', label: 'less than $500' },
      { value: '$500-$1000', label: '$500-$1000' },
      { value: '$1000-$5000', label: '$1000-$5000' },
      { value: '$5000-$10000', label: '$5000-$10000' },
      { value: '$10000-$15000', label: '$10000-$15000' },
      { value: 'More than $15000', label: 'More than $15000' },
    ];

    const options2 = [
      { value: '< 1 Month', label: 'Less than 1 Month' },
      { value: '1 Months - 3 Months', label: '1 Months - 3 Months' },
      { value: '3 Months - 6 Months', label: '3 Months - 6 Months' },
      { value: 'More than 6 Months', label: 'More than 6 Months' },
    ];
    const options3 = [
      { value: 'Google', label: 'Google' },
      { value: 'Website', label: 'Website' },
      { value: 'Referral', label: 'Referral' },
      { value: 'Facebook', label: 'Facebook' },
      { value: 'Instagram', label: 'Instagram' },
      { value: 'Linkedin', label: 'Linkedin' },
      { value: 'Online Search', label: 'Online Search' },
    ];

    return (
      <div>
        {step === 1 && (
          <div className="personal-detail-text">
            <div className="form-title">
              <h3 className="mt-sm-20">Tell us about yourself</h3>
              <p className="text-regular mt-10">Let’s start with your name</p>
            </div>
            <div className="multistep-from pt-30">
              <Form onSubmit={this.handleSubmit}>
                <Row>
                  <Col lg={12}>
                    <Row>
                      <Col lg={6}>
                        <div className="custom-input mb-20">
                          <Form.Group controlId="validationCustom01">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="first_name"
                              value={first_name}
                              placeholder="First Name"
                              onChange={this.handleChange}
                              isInvalid={validated && !first_name}
                            />
                            <Form.Control.Feedback type="invalid">Please enter your first name.</Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="custom-input mb-20">
                          <Form.Group controlId="validationCustom02">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              required
                              type="text"
                              name="last_name"
                              value={last_name}
                              placeholder="Last Name"
                              onChange={this.handleChange}
                              isInvalid={validated && !last_name}
                            />
                            <Form.Control.Feedback type="invalid">Please enter your last name.</Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="custom-input mb-20">
                          <Form.Group controlId="validationCustom03">
                            <Form.Label>Business Email</Form.Label>
                            <Form.Control
                              required
                              type="email"
                              name="email"
                              value={email}
                              placeholder="Enter Your Business Email"
                              onChange={this.handleChange}
                              isInvalid={validated && !this.isValidEmail(email)}
                              pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"

                            />
                            <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="custom-input mb-20">

                          <Form.Group controlId="validationCustom04">
                            <Form.Label>Phone Number</Form.Label>
                            <PhoneInput
                              required
                              country={'in'} // Set default country if needed
                              value={phone_number}
                              enableSearch={true}
                              onChange={this.handlePhoneChange}
                              placeholder="Phone Number"
                              isInvalid={validated && !phone_number}
                            />
                            {/* Optionally, provide feedback for PhoneInput */}
                            <Form.Control.Feedback type="invalid">Please enter your phone number</Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="next-btn float-end">
                          <Button className="btn-wrapper btn-wrapper-blue" onClick={this.nextStep} aria-label='next'>
                            Next
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        )}

        {step === 2 && (
          <div>
            <div className="About-project-form">
              <div className="form-title">
                <h4 className="mt-sm-20">A few words about your project</h4>
                <h6 className="text-regular pt-10">
                  What is the value proposition of your product? Do you have a wishlist for the features or do you wish to build it together with us?
                </h6>
              </div>
              <div className="multistep-from pt-30">
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col lg={12}>
                          <div className="custom-input mb-20">
                            <Form.Group controlId="validationCustom6">
                              <Form.Label>Description</Form.Label>
                              <Form.Control
                                required
                                as="textarea"
                                rows={3}
                                placeholder="Description"
                                value={description}
                                name="description"
                                onChange={this.handleChange}
                                isInvalid={validated && !description}
                              />
                              <Form.Control.Feedback type="invalid">Please enter a description.</Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="custom-input mb-20 ">
                            <Form.Group controlId="validationCustom7">
                              <div className="custom-file float-end">
                                <span className="file-upload float-end">
                                  <img className="img-fluid" src={require('../assets/images/attach-file.svg').default} alt="attach-file" />
                                </span>
                                <Form.Control
                                  type="file"
                                  onChange={this.handleFileChange}

                                />
                                {attach_file && (
                                  <p className="lg-text-semibold">{attach_file.name}</p>
                                )}
                                <p className="doc-text">.pdf/.doc/.docx 10MB max.</p>
                                {/* <Form.Control.Feedback type="invalid">Please select a file.</Form.Control.Feedback> */}
                              </div>
                            </Form.Group>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="d-flex mt-20">
                            <div className="next-btn flex-grow-1">
                              <Button className="btn-wrapper btn-wrapper-blue" onClick={this.prevStep} aria-label='back'>
                                Back
                              </Button>
                            </div>
                            <div className="next-btn float-end">
                              <Button className="btn-wrapper btn-wrapper-blue" onClick={this.nextStep} aria-label='next'>
                                Next
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>

              </div>
            </div>
          </div>
        )}

        {step === 3 && (
          <div>
            <div className="budget-form">
              <div className="form-title">
                <h4 className="mt-sm-20">Budget & Timeline</h4>
                <h6 className="text-regular pt-10">
                  What is the value proposition of your product? Do you have a wishlist for the features or do you wish to build it together with us?
                </h6>
              </div>
              <div className="multistep-from pt-30">
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col lg={12}>

                          <div className="custom-select mb-20">
                            <Select
                              placeholder="Select Budget Range"
                              value={budget}
                              onChange={(selectedOption) => this.setState({ budget: selectedOption })}
                              options={options}
                              className={validated && !budget ? 'is-invalid' : ''}
                            />
                            {validated && !budget && <div className="invalid-feedback">Please select a budget.</div>}
                          </div>
                        </Col>
                        <Col lg={12}>

                          <div className="custom-select mb-20">
                            <Select
                              value={timeline}
                              onChange={(selectedOption2) => this.setState({ timeline: selectedOption2 })}
                              options={options2}
                              className={validated && !timeline ? 'is-invalid' : ''}
                            />
                            {validated && !timeline && <div className="invalid-feedback">Please select youu timeline.</div>}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={12}>
                      <div className="d-flex mt-20">
                        <div className="previous-btn flex-grow-1">
                          <Button
                            className="btn-wrapper btn-wrapper-blue btn-wrapper-medium"
                            onClick={this.prevStep} aria-label='back'
                          >
                            Back
                          </Button>
                        </div>
                        <div className="next-btn float-end">
                          <Button className="btn-wrapper btn-wrapper-blue" onClick={this.nextStep} aria-label='next'>
                            Next
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>

              </div>
            </div>
          </div>
        )}

        {step === 4 && (
          <div>
            <div className="lastthing-form">
              <div className="form-title">
                <h4 className="mt-sm-20">One last thing</h4>
                <h6 className="text-regular pt-10">How did you hear about Sensussoft?</h6>
              </div>
              <div className="multistep-from pt-30">
                <Form onSubmit={this.handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <Row>
                        <Col lg={12}>

                          <div className="custom-select mb-20">
                            <Select
                              value={one_last_thing}
                              onChange={(selectedOption3) => this.setState({ one_last_thing: selectedOption3 })}
                              options={options3}
                              className={validated && !one_last_thing ? 'is-invalid' : ''}
                            />
                            {validated && !one_last_thing && <div className="invalid-feedback">Please select how you heard about Sensussoft.</div>}
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="d-flex mt-20">
                            <div className="previous-btn flex-grow-1">
                              <Button
                                className="btn-wrapper btn-wrapper-blue btn-wrapper-medium"
                                onClick={this.prevStep} aria-label='back'
                              >
                                Back
                              </Button>
                            </div>
                            <div className="next-btn float-end">

                              <Button type="submit" className="btn-wrapper btn-wrapper-blue" disabled={isLoading} aria-label='submit'>
                                {isLoading ? <span> loading <i className="fa fa-spinner fa-spin"></i> </span> : 'Submit'}
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        )}
        <div className="sucess-modal">
          <Modal className='sucess-modal-main' centered show={successModalOpen} onHide={this.handleSuccessModalClose}>

            <Modal.Body className='sucess-modal-info'>
              <div className='sucessfully-content text-center'>

                <div className='sucess-img'>
                  <img className="img-fluid " src={require('../assets/images/sucess.gif')} alt="Attach File" />
                </div>
                <p className='pt-10'>Form submitted successfully!</p>
              </div>
              <div className='sucess-close text-center pt-20'>
                <Button className="btn-wrapper btn-wrapper-blue" onClick={this.handleSuccessModalClose} aria-label='close'>
                  Ok
                </Button>
              </div>

            </Modal.Body>

          </Modal>
        </div>
      </div>
    );
  }
}

export default MultiStepForm;

