import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Col } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';

export default function Footer() {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('email', email);
        axios
            .post('https://api.sensussoft.com/api/subscribe', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                setEmail(''); // Reset the email field
                setShowSuccessModal(true); // Show success modal
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCloseModal = () => {
        setShowSuccessModal(false);
    };

    return (
        <footer aria-label="Content Information">
            <div className="contact-info-box">
                <div className="container">
                    <div className="col-lg-12">
                        <div className="footer-bg-parent">
                            <div className="my-element-to-clip">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="ss-contact-info footer-location-border">
                                            <div className="contact-info-main d-flex mb-15">
                                                <div className="ss-location">
                                                    <div className="location-img">
                                                        <img src={require('../assets/images/location.svg').default} className="img-fluid" alt='img' />
                                                    </div>
                                                </div>
                                                <div className="location-text">
                                                    <p className="text-regular">
                                                        402 To 406, Angel Square, Near Punjab National Bank Utran(Digital Valley), Surat, Gujarat 394105

                                                    </p>
                                                </div>
                                            </div>
                                            <div className="contact-info-main d-flex mb-15">
                                                <div className="ss-location">
                                                    <div className="ss-phn-img">
                                                        <FontAwesomeIcon icon={faPhone} />

                                                    </div>
                                                </div>
                                                <div className="location-text">
                                                    <Link to="tel:+91 9558189523" aria-label='phone number'>
                                                        <p className="text-regular">
                                                            +91 9558189523
                                                        </p>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="contact-info-main d-flex mb-15">
                                                <div className="ss-location">
                                                    <div className="ss-phn-img">
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </div>
                                                </div>
                                                <div className="location-text">
                                                    <Link to="mailto:info@sensussoft.com" aria-label='mail id'>
                                                        <p className="text-regular">
                                                            info@sensussoft.com
                                                        </p>
                                                    </Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="ss-contact-info footer-location-border">
                                            <div className="contact-info-main d-flex mb-15">
                                                <div className="ss-location">
                                                    <div className="location-img">
                                                        <img src={require('../assets/images/caloaction.svg').default} className="img-fluid" alt='img' />
                                                    </div>
                                                </div>
                                                <div className="location-text">
                                                    <p className="text-regular">
                                                        1458 Altamont Ave, Schenectady, NY-12188
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="contact-info-main d-flex mb-15">
                                                <div className="ss-location">
                                                    <div className="ss-phn-img">
                                                        <FontAwesomeIcon icon={faPhone} />

                                                    </div>
                                                </div>

                                                <div className='location-text'>
                                                    <Link to="tel:+14175383479 " aria-label='phone number'>
                                                        <p className="text-regular">
                                                            +14175383479
                                                        </p>
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="ss-contact-info ">
                                            <div className="contact-info-main d-flex mb-15">
                                                <div className="ss-location">
                                                    <div className="location-img">
                                                        <img src={require('../assets/images/location.svg').default} className="img-fluid" alt='img' />
                                                    </div>
                                                </div>
                                                <div className="location-text">
                                                    <p className="text-regular">
                                                        344, 3rd Floor, Central Bazzar, Navsari,<br />
                                                        Gujarat 396445
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="contact-info-main d-flex mb-15">
                                                <div className="ss-location">
                                                    <div className="ss-phn-img">
                                                        <FontAwesomeIcon icon={faPhone} />
                                                    </div>
                                                </div>
                                                <div className="location-text">
                                                    <Link to="tel:+91 9558189523" aria-label='phone number'>
                                                        <p className="text-regular">
                                                            +91 9558189523
                                                        </p>
                                                    </Link>

                                                </div>
                                            </div>
                                            <div className="contact-info-main d-flex mb-15">
                                                <div className="ss-location">
                                                    <div className="ss-phn-img">
                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                    </div>
                                                </div>
                                                <div className="location-text">
                                                    <Link to="mailto:info@sensussoft.com" aria-label='mail id'>
                                                        <p className="text-regular">
                                                            info@sensussoft.com

                                                        </p>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <svg width="0" height="0">
                            <defs>
                                <clipPath id="myCurve" clipPathUnits="objectBoundingBox">
                                    <path
                                        d="M 0,1
                                                  L 0,0
                                                  L 1,0
                                                  L 1,1
                                                  C .65 .8, .35 .8, 0 1
                                                  Z"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="footer-main pt-40 pb-40">
                <div className="footer-custom container-fluid ">
                    <div className="footer-border pt-110">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="footer-links-main">
                                        <h2>Services We offer</h2>
                                        <ul className="footer-links">
                                            <li>
                                                <Link to="/product-ideation-services" aria-label='Ideation and planning'>
                                                    Ideation and planning
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/mobile-app-development" aria-label='Mobile App Development'>
                                                    Mobile App Development
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/web-application-development " aria-label="Website Development">
                                                    Website Development
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/ui-ux-design" aria-label='UI & UX Design'>
                                                    UI & UX Design
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/quality-assurance" aria-label='Quality Assurance'>
                                                    Quality Assurance
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/maintenance-support" aria-label='Maintenance and support'>
                                                    Maintenance and support
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/ai-ml-development" aria-label='AI/ML Development'>
                                                    AI/ML Development
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/blockchain-development" aria-label='Blockchain Development'>
                                                    Blockchain Development
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/marketplace-apps" aria-label='Marketplace Apps'>
                                                    Marketplace Apps
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="footer-links-main">
                                        <h2>Quick Links</h2>
                                        <ul className="footer-links">

                                            <li>
                                                <Link to='/case-study' aria-label='Case Study'>
                                                    Case Study
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to='/about-us' aria-label='About Us'>
                                                    About Us
                                                </Link>
                                            </li>
                                            <li className="hiring" >
                                                <Link to="/careers" aria-label=' Careers hiring'>
                                                    Careers<span>Hiring!</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/team" aria-label=' Our Team'>
                                                    Our Team
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/testimonials" aria-label='Testimonials'>
                                                    Testimonials
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/privacy-policy" aria-label='Privacy-Policy'>
                                                    Privacy-Policy
                                                </Link>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="footer-links-main">
                                        {/*<h2>Keep In Touch</h2>
                                        <div className="footer-contact d-flex mb-10">
                                            <div className="phn-img align-self-center">
                                                <FontAwesomeIcon icon={faPhone} />
                                            </div>
                                            <div className="contact-line">

                                            </div>
                                            <div className="contact-text align-self-center flex-grow-1">
                                                <Link to="tel:+91 9558189523" aria-label='phone number'>
                                                    <h3 className="mb-0 text-regular">+91 9558189523</h3>
                                                </Link>

                                            </div>
                                        </div>
                                        <div className="footer-contact d-flex mb-10">
                                            <div className="phn-img align-self-center">
                                                <FontAwesomeIcon icon={faEnvelope} />
                                            </div>
                                            <div className="contact-line">

                                            </div>
                                            <div className="contact-text align-self-center flex-grow-1">

                                                <Link to="mailto:info@sensussoft.com" aria-label='mail'>
                                                    <h3 className="text-regular mb-0">info@sensussoft.com</h3>
                                                </Link>
                                            </div>
                                        </div>*/}

                                        <h2 className='pt-2'>HR Contact</h2>
                                        <div className="footer-contact d-flex mb-10">
                                            <div className="phn-img align-self-center">
                                                <FontAwesomeIcon icon={faPhone} />
                                            </div>
                                            <div className="contact-line">

                                            </div>
                                            <div className="contact-text align-self-center flex-grow-1">
                                                <Link to="tel:+91-722-782-9705" aria-label='phone number'>
                                                    <h3 className="mb-0 text-regular">+91 7227829705</h3>
                                                </Link>

                                            </div>
                                        </div>
                                        <div className="footer-contact d-flex mb-10">
                                            <div className="phn-img align-self-center">
                                                <FontAwesomeIcon icon={faEnvelope} />
                                            </div>
                                            <div className="contact-line">

                                            </div>
                                            <div className="contact-text align-self-center flex-grow-1">

                                                <Link to="mailto:hr@sensussoft.com" aria-label='mail to hr'><h3 className="text-regular mb-0">hr@sensussoft.com</h3></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-part2 row pt-50 pb-60 align-items-center">
                                <div className="col-lg-8 ">
                                    <div className="footer-text">
                                        <p className="text-regular">
                                            Sensussoft is a prominent service provider that brings ideas to reality.
                                        </p>
                                    </div>
                                </div>
                                {/*<div className="col-lg-4">
                                    <div className="search-box-main mt-sm-20 mt-md-20">
                                        <Form.Control
                                            type="text"
                                            placeholder="Your email"
                                            className="me-2 seachbox"
                                            aria-label="Search"
                                        />
                                        <div className="send-btn-main">
                                            <Button className="send-btn" aria-label='send button'>
                                                <img src={require('../assets/images/send-ic.svg').default} className="img-fluid" alt='img' />
                                            </Button>
                                        </div>

                                    </div>
                                </div>*/}
                                <div className="col-lg-4">
                                    <div className="search-box-main mt-sm-20 mt-md-20">
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Control
                                                type="email"
                                                placeholder="Your email"
                                                className="me-2 seachbox"
                                                aria-label="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                            <div className="send-btn-main">
                                                <Button type="submit" className="send-btn" aria-label='send button' disabled={loading}>
                                                    {loading ? 'Submitting...' : (
                                                        <img src={require('../assets/images/send-ic.svg').default} className="img-fluid" alt='img' />
                                                    )}
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='footer-social-mobile footer-social'>
                                <div className="social-link d-flex ">

                                    <div className="logo-sm phn-img align-self-center flex-fill text-center">
                                        <Link to="/" aria-label='home page'>
                                            <img src={require('../assets/images/sensussoft-ic.png')} className="img-fluid" alt='img' />
                                        </Link>
                                    </div>

                                    <div className="phn-img-main align-self-center flex-fill text-center">
                                        <div className="phn-img">
                                            <Link to="https://www.facebook.com/Sensussoft/" target='_blank' aria-label='facebook profile'>
                                                <i class="fa fa-facebook" aria-hidden="true"></i>

                                            </Link>
                                        </div>
                                    </div>

                                    <div className="phn-img-main align-self-center flex-fill text-center">
                                        <div className="phn-img ">
                                            <Link to="https://twitter.com/sensussoft" target='_blank' aria-label='twitter profile'>
                                                <i class="fa fa-twitter" aria-hidden="true"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="phn-img-main align-self-center flex-fill text-center">
                                        <div className="phn-img ">
                                            <Link to="https://www.behance.net/Sensussoft" target='_blank' aria-label='behance profile'>
                                                <i class="fa fa-behance" aria-hidden="true"></i>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="phn-img-main align-self-center flex-fill text-center">
                                        <div className="phn-img ">
                                            <Link to="https://www.linkedin.com/company/sensussoft" target='_blank' aria-label='linkedin profile'>
                                                <i class="fa fa-linkedin" aria-hidden="true"></i>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="phn-img-main align-self-center flex-fill text-center">
                                        <div className="phn-img ">
                                            <Link to="https://www.instagram.com/sensussoft/" target='_blank' aria-label='instagram profile'>
                                                <i class="fa fa-instagram" aria-hidden="true"></i>
                                            </Link>
                                        </div>

                                    </div>

                                </div>
                                <div className="footer-contact d-flex d-sm-md-block">
                                    <div className="footer-copyright">

                                        <div className="copyright-text align-self-center">
                                            <p className="mb-0 ">Copyright & 2012 - {(new Date().getFullYear())}. All Rights Reserved - Sensussoft Software Pvt. Ltd</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-social">
                                <div className="footer-contact d-flex d-sm-md-block">
                                    <div className="footer-copyright d-flex flex-grow-1 ">
                                        <div className="phn-img-main  bd-highlight align-self-center">
                                            <div className="phn-img ">
                                                <Link to="/what-we-do" aria-label='home page'>
                                                    <img src={require('../assets/images/sensussoft-ic.png')} className="img-fluid" alt='img' />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="contact-line">
                                        </div>
                                        <div className="copyright-text align-self-center">
                                            <p className="mb-0 ">Copyright & 2012 - {(new Date().getFullYear())}. All Rights Reserved - Sensussoft Software Pvt. Ltd</p>
                                        </div>
                                    </div>
                                    <div className="social-link d-flex ">
                                        <div className="contact-line">
                                        </div>
                                        <div className="phn-img-main align-self-center">
                                            <div className="phn-img">
                                                <Link to="https://www.facebook.com/Sensussoft/" target='_blank' aria-label='facebook profile'>
                                                    <i class="fa fa-facebook" aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="contact-line">
                                        </div>
                                        <div className="phn-img-main align-self-center">
                                            <div className="phn-img ">
                                                <Link to="https://twitter.com/sensussoft" target='_blank' aria-label='twitter profile'>
                                                    <i class="fa fa-twitter" aria-hidden="true"></i>

                                                </Link>
                                            </div>
                                        </div>
                                        <div className="contact-line">
                                        </div>
                                        <div className="phn-img-main align-self-center">
                                            <div className="phn-img ">
                                                <Link to="https://www.behance.net/Sensussoft" target='_blank' aria-label='behance profile'>
                                                    <i class="fa fa-behance" aria-hidden="true"></i>

                                                </Link>
                                            </div>

                                        </div>
                                        <div className="contact-line">
                                        </div>
                                        <div className="phn-img-main align-self-center">
                                            <div className="phn-img ">
                                                <Link to="https://www.linkedin.com/company/sensussoft" target='_blank' aria-label='linkedin profile'>
                                                    <i class="fa fa-linkedin" aria-hidden="true"></i>

                                                </Link>
                                            </div>
                                        </div>

                                        <div className="contact-line">
                                        </div>
                                        <div className="phn-img-main align-self-center">
                                            <div className="phn-img ">
                                                <Link to="https://www.instagram.com/sensussoft/" target='_blank' aria-label='facebook profile'>
                                                    <i class="fa fa-instagram" aria-hidden="true"></i>

                                                </Link>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Col lg={12}>
                <div className="sucess-modal">
                    <Modal className='sucess-modal-main' centered show={showSuccessModal} onHide={handleCloseModal}>

                        <Modal.Body className='sucess-modal-info'>
                            <div className='sucessfully-content text-center'>

                                <div className='sucess-img'>
                                    <img className="img-fluid " src={require('../assets/images/sucess.gif')} alt="Attach File" />
                                </div>
                                <p className='pt-10'>Form submitted successfully!</p>
                            </div>
                            <div className='sucess-close text-center pt-20'>
                                <Button className="btn-wrapper btn-wrapper-blue" onClick={handleCloseModal} aria-label='ok'>
                                    Ok
                                </Button>
                            </div>

                        </Modal.Body>



                    </Modal>

                </div>
            </Col>
        </footer>
    )
}
