import React from 'react'
import { Col, Container, Row, Stack, Nav, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ScheduleCall from '../../components/ScheduleCall';
import Faq from '../../components/Faq';
import Accordion from 'react-bootstrap/Accordion';
import Portfolio from '../../components/Portfolio';
import { Helmet } from 'react-helmet-async';

function Blockchaindevelopment() {
  return (
    <>
      <Helmet>
        <title>Revolutionizing Business with Advanced Blockchain Solutions</title>
        <meta name="title" content="Revolutionizing Business with Advanced Blockchain Solutions" />
        <meta name="keywords" content="Advanced Blockchain Solutions, Blockchain Innovations, Blockchain Development, Business Blockchain Solutions, Sensussoft Blockchain" />

        <meta name="description" content="Elevate your business with our cutting-edge blockchain solutions. From NFTs to Whitelabel options, trust us to revolutionize your operations and drive efficiency." />
      </Helmet>
      <main>
        <div className='aimi-serive-main blockchain-serivce-main'>

          {/* section 1 */}
          <div className="maintanace-main website-development ptb-80 bg-logo">
            <Container>
              <Row className='pb-80 justify-content-end'>
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className=" service-btn-mobile  next-prev-btn comm-btn ">
                    <Link aria-label='productideation' to='/ai-ml-development'>
                      <button className="service-previous-btn learn-more arrow-btn " aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className="service-btn-mobile next-prev-btn comm-btn text-end ">
                    <Link aria-label='mobileappdevelopment' to='/marketplace-apps'>
                      <button className="learn-more arrow-btn" aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="align-self-center">
                  <div className="website-development-text proof-text-main">
                    <div className="ss-heading proof-text">
                      <h1><span>The Evolution of Blockchain Development</span></h1>
                      <p className="text-regular mt-20">
                        Empowering businesses with superior blockchain solutions, Sensussoft proudly stands as the premier blockchain development company. Our expertise lies in crafting custom blockchain solutions and decentralized innovations, focusing on NFTs, Whitelabel Solutions, and more. From start-ups to enterprises, we offer a wide spectrum of services, elevating efficiency and automation. Experience a transformational journey as we develop cutting-edge blockchain solutions for your business. Trust us to build a decentralized future that revolutionizes your operations.

                      </p>

                      <Link to='/get-in-touch-with-us' aria-label='contact us for detail'>
                        <button className="learn-more btn-large mt-25" aria-label='contact us for detail'>
                          <span className="circle" aria-hidden="true">
                            <span className="icon arrow">
                              <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                            </span>
                          </span>
                          <span className="button-text">CONTACT US FOR DETAILS</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="align-self-center text-center">
                  <div className="change_vision_img"><img className="img-fluid" src={require('../../assets/images/blockchainimg.png')} alt='service1' /></div>

                  <div className="website-development-img-main">

                    <div className="website-development-img mt-sm-20 mt-md-20">
                      <img className="img-fluid" src={require('../../assets/images/blockchainimg.png')} alt="ai-service"></img>
                    </div>
                    <div className="web-build-box maitanance-box">
                      <div className="like-round">
                        <img className="img-fluid " src={require('../../assets/images/blub.svg').default} alt="blub">
                        </img>
                      </div>
                      <div className="build-text">
                        <h2>
                          50+
                        </h2>
                        <p className="text-regular">
                          360° services <br />support
                        </p>
                      </div>
                    </div>
                    <div className="web-build-box2 maitanance-box2">
                      <p>
                        Unmatched Maintenance Support:<br></br> Covering Every Dimension of Your Needs.
                      </p>
                      <div className="line">
                        <img className="img-fluid" src={require('../../assets/images/linefill.png')} alt="line"></img><br />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 1 end*/}

          {/* section 2 */}
          <div className="idea-and-enhance bg-service ">
            <Container>
              <Row>
                <Col lg={6}>
                  <div className="ai-service-list  ideation-services">
                    <div className="services-in-action-main mb-md-20">
                      <div className="services-in-action pt-100 pb-200">
                        <div className="our-vision-text-main d-flex">
                          <div className="bd-highlight vision-img-main">
                            <div className="our-vision-img overview-img">
                              <img className="img-fluid" src={require('../../assets/images/overview.svg').default} alt="vision-ic"></img>
                            </div>
                          </div>
                          <div className="our-vision-text">
                            <p>
                              Service Overview
                            </p>
                          </div>
                        </div>
                        <div className="ss-heading ss-portfolio-text">
                          <h2 className="ptb-15">
                            Our <span>Blockchain</span>  Software Development Services
                          </h2>

                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={12} className='align-self-center'>
                  <div className="ideation-content ptb-100">
                    <div className="enhance-content-main " >
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Coin & Token Development Services:</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          With expertise in blockchain protocols and smart contract development, we offer end-to-end solutions for creating customized coins and tokens. Whether it's for crowdfunding, loyalty programs, or asset digitization, our comprehensive approach ensures compliance, scalability, and interoperability with existing ecosystems, giving your project a solid foundation for success.
                        </p>

                      </div>
                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Custom Blockchain Software Development:</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Our team of skilled developers specializes in building tailor-made blockchain software solutions that align perfectly with your business requirements. From private, public, to hybrid blockchains, we leverage the latest technologies to create robust, secure, and high-performance systems that drive efficiency and productivity across various industries.
                          <br></br>
                          Programming languages: Solidity, Vyper, Rust, Python, C++
                          <br></br>
                          Frameworks: Ether.JS, Web3.JS, React.JS, Node JS

                        </p>

                      </div>

                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Blockchain Smart Contract Development:</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Our team of skilled developers specializes in building tailor-made blockchain software solutions that align perfectly with your business requirements. From private, public, to hybrid blockchains, we leverage the latest technologies to create robust, secure, and high-performance systems that drive efficiency and productivity across various industries.
                          <br></br>
                          Technologies:- Truffle, OpenZeppline, HardHat, Infura, Alchemy, Quicknode, Ganache, Pinata, Metamask, Geth, Chainlink
                        </p>

                      </div>

                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">White Papers And Transaction Services:</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Our whitepaper services help articulate your blockchain project's vision, technology, and potential, fostering investor confidence and interest. We also provide efficient transaction services to facilitate smooth interactions within your blockchain network, promoting seamless user experiences and increased adoption.
                        </p>

                      </div>

                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">DeFi Development:</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          As pioneers in decentralized finance (DeFi) development, we create groundbreaking solutions like decentralized exchanges (DEXs), liquidity protocols, lending platforms, and yield farming applications. Empower your users with financial autonomy and security through our DeFi expertise.
                        </p>

                      </div>

                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">NFT Development:</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Embrace the NFT revolution with our comprehensive NFT development services. From digital art, and gaming assets, to collectibles, we build and deploy non-fungible token solutions on various blockchains, enabling ownership, provenance, and unique experiences for creators and collectors alike.
                        </p>
                      </div>
                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Whitelabel Solutions:</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Accelerate your blockchain project's launch with our whitelabel solutions. We offer fully customizable, pre-built platforms for exchanges, wallets, and DeFi applications, saving you time and resources while ensuring top-notch performance and security.
                        </p>
                      </div>
                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Crypto Bank Development:
                          </span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Enter the world of digital banking with our crypto bank development services. We create secure, compliant, and user-friendly platforms that offer a wide range of financial services, bridging the gap between traditional banking and the emerging crypto economy.
                        </p>
                      </div>
                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Exchange Development:
                          </span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Our exchange development services cover everything from centralised and decentralised exchanges to peer-to-peer trading platforms. We ensure seamless order matching, high liquidity, and top-grade security features to enable smooth trading experiences for your users.
                        </p>
                      </div>
                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Wallet Development:


                          </span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Securely store, send, and receive digital assets with our custom wallet development services. We build user-friendly, multi-currency wallets with robust security features, ensuring the safety of funds and private keys while offering a seamless user experience.
                          <br></br>
                          Partner with us to leverage the full potential of blockchain technology and drive your business towards a decentralized and prosperous future.
                          <br></br>
                          Technologies: Metamask, Trust Wallet, Binance, Coinbase, WalletConnect


                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 2 end*/}

          {/* section 3 */}
          <div className="process-main ptb-80">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      How does blockchain work?
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row className="pt-50">
                <Col lg={12}>
                  <div className="ss-process-tab">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                      <Row className="justify-content-between">
                        <Col lg={4} md={12}>
                          <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                              <Nav.Link eventKey="first" aria-label='Transaction'>1. Transaction<span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="second" aria-label='Block'>2. Block<span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="third" aria-label='Verification'>3. Verification<span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="fourth" aria-label='Hash'>4. Hash <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="fifth" aria-label='Execution'>5. Execution <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                          <Nav.Link eventKey="sixth">6. Test Cycle Closure <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                        </Nav.Item> */}
                          </Nav>
                        </Col>
                        <Col lg={7} md={12}>
                          <Tab.Content className="pt-50">
                            <Tab.Pane eventKey="first">
                              <div className="ss-process-content">
                                <div className="ss-process-img d-flex flex-wrap">
                                  <div className="bd-highlight align-self-center">
                                    <div className="core-value-img">
                                      <img className="img-fluid" src={require('../../assets/images/maintanance-ic1.svg').default} alt="maintanance-ic1" />
                                    </div>
                                  </div>
                                </div>

                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <p className="light-heading">
                                        In the transaction stage, users initiate the transfer of digital assets or information on the blockchain network.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        The transaction data is encrypted, ensuring security and privacy during its journey through the blockchain.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <div className="ss-process-content">
                                <div className="ss-process-img d-flex flex-wrap">
                                  <div className="bd-highlight align-self-center">
                                    <div className="core-value-img">
                                      <img className="img-fluid" src={require('../../assets/images/maintanance-ic2.svg').default} alt="maintanance-ic2" />
                                    </div>
                                  </div>
                                </div>

                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <p className="light-heading">
                                        Validated transactions are grouped into a "block," creating a sequential data structure for efficient storage and processing.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Each block contains a reference to the previous block, forming a chain-like structure, ensuring chronological order and immutability.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                              <div className="ss-process-content">
                                <div className="ss-process-img d-flex flex-wrap">
                                  <div className="bd-highlight align-self-center">
                                    <div className="core-value-img">
                                      <img className="img-fluid" src={require('../../assets/images/maintanance-ic3.svg').default} alt="maintanance-ic2" />
                                    </div>
                                  </div>
                                </div>

                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <p className="light-heading">
                                        Miners or validators check the validity of each transaction, ensuring that the sender has sufficient funds and adheres to network rules.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Consensus mechanisms like Proof-of-Work (PoW) or Proof-of-Stake (PoS) are used to achieve agreement among network participants on the validity of the transactions.
                                      </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                              <div className="ss-process-content">
                                <div className="ss-process-img d-flex flex-wrap">
                                  <div className="bd-highlight align-self-center">
                                    <div className="core-value-img">
                                      <img className="img-fluid" src={require('../../assets/images/maintanance-ic4.svg').default} alt="maintanance-ic2" />
                                    </div>
                                  </div>
                                </div>

                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <p className="light-heading">
                                        A unique cryptographic hash is generated for each verified block, representing a fixed-length sequence of characters.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Even a minor change in the block's data will lead to a completely different hash, ensuring data integrity and preventing tampering.
                                      </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                              <div className="ss-process-content">
                                <div className="ss-process-img d-flex flex-wrap">
                                  <div className="bd-highlight align-self-center">
                                    <div className="core-value-img">
                                      <img className="img-fluid" src={require('../../assets/images/maintanance-ic5.svg').default} alt="maintanance-ic2" />
                                    </div>
                                  </div>
                                </div>

                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <p className="light-heading">
                                        The validated block, along with its unique hash, is added to the blockchain, becoming an immutable part of the distributed ledger.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Nodes on the network update their copies of the blockchain, reflecting the successful execution of the new block and its transactions</p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </Tab.Pane>

                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 3 end*/}
          <div className="our-industry ptb-100 bg-multi ptb-100">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      Our Industry <span> Specific Experience </span>
                    </h2>
                    <p className="pt-10 text-regular">
                      Sensussoft has been dedicated to providing mobile app and website solutions with industry specific experience. Whether it is lifestyle, health, fitness, booking, or e-commerce, Sensussoft has worked on various projects tailored to individual businesses' needs.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="pt-50 justify-content-center ">
                <Col lg={12}>
                  <div className="experience-box-main d-flex flex-wrap justify-content-center">
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic8.svg').default} className="img-fluid" alt='experience-ic1' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Restaurant
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic9.svg').default} className="img-fluid" alt='experience-ic2' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Logistics
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic10.svg').default} className="img-fluid" alt='experience-ic3' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Games & Sports
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic5.svg').default} className="img-fluid" alt='experience-ic5' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Aviation
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic11.svg').default} className="img-fluid" alt='experience-ic4' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Real Estate
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic12.svg').default} className="img-fluid" alt='experience-ic6' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          On-Demand
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic13.svg').default} className="img-fluid" alt='experience-ic7' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Government
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic1.svg').default} className="img-fluid" alt='experience-ic1' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Social Media
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic2.svg').default} className="img-fluid" alt='experience-ic2' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          E-commerce
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic3.svg').default} className="img-fluid" alt='experience-ic3' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Entertainment
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic4.svg').default} className="img-fluid" alt='experience-ic4' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Healthcare
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic5.svg').default} className="img-fluid" alt='experience-ic5' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Travel
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic6.svg').default} className="img-fluid" alt='experience-ic6' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Fintech
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic7.svg').default} className="img-fluid" alt='experience-ic7' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Education
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>


          {/* section  5*/}
          <div className="attractive-main ptb-120">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="attractive-box">
                    <Stack direction="horizontal" className="d-mobile-block">
                      <div className="attractive-text flex-grow-1">
                        <h3 className="ss-title">
                          <span className="text-regular">We Have Rolled Up Our Sleeves To <br></br>Provide You With 360-degree It Support.</span>
                        </h3>
                      </div>
                      <div className="attractive-btn">
                        <ScheduleCall></ScheduleCall>
                      </div>
                    </Stack>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 5 end */}
          {/* sectoion 6 */}
          <div className="services-portfolio-main ptb-80">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      <span>Our Portfolio</span>
                    </h2>

                  </div>
                </Col>
              </Row>
            </Container>
            <Portfolio></Portfolio>
          </div>
          {/* sectoion 6 end*/}
          {/* section 7 */}
          <div className="ss-faq-main">
            <div className="faq-main pt-120   ">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="faq-title proof-text-main text-center mb-30">
                      <div className="ss-heading proof-text">
                        <h2><span>Frequently Asked<br />
                          Questions </span></h2>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <div className="faq-accordion">
                      <Accordion defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>How blockchain can be used in business?
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                <ul>
                                  <li><p>Blockchain can benefit various industries, including global supply chains, healthcare, government, financial services, and many other industries. Startups and enterprises are exploring ways to disrupt blockchain to transform traditional business models. By providing you blockchain consulting services, our blockchain consulting team can help you understand how blockchain can bring transparency, traceability, security, and efficiency to your business ecosystem.

                                  </p></li>


                                </ul>
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Which is the right Blockchain platform for my use case?


                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                <ul>
                                  <li>
                                    <p>
                                      Before you think to build a blockchain solution for your business, you should select the right blockchain platform.
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Only an experienced blockchain expert can help you choose an ideal blockchain platform. Their solution will have the following basis:

                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      How much privacy your blockchain solution requires?
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Level of scalability you require
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Type of Blockchain App you need to build. For example, public, private, or permissioned.
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Consensus Algorithm
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Smart Contract Functionality
                                    </p>
                                  </li>
                                </ul>

                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>What is the Blockchain Development and benefits ?
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                A blockchain is a decentralized digital ledger that exists on multiple computers at the same time. It is a chain of blocks that contain records of information with a unique hash value that makes tampering with the data impossible. The development of applications or platforms using blockchain technology is defined as blockchain development. It can bring trust and transparency to the business ecosystems with its ability to store immutable data.  We are a blockchain development company that can provide blockchain development services right from building a PoC to setting up a decentralized application, deploying it on the cloud or on-premise, and maintaining it after the development stage.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>What is our Blockchain Development Process?


                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular pb-5">
                                We know that anything written on the blockchain or smart contracts cannot be deleted or altered. Therefore, we always ensure to follow the right strategy when it comes to blockchain implementation.
                                Our team has created the right approach to building blockchain applications and we follow a step-by-step blockchain development process using the agile methodology.

                              </p>
                              <p className="text-regular pb-5">
                                Following are the stages involved in the development of blockchain applications in chronological order:
                              </p>
                              <p className="text-regular">
                                <ul>
                                  <li>
                                    <p>
                                      Ideation

                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Selection of Blockchain Platform
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Blockchain Ideation to identify other technology stacks for the application


                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Development of PoC or Prototype


                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Visual and Technical Designs</p>
                                  </li>
                                  <li>
                                    <p>
                                      Blockchain App Development
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Maintenance and Upgrades
                                    </p>
                                  </li>
                                </ul>

                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>What are the best practices your blockchain developers follow?
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Our USP is to not compromise on quality and ensure customer satisfaction at all stages. Sensussoft follows a simplified development process where the project is managed in an agile approach from the beginning through the development stage to the final implementation, testing, and customer support.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <Faq></Faq>
          </div>
          {/* section 7 end*/}
        </div>
      </main>
    </>
  )
}

export default Blockchaindevelopment
