import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
// import { Link } from 'react-router-dom'

export default function PricingHire(props) {
    return (
        <div>
            {/* pricing list section start */}
            <div className='pricing-list-main ptb-100'>
                <Container>
                    <Row className='justify-content-center'>
                        <Col lg={10} md={12} className='text-center'>
                            <div className='pricing-first-heading'>
                                <p className='pricing-list-center-title'><span>PRICING TABLE</span></p>
                            </div>
                            <div className='ss-heading'><h2 className="heading-s1 text-center mb-0">Hire Top 1% <span>{props.tecnology}</span> according to your needs</h2></div>
                            <div className='pricing-list-detail'>
                                <p className='light-heading'>Here you can hire talented {props.tecnology} at the best price who understand your business needs.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center pt-50'>
                        <Col lg={12} md={4} className='mb-10'>
                            <div className='row same_pricing_class'>
                                <div className='row'>
                                    <Col className='pricing-availability-box' lg={3} md={4}>
                                        <div className='pricing-availability-img align-self-center'>
                                            <div>
                                                <img src={require("../assets/images/hire-devloper/pricing-list.svg").default} className='img-fluid' alt='pricing-list' />
                                            </div>

                                        </div>
                                        <div className='pricing-availability-text align-self-center'>
                                            <p>24 *7 Availability</p>
                                        </div>
                                    </Col>
                                    <Col className='pricing-availability-box' lg={3} md={4}>
                                        <div className='pricing-availability-img align-self-center'>
                                            <div>
                                                <img src={require("../assets/images/hire-devloper/pricing-list2.svg").default} className='img-fluid' alt='pricing-list' />
                                            </div>

                                        </div>
                                        <div className='pricing-availability-text align-self-center'>
                                            <p>Hourly Hiring</p>
                                        </div>
                                    </Col>
                                    <Col className='pricing-availability-box' lg={3} md={4}>
                                        <div className='pricing-availability-img align-self-center'>
                                            <div>
                                                <img src={require("../assets/images/hire-devloper/pricing-list3.svg").default} className='img-fluid' alt='pricing-list' />
                                            </div>

                                        </div>
                                        <div className='pricing-availability-text align-self-center'>
                                            <p>30+ Experts</p>
                                        </div>
                                    </Col>
                                    <Col className='pricing-availability-box' lg={3} md={4}>
                                        <div className='pricing-availability-img align-self-center'>
                                            <div>
                                                <img src={require("../assets/images/hire-devloper//pricing-list4.svg").default} className='img-fluid' alt='pricing-list' />
                                            </div>

                                        </div>
                                        <div className='pricing-availability-text align-self-center'>
                                            <p>Full-Time hiring</p>
                                        </div>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={6} className='mb-10'>
                            <div className='inquires_of_pricing'>
                                <h3>Hire a Dedicated Team</h3>
                                <p className='light-heading'>With Sensussoft, you get the right, long-term dedicated team of vetted specialists to meet your company’s goals. Get access to a full team of developers, designers, and project managers who work exclusively on your project. Enjoy the benefits of streamlined communication, collaborative workflows, and accelerated project timelines.</p>
                            </div>
                        </Col>
                        <Col lg={12} md={6} className='mb-10'>
                            <div className='inquires_of_pricing mb-20'>
                                <h3>Hire a Resource</h3>
                                <p className='light-heading'>Need a specific skillset for your project? Hire a dedicated resource from our talented pool of professionals. Whether it's a short-term task or a long-term project, our skilled resources are ready to step in and deliver high-quality results.</p>
                            </div>
                        </Col>
                        <Col lg={12} md={12}>
                            <div className='row middle'>
                                <Col lg={6} md={6} className='mb-10'>
                                    <label>
                                        <input type="radio" name="radio" checked />
                                        <div className="front-end box">
                                            <div className='pricing-card same_pricing_class'>
                                                <div className='pakages_of_price'><h4 className='text-center'>For individual</h4></div>
                                                <div className='price-number text-center'>
                                                    <p>MONTHLY (USD)</p>
                                                    <h2>$20/<span>h</span></h2>
                                                </div>
                                                <div className='our-facility-main'>
                                                    <ul className='our-facility-inner-div grey'>
                                                        <li>
                                                            <p className='light-heading'>Dedicated developer</p>
                                                        </li>
                                                        <li>
                                                            <p className='light-heading'>Pay as you go</p>
                                                        </li>
                                                        <li>
                                                            <p className='light-heading'>Suitable for short-term engagement</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </Col>
                                <Col lg={6} md={6} className='mb-10'>
                                    <label>
                                        <input type="radio" name="radio" />
                                        <div className="back-end box">
                                            <div className='pricing-card same_pricing_class'>
                                                <div className='pakages_of_price'><h3 className='text-center '>For Team</h3></div>
                                                <div className='price-number text-center'>
                                                    <p>MONTHLY (USD)</p>
                                                    <div className="contact_sales_btn get-touch-btn ps-0">
                                                        <button onClick={props.click} className="btn-wrapper-get border-0" aria-label='Contact Sales'>
                                                            Contact Sales
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='our-facility-main'>
                                                    <ul className='our-facility-inner-div'>
                                                        <li>
                                                            <p className='light-heading'>Suitable for long-term engagement</p>
                                                        </li>
                                                        <li>
                                                            <p className='light-heading'>Dedicated team of your required resources </p>
                                                        </li>
                                                        <li>
                                                            <p className='light-heading'>160 hours per month</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </label>

                                </Col>
                            </div>
                        </Col>
                        <Col lg={12} className='text-center'>
                            <div className="contact_sales_btn get-touch-btn mt-10 ps-0">
                                <button className="btn-wrapper-get border-0" onClick={props.click} aria-label='schedule an interview'>
                                    SCHEDULE An INTERVIEW
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* pricing list section end */}
        </div>
    )
}
