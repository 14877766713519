import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialSlider() {
  const [testimonial, setTestimonials] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://api.sensussoft.com/api/clienttestimonial?limit=6')
      .then(response => {
        if (response.data && response.data.success) {
          const testimonialData = response.data.data || [];
          setTestimonials(testimonialData);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching testimonials:', error);
        setLoading(false);
      });
  }, []);
  const handleFocusManagement = () => {
    // Ensure aria-hidden slides are not focusable
    document.querySelectorAll('.slick-slide[aria-hidden="true"]').forEach(slide => {
      slide.setAttribute('tabindex', '-1');
      slide.querySelectorAll('a, button, input, select, textarea').forEach(elem => {
        elem.setAttribute('tabindex', '-1');
      });
    });
  };

  useEffect(() => {
    handleFocusManagement();
  }, [testimonial]); // Reapply focus management when blogs change


  if (loading) {
    return (
      <div className='blog-loader-main'>
        <div className='blog-loader'>
          <div className='loader-img'>
            <Container>
              <Row className='justify-content-center ptb-100 text-center'>
                <Col lg={12}>
                  <div className='loading-content'>
                    <div style={{ position: 'relative', width: '250px', height: '150px' }}>
                      {/* SVG Markup */}
                      <svg width="100%" height="100%" viewBox="0 0 187.3 93.7" preserveAspectRatio="xMidYMid meet" className="svg-animation">
                        <path
                          stroke="#001B50"
                          id="outline"
                          fill="none"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                        />
                        <path
                          id="outline-bg"
                          opacity="0.05"
                          fill="none"
                          stroke="#001B50"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                        />
                      </svg>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
  const CustomArrow = ({ direction, onClick }) => {
    const icon = direction === 'next' ? faChevronRight : faChevronLeft;

    return (
      <div
        className={`custom-arrow ${direction}`}
        style={{ zIndex: '1', display: 'block' }}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    responsive: [
      {
        breakpoint: 1400, // Extra-large screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024, // Large screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };


  return (
    <div className="testimonials ptb-80">
      <div className="container">
        <div className="col-lg-12">
          <div className="testimonials-section">
            <div className="ss-heading">
              <h2>
                What Clients Say <span>About Sensussoft</span>
              </h2>
            </div>
            <div className="testimonials-box-main pt-35" style={{ position: 'relative' }}>
              <Slider {...settings}>
                {testimonial.map((testimonials) => (
                  <div key={testimonials.id} className="testimonials-box-s1 d-flex flex-column">
                    <div className="client-box d-flex">
                      <div className="bd-highlight align-self-center">
                        <div className="client-img">
                          <img src={testimonials.image} className="img-fluid" alt='img' />
                        </div>
                      </div>
                      <div className="client-name align-self-center">
                        <h2>{testimonials.name}</h2>
                        <p className="text-small mb-0">{testimonials.role}</p>
                      </div>
                    </div>
                    <div className="client-info pt-10 flex-grow-1">
                      <p>{testimonials.description}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className='view-testimonials text-center pt-50'>
              <Link to="/testimonials" aria-label='view all testimonials'>
                <button className="learn-more arrow-btn-secondary" aria-label='learn more'>
                  <span className="circle" aria-hidden="true">
                    <span className="icon arrow">
                      <img src={require('../assets/images/btn-arrow2.svg').default} className="img-fluid" alt='img' />
                    </span>
                  </span>
                  <span className="button-text ">View All</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
