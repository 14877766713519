import React from 'react'
import { Accordion, Col, Container, Row, Stack } from 'react-bootstrap'
import Portfolio from '../../components/Portfolio';
import Faq from '../../components/Faq';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Servicetab1 from '../../sliderdata/Servicetab1.json';
import Servicetab2 from '../../sliderdata/Servicetab2.json';
import { Link } from 'react-router-dom';
import ScheduleCall from '../../components/ScheduleCall';
import { Helmet } from 'react-helmet-async';

export default function Maintenance() {
  return (
    <>
      <Helmet>
        <title>Comprehensive 360° Software Support Services</title>
        <meta name="title" content="Comprehensive 360° Software Support Services" />
        <meta name="keywords" content="360° Software Support, Comprehensive Software Services, Software Maintenance, Software Support Solutions, Sensussoft Software Support" />
        <meta name="description" content="Get comprehensive 360° software support and maintenance services from Sensussoft. Our expert team is dedicated to resolving bugs, updating app versions, implementing new features, maintaining code quality, managing application security, and improving performance for a seamless user experience." />
      </Helmet>
      <div className="maintenance-support-main">

        <main>
          {/* section 1 */}
          <div className="maintanace-main website-development ptb-80 bg-logo">
            <Container>
              <Row className='pb-80 justify-content-end'>
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className=" service-btn-mobile  next-prev-btn comm-btn ">
                    <Link aria-label='productideation' to='/quality-assurance'>
                      <button className="service-previous-btn learn-more arrow-btn " aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className="service-btn-mobile next-prev-btn comm-btn text-end ">
                    <Link aria-label='mobileappdevelopment' to='/ai-ml-development'>
                      <button className="learn-more arrow-btn" aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="align-self-center">
                  <div className="website-development-text proof-text-main">
                    <div className="ss-heading proof-text">
                      <h1><span>360° Services Support</span></h1>
                      <p className="text-regular mt-20">
                        Whenever you want our 360° software support we are always there for you. Your software responsibilities are 100% ours, and we accept them gratefully on a daily basis. Our experts can assist you with any software issue, including standard issue fixing, proactive monitoring, Re-engineering – Rebuild applications, source code issue resolution, application code optimization, and new functionality.            </p>
                      <Link to='/get-in-touch-with-us' aria-label='contact us for detail'>
                        <button className="learn-more btn-large mt-25" aria-label='contact us for detail'>
                          <span className="circle" aria-hidden="true">
                            <span className="icon arrow">
                              <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                            </span>
                          </span>
                          <span className="button-text">CONTACT US FOR DETAILS</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="align-self-center text-center">
                  <div className="change_vision_img"><img className="img-fluid" src={require('../../assets/images/serviceimg6.jpg')} alt='service1' /></div>

                  <div className="website-development-img-main">

                    <div className="website-development-img mt-sm-20 mt-md-20">
                      <img className="img-fluid" src={require('../../assets/images/maintanance.jpg')} alt="maintanance"></img>
                    </div>
                    <div className="web-build-box maitanance-box">
                      <div className="like-round">
                        <img className="img-fluid " src={require('../../assets/images/blub.svg').default} alt="blub">
                        </img>
                      </div>
                      <div className="build-text">
                        <h2>
                          50+
                        </h2>
                        <p className="text-regular">
                          360° services <br />support
                        </p>
                      </div>
                    </div>
                    <div className="web-build-box2 maitanance-box2">
                      <p>
                        Unmatched Maintenance Support: <br />Covering Every Dimension of Your Needs.
                      </p>
                      <div className="line">
                        <img className="img-fluid" src={require('../../assets/images/linefill.png')} alt="line"></img><br />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 1 end */}
          {/* section 2*/}
          <div className="application-support pt-80">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      Our 360° <span> Application Support</span> And Maintenance Services

                    </h2>
                    <p className="pt-10 text-regular">
                      We all know that great customer service converts to happy customers. After a good service, 81% of consumers are more likely to give the company repeat business.

                    </p>
                  </div>
                </Col>
              </Row>
              <div className="ss-case-study pb-80 ">
                <Tab.Container defaultActiveKey="Mobile">
                  <div className="">
                    <Row className=" pt-30">
                      <Col lg={8}>
                        <Nav variant="pills" className="flex-row " defaultActiveKey="Mobile">
                          <Nav.Item>
                            <Nav.Link eventKey="Mobile" aria-label='mobile'>Mobile</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="Web" aria-label='web'>Web</Nav.Link>
                          </Nav.Item>

                        </Nav>
                      </Col>
                    </Row>
                  </div>
                  <div className="container-fluid p-0">
                    <Row >
                      <Col sm={12} >
                        <Tab.Content>
                          <Tab.Pane eventKey="Mobile">
                            <Row>

                              {Servicetab1.map((mobile) => (
                                <Col lg={4} key={mobile.id}>
                                  <div className="maintanance-box mt-20">
                                    <div className="maintance-img">
                                      <img className="img-fluid" src={require(`../../assets/images/${mobile.moblieimg}`)} alt="maintanance-img1" />
                                    </div>
                                    <div className="maintance-text">
                                      <h3>
                                        {mobile.title}

                                      </h3>
                                    </div>
                                    <div className="maintanance-hover">
                                      <h3>
                                        {mobile.hovertitle}
                                      </h3>
                                      <h4>
                                        {mobile.hovertext}
                                      </h4>
                                    </div>
                                  </div>
                                </Col>
                              ))}




                            </Row>
                          </Tab.Pane>
                          <Tab.Pane eventKey="Web">
                            <Row>
                              {Servicetab2.map((mobile) => (
                                <Col lg={4} key={mobile.id}>
                                  <div className="maintanance-box mb-20">
                                    <div className="maintance-img">
                                      <img className="img-fluid" src={require(`../../assets/images/${mobile.moblieimg}`)} alt="maintanance-img1" />
                                    </div>
                                    <div className="maintance-text">
                                      <h3>
                                        {mobile.title}

                                      </h3>
                                    </div>
                                    <div className="maintanance-hover">
                                      <h3>
                                        {mobile.hovertitle}
                                      </h3>
                                      <h4>
                                        {mobile.hovertext}
                                      </h4>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </Tab.Pane>

                        </Tab.Content>
                      </Col>
                    </Row>
                  </div>


                </Tab.Container>
              </div>
            </Container>
          </div>
          {/* section 2 end*/}

          {/* section 3 */}
          <div className="process-main ptb-80">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      Our 360° <span>Software Solutions Modernization <br /></span> service! We provide:
                    </h2>
                    <p className="pt-10 text-regular">
                      Our 360° Software Solutions Modernization service includes identifying software issues, optimizing legacy code performance, testing workflows and integrations, and replacing legacy code with modern technology.


                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="pt-50">
                <Col lg={12}>
                  <div className="ss-process-tab">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                      <Row className="justify-content-between">
                        <Col lg={4} md={12}>
                          <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                              <Nav.Link eventKey="first" aria-label='Software Migration'>1. Software Migration<span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="second" aria-label='Re-engineering'>2. Re-engineering<span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="third" aria-label='Application Re-Hosting'>3. Application Re-Hosting<span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="fourth" aria-label='Real-time Support'>4. Real-time Support <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="fifth" aria-label='Competitive Advantage'>5. Competitive Advantage <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                          <Nav.Link eventKey="sixth">6. Test Cycle Closure <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                        </Nav.Item> */}
                          </Nav>
                        </Col>
                        <Col lg={7} md={12}>
                          <Tab.Content className="pt-50">
                            <Tab.Pane eventKey="first">
                              <div className="ss-process-content">
                                <div className="ss-process-img d-flex flex-wrap">
                                  <div className="bd-highlight align-self-center">
                                    <div className="core-value-img">
                                      <img className="img-fluid" src={require('../../assets/images/maintanance-ic1.svg').default} alt="maintanance-ic1" />
                                    </div>
                                  </div>
                                </div>

                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <p className="light-heading">
                                        Efficient and cost-effective migration of app to cloud


                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Minimize time, effort, and risk during the migration process

                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Enhance application infrastructure value
                                      </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <div className="ss-process-content">
                                <div className="ss-process-img d-flex flex-wrap">
                                  <div className="bd-highlight align-self-center">
                                    <div className="core-value-img">
                                      <img className="img-fluid" src={require('../../assets/images/maintanance-ic2.svg').default} alt="maintanance-ic2" />
                                    </div>
                                  </div>
                                </div>

                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <p className="light-heading">
                                        Rebuild applications with enhanced functionality



                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Transform to a Service Architecture (SOA)


                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Improve maintainability and scalability


                                      </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                              <div className="ss-process-content">
                                <div className="ss-process-img d-flex flex-wrap">
                                  <div className="bd-highlight align-self-center">
                                    <div className="core-value-img">
                                      <img className="img-fluid" src={require('../../assets/images/maintanance-ic3.svg').default} alt="maintanance-ic2" />
                                    </div>
                                  </div>
                                </div>

                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <p className="light-heading">
                                        Rebuild applications for enhanced functionality



                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Modularization and decoupling for easier maintenance

                                      </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                              <div className="ss-process-content">
                                <div className="ss-process-img d-flex flex-wrap">
                                  <div className="bd-highlight align-self-center">
                                    <div className="core-value-img">
                                      <img className="img-fluid" src={require('../../assets/images/maintanance-ic4.svg').default} alt="maintanance-ic2" />
                                    </div>
                                  </div>
                                </div>

                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <p className="light-heading">
                                        Cost-effective and efficient support services


                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Prompt resolution of technical issues


                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Proactive maintenance and continuous monitoring




                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                              <div className="ss-process-content">
                                <div className="ss-process-img d-flex flex-wrap">
                                  <div className="bd-highlight align-self-center">
                                    <div className="core-value-img">
                                      <img className="img-fluid" src={require('../../assets/images/maintanance-ic5.svg').default} alt="maintanance-ic2" />
                                    </div>
                                  </div>
                                </div>

                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <p className="light-heading">
                                        Ongoing support and enhancement

                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Stay one step ahead of the competition
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Agile development to adapt to changing market needs
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tab.Pane>

                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 3 end*/}
          {/* section 4 */}
          <div className="attractive-main ptb-120">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="attractive-box">
                    <Stack direction="horizontal" className="d-mobile-block">
                      <div className="attractive-text flex-grow-1">
                        <h3 className="ss-title">
                          <span className="text-text-regular">We Have Rolled Up Our Sleeves To Provide You With 360-degree It Support.</span>
                        </h3>
                      </div>

                      <div className="attractive-btn">
                        {/* <div className="comm-btn mb-sm-20 mb-md-20 mt-20">
                      <button className="learn-more btn-medium">
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                             <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                        <span className="button-text">Schedule a call</span>
                      </button>
                    </div> */}
                        <ScheduleCall />
                      </div>
                    </Stack>
                  </div>
                </Col>
              </Row>
            </Container>

          </div>
          {/* section 4 end*/}
          {/* sectoion 5 */}
          <div className="services-portfolio-main ptb-80">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      <span>Our Portfolio</span>
                    </h2>

                  </div>
                </Col>
              </Row>
            </Container>
            <Portfolio></Portfolio>
          </div>
          {/* sectoion 5 end*/}
          {/* section 6 */}
          <div className="ss-faq-main">
            <div className="faq-main pt-120   ">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="faq-title proof-text-main text-center mb-30">
                      <div className="ss-heading proof-text">
                        <h2><span>Frequently Asked<br />
                          Questions </span></h2>
                      </div>
                    </div>

                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <div className="faq-accordion">

                      <Accordion defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>What does software support and maintenance involve?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Support and maintenance for software projects include post-release activities such as adapting the application to any real-world user feedback, enhancing the user experience, adding new functionality, and addressing any problems that may occur after the software launches. Our services include monitoring, bug fixing, consultancy, ideation, feature enhancement, etc. We document everything (source code, architecture, etc.) to make future work easier.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>What software projects need support and maintenance?</Accordion.Header>
                          <Accordion.Body>
                            We recommend keeping a support team involved for some time after the project's release to address any issues, take user feedback into account, and ensure everything runs smoothly. Generally, a stable application does not require perpetual support, unless it wants to add or adjust features.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>What are the benefits of modernizing a software system?</Accordion.Header>
                          <Accordion.Body>
                            Choosing a new software to replace an old one allows the business to choose any technology with a sufficiently long lifespan. Updating new technologies will provide speed, security, and usability that existing systems cannot offer. By replacing an existing software solution with a modern technology one, businesses can take advantage of any upgrades and changes that were not available when the software was created.
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            Do you offer a mobile app or web app support and maintenance?</Accordion.Header>
                          <Accordion.Body>
                            Yes, of course. We provide 360° holistic software support when we take on a project. As a result, it becomes our full responsibility to see it through to the end. After understanding your current software and business objectives, we tailor a solution for you. We work with you to enhance and develop solutions that are tailored to your business needs.
                          </Accordion.Body>
                        </Accordion.Item>

                      </Accordion>
                    </div>
                  </Col>
                </Row>



              </Container>
            </div>
            <Faq></Faq>
          </div>
        </main>
        {/* section 6 end*/}
      </div>
    </>
  )
}
