import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Select from 'react-select';

// Position options for the dropdown
const options2 = [
    { value: 'Backend Engineer', label: 'Backend Engineer' },
    { value: 'Flutter Engineer', label: 'Flutter Engineer' },
    { value: 'IOS Engineer', label: 'IOS Engineer' },
    { value: 'Android Engineer', label: 'Android Engineer' },
    { value: 'Business Development Executive', label: 'Business Development Executive' },
    { value: 'Quality Assurance', label: 'Quality Assurance' },
    { value: 'Other', label: 'Other' },
];

function MyVerticallyCenteredModal(props) {
    // State variables to store form data
    const [selectedOption1, setSelectedOption1] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [city, setCity] = useState('');
    const [position, setPosition] = useState('');
    const [validated, setValidated] = useState(false);
    // const [showError, setShowErrors] = useState(false);
    const [selectError, setSelectError] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const handlePhoneChange = (phone, country) => {
        if (typeof phone === 'string' && phone && typeof country.dialCode === 'string') {
            const reducedPhone = phone.replace(country.dialCode, '').trim();
            setMobile(country.dialCode + ' ' + reducedPhone);
        }
    };

    // Function to handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const [isValid, setIsValid] = useState(true);

    const handleChange = (event) => {
        const inputValue = event.target.value;
        setEmail(inputValue);
        validateEmail(inputValue);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValid(emailRegex.test(email));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        // Check if all required fields are filled
        const isFormComplete = name && mobile && email && comment && city && position && selectedFile;

        // Check if the select field has a value
        if (!selectedOption1) {
            setSelectError(true);
        } else {
            setSelectError(false);
        }

        if (!isFormComplete || selectError) { // Include selectError in the condition
            setLoading(false);
            setValidated(true);
            return;
        }

        // Your form submission logic here
        const formData = new FormData();
        formData.append('name', name);
        formData.append('mobile', mobile);
        formData.append('email', email);
        formData.append('comment', comment);
        formData.append('city', city);
        formData.append('position', position);
        formData.append('attachment', selectedFile);

        axios
            .post('https://api.sensussoft.com/api/apply', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                resetForm(); // Clear the form fields
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
                setShowSuccessModal(true);
            });
    };

    // Function to reset the form fields
    const resetForm = () => {
        setSelectedOption1(null);
        setSelectedFile(null);
        setName('');
        setMobile('');
        setEmail('');
        setComment('');
        setCity('');
        setPosition('');
        // setShowErrors(false);
        setValidated(false);
    };

    const handleCloseModal = () => {
        setShowSuccessModal(false);
    };

    return (
        <Modal
            className="apply_job_modal"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>Apply For {props.title}</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} noValidate validated={validated} >
                    <Row>
                        <Col lg="6">
                            <div className="custom-input mb-20">
                                <Form.Group controlId="validationCustom01">
                                    <Form.Control required autoComplete='false' type="text" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Your Name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="custom-input mb-20">
                                <Form.Group className="mb-3" controlId="formGroupPassword1">
                                    {/* <Form.Label>Phone Number<span style={{ color: '#FA483C' }}>*</span></Form.Label> */}
                                    <PhoneInput
                                        value={mobile}
                                        country={"in"}
                                        enableSearch={true}
                                        onChange={handlePhoneChange}
                                        placeholder="Phone Number"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter your phone number
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </Col>
                        <Col lg="12">
                            <div className="custom-input mb-20">
                                <Form.Group controlId="validationCustom03">
                                    <Form.Control required type="email" placeholder="Email" value={email} onChange={handleChange} isInvalid={!isValid} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid email address.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </Col>
                        <Col lg="12">
                            <div className="custom-input mb-20">
                                <Form.Group controlId="validationCustom04">
                                    <Form.Control required type="text" as="textarea" placeholder="Your Message" value={comment} onChange={(e) => setComment(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Message.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="custom-input mb-20">
                                <Form.Group controlId="validationCustom05">
                                    <Form.Control required type="text" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter City.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="custom-select  mb-20">
                                <Select
                                    placeholder="Select position"
                                    required
                                    value={selectedOption1}
                                    onChange={(selectedOption) => {
                                        setPosition(selectedOption.value);
                                        setSelectedOption1(selectedOption);
                                        setSelectError(false); // Clear select error when a value is selected
                                    }}
                                    options={options2}
                                    className={selectError ? 'is-invalid' : ''}
                                    isInvalid={!selectedOption1 && selectError}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please select a position.
                                </Form.Control.Feedback>
                            </div>
                        </Col>
                        <Col lg="12">
                            <div className="Apply-job-form">
                                <div className="custom-input mb-20">
                                    <Form.Group controlId="validationCustom05">
                                        <div className="custom-file">
                                            <Form.Control
                                                required
                                                type="file"
                                                accept=".doc, .docx, .ppt, .pptx, .pdf, .txt, .zip"
                                                onChange={handleFileChange}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please Enter valid file.
                                            </Form.Control.Feedback>
                                            {selectedFile && selectedFile.size > 10 * 1024 * 1024 ? (
                                                <div className="error-message" style={{ color: '#dc3545', fontSize: '.875em' }}>
                                                    File size exceeds the limit of 10MB.
                                                </div>
                                            ) : (
                                                selectedFile && (
                                                    <p className="lg-text-semibold">{selectedFile.name}</p>
                                                )
                                            )}
                                            <p className="doc-text">
                                                Attach any files you feel would be useful <br />
                                                Attach Files (Xls, Pdf Only)
                                            </p>

                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                        </Col>
                        <Col lg="12">
                            <div className="submit_cancel_btn d-flex justify-content-end mt-20">
                                <div className="next-btn text-center">
                                    <Button onClick={props.onHide} className="btn-wrapper btn-wrapper-blue" aria-label='cancel'>
                                        Cancel
                                    </Button>
                                </div>
                                <div className="next-btn text-center">

                                    <Button type="submit" className="btn-wrapper btn-wrapper-blue" disabled={loading} aria-label='submit'>
                                        {loading ? <span> loading <i className="fa fa-spinner fa-spin"></i> </span> : 'Submit'}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="sucess-modal">
                                <Modal className='sucess-modal-main' centered show={showSuccessModal} onHide={handleCloseModal}>

                                    <Modal.Body className='sucess-modal-info'>
                                        <div className='sucessfully-content text-center'>

                                            <div className='sucess-img'>
                                                <img className="img-fluid " src={require('../assets/images/sucess.gif')} alt="Attach File" />
                                            </div>
                                            <p className='pt-10'>Form submitted successfully!</p>
                                        </div>
                                        <div className='sucess-close text-center pt-20'>
                                            <Button className="btn-wrapper btn-wrapper-blue" onClick={handleCloseModal} aria-label='ok'>
                                                Ok
                                            </Button>
                                        </div>

                                    </Modal.Body>
                                </Modal>

                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

function Applyjob(props) {
    const [modalShow, setModalShow] = useState(false);

    return (
        <>
            <div className="" variant="primary" onClick={() => setModalShow(true)}>
                <span className="circle" aria-hidden="true">
                    <span className="icon arrow">
                        <img src={props.image} className="img-fluid" alt="img" />
                    </span>
                </span>
                <span className="button-text">Apply Now</span>
            </div>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                title={props.title}
                image={props.image}
            />
        </>
    );
}

export default Applyjob;



