import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import HiringDedication from '../../components/HiringDedication';
import PricingHire from '../../components/PricingHire';
import HireDedicationContect from '../../components/HireDedicationContect';
import { Helmet } from 'react-helmet-async';
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

export default function HireReactJSDevelopers() {
  const scrollToSection = () => {
    // Scroll to the section containing HireDedicationContect
    const section = document.getElementById('hireDedicationSection');
    if (section) {
      const topOffset = section.offsetTop - 200;
      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
  };
  const CustomArrow = ({ direction, onClick }) => {
    const icon = direction === 'next' ? faChevronRight : faChevronLeft;

    return (
      <div
        className={`custom-arrow ${direction}`}
        style={{ zIndex: '1', display: 'block' }}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  };

  const hireresource = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <Helmet>
        <title>	Premier React Development Company | Sensussoft</title>
        <meta name="title" content="Premier React Development Company | Sensussoft" />
        <meta name="keywords" content="Premier React Development Company, React Development Services, React Development Solutions, React Company, Sensussoft React Experts" />
        <meta name="description" content="Explore top-notch React development services for retail, healthcare & finance sectors. Custom solutions, component development, maintenance & support."></meta>
      </Helmet>
      <main>
        <div className='hiring_dedication_section'>
          <section>
            <div className="mobile-app-development website-development ptb-80 bg-logo">
              <Container >
                <Row>
                  <Col lg={6} className="align-self-center">
                    <div className="website-development-text proof-text-main">
                      <div className="ss-heading proof-text">
                        <h1><span>React Development </span> <br />
                          Company </h1>
                        <p className="text-regular mt-20">
                          At Sensussoft, our expertise in React development services is unmatched, catering to diverse industries like retail, healthcare, and finance. Our commitment to using React ensures your product excels in a competitive digital landscape.
                        </p>
                      </div>
                      {/* <Link to='/get-in-touch-with-us'> */}
                      <button className="learn-more btn-large mt-25" onClick={scrollToSection} aria-label='contact us for detail'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                        <span className="button-text">CONTACT US FOR DETAILS</span>
                      </button>
                      {/* </Link> */}
                    </div>
                  </Col>
                  <Col lg={6} className="align-self-center text-center">
                    <div className="change_vision_img"><img className="img-fluid" src={require('../../assets/images/hire-devloper/reactjs_img1.png')} alt='service1' /></div>

                    <div className="website-development-img-main">

                      <div className="website-development-img mt-sm-20 mt-md-20">
                        <img className="img-fluid" src={require('../../assets/images/hire-devloper/reactjs_img1.png')} alt="mobile-app-development"></img>
                      </div>


                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <div className="idea-and-enhance bg-service ">
              <Container>
                <Row>
                  <Col lg={6}>
                    <div className="ideation-services">
                      <div className="services-in-action-main mb-md-20">
                        <div className="services-in-action pt-100 pb-200">
                          <div className="our-vision-text-main d-flex">
                            <div className="bd-highlight vision-img-main">
                              <div className="our-vision-img overview-img">
                                <img className="img-fluid" src={require('../../assets/images/overview.svg').default} alt="vision-ic"></img>
                              </div>
                            </div>
                            <div className="our-vision-text">
                              <p>
                                React Development Services
                              </p>
                            </div>
                          </div>
                          <div className="ss-heading ss-portfolio-text">
                            <h2 className="ptb-15">
                              {/* See <span>Product  <br /> Ideation Services  </span> <br />in action. */}
                              Leveraging React's potential, we craft innovative and secure web, translating your vision into effective technical solutions.
                            </h2>

                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={12} className='align-self-center'>
                    <div className="ideation-content ptb-100">
                      <div className="enhance-content-main " >
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Custom React Web Development</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Personalized, high-performance solutions ensuring your business stands out.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">React Component Development</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Building reusable and efficient React components for a seamless UI experience.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Maintenance & Support</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Continuous, reliable support ensuring your React applications remain cutting-edge.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">API Integration and Development</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Streamlining your business processes with bespoke React API integration services.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">React Migration and Upgradation</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Seamlessly transitioning to React for enhanced UI/UX and performance.
                          </p>
                        </div>
                      </div>

                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <div className="tecnology_plus_slider tecnology_plus_slider_nav testimonials ptb-80 mtb-80">
              <div className="container">
                <div className="col-lg-12">
                  <div className="testimonials-section">
                    <div className="ss-heading">
                      <h2>Most-Preferred<br /><span>React</span> Combinations We Cater</h2>
                    </div>
                    <div className='pt-10'><p className="light-heading">
                      Providing a spectrum of React combinations, tailored to elevate your business technology stack.
                    </p></div>
                    <div className="testimonials-box-main pt-35" >
                      <Slider {...hireresource} className='ss-testimonial'>
                        <div className='tecnology_hirededicate'>

                          <h3>React  + Node.js</h3>
                          <p>Combine React frontend with Node.js backend for high-performance, real-time web applications.</p>
                        </div>
                        <div className='tecnology_hirededicate'>


                          <h3>React + Django</h3>
                          <p>Leverage React's dynamic UI with Django's robust backend for scalable and feature-rich web development.</p>
                        </div>


                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <Container>
              <Row>
                <Col lg={12} md={12} className='align-self-center'>
                  <div className="ideation-content pb-80">
                    <div className="enhance-content-main ">
                      <div className="enhance-content">
                        <h3 className="text-regular text-center">
                          <span className="lg-text-semibold">Why Choose React Development Service From Sensussoft</span>
                        </h3>
                        <p className="pt-20 light-heading text-center">
                          Empowering businesses with high-performing, scalable React solutions that make a mark in the digital realm.
                        </p>
                        <div className="sotfware-info pt-40">
                          <Row>
                            <Col lg={6}>
                              <div className="application-title">
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Innovative Development Techniques
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Adaptable Engagement Models
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Customer-Centric Approach
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      End-to-End Solutions: From Concept to Deployment
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="application-title">
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Enhanced Performance Assurance
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Strict Adherence to Security Standards
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Dedicated Post-Launch Support
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Tailored, Market-Ready Solutions
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section>
            <div className="solve-the-unsolved">
              <Container>
                <Row>
                  <Col lg={6} className='align-self-center'>
                    <div className="solve-img text-center">
                      <img src={require('../../assets/images/hire-devloper/reactjs_img2.png')} className="img-fluid" alt='solve' />
                    </div>
                  </Col>
                  <Col lg={6} className='align-self-center'>
                    <div className="ss-heading ss-portfolio-text">
                      <h2>
                        Reasons to Opt for Our <br /><span> Dedicated Resources</span>
                      </h2>
                      <ul className='dedicated_resources'>
                        <li>Diverse talent pool with extensive React expertise.</li>
                        <li>Flexible hiring models catering to your project needs.</li>
                        <li>State-of-the-art infrastructure and modern toolsets.</li>
                        <li>Commitment to confidentiality and data security.</li>
                        <li>Punctual delivery adhering to predetermined timelines.</li>
                        <li>Transparent pricing with no hidden costs.</li>
                        <li>Open communication for seamless collaboration.</li>
                        <li>Regular project updates for complete visibility.</li>
                        <li>Round-the-clock technical assistance and maintenance.</li>
                        <li>Satisfaction guarantee with a money-back policy.</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <PricingHire tecnology="ReactJs Developers" click={scrollToSection} />
          </section>
          <section>
            <div className='hiring_process_blog pt-80 pb-80' style={{ backgroundColor: '#F4F5F9' }}>
              <Container>
                <Row>
                  <Col lg="12">
                    <div className='hiring_heading'>
                      <div className="nurture-creativity-title pb-20">
                        <div className="our-vision-text-main d-flex">
                          <div className="bd-highlight vision-img-main">
                            <div className="our-vision-img">
                              <img className="img-fluid" src={require('../../assets/images/vision-ic.svg').default} alt="vision-ic"></img>
                            </div>
                          </div>
                          <div className="our-vision-text">
                            <p>
                              Our Procedure
                            </p>
                          </div>
                        </div>
                        <div className="our-vision-heading">
                          <h2 className="lg-text-light ptb-15">
                            Discover Your Future
                            <span> Join Us Today!</span>
                          </h2>
                          <p style={{ fontWeight: '300' }}>We have simple and smart procedures to get you the best team that gives the best result. To know more about how we build & maintain an exclusive offshore team for you, get in touch with us now.</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <slider of hiring process start  */}
                  <HiringDedication />
                  {/* <slider of hiring process end */}
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <div className="ss-faq-main mb-80">
              <div className="faq-main pt-80 pb-80">
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className="faq-title proof-text-main text-center mb-30">
                        <div className="ss-heading proof-text">
                          <h1><span>Frequently Asked<br />
                            Questions </span></h1>
                        </div>
                      </div>

                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      <div className="faq-accordion">
                        <Accordion defaultActiveKey="0" flush>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Why Opt for React in Web Development?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  React’s dynamic and component-based architecture is ideal for responsive web solutions.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Cost of React Web Application Development? </Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Costs vary; we offer personalized estimates upon request.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>Timeframe for React Web Development?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Project timelines are tailored to scope but optimized for efficiency.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>How to Monitor React Project Progress?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  We ensure transparency with regular updates and the use of project management tools.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>Post-Development Support in React? </Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Yes, we provide extensive post-launch support and maintenance.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="5">
                            <Accordion.Header>Available Pricing Models?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Our flexible pricing includes fixed, hourly, or project-based options.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>

            </div>
          </section>
          <section id="hireDedicationSection">
            <HireDedicationContect />
          </section>
        </div>
      </main>
    </>
  )
}
