import { Col, Container, Row, Stack } from 'react-bootstrap'
import Portfolio from '../../components/Portfolio';
import Faq from '../../components/Faq';
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import ScheduleCall from '../../components/ScheduleCall';
import { Helmet } from 'react-helmet-async';
export default function MobileAppDevelopment() {

  return (
    <>
      <Helmet>
        <title>Mobile App Development: Transforming User Experiences Everywhere</title>
        <meta name="title" content="Mobile App Development: Transforming User Experiences Everywhere" />
        <meta name="keywords" content="Mobile App Development, User Experience Transformation, App Development Solutions, Sensussoft Mobile Solutions" />
        <meta name="description" content="Elevate your mobile experience with Sensussoft. Expert in native, cross-platform, and Unity game development. Industry-specific solutions for social, e-commerce, entertainment & more." />
      </Helmet>
      <main>
        <div className="mobile-app-development-main">
          {/* section 1 */}
          <div className="mobile-app-development website-development ptb-80 bg-logo">
            <Container >
              <Row className='pb-80 justify-content-end'>
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className=" service-btn-mobile  next-prev-btn comm-btn ">
                    <Link aria-label='productideation' to='/product-ideation-services'>
                      <button className="service-previous-btn learn-more arrow-btn " aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className="service-btn-mobile next-prev-btn comm-btn text-end ">
                    <Link aria-label='mobileappdevelopment' to='/web-application-development'>
                      <button className="learn-more arrow-btn" aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="align-self-center">
                  <div className="website-development-text proof-text-main">
                    <div className="ss-heading proof-text">
                      <h2><span>Mobile App </span> <br />
                        Development </h2>
                      <p className="text-regular mt-20">
                        Delivering compelling, on-the-go mobile experiences across a variety of platforms. We have dedicated teams for native iOS, native Android, Cross-Platform, and Flutter development to support our partners' mobile app initiatives. We are a 360° Software Development Partner providing custom mobile app development services, so you can blow your users' minds.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="align-self-center text-center">
                  <div className="change_vision_img"><img className="img-fluid" src={require('../../assets/images/serviceimg3.png')} alt='service1' /></div>

                  <div className="website-development-img-main">

                    <div className="website-development-img mt-sm-20 mt-md-20">
                      <img className="img-fluid" src={require('../../assets/images/mobile-app-development.jpg')} alt="mobile-app-development"></img>
                    </div>
                    <div className="web-build-box">
                      <div className="like-round">
                        <img className="img-fluid " src={require('../../assets/images/blub.svg').default} alt="blub">
                        </img>
                      </div>
                      <div className="build-text">
                        <h2>
                          100+
                        </h2>
                        <p className="text-regular">
                          Mobile App<br />
                          Development

                        </p>
                      </div>
                    </div>
                    <div className="web-build-box2">
                      <p>
                        Transforming businesses through <br /> mobile app magic.
                      </p>
                      <div className="line">
                        <img className="img-fluid" src={require('../../assets/images/linefill.png')} alt="line"></img><br />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* section 2 */}
          <div className="game-development-section bg-service">
            <Container>
              <Row>
                <Col lg={6}>
                  <div className="ideation-services">
                    <div className="services-in-action-main  mb-md-20">
                      <div className="services-in-action ptb-100">
                        <div className="our-vision-text-main d-flex">
                          <div className="bd-highlight vision-img-main">
                            <div className="our-vision-img overview-img">
                              <img className="img-fluid" src={require('../../assets/images/overview.svg').default} alt="vision-ic"></img>
                            </div>
                          </div>
                          <div className="our-vision-text">
                            <p>
                              Service Overview
                            </p>
                          </div>
                        </div>
                        <div className="ss-heading ss-portfolio-text">
                          <h2 className="ptb-15">
                            See   <span>Mobile App  & <br /> Game Development  </span> <br /> service in action.
                          </h2>
                          <div className="comm-btn ">
                            <Link to='/get-in-touch-with-us' aria-label='contact us for detail'>
                              <button className="learn-more btn-large arrow-btn-secondary2" aria-label='contact us for detail'>
                                <span className="circle" aria-hidden="true">
                                  <span className="icon arrow">
                                    <img src={require('../../assets/images/btn-arrow3.svg').default} className="img-fluid" alt='img' />
                                  </span>
                                </span>
                                <span className="button-text">CONTACT US FOR DETAILS</span>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={12}>
                  <div className="ideation-content ptb-100">
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Native App Development For Performant Apps.</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          A native application is a software application developed specifically for a particular platform or device. The technologies behind native apps are specific to either Android or iOS.
                        </p>
                        <h4 className="ptb-20">
                          What Are The Benefits Of Choosing It?
                        </h4>
                        <div className="cms-needed ">
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Richer performance is delivered through simpler code.
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                Offline environments are better suited for optimal performance.
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                High usability of API.                          </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Great UI/UX - custom user interface components improve the user experience.
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                Bugs are easier to avoid & full responsive.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="technology-box d-flex mt-50 d-mobile-block">
                          <div className="technology-text align-self-center ">
                            <p className="light-heading" style={{ color: '#a8a8a8' }}>Technology</p>
                          </div>
                          <div className="technology-border"></div>
                          <div className="ss-tecchnologies d-flex">
                            <div className="bd-highlight">
                              <div className="technology-img">
                                <img src={require('../../assets/images/technology-ic1.svg').default} className="img-fluid" alt='itechnology-ic1' />
                              </div>
                            </div>
                            <div className="bd-highlight">
                              <div className="technology-img">
                                <img src={require('../../assets/images/technology-ic2.svg').default} className="img-fluid" alt='itechnology-ic2' />
                              </div>
                            </div>
                            <div className="bd-highlight">
                              <div className="technology-img">
                                <img src={require('../../assets/images/technology-ic3.svg').default} className="img-fluid" alt='itechnology-ic3' />
                              </div>
                            </div>
                            <div className="bd-highlight">
                              <div className="technology-img">
                                <img src={require('../../assets/images/technology-ic4.svg').default} className="img-fluid" alt='itechnology-ic4' />
                              </div>
                            </div>
                            <div className="bd-highlight">
                              <div className="technology-img">
                                <img src={require('../../assets/images/technology-ic5.svg').default} className="img-fluid" alt='itechnology-ic5' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Cross-Platform App Development.</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Cross-platform apps share the same codebase as hybrid apps. Instead of developing separate applications for each operating system, cross-platform mobile development creates a single application that uses multiple platforms including Android, iOS, Microsoft, etc.                  </p>
                        <h4 className="ptb-20">
                          What Are The Benefits Of Choosing It?
                        </h4>
                        <div className="cms-needed">
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Developing cross-platform apps are more efficient and cost-effective systems and apps.
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                It is easy to make changes and add features.                          </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                UI performance matches native apps as it shares a similar codebase.
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                If you are unsure of which groups to target, launch your app for both audiences.
                              </p>
                            </div>
                          </div>

                        </div>
                        <div className="technology-box d-flex mt-50 d-mobile-block">
                          <div className="technology-text align-self-center ">
                            <p className="light-heading" style={{ color: '#a8a8a8' }}>Technology</p>
                          </div>
                          <div className="technology-border"></div>
                          <div className="ss-tecchnologies d-flex">
                            <div className="bd-highlight">
                              <div className="technology-img">
                                <img src={require('../../assets/images/technology-ic6.svg').default} className="img-fluid" alt='itechnology-ic1' />
                              </div>
                            </div>
                            <div className="bd-highlight">
                              <div className="technology-img">
                                <img src={require('../../assets/images/technology-ic7.svg').default} className="img-fluid" alt='itechnology-ic2' />
                              </div>
                            </div>
                            <div className="bd-highlight">
                              <div className="technology-img">
                                <img src={require('../../assets/images/technology-ic8.svg').default} className="img-fluid" alt='itechnology-ic3' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="enhance-content-main ">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Unity Game development 2D</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Unity is a powerful game development engine that allows developers to create 2D games with ease. It provides a wide range of tools, features, and resources to facilitate the entire game development process.
                        </p>
                        <h4 className="ptb-20">
                          What Are The Benefits Of Choosing It?
                        </h4>
                        <div className="cms-needed">
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Powerful physics engine: Simulate realistic 2D physics interactions, adding depth and realism to gameplay.                          </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                Scripting capabilities: Use C# scripting to build complex gameplay mechanics, UI, and logic.                          </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                Collaboration features: Facilitate teamwork and simultaneous development with collaborative tools.                          </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Cross-platform compatibility: Develop games for multiple platforms (mobile, desktop, consoles) using a single codebase.                          </p>
                            </div>
                          </div>

                        </div>
                        <div className="technology-box d-flex mt-50 d-mobile-block">
                          <div className="technology-text align-self-center ">
                            <p className="light-heading" style={{ color: '#a8a8a8' }}>Technology</p>
                          </div>
                          <div className="technology-border"></div>
                          <div className="ss-tecchnologies d-flex">
                            <div className="bd-highlight">
                              <div className="technology-img">
                                <img src={require('../../assets/images/technology-ic1.svg').default} className="img-fluid" alt='itechnology-ic1' />
                              </div>
                            </div>
                            <div className="bd-highlight">
                              <div className="technology-img">
                                <img src={require('../../assets/images/technology-ic2.svg').default} className="img-fluid" alt='itechnology-ic2' />
                              </div>
                            </div>
                            <div className="bd-highlight">
                              <div className="technology-img">
                                <img src={require('../../assets/images/technology-ic3.svg').default} className="img-fluid" alt='itechnology-ic3' />
                              </div>
                            </div>
                            <div className="bd-highlight">
                              <div className="technology-img">
                                <img src={require('../../assets/images/technology-ic4.svg').default} className="img-fluid" alt='itechnology-ic4' />
                              </div>
                            </div>
                            <div className="bd-highlight">
                              <div className="technology-img">
                                <img src={require('../../assets/images/technology-ic5.svg').default} className="img-fluid" alt='itechnology-ic5' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 2 end*/}
          {/* section 3 */}
          <div className="services-portfolio-main ptb-80">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      <span>Our Portfolio</span>
                    </h2>

                  </div>
                </Col>
              </Row>
            </Container>
            <Portfolio></Portfolio>
          </div>
          {/* section 3 end */}
          {/* section 4 */}
          <div className="our-industry ptb-100 bg-multi ptb-100">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      Our Industry <span> Specific Experience </span>
                    </h2>
                    <p className="pt-10 text-regular">
                      Sensussoft has been dedicated to providing mobile app and website solutions with industry specific experience. Whether it is lifestyle, health, fitness, booking, or e-commerce, Sensussoft has worked on various projects tailored to individual businesses' needs.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="pt-50 justify-content-center ">
                <Col lg={12}>
                  <div className="experience-box-main d-flex flex-wrap justify-content-center">
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic1.svg').default} className="img-fluid" alt='experience-ic1' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Social Media
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic2.svg').default} className="img-fluid" alt='experience-ic2' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          E-commerce
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic3.svg').default} className="img-fluid" alt='experience-ic3' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Entertainment
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic4.svg').default} className="img-fluid" alt='experience-ic4' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Healthcare
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic5.svg').default} className="img-fluid" alt='experience-ic5' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Travel
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic6.svg').default} className="img-fluid" alt='experience-ic6' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Fintech
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic7.svg').default} className="img-fluid" alt='experience-ic7' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Education
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>

              </Row>
            </Container>
          </div>
          {/* section 4 end*/}
          {/* section  5*/}
          <div className="attractive-main ptb-120">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="attractive-box">
                    <Stack direction="horizontal" className="d-mobile-block">
                      <div className="attractive-text flex-grow-1">
                        <h3 className="ss-title">
                          <span className="text-regular">Wish To Build Your</span> Dream App But <br /> <span className="text-regular">Confused About The Platforms?</span>
                        </h3>
                      </div>
                      <div className="attractive-btn">
                        <ScheduleCall></ScheduleCall>
                      </div>
                    </Stack>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 5 end */}
          {/* section 6 */}
          <div className="process-main ptb-80">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      Our Process for <span>Mobile App Development</span>
                    </h2>
                    <p className="pt-10 text-regular">
                      We will be there for you every step of the way, from vision to version 1.


                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="pt-50">
                <Col lg={12}>
                  <div className="ss-process-tab">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                      <Row className="justify-content-between">
                        <Col lg={4} md={12}>
                          <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                              <Nav.Link eventKey="first" aria-label='Requirement gathering and analyzing'>1. Requirement gathering and analyzing <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="second" aria-label='Wireframing and planning'>2. Wireframing and planning  <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="third" aria-label='Graphic and UI designing'>3. Graphic and UI designing <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="fourth" aria-label='Development and optimization'>4. Development and optimization <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="fifth" aria-label='Agile quality assurance'>5. Agile quality assurance <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="sixth" aria-label='Deployment & support'>6. Deployment & support <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col lg={7} md={12}>
                          <Tab.Content className="pt-50">
                            <Tab.Pane eventKey="first">
                              <div className="ss-process-content">
                                <div className="core-value-img">
                                  <img className="img-fluid" src={require('../../assets/images/process-ic1.svg').default} alt="process-ic1" />
                                </div>
                                <p className="light-heading pt-20">
                                  We're committed to helping you turn your great vision into reality. We gather requirements, discuss the idea, brainstorm upon it and clarify all doubts regarding the project in advance.
                                </p>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <div className="ss-process-content">
                                <div className="core-value-img">
                                  <img className="img-fluid" src={require('../../assets/images/process-ic2.svg').default} alt="process-ic2" />
                                </div>
                                <p className="light-heading pt-20">
                                  In this phase, we help you turn your vision into a detailed product roadmap, and then we work with you to bring that roadmap to life. These processes involve wireframes, prototypes, and feasibility analyses.              </p>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                              <div className="ss-process-content">
                                <div className="core-value-img">
                                  <img className="img-fluid" src={require('../../assets/images/process-ic3.svg').default} alt="process-ic2" />
                                </div>
                                <p className="light-heading pt-20">
                                  In the next step of the design process, visual components and plans, such as design strategies, PSDs, and screens, are crafted.         </p>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                              <div className="ss-process-content">
                                <div className="core-value-img">
                                  <img className="img-fluid" src={require('../../assets/images/process-ic4.svg').default} alt="process-ic2" />
                                </div>
                                <p className="light-heading pt-20">
                                  Here, we prioritized the development of modules and user flows. Our developers create functional elements, algorithms, and other backend modules.
                                </p>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                              <div className="ss-process-content">
                                <div className="core-value-img">
                                  <img className="img-fluid" src={require('../../assets/images/process-ic5.svg').default} alt="process-ic5" />
                                </div>
                                <p className="light-heading pt-20">
                                  Our team puts a lot of effort into every detail to ensure users are satisfied, including testing each layer before doing end-to-end testing by performing integration, performance, and stress testing.
                                </p>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="sixth">
                              <div className="ss-process-content">
                                <div className="core-value-img">
                                  <img className="img-fluid" src={require('../../assets/images/process-ic6.svg').default} alt="process-ic6" />
                                </div>
                                <p className="light-heading pt-20">
                                  We follow the store guidelines and release apps on the appropriate app stores. Additionally, we offer 360° IT Support including monitoring, bug fixing, consulting, ideation, and feature enhancement.
                                </p>
                              </div>
                            </Tab.Pane>

                          </Tab.Content>

                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 6  end*/}

          {/* section 7 */}
          <div className="ss-faq-main">
            <div className="faq-main pt-120   ">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="faq-title proof-text-main text-center mb-30">
                      <div className="ss-heading proof-text">
                        <h1><span>Frequently Asked<br />
                          Questions </span></h1>
                      </div>
                    </div>

                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <div className="faq-accordion">
                      <Accordion defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>Why is it important to develop mobile apps?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                We believe a well-designed and developed mobile app can help reinforce your brand value, increase visibility through a new channel, connect with users on the go, cater to a larger audience, and beyond.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>What does your mobile application development services include?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                We provide mobile app development services that include strategy consultation, concept documentation, project management, UI/UX design, iOS and Android platform development, backend development, testing, publishing on stores, and maintenance.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>What is the cost of developing a mobile app?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                The cost of mobile app development depends on the concept of your application. Several factors affect the cost, such as the number of features, complexity of the project, number of hours of development, and strength of the development team etc.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>If my app does not run on the latest version of the iPhone, do I need to redevelop it?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                No, we are your 360° software development partners. You can count on us, our team to make your current app compatible with the latest version of the iPhone.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>What is the process for keeping me informed during the development stages?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                During the workday, you communicate with the project team via Slack. You will be added to all project boards by our project manager. You are welcome to attend our daily meetings, refinements, planning, and demo meetings where you will receive updates on the project. JIRA is currently our project management tool. If there is anything you would like to discuss, you can also request a meeting with the assigned project manager at any time.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Col>
                </Row>



              </Container>
            </div>
            <Faq></Faq>
          </div>
          {/* section 7 end*/}



        </div>
      </main>
    </>
  )
}
