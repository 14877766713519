import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import HiringDedication from '../../components/HiringDedication';
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import PricingHire from '../../components/PricingHire';
import HireDedicationContect from '../../components/HireDedicationContect';
import { Helmet } from 'react-helmet-async';

export default function HireNextJSDevelopers() {
  const scrollToSection = () => {
    // Scroll to the section containing HireDedicationContect
    const section = document.getElementById('hireDedicationSection');
    if (section) {
      const topOffset = section.offsetTop - 200;
      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
  };
  const CustomArrow = ({ direction, onClick }) => {
    const icon = direction === 'next' ? faChevronRight : faChevronLeft;

    return (
      <div
        className={`custom-arrow ${direction}`}
        style={{ zIndex: '1', display: 'block' }}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  };

  const hireresource = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <Helmet>
        <title>	Leading Next.js Development Company | Sensussoft</title>
        <meta name="title" content="Leading Next.js Development Company | Sensussoft" />
        <meta name="keywords" content="Next.js Development Company, Leading Next.js Company, Next.js Development Services, Next.js Development Solutions, Sensussoft Next.js Services" />
        <meta name="description" content="Explore specialized Next.js development services for industries like retail, media, and education. Custom solutions, SSR, API integration, and maintenance."></meta>
      </Helmet>
      <main>
        <div className='hiring_dedication_section'>
          <section>
            <div className="mobile-app-development website-development ptb-80 bg-logo">
              <Container >
                <Row>
                  <Col lg={6} className="align-self-center">
                    <div className="website-development-text proof-text-main">
                      <div className="ss-heading proof-text">
                        <h1><span>Next.js Development </span> <br />
                          Company </h1>
                        <p className="text-regular mt-20">
                          At Sensussoft, we specialize in Next.js, a leading framework for server-rendered React applications. Our dedicated Next.js development services cater to industries such as retail, media, and education, ensuring cutting-edge, SEO-friendly web applications.
                        </p>
                      </div>
                      {/* <Link to='/get-in-touch-with-us'> */}
                      <button className="learn-more btn-large mt-25" onClick={scrollToSection} aria-label='contact us for detail'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                        <span className="button-text">CONTACT US FOR DETAILS</span>
                      </button>
                      {/* </Link> */}
                    </div>
                  </Col>
                  <Col lg={6} className="align-self-center text-center">
                    <div className="change_vision_img"><img className="img-fluid" src={require('../../assets/images/hire-devloper/nextjs_img1.png')} alt='service1' /></div>

                    <div className="website-development-img-main">

                      <div className="website-development-img mt-sm-20 mt-md-20">
                        <img className="img-fluid" src={require('../../assets/images/hire-devloper/nextjs_img1.png')} alt="mobile-app-development"></img>
                      </div>


                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <div className="idea-and-enhance bg-service ">
              <Container>
                <Row>
                  <Col lg={6}>
                    <div className="ideation-services">
                      <div className="services-in-action-main mb-md-20">
                        <div className="services-in-action pt-100 pb-200">
                          <div className="our-vision-text-main d-flex">
                            <div className="bd-highlight vision-img-main">
                              <div className="our-vision-img overview-img">
                                <img className="img-fluid" src={require('../../assets/images/overview.svg').default} alt="vision-ic"></img>
                              </div>
                            </div>
                            <div className="our-vision-text">
                              <p>
                                Next.js Development Services
                              </p>
                            </div>
                          </div>
                          <div className="ss-heading ss-portfolio-text">
                            <h2 className="ptb-15">
                              {/* See <span>Product  <br /> Ideation Services  </span> <br />in action. */}
                              Our expertise in Next.js enables us to build fast, reliable, and modern web applications that meet your business's specific needs.
                            </h2>

                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={12} className='align-self-center'>
                    <div className="ideation-content ptb-100">
                      <div className="enhance-content-main " >
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Custom Next.js Development</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Tailor-made solutions that align with your business objectives.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Server-Side Rendering (SSR) Solutions</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Enhanced performance and SEO with Next.js SSR.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Next.js Migration Services</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Smooth transition to Next.js from other platforms.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">API Integration using Next.js</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Seamless integration of various services and APIs.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Maintenance & Support</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Ongoing support to ensure the optimal performance of your Next.js applications.
                          </p>
                        </div>
                      </div>

                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <div className="tecnology_plus_slider testimonials ptb-80 mtb-80">
              <div className="container">
                <div className="col-lg-12">
                  <div className="testimonials-section">
                    <div className="ss-heading">
                      <h2>Most-Preferred<br /><span>Next.js</span> Combinations We Cater</h2>
                    </div>
                    <div className='pt-10'><p className="light-heading">
                      Providing versatile Next.js combinations to suit diverse project requirements.
                    </p></div>
                    <div className="testimonials-box-main pt-35" >
                      <Slider {...hireresource} className='ss-testimonial'>
                        <div className='tecnology_hirededicate'>

                          <h3>Next.js + Headless CMS</h3>
                          <p>Integrate Next.js with a headless CMS for flexible content management and dynamic web experiences.</p>
                        </div>
                        <div className='tecnology_hirededicate'>

                          <h3>Next.js + eCommerce Platforms</h3>
                          <p>Implement Next.js with eCommerce platforms for fast and engaging online stores.</p>
                        </div>
                        <div className='tecnology_hirededicate'>

                          <h3>Next.js + API-first Development</h3>
                          <p>Embrace Next.js for API-first development to build powerful and interactive web applications.</p>
                        </div>
                        <div className='tecnology_hirededicate'>

                          <h3>Next.js + Headless CMS</h3>
                          <p>Streamlined content management and delivery.</p>
                        </div>

                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <Container>
              <Row>
                <Col lg={12} md={12} className='align-self-center'>
                  <div className="ideation-content pb-80">
                    <div className="enhance-content-main ">
                      <div className="enhance-content">
                        <h3 className="text-regular text-center">
                          <span className="lg-text-semibold">Why Choose Next.js Development Service From Sensussoft</span>
                        </h3>
                        <p className="pt-20 light-heading text-center">
                          We are committed to delivering Next.js solutions that are not just efficient but also future-proof.
                        </p>
                        <div className="sotfware-info pt-40">
                          <Row>
                            <Col lg={6}>
                              <div className="application-title">
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Advanced and Proactive Development Approach
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Flexible and Client-centric Engagement Models
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      User Experience Focused Design and Development
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Full Spectrum Services from Ideation to Deployment
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="application-title">
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Ensured Performance and Speed Optimization
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Strict Adherence to Security Best Practices
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Continuous Support and Maintenance After Deployment
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Quick to Market with Ready-to-Deploy Solutions
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section>
            <div className="solve-the-unsolved">
              <Container>
                <Row>
                  <Col lg={6} className='align-self-center'>
                    <div className="solve-img text-center">
                      <img src={require('../../assets/images/hire-devloper/nextjs_img2.png')} className="img-fluid" alt='solve' />
                    </div>
                  </Col>
                  <Col lg={6} className='align-self-center'>
                    <div className="ss-heading ss-portfolio-text">
                      <h2>
                        Reasons to Opt for Our <br /><span> Dedicated Resources</span>
                      </h2>
                      <ul className='dedicated_resources'>
                        <li>A pool of specialized Next.js developers with industry-specific expertise.</li>
                        <li>Tailored hiring models to fit your unique project requirements.</li>
                        <li>State-of-the-art infrastructure and access to modern development tools.</li>
                        <li>Strict confidentiality measures to safeguard your project’s privacy.</li>
                        <li>Punctual project delivery within agreed timeframes.</li>
                        <li>Transparent cost structure with no hidden charges.</li>
                        <li>Collaborative and communicative work environment.</li>
                        <li>Regular progress updates for full project transparency.</li>
                        <li>24/7 support and maintenance services post-launch.</li>
                        <li>Satisfaction guaranteed, with a money-back policy if not satisfied.</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <PricingHire tecnology="NextJs Developers" click={scrollToSection} />
          </section>
          <section>
            <div className='hiring_process_blog pt-80 pb-80' style={{ backgroundColor: '#F4F5F9' }}>
              <Container>
                <Row>
                  <Col lg="12">
                    <div className='hiring_heading'>
                      <div className="nurture-creativity-title pb-20">
                        <div className="our-vision-text-main d-flex">
                          <div className="bd-highlight vision-img-main">
                            <div className="our-vision-img">
                              <img className="img-fluid" src={require('../../assets/images/vision-ic.svg').default} alt="vision-ic"></img>
                            </div>
                          </div>
                          <div className="our-vision-text">
                            <p>
                              Our Procedure
                            </p>
                          </div>
                        </div>
                        <div className="our-vision-heading">
                          <h2 className="lg-text-light ptb-15">
                            Discover Your Future
                            <span> Join Us Today!</span>
                          </h2>
                          <p style={{ fontWeight: '300' }}>We have simple and smart procedures to get you the best team that gives the best result. To know more about how we build & maintain an exclusive offshore team for you, get in touch with us now.</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <slider of hiring process start  */}
                  <HiringDedication />
                  {/* <slider of hiring process end */}
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <div className="ss-faq-main mb-80">
              <div className="faq-main pt-80 pb-80">
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className="faq-title proof-text-main text-center mb-30">
                        <div className="ss-heading proof-text">
                          <h2><span>Frequently Asked<br />
                            Questions </span></h2>
                        </div>
                      </div>

                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      <div className="faq-accordion">
                        <Accordion defaultActiveKey="0" flush>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Why Choose Next.js for Web Development?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Next.js offers fast, SEO-friendly, and scalable solutions for modern web applications.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>What’s the Cost of Next.js Web Application Development?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Costs vary based on project complexity; contact us for a tailored quote.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>How Long Does It Take to Develop a Next.js Web Application?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Development time varies based on project scope, but we focus on timely, quality delivery.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>How Can I Track My Next.js Project’s Progress? </Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  We provide regular updates and employ project management tools for transparency.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>Do You Offer Post-Development Support in Next.js?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Yes, we offer comprehensive after-launch support and maintenance services.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="5">
                            <Accordion.Header>What Pricing Models Are Available for Next.js Development?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  We offer various pricing models, including fixed-price, time & material, and dedicated hiring.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>

            </div>
          </section>
          <section id="hireDedicationSection">
            <HireDedicationContect />
          </section>
        </div>
      </main>
    </>
  )
}
