import React from 'react'
// import Accordion from 'react-bootstrap/Accordion';
import MultiStep from '../components/MultiStep';
import { Col, Container, Row, Stack } from 'react-bootstrap'

export default function Faq() {
  return (
    <div>

      <div className="ss-from-step pb-80">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <div className="dream-project-box">
                <div className="dream-project-middle">
                  <Stack direction="horizontal" className="d-mobile-block">
                    <div className="dream-project-text ss-heading w-30 align-self-center">
                      <h2 className="text-regular">
                        Tell us bit <br />
                        about your <br />
                        <span>Dream Project!</span>
                      </h2>

                    </div>
                    <div className="dream-project-border"></div>
                    <div className="dream-project-form w-50 align-self-center ">
                      <div className="form-title">

                        <MultiStep></MultiStep>
                      </div>
                    </div>
                  </Stack>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}
