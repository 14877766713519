import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { Helmet } from 'react-helmet-async';
import { SlideshowLightbox } from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';
import Slider from 'react-slick';

function Newsletters() {
  // lightbox slider
  let [isOpen, setIsOpen] = useState(false);
  let [boxId, setBoxId] = useState();
  let [selectedImageIndex, setSelectedImageIndex] = useState(); // Add this line to store the selected image index


  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(new Date().getFullYear().toString());
  const [activeSettingsTab, setActiveSettingsTab] = useState("subtab1");
  const [years, setYears] = useState([]);
  const [newsletterData, setNewsletterData] = useState([]);

  // lightbox slider
  const openBox = (id, imageIndex) => {
    setIsOpen(true);
    setBoxId(id);
    setSelectedImageIndex(imageIndex); // Add this line to store the selected image index
  };

  const settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nav: true,
    responsive: [

      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],

  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentYear = new Date().getFullYear();
        const response = await axios.get(`https://api.sensussoft.com/api/newsletter?month=1&year=${currentYear}`);
        if (response.data && response.data.success) {
          const filteredData = response.data.data[0].newsletter; // Update this based on your API response structure
          setNewsletterData(filteredData);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching newsletters:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let yearToFetch = activeTab; // Fetch data for the currently active tab (year)
        if (!yearToFetch) {
          yearToFetch = new Date().getFullYear(); // If no active tab is set, fetch data for the current year
        }
        const response = await axios.get(`https://api.sensussoft.com/api/newsletter?month=1&year=${yearToFetch}`);
        if (response.data && response.data.success) {
          const filteredData = response.data.data[0].newsletter; // Update this based on your API response structure
          setNewsletterData(filteredData);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching newsletters:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [activeTab]); // Add activeTab as a dependency to rerun the effect when it changes

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://api.sensussoft.com/api/newsletter");
        if (response.data && response.data.success) {
          const data = response.data.data || [];
          setYears(data);
          // setNewsletterData(data.flatMap((item) => item.newsletter));

          const filteredData = response.data.data[0].newsletter; // Update this based on your API response structure
          setNewsletterData(filteredData);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching newsletters:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  if (loading) {
    return (
      <div className="blog-loader-main">
        <div className="blog-loader">
          <div className="loader-img">
            <Container>
              <Row className="justify-content-center ptb-100 text-center">
                <Col lg={12}>
                  <div className="loading-content">
                    <div
                      style={{
                        position: "relative",
                        width: "250px",
                        height: "150px",
                      }}
                    >
                      {/* SVG Markup */}
                      <svg width="100%" height="100%" viewBox="0 0 187.3 93.7" preserveAspectRatio="xMidYMid meet" className="svg-animation">
                        <path stroke="#001B50" id="outline" fill="none" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z" />
                        <path id="outline-bg" opacity="0.05" fill="none" stroke="#001B50" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z" />
                      </svg>
                      {/* Custom text or content below the SVG */}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
  const handleTabClick = async (year) => {
    setActiveTab(year);
    setActiveSettingsTab(`subtab1`);
  };


  const handleMonthClick = async (month) => {
    const subtab = `subtab${month}`;
    setActiveSettingsTab(subtab);

    try {
      const response = await axios.get(`https://api.sensussoft.com/api/newsletter?month=${month}&year=${activeTab}`);
      if (response.data && response.data.success) {
        const filteredData = response.data.data[0].newsletter; // Update this based on your API response structure
        setNewsletterData(filteredData);
        const newsletterSection = document.getElementById("newsletterSection");
        if (newsletterSection) {
          newsletterSection.scrollIntoView({ top: 0, behavior: 'smooth' });
        }
      }

    } catch (error) {
      console.error("Error fetching filtered newsletters:", error);
    }
  };


  return (
    <>
      <Helmet>
        <title>Software Insights Newsletter | Stay Updated with Latest Trends & Innovations</title>
        <meta name="title" content="Software Insights Newsletter | Stay Updated with Latest Trends & Innovations" />
        <meta name="keywords" content="Software Insights Newsletter, Latest Software Trends, Software Innovations, Technology Newsletter, Tech Updates, Software News, Tech Insights, Innovation Updates, Software Development News, Tech Trends" />
        <meta name="description" content="Explore our newsletter for expert insights on software development, tech trends, and digital solutions." />
      </Helmet>
      <main>
        <div className="newsletter_our_website " id="newsletterSection" >
          <div className="Product-ideation-section  website-development ptb-80 ">
            <Container>
              <Row>
                <Col lg={10} className="align-self-center">
                  <div className="website-development-text proof-text-main">
                    <div className="ss-heading proof-text">
                      <h1>
                        <span>Newsletter</span>{" "}
                      </h1>
                      <p className="text-regular mt-15">
                        Unlocking the Power of Software: Explore our insightful
                        blog for the latest trends, industry insights, and expert
                        perspectives on software development, technology
                        innovations, and digital solutions.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="newsletter_main_tabpanal ptb-100">
            <Container>
              <Row>
                <Col lg={3} md="12">
                  <div className="parent_slidepanal_mainblog">
                    <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                      {years.map((yearData) => (
                        <Nav.Item key={yearData.year}>
                          <Nav.Link
                            role="tab"
                            aria-selected={activeTab === yearData.year}

                            eventKey={yearData.year}
                            active={activeTab === yearData.year}
                            onClick={() => handleTabClick(yearData.year)}
                            className={`parent_active_tab${activeTab === yearData.year ? " dropdown-active" : ""}`}

                          >
                            {yearData.year}
                            <img src={require("../assets/images/newsletter/down-arrow.svg").default} alt="Arrow" className="arrow-image" />
                          </Nav.Link>
                          {activeTab === yearData.year && (
                            <div className="chlid_tab_box">
                              {yearData.month.map((monthitem) => (
                                <Nav.Item key={monthitem.number}>
                                  <Nav.Link
                                    role="tab"
                                    aria-selected={activeSettingsTab === `subtab${monthitem.number}`}

                                    eventKey={`subtab${monthitem.number}`}
                                    active={activeSettingsTab === `subtab${monthitem.number}`}
                                    onClick={() => handleMonthClick(monthitem.number)}
                                  >
                                    {monthitem.month}
                                  </Nav.Link>
                                </Nav.Item>
                              ))}
                            </div>
                          )}
                        </Nav.Item>
                      ))}
                    </div>
                  </div>
                </Col>
                <Col lg={9} md="12">
                  {newsletterData.map((newsletter) => (
                    <div key={newsletter.id}>
                      <Row>
                        <Col>
                          {newsletter.joinee.length > 0 && (
                            <div className="designing-content mb-10">
                              <div className="our-vision-text-main d-flex">
                                <div className="bd-highlight vision-img-main">
                                  <div className="our-vision-img overview-img">
                                    <img className="img-fluid" src={require('../assets/images/overview.svg').default} alt="vision-ic"></img>
                                  </div>
                                </div>
                                <div className="our-vision-text">
                                  <h5>
                                    Team Transformations
                                  </h5>
                                </div>
                              </div>
                              <div className="ss-heading ss-portfolio-text">
                                <h2 className="pb-10">
                                  Meet Our <span> New Joinee</span>
                                </h2>
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                      {newsletter.joinee.length > 0 && (
                        <Row className="mb-50">
                          {newsletter.joinee.map((joinee) => (
                            <Col xl={3} lg={4} md={6} className="mb-20" key={joinee.id}>
                              <div className="joinee-box">
                                <div className="joinee-box-part1">
                                  <div className="joinee-img">
                                    <img className="img-fluid" src={joinee.image} alt="innovation-img" />
                                  </div>
                                  <div className="joinee-name pt-15">
                                    <h5>{joinee.name}</h5>
                                  </div>
                                </div>
                                <div className="joinee-line"></div>
                                <div className="joinee-box-part2">
                                  <div className="joinee-position">
                                    <h5 className="text-center">{joinee.role}</h5>
                                    <p className="text-center">Date of joining :<br /><span>{joinee.date}</span></p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          )
                          )}
                        </Row>
                      )}
                    </div>
                  ))}

                  {newsletterData.map((newsletter) => (
                    <div key={newsletter.id}>
                      {newsletter.newsletter_data.length > 0 && newsletter.newsletter_data.map((newsletter_data, data_index) => (
                        <div className={`company_goal_metting_wrapper pb-50 ${data_index % 2 !== 0 ? 'main_goal' : ''}`} key={newsletter_data.id}>
                          <Row className="justify-content-between">
                            <Col lg='6' md='6' className="align-self-center">
                              <div className="desciption_of_compnaygoal">
                                <h2 style={{ color: '#001B50', fontWeight: '400', marginBottom: '10px' }}>{newsletter_data.title}</h2>
                                <p className="light-heading" style={{ color: '#435063' }}>{newsletter_data.description}</p>
                              </div>
                            </Col>
                            <Col lg='5' md='6' className="align-self-center">
                              <Slider {...settings}>
                                {newsletter_data.image.map((image, imageIndex) => (
                                  <div key={image.id} className="newsletter_slider" onClick={() => openBox(newsletter_data.id, imageIndex)}>
                                    <img src={image.name} alt="" />
                                  </div>
                                ))}
                              </Slider>
                              <SlideshowLightbox
                                images={newsletter_data.image.map((image) => ({ src: image.name, caption: newsletter_data.title }))}
                                showThumbnails={true}
                                open={isOpen && boxId === newsletter_data.id}
                                lightboxIdentifier={`lbox${newsletter_data.id}`}
                                onClose={() => setIsOpen(false)}
                              />

                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  ))}


                </Col>

              </Row>
            </Container>
          </div>
        </div>
      </main>
    </>

  );



}

export default Newsletters;
