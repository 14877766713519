import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function CasestudySlider() {

  const [loading, setLoading] = useState(true);
  const [mainportfolioData, setportfolioData] = useState([]);

  useEffect(() => {
    // Fetch blog post details and comments using the API
    Axios.get(`https://api.sensussoft.com/api/casestudy?limit=5`)
      .then((response) => {
        const portfolioDatamain = response.data.data || [];
        setportfolioData(portfolioDatamain);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);
  const handleFocusManagement = () => {
    // Ensure aria-hidden slides are not focusable
    document.querySelectorAll('.slick-slide[aria-hidden="true"]').forEach(slide => {
      slide.setAttribute('tabindex', '-1');
      slide.querySelectorAll('a, button, input, select, textarea').forEach(elem => {
        elem.setAttribute('tabindex', '-1');
      });
    });
  };

  useEffect(() => {
    handleFocusManagement();
  }, [mainportfolioData]); // Reapply focus management when blogs change

  if (loading) {
    return (
      <div className='blog-loader-main'>
        <div className='blog-loader'>
          <div className='loader-img'>
            <Container>
              <Row className='justify-content-center ptb-100 text-center'>
                <Col lg={12}>
                  <div className='loading-content'>
                    <div style={{ position: 'relative', width: '250px', height: '150px' }}>
                      {/* SVG Markup */}
                      <svg width="100%" height="100%" viewBox="0 0 187.3 93.7" preserveAspectRatio="xMidYMid meet" className="svg-animation">
                        <path
                          stroke="#001B50"
                          id="outline"
                          fill="none"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                        />
                        <path
                          id="outline-bg"
                          opacity="0.05"
                          fill="none"
                          stroke="#001B50"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                        />
                      </svg>
                      {/* Custom text or content below the SVG */}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }

  const CustomArrow = ({ direction, onClick }) => {
    const icon = direction === 'next' ? faAngleRight : faAngleLeft;
    return (
      <div
        className={`custom-arrow ${direction}`}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    afterChange: handleFocusManagement,
    beforeChange: handleFocusManagement,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="our-case-study">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-lg-center">
              <div className="ss-heading">
                <h1>Our <span>Case study</span></h1>
              </div>
            </div>
          </div>
        </div>
        <div className="ss-case-study ">
          <div className="container p-0">
            <Row className="justify-content-center">
              <Col sm={12} className="pt-50">
                <Tab.Content>
                  <div className="portfolio-slider testimonials-box-main">
                    <Slider {...settings} className="ss-portfolio pb-80">
                      {mainportfolioData
                        .filter(portfolio => portfolio.category !== 'Logo Design')
                        .map((portfolio) => (
                          <div key={portfolio.id} >
                            <div className="home-casestudy-wrepper portfolio-box-main d-flex" style={{ backgroundColor: portfolio.backimg_color_code, flexDirection: 'column' }}>
                              <Link
                                aria-label='UI/UX portfolio'
                                to={portfolio.category !== 'UI/UX' ? `/casestudydetails/${portfolio.app_url}` : portfolio.url}
                                target={portfolio.category !== 'UI/UX' ? '' : '_blank'}
                                className={`d-flex flex-column flex-fill justify-content-end ${portfolio.category === 'UI/UX' ? 'ui-ux-category' : ''}`}
                              >
                                <div className="portfolio-img mt-40 mx-auto">
                                  <img className="img-fluid" src={portfolio.thumbnail} alt="portfolio-img" />
                                </div>
                              </Link>
                              <div className="d-flex bd-highlight position_absolute_header">
                                <div className="flex-grow-1 bd-highlight">
                                  <Link
                                    aria-label='UI/UX portfolio'
                                    to={portfolio.category !== 'UI/UX' ? `/casestudydetails/${portfolio.app_url}` : portfolio.url}
                                    target={portfolio.category !== 'UI/UX' ? '' : '_blank'}
                                  >
                                    <div className="portfolio-text">
                                      <h2>{portfolio.title}</h2>
                                      <h3>{portfolio.subtitle}</h3>
                                    </div>
                                  </Link>
                                </div>
                                <div className="bd-highlight">
                                  <div className='ss_link_casestudy_main d-flex justify-content-end' style={{ columnGap: '5px' }}>
                                    {portfolio.web_link && (
                                      <div className='web_link_icon1'>
                                        <Link aria-label='web url' to={portfolio.web_link} target='_blank' className='link-icon-1'>
                                          <img className='img-fluid' src={require('../assets/images/weblink.svg').default} alt='arrow' />
                                        </Link>
                                      </div>
                                    )}
                                    {portfolio.apple_link && (
                                      <div className='ios_link_icon1'>
                                        <Link aria-label='appstore' to={portfolio.apple_link} target='_blank' className='link-icon-1'>
                                          <img className='img-fluid' src={require('../assets/images/ioslink.svg').default} alt='arrow' />
                                        </Link>
                                      </div>
                                    )}
                                    {portfolio.google_link && (
                                      <div className='play_link_icon1'>
                                        <Link aria-label='google-link' to={portfolio.google_link} target='_blank' className='link-icon-1'>
                                          <img className='img-fluid' src={require('../assets/images/applink.svg').default} alt='arrow' />
                                        </Link>
                                      </div>
                                    )}
                                    {portfolio.url && (
                                      <div className='play_link_icon1'>
                                        <Link ria-label='behance-link' to={portfolio.url} target='_blank' className='link-icon-1'>
                                          <img className='img-fluid ms-0' src={require('../assets/images/behance-case.svg').default} alt='arrow' />
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </Slider>
                  </div>
                </Tab.Content>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
