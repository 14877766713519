import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import Plyr from 'plyr';
import { Helmet } from 'react-helmet-async';
import 'plyr/dist/plyr.css';
import axios from 'axios';
import Slider from 'react-slick';


export default function Testimonials() {

    // testimonials API calling
    // const [loading, setLoading] = useState(true);
    const [testimonial, settestimonials] = useState([]);
    const baseurl = "https://api.sensussoft.com/api/clienttestimonial";
    useEffect(() => {
        axios.get(baseurl)
            .then(response => {
                //   console.log('API Response:', response.data);
                //   console.log("Response", response.data);
                const testimonialData = response.data.data || [];
                settestimonials(testimonialData);
                // setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching blogs:', error);
                // setLoading(false);
            });
    }, []);


    const onTranslated = () => {
        const players = Array.from(document.querySelectorAll('.js-player'));
        players.forEach((instance) => {
            instance.pause();
        });
    };

    useEffect(() => {
        const playerSettings = {
            controls: ['play-large'],
            fullscreen: { enabled: false },
            resetOnEnd: true,
            hideControls: true,
            clickToPlay: true,
            keyboard: false,
        };

        const players = Array.from(document.querySelectorAll('.js-player')).map(
            (element) => new Plyr(element, playerSettings)
        );

        players.forEach((instance, index) => {
            instance.on('play', () => {
                players.forEach((instance1) => {
                    if (instance !== instance1) {
                        instance1.pause();
                    }
                });
            });
        });

        return () => {
            players.forEach((instance) => {
                instance.destroy();
            });
        };
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        afterChange: onTranslated,
    };

    //  if (loading) {
    //     return <div className='blog-loader-main'>
    //       <div className='blog-loader'>
    //         <div className='loader-img'>
    //           <Container>
    //             <Row className='justify-content-center ptb-100 text-center'>
    //               <Col lg={12}>
    //               <div className='loading-content'>
    //           <div style={{ position: 'relative', width: '250px', height: '150px' }}>
    //       {/* SVG Markup */}
    //       <svg width="100%" height="100%" viewBox="0 0 187.3 93.7" preserveAspectRatio="xMidYMid meet" className="svg-animation">
    //         <path
    //           stroke="#003ADC"
    //           id="outline"
    //           fill="none"
    //           strokeWidth="4"
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           strokeMiterlimit="10"
    //           d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
    //         />
    //         <path
    //           id="outline-bg"
    //           opacity="0.05"
    //           fill="none"
    //           stroke="#003ADC"
    //           strokeWidth="4"
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           strokeMiterlimit="10"
    //           d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
    //         />
    //       </svg>

    //       {/* Custom text or content below the SVG */}
    //     </div>
    //     </div>
    //               </Col>
    //             </Row>
    //           </Container></div>;
    //       </div>
    //     </div>
    //   }
    return (

        <div>
            <Helmet>
                <title>"Testimonials at Sensussoft: Insights and Feedback from Clients"</title>
                <meta name="title" content="Testimonials at Sensussoft: Insights and Feedback from Clients" />
                <meta name="keywords" content="Testimonials, Client Feedback, Customer Reviews, Client Testimonials, Sensussoft Testimonials, Client Insights, Customer Experience, Testimonial Feedback" />
                <meta name="description" content="Explore testimonials from satisfied clients of Sensussoft Software Pvt. Ltd. Read about their experiences and success stories with our services." />
            </Helmet>
            <main>
                <div className='testimonials-page-main'>
                    <div className="ui-ux-design website-development ptb-80 bg-logo">
                        <div className='testimonials-section'>
                            <Container >
                                <Row>
                                    <Col lg={4} className="align-self-center">
                                        <div className="website-development-text proof-text-main">
                                            <div className="ss-heading proof-text testimonials-title">
                                                <h1>                          <img src={require('../assets/images/testimonial-ic.svg').default} className="img-fluid" alt='img' />
                                                    <span>Testimonials </span>
                                                </h1>
                                                <p className="text-regular mt-20">
                                                    Blockchain development refers to the process of creating and implementing applications,
                                                </p>

                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={7} className="align-self-center text-center">
                                        <div className='testimonials-video testimonials-page-slider mt-sm-15 mt-md-15'>


                                            <div className="video-section">
                                                <Slider {...settings} >
                                                    <div className="testimonials-video-main">
                                                        <video className="js-player" playsInline controls={false}>
                                                            <source src={require("../assets/images/testimonials-video1.mp4")} type="video/mp4" />
                                                        </video>
                                                    </div>
                                                    <div className="testimonials-video-main">
                                                        <video className="js-player" playsInline controls={false}>
                                                            <source src={require("../assets/images/testimonials-video2.mp4")} type="video/mp4" />
                                                        </video>
                                                    </div>
                                                    <div className="testimonials-video-main">
                                                        <video className="js-player" playsInline controls={false}>
                                                            <source src={require("../assets/images/testimonials-video3.mp4")} type="video/mp4" />
                                                        </video>
                                                    </div>
                                                </Slider>
                                            </div>
                                        </div>

                                    </Col>
                                    <Col lg={12}>
                                        <div className="title-testimonials pt-100"><h2>Testimonials</h2></div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                    <div className='testimonials-page-box-main pt-50 pb-80'>
                        <Container>
                            <Row >
                                {testimonial.map(testimonials => (
                                    <Col lg={4} className='mb-20' key={testimonials.id}>
                                        <div className='testimonials-page-box '>
                                            <div className="testimonials-box-s1 d-flex flex-column">
                                                <div className="client-box d-flex">
                                                    <div className="bd-highlight align-self-center">
                                                        <div className="client-img">
                                                            <img src={testimonials.image} className="img-fluid" alt='img' />
                                                        </div>
                                                    </div>
                                                    <div className="client-name align-self-center">
                                                        <h5>{testimonials.name}</h5>
                                                        <p className="text-small mb-0">
                                                            {testimonials.role}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="client-info pt-30 flex-grow-1">
                                                    <p>{testimonials.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))}

                            </Row>
                        </Container>
                    </div>
                </div>
            </main>
        </div>
    )
}
