
// import { Link } from 'react-router-dom';
import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';



export default function ScheduleCall(props) {
    // modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className="comm-btn mt-20">
                <button className="learn-more btn-medium" onClick={handleShow} aria-label='schedule a call'>
                    <span className="circle" aria-hidden="true">
                        <span className="icon arrow">
                            <img src={require('../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                        </span>
                    </span>
                    <span className="button-text">Schedule a call</span>
                </button>
            </div>

            {/* shedule meeting modal */}
            <Modal size="lg"
                show={show}
                onHide={handleClose}

                keyboard={false} className="shedule-meeting-modal modal-common">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="modal-title-common">
                            <h4 className="lg-text-semibold">
                                Schedule Meeting
                            </h4>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="quote-form">
                        <div class="calendly-inline-widget"  >
                            <iframe src="https://calendly.com/vinodkalathiya" title="Example Website"></iframe>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
            {/* shedule meeting modal end*/}

        </>



    )
}
