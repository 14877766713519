import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Ourservices from '../components/Ourservices';
import Hirededicationtecdata from '../sliderdata/Hirededicationtecdata.js';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import HiringDedication from '../components/HiringDedication.js';
import Modal from 'react-bootstrap/Modal';
import HireDedicationContect from '../components/HireDedicationContect.js';
import CasestudySlider from '../components/CasestudySlider.js';
import TestimonialSlider from '../components/TestimonialSlider.js';
import BlogSlider from '../components/BlogSlider.js';
import Accordion from 'react-bootstrap/Accordion';
import { Helmet } from 'react-helmet-async';

export default function HireResources() {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Helmet>
                <title>Hire Top 5% Resources | Boost Project Success 80%</title>
                <meta name="title" content="Hire Top 5% Resources | Boost Project Success 80%" />
                <meta name="keywords" content="Hire Top Resources, Top 5% Talent, Project Success, Boost Project Success, Expert Resources, High-Quality Talent, Hire Professionals, Project Excellence, Talent Acquisition, Resource Hiring" />
                <meta name="description" content="Find highly skilled dedicated resources for web/mobile app development. Cut costs by 40%. Get quality work on time & within budget. Connect now!"></meta>
            </Helmet>
            <main>
                <div className='hire_dedication_blog'>
                    <section>
                        <div className="mobile-app-development website-development ptb-80 bg-logo">
                            <Container >
                                <Row>
                                    <Col lg={6} className="align-self-center">
                                        <div className="website-development-text proof-text-main">
                                            <div className="ss-heading proof-text">
                                                <h1>Hire the Top 5% of Top Dedicated Resources </h1>
                                                <p style={{ color: '#F9D14B', margin: '10px 0', fontWeight: '500', fontSize: '16px' }}>Boost Your Project Success Rate by 80%,  Cut Costs by more than 40%</p>
                                                <p className="text-regular">
                                                    Are you a business owner looking to develop a web or mobile app? Lack of resources for managing development? Hire Sensussoft's dedicated experts! No need for supervision; we handle it all. With Sensussoft, get quality work, on time, and within budget. Save on recruiting, training, and infrastructure costs. Just verify your concept, hire skilled resources, and we'll handle the rest.
                                                </p>
                                            </div>
                                            <div className='class_of_brochers mt-10'>
                                                <button style={{ paddingRight: '10px' }} className="learn-more " onClick={handleShow} aria-label='contact us'>
                                                    <span className="circle" aria-hidden="true">
                                                        <span className="icon arrow">
                                                            <img src={require('../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                                                        </span>
                                                    </span>
                                                    <span className="button-text">contact Us</span>
                                                </button>
                                                <a href={require('../assets/images/Presentation-Sensussoft.pdf')} download="Presentation-Sensussoft" target='_blank' rel="noreferrer noopener">
                                                    <button className="learn-more btn-large" aria-label='Download company profile'>
                                                        <span className="circle" aria-hidden="true">
                                                            <span className="icon arrow">
                                                                <img src={require('../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                                                            </span>
                                                        </span>
                                                        <span className="button-text">DOWNLOAD COMPANY PROFILE</span>
                                                    </button>
                                                </a>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="align-self-center text-center">
                                        <div className="change_vision_img"><img className="img-fluid" src={require('../assets/images/hire-devloper/hireskilled-res.png')} alt='service1' /></div>

                                        <div className="website-development-img-main">
                                            <div className="website-development-img mt-sm-20 mt-md-20">
                                                <img className="img-fluid" src={require('../assets/images/hire-devloper/hireskilled.png')} alt="product-ideation"></img>
                                            </div>
                                            <div className="web-build-box">
                                                <div className="like-round">
                                                    <img className="img-fluid " src={require('../assets/images/blub.svg').default} alt="blub">
                                                    </img>
                                                </div>
                                                <div className="build-text">
                                                    <h2>
                                                        30+
                                                    </h2>
                                                    <p className="text-regular">
                                                        Hire Skilled  <br />Resources

                                                    </p>
                                                </div>
                                            </div>
                                            <div className="web-build-box2">
                                                <p>
                                                    Hire Skilled resources for Your <br /> Success.
                                                </p>
                                                <div className="line">
                                                    <img className="img-fluid" src={require('../assets/images/linefill.png')} alt="line"></img><br />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>
                    <section className='pt-40 pb-80'>
                        <Container>
                            <Row>
                                {
                                    Hirededicationtecdata.map((item) => (
                                        <Col lg='3' md='6' key={item.id} >
                                            <Link className='added_liked_class' to={item.tecLink} aria-label='technology'>
                                                <div className='box_of_tecnology'>
                                                    <div className='text_tecnology_hd'>
                                                        <div className='icons_of_dedication_tec'>
                                                            <img className='img-fluid' src={item.tecicon} alt='tec1' />
                                                        </div>
                                                        <h2>{item.tecname}</h2>
                                                        <p className='sm-text-semibold'>{item.tectype}</p>
                                                        <div className='arrow_icon_of_click'>
                                                            <img className='img-fluid' src={require('../assets/images/btn-arrow2.svg').default} alt='arrow' />
                                                        </div>
                                                    </div>

                                                </div>
                                            </Link>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Container>
                    </section>
                    {/* delivering section */}
                    <section className='tecnology_main_exclusive '>
                        <div className="idea-and-enhance bg-service  ptb-80 ">
                            <Container>
                                <Row>
                                    <Col lg={6} className='align-self-center'>
                                        <div className="ideation-services1">
                                            <div className="services-in-action-main1 mb-md-20">
                                                <div className="services-in-action ">
                                                    <div className="our-vision-text-main d-flex">
                                                        <div className="bd-highlight vision-img-main">
                                                            <div className="our-vision-img overview-img">
                                                                <img className="img-fluid" src={require('../assets/images/overview.svg').default} alt="vision-ic"></img>
                                                            </div>
                                                        </div>
                                                        <div className="our-vision-text">
                                                            <p>
                                                                Exclusive Benefits
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="ss-heading ss-portfolio-text">
                                                        <h2 className="ptb-15">
                                                            Exclusive Benefits of Our Dedicated Remote Offshore Team or resources
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={12} className='align-self-center'>
                                        <div className="ideation-content">
                                            <div className="enhance-content-main " >
                                                <div className="enhance-content">
                                                    <h3 className="light-heading">
                                                        Highly skilled resources are available immediately.
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="enhance-content-main ">
                                                <div className="enhance-content">
                                                    <h3 className="light-heading">
                                                        Direct collaboration with our tech resources, no intermediaries.
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="enhance-content-main ">
                                                <div className="enhance-content">
                                                    <h3 className="light-heading">
                                                        Fully engaged resources with tracked time and no offline hours.
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="enhance-content-main ">
                                                <div className="enhance-content">
                                                    <h3 className="light-heading">
                                                        Customized to fit seamlessly into your development workflow.
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="enhance-content-main ">
                                                <div className="enhance-content">
                                                    <h3 className="light-heading">
                                                        Transparent pricing with minor rate adjustments after a year.
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="enhance-content-main ">
                                                <div className="enhance-content">
                                                    <h3 className="light-heading">
                                                        Flexibility to pause contracts and resume anytime.
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="enhance-content-main ">
                                                <div className="enhance-content">
                                                    <h3 className="light-heading">
                                                        Convenient payment terms with discounts for long-term hires.
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="enhance-content-main ">
                                                <div className="enhance-content">
                                                    <h3 className="light-heading">
                                                        No upfront hiring fees or minimum terms, with minimal contractual obligations.
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="enhance-content-main ">
                                                <div className="enhance-content">
                                                    <h3 className="light-heading">
                                                        Diverse skill sets and complimentary consulting for optimal hiring outcomes.
                                                    </h3>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>
                    <section className='pb-80'>
                        <div className='hiring_process_blog pt-80 pb-80' style={{ backgroundColor: '#F4F5F9' }}>
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <div className='hiring_heading'>
                                            <div className="nurture-creativity-title pb-20">
                                                <div className="our-vision-text-main d-flex">
                                                    <div className="bd-highlight vision-img-main">
                                                        <div className="our-vision-img">
                                                            <img className="img-fluid" src={require('../assets/images/vision-ic.svg').default} alt="vision-ic"></img>
                                                        </div>
                                                    </div>
                                                    <div className="our-vision-text">
                                                        <p>
                                                            Our Procedure
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="our-vision-heading">
                                                    <h2 className="lg-text-light ptb-15">
                                                        Discover Your Future
                                                        <span> Join Us Today!</span>
                                                    </h2>
                                                    <p style={{ fontWeight: '300' }}>We have simple and smart procedures to get you the best team that gives the best result. To know more about how we build & maintain an exclusive offshore team for you, get in touch with us now.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>

                                    <HiringDedication />

                                </Row>
                            </Container>
                        </div>
                    </section>
                    <div className="we-are-delivering pb-80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ss-heading">
                                        <div className="bg-hover">
                                            <h1>We are <span>Delivering</span></h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Ourservices />
                        </div>
                    </div>
                    {/* delivering section end*/}
                    {/* What Clients Say About Sensussoft start */}
                    <TestimonialSlider />
                    {/* What Clients Say About Sensussoft end */}
                    {/* Latest Blogs section */}
                    <BlogSlider />
                    {/* Latest Blogs section end*/}
                    {/* Our Case study start */}
                    <CasestudySlider />
                    {/* <HoverEffect></HoverEffect> */}
                    <section className='hire_main_faq_resource'>
                        <div className="ss-faq-main mb-80">
                            <div className="faq-main pt-80" >
                                <Container>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="faq-title proof-text-main text-center mb-30">
                                                <div className="ss-heading proof-text">
                                                    <h2><span>Frequently Asked<br />
                                                        Questions </span></h2>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col lg={8}>
                                            <div className="faq-accordion">
                                                <Accordion defaultActiveKey="0" flush>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>Can I choose the resources based on my project requirements?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="faq-content">
                                                                <p className="text-regular">
                                                                    Yes, we offer complete flexibility in selecting resources based on your project needs. Our team will work closely with you to understand your requirements and provide the most suitable candidates.
                                                                </p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>How do you ensure data security and confidentiality?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="faq-content">
                                                                <p className="text-regular">
                                                                    We take data security and confidentiality seriously. Our team follows strict security protocols and implements measures to safeguard your data and intellectual property throughout the project lifecycle.
                                                                </p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="2">
                                                        <Accordion.Header>What payment methods do you accept for hiring dedicated resources?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="faq-content">
                                                                <p className="text-regular">
                                                                    We accept various payment methods, including bank transfers, credit/debit cards, and online payment platforms, to ensure convenience for our clients.
                                                                </p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="3">
                                                        <Accordion.Header>What are your rates and billing policies? Are there any additional costs? </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="faq-content">
                                                                <p className="text-regular">
                                                                    Our rates are competitive and transparent, with no hidden costs. We offer flexible billing options, including hourly, weekly, or monthly rates, depending on your project requirements.
                                                                </p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="4">
                                                        <Accordion.Header>What is the expertise of your developers in terms of technologies and industries?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="faq-content">
                                                                <p className="text-regular">
                                                                    Our developers are highly skilled and experienced in a wide range of technologies and industries. We carefully match our developers' expertise with your project requirements to ensure the best possible outcome.
                                                                </p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="5">
                                                        <Accordion.Header>What exactly hiring dedicated resources is, and how are they beneficial?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="faq-content">
                                                                <p className="text-regular">
                                                                    Hiring dedicated resources involves supplementing your existing team with external resources to fill skill gaps or meet project demands. It offers flexibility, scalability, and cost-effectiveness compared to traditional hiring models.
                                                                </p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="6">
                                                        <Accordion.Header>Do you offer dedicated resources for overseas clients?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="faq-content">
                                                                <p className="text-regular">
                                                                    Yes, we provide dedicated resources to clients worldwide. Our remote teams are equipped to collaborate seamlessly with clients across different geographical locations.
                                                                </p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>

                        </div>
                    </section>
                    <Modal className='contect_modal_hire' size='lg' show={show} onHide={handleClose} centered>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <HireDedicationContect />
                        </Modal.Body>

                    </Modal>
                </div>
            </main>
        </>
    )
}
