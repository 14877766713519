import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Cultureheader from './Cultureheader';


export default function Header(props) {

    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [isbannerClicked, setIsBannerClicked] = useState(sessionStorage.getItem('showBanner'));

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    // modal end

    const handleCloseOffcanvas = () => {
        setShowOffcanvas(false);
    };

    useEffect(() => {
        setIsBannerClicked(sessionStorage.getItem('showBanner'));
    }, [])

    const handleLinkClick = () => {
        if (isbannerClicked === null || isbannerClicked === true) {
            props.setShowBanner(false);
            sessionStorage.setItem('showBanner', false);
            // sessionStorage.setItem('showBanner', true);
        }
        if (showOffcanvas) {
            setShowOffcanvas(false);
        }
    };

    const [isHeaderFixed, setIsHeaderFixed] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const isFixed = window.pageYOffset > 0;
            setIsHeaderFixed(isFixed);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
    };



    const [showCultureHeader, setShowCultureHeader] = useState(false);

    const handleCultureClick = () => {
        if (isbannerClicked === null || isbannerClicked === true) {
            props.setShowBanner(false);
            sessionStorage.setItem('showBanner', true);
        }
        localStorage.setItem('cultureClicked', 'true');
        // sessionStorage.setItem('cultureClicked', 'true');
        sessionStorage.setItem('activeHeader', 'header');
        setShowCultureHeader(true);
    };

    useEffect(() => {
        // const isCultureClicked = localStorage.getItem('cultureClicked');
        const isCultureClicked = sessionStorage.getItem('cultureClicked');

        if (isCultureClicked === 'true') {
            setShowCultureHeader(true);
            localStorage.removeItem('cultureClicked');
            // sessionStorage.removeItem('cultureClicked');
        }
    }, []);

    return (
        <div className='home-page-main main-functinality-main'>
            {showCultureHeader ? (
                <Cultureheader
                    handleCloseCultureHeader={() => {
                        setShowCultureHeader(false);
                    }}
                    handleCultureClick={handleCultureClick}
                />
            ) : (

                <header className={isHeaderFixed ? 'fixed-header' : ''}>
                    <Container>
                        <Row>
                            <Col>
                                {['lg'].map((expand) => (
                                    <Navbar key={expand} expand={expand} className="bg-body-tertiary">
                                        {/* <Container> */}
                                        <Navbar.Brand>
                                            <div className="ss-logo">
                                                <Link onClick={handleLinkClick} to="/" aria-label='home page'>
                                                    <img src={require('../assets/images/Sensussoft_Logo.svg').default} className="img-fluid" alt='img' />
                                                </Link>

                                            </div>
                                        </Navbar.Brand>
                                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => setShowOffcanvas(true)} />
                                        <Navbar.Offcanvas
                                            show={showOffcanvas}
                                            onHide={handleCloseOffcanvas}
                                            id={`offcanvasNavbar-expand-${expand}`}
                                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                            placement="start"
                                            className='header_sub_main_wapper'
                                        // backdrop="static"
                                        >
                                            <Offcanvas.Header closeButton>
                                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>

                                                    <div className="ss-logo">
                                                        <Link to="/" aria-label='home'>
                                                            <img src={require('../assets/images/Sensussoft_Logo.svg').default} className="img-fluid" alt='img' />
                                                        </Link>
                                                    </div>
                                                </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            <Offcanvas.Body>

                                                <div className="d-flex nav-section1 me-auto ">
                                                    <div className="about_after_line "></div>
                                                    <div className="ss-link-main-part responsive_header_h1 align-self-center">
                                                        <Link aria-label='our culture' to="/culture" onClick={handleCultureClick} >OUR CULTURE</Link>
                                                    </div>

                                                    <div className="about_after_line "></div>
                                                    <div className="ss-link-main-part responsive_header_h1 align-self-center">
                                                        <Link to="/what-we-do" aria-label='what we do' onClick={handleLinkClick}>WHAT WE DO</Link>
                                                    </div>

                                                </div>
                                                <Nav className='nev-section2 m-auto ps-2'>

                                                    <div className="ss-link-main">
                                                        <NavLink className="ss-link nav-link" to="/about-us" onClick={handleLinkClick} aria-label='About Us'>About Us</NavLink>
                                                    </div>
                                                    <div className="ss-link-main">
                                                        <NavLink className="ss-link nav-link" to="/technologies" onClick={handleLinkClick} aria-label='Technologies'>Technologies</NavLink>
                                                    </div>
                                                    <div className="ss-link-main">
                                                        <NavLink className="ss-link nav-link" to="/our-services" onClick={handleLinkClick} aria-label='Our Services'>Our Services</NavLink>
                                                    </div>
                                                    <div className="ss-link-main">
                                                        <NavLink className="ss-link nav-link" to="/blog" onClick={handleLinkClick} aria-label='blog'>Blog</NavLink>
                                                    </div>

                                                    <div className="ss-link-main btn-sewrvice-get align-self-center mt-lg-0 mt-md-2">
                                                        <NavLink className="nav-link animate-charcter" to="/case-study" onClick={handleLinkClick} aria-label='case study'>
                                                            Case Studies</NavLink>
                                                    </div>
                                                </Nav>


                                                <Nav className="d-flex align-items-center btns_of_comman">
                                                    <div className="ss-link-main">
                                                        <NavLink className="ss-link nav-link" to="/hire-resources" onClick={handleLinkClick} aria-label='Hire Resources'>Hire Resources</NavLink>
                                                    </div>

                                                    <div className="get-touch-btn">
                                                        <Link to="/get-in-touch-with-us" onClick={handleLinkClick} className="btn-wrapper-get" aria-label='get in touch'>
                                                            GET IN TOUCH
                                                        </Link>
                                                    </div>
                                                </Nav>

                                            </Offcanvas.Body>
                                        </Navbar.Offcanvas>
                                        {/* </Container> */}
                                    </Navbar>
                                ))}
                            </Col>
                        </Row>
                    </Container>

                </header>
            )}

            {/*get in touch modal*/}
            <Modal size="lg"
                show={show2}
                onHide={handleClose2}
                className="modal-common"
                keyboard={false}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-content-main">
                        <div className="modal-heading text-center">
                            <h4 className="lg-text-semibold">
                                Get In Touch
                            </h4>
                            <p className="text-regular mt-10">
                                Let’s work together for the next big digital story
                            </p>
                            <Form validated={validated} onSubmit={handleSubmit}>
                                <Row className="mt-30">
                                    <Col lg={6}>
                                        <div className="custom-input mb-20">
                                            <Form.Group className="mb-3" controlId="validationCustom01">

                                                <Form.Control required type="text" placeholder="Full Name" />

                                            </Form.Group>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="custom-input mb-20">
                                            <Form.Group className="mb-3" controlId="validationCustom01">

                                                <Form.Control required type="text" placeholder="Mobile No" />

                                            </Form.Group>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="custom-input mb-20">
                                            <Form.Group className="mb-3" controlId="formGroupPassword">

                                                <Form.Control type="email" placeholder="Email Address" required />
                                            </Form.Group>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="custom-input mb-20">
                                            <Form.Group className="mb-3" controlId="formGroupPassword">

                                                <Form.Control type="text" placeholder="Subject" required />
                                            </Form.Group>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="custom-input mb-20">
                                            <Form.Group controlId="validationCustom01">

                                                <Form.Control
                                                    required
                                                    as="textarea" rows={3}
                                                    placeholder="Your Message"

                                                />
                                            </Form.Group>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="next-btn text-center">
                                            <Button type="submit" className="btn-wrapper btn-wrapper-blue" aria-label='submit'>
                                                Submit</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/*get in touch modal end*/}


        </div>
    )
}
