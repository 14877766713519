
import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export default function HireDedicationContect() {
    const [firstnm, setFirstNm] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [message, setMessage] = useState('');
    const [resource_type, setResourcetype] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [validated, setValidated] = useState(false);

    const handlePhoneChange = (phone, country) => {
        if (typeof phone === 'string' && phone && typeof country.dialCode === 'string') {
            const reducedPhone = phone.replace(country.dialCode, '').trim();
            setMobile(country.dialCode + ' ' + reducedPhone);
        }
    };
    useEffect(() => {
        // Get the last segment of the URL
        const url = window.location.href;
        const lastSegment = url.substring(url.lastIndexOf('/') + 1);

        // Update the state with the last segment
        setResourcetype(lastSegment);
    }, []);

    const handleChange = (event) => {
        const inputValue = event.target.value;
        setEmail(inputValue);
        validateEmail(inputValue);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValid(emailRegex.test(email));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setLoading(true);
            axios
                .post('https://api.sensussoft.com/api/hire-developer', {
                    fullname: firstnm,
                    mobile: mobile,
                    email: email,
                    dev_type: resource_type,
                    message: message
                })
                .then((response) => {
                    setShowSuccessModal(true);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setFirstNm('');
                    setEmail('');
                    setMobile('');
                    setMessage('');
                    setValidated(false);
                    setLoading(false);
                    setShowSuccessModal(true);
                });
        }
        setValidated(true);
    };

    const handleCloseModal = () => {
        setShowSuccessModal(false);
    };

    return (
        <div className='contactus-page-main'>
            <div className='contact-us-form-main ptb-80'>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className='hirecontact contact-us-form-box mt-0'>
                                <div className='contact-us-title'>
                                    <h2>Tell Us A Bit About Yourself</h2>
                                    <p className='text-regular pt-5'>Give your business digital advancement with us</p>
                                </div>
                                <div className='contact-form-main'>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Row className="mt-30">
                                            <Col lg={4}>
                                                <div className="custom-input mb-20">
                                                    <Form.Group className="mb-3" controlId="validationCustom01">
                                                        <Form.Label>Full Name<span style={{ color: '#FA483C' }}>*</span></Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            value={firstnm}
                                                            onChange={(e) => setFirstNm(e.target.value)}
                                                            placeholder="Full Name"
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter your first name.
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="custom-input mb-20">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                                        <Form.Label>Email Address<span style={{ color: '#FA483C' }}>*</span></Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            value={email}
                                                            onChange={handleChange}
                                                            isInvalid={!isValid}
                                                            placeholder="Email Address"
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter a valid email address
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </Col>

                                            <Col lg={4}>
                                                <div className="custom-input mb-20">
                                                    <Form.Group className="mb-3" controlId="formGroupPassword1">
                                                        <Form.Label>Phone Number<span style={{ color: '#FA483C' }}>*</span></Form.Label>
                                                        <PhoneInput
                                                            value={mobile}
                                                            country={"in"}
                                                            enableSearch={true}
                                                            onChange={handlePhoneChange}
                                                            placeholder="Phone Number"
                                                            required
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter your phone number
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="custom-input mb-20">
                                                    <Form.Group controlId="validationCustom02">
                                                        <Form.Label>Brief About The Project<span style={{ color: '#FA483C' }}>*</span></Form.Label>
                                                        <Form.Control
                                                            // required
                                                            as="textarea"
                                                            rows={3}
                                                            placeholder="Your Message"
                                                            value={message}
                                                            onChange={(e) => setMessage(e.target.value)}
                                                        />

                                                    </Form.Group>
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="form-submit-btn text-end pt-50">
                                                    <Button type="submit" className="btn-wrapper btn-wrapper-blue" disabled={loading} aria-label='submit'>
                                                        {loading ? <span>Loading <i className="fa fa-spinner fa-spin"></i></span> : 'Submit'}
                                                    </Button>
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="sucess-modal">
                                                    <Modal className='sucess-modal-main' centered show={showSuccessModal} onHide={handleCloseModal}>
                                                        <Modal.Body className='sucess-modal-info'>
                                                            <div className='sucessfully-content text-center'>
                                                                <div className='sucess-img'>
                                                                    <img className="img-fluid " src={require('../assets/images/sucess.gif')} alt="Attach File" />
                                                                </div>
                                                                <p className='pt-10'>Form submitted successfully!</p>
                                                            </div>
                                                            <div className='sucess-close text-center pt-20'>
                                                                <Button className="btn-wrapper btn-wrapper-blue" onClick={handleCloseModal} aria-label='close button'>
                                                                    Ok
                                                                </Button>
                                                            </div>
                                                        </Modal.Body>
                                                    </Modal>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
