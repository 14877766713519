import React from 'react'
import { Col, Container, Row, Stack, Nav, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Ourprocess from '../../components/Ourprocess';
import ScheduleCall from '../../components/ScheduleCall';
import Faq from '../../components/Faq';
import Accordion from 'react-bootstrap/Accordion';
import { Helmet } from 'react-helmet-async';
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function AlMldevelopment() {
  const CustomArrow = ({ direction, onClick }) => {
    const icon = direction === 'next' ? faChevronRight : faChevronLeft;

    return (
      <div
        className={`custom-arrow ${direction}`}
        style={{ zIndex: '1', display: 'block' }}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: true,
    autoplay: false,
    margin: 10,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };
  return (
    <>
      <Helmet>
        <title>Transforming Businesses with AI & ML Innovations</title>
        <meta name="title" content="Transforming Businesses with AI & ML Innovations" />
        <meta name="keywords" content="AI & ML Innovations, AI Transformations, ML Solutions, Business AI Applications, Sensussoft AI & ML" />

        <meta name="description" content="Unlock the full potential of your business with our comprehensive AI and ML development services. From mobile and web applications powered by AI to natural language processing (NLP) solutions, we help businesses enhance user experiences, automate processes, and make data-driven decisions."></meta>
      </Helmet>
      <main>
        <div className='aimi-serive-main'>

          {/* section 1 */}
          <div className="maintanace-main website-development ptb-80 bg-logo">
            <Container>
              <Row className='pb-80 justify-content-end'>
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className=" service-btn-mobile  next-prev-btn comm-btn ">
                    <Link aria-label='productideation' to='/maintenance-support'>
                      <button className="service-previous-btn learn-more arrow-btn " aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className="service-btn-mobile next-prev-btn comm-btn text-end ">
                    <Link aria-label='mobileappdevelopment' to='/blockchain-development'>
                      <button className="learn-more arrow-btn" aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="align-self-center">
                  <div className="website-development-text proof-text-main">
                    <div className="ss-heading proof-text">
                      <h1><span>We design AI solutions for your Business</span></h1>
                      <p className="text-regular mt-20">
                        We offer comprehensive AI and ML development services to help businesses unlock their full potential. By leveraging cutting-edge technologies, we deliver solutions that enhance user experiences, automate processes, and enable data-driven decision-making.          </p>

                      <Link to='/get-in-touch-with-us' aria-label='contact us'>
                        <button className="learn-more btn-large mt-25" aria-label='contact us for detail'>
                          <span className="circle" aria-hidden="true">
                            <span className="icon arrow">
                              <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                            </span>
                          </span>
                          <span className="button-text">CONTACT US FOR DETAILS</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="align-self-center text-center">
                  <div className="change_vision_img"><img className="img-fluid" src={require('../../assets/images/ai-responsive.png')} alt='service1' /></div>

                  <div className="website-development-img-main">

                    <div className="website-development-img mt-sm-20 mt-md-20">
                      <img className="img-fluid" src={require('../../assets/images/ai-service.jpg')} alt="ai-service"></img>
                    </div>
                    <div className="web-build-box maitanance-box">
                      <div className="like-round">
                        <img className="img-fluid " src={require('../../assets/images/blub.svg').default} alt="blub">
                        </img>
                      </div>
                      <div className="build-text">
                        <h2>
                          50+
                        </h2>
                        <p className="text-regular">
                          360° services <br />support
                        </p>
                      </div>
                    </div>
                    <div className="web-build-box2 maitanance-box2">
                      <p>
                        Unmatched Maintenance Support:<br></br> Covering Every Dimension of Your Needs.
                      </p>
                      <div className="line">
                        <img className="img-fluid" src={require('../../assets/images/linefill.png')} alt="line"></img><br />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 1 end*/}

          {/* section 2 */}
          <div className="idea-and-enhance bg-service ">
            <Container>
              <Row>
                <Col lg={6}>
                  <div className="ai-service-list  ideation-services">
                    <div className="services-in-action-main mb-md-20">
                      <div className="services-in-action pt-100 pb-200">
                        <div className="our-vision-text-main d-flex">
                          <div className="bd-highlight vision-img-main">
                            <div className="our-vision-img overview-img">
                              <img className="img-fluid" src={require('../../assets/images/overview.svg').default} alt="vision-ic"></img>
                            </div>
                          </div>
                          <div className="our-vision-text">
                            <p>
                              Service Overview
                            </p>
                          </div>
                        </div>
                        <div className="ss-heading ss-portfolio-text">
                          <h2 className="ptb-15">
                            Our <span>AIML</span>  Development Services
                          </h2>

                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={12} className='align-self-center'>
                  <div className="ideation-content ptb-100">
                    <div className="enhance-content-main " >
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Mobile web applications powered by AI:</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          In a field where mobile and web applications play a key role in the interaction between businesses and their customers, combining AI capabilities can provide a competitive advantage Our mobile & web applications powered by AI functionality empower you to deliver intelligence harnessing the full capabilities of the developed business, delivering enhanced user experience and intelligent operation They can. Whether it’s recommendation systems, personalized offers, or voice recognition, we have the expertise to streamline your applications through AI, while expertly managing your users constant demand management
                        </p>

                      </div>
                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Natural Language Properties:</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Understanding and processing human language presents a daunting challenge, but with our Natural Language Processing (NLP) services this effort becomes effortless NLP enables your applications to identify, analyze they were human-like statements. From monitoring emotions and streamlining language translation to implementing chatbots and voice assistants, we can enable your applications to run on the power of NLP that opens up new concepts for communication and communication </p>

                      </div>

                    </div>


                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 2 end*/}

          {/* SECTION 3 */}
          <div className="Technology-we-use ">
            <div className="ss-case-study pb-80">
              <Tab.Container defaultActiveKey="web-development">
                <div className="container">
                  <Row>
                    <Col lg="12">
                      <div className="tecnology_header pt-80">
                        <div className="ss-heading ss-portfolio-text">
                          <h2>
                            <span>Technology</span> we use
                          </h2>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="pt-30">
                    <Col lg={12}>
                      <Nav variant="pills" className="flex-row" defaultActiveKey="web-development" id='tecnology_scroll'>
                        <Slider {...settings} className="ss_tecnology_blog">
                          <Nav.Item>
                            <Nav.Link eventKey="web-development" aria-label='Languages'>Languages</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="mobile-development" aria-label='Data Engineering'>Data Engineering</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="design" aria-label='Sqlneural Networks'>Sqlneural Networks</Nav.Link>
                          </Nav.Item>

                        </Slider>

                      </Nav>
                    </Col>
                    <Col lg={11}>
                      <Nav variant="pills" className="flex-row" defaultActiveKey="web-development" id='tecnology_scroll_secound'>

                        <Nav.Item>
                          <Nav.Link eventKey="web-development" aria-label='Languages'>Languages</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="mobile-development" aria-label='Data Engineering'>Data Engineering</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="design" aria-label='Sqlneural Networks'>Sqlneural Networks</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                  </Row>
                </div>
                <div className="container">
                  <Row className="justify-content-center">
                    <Col sm={12} className="pt-50">
                      <Tab.Content>
                        <Tab.Pane eventKey="web-development">
                          <Row className="technology-info">
                            <Col lg={12} md={6}>
                              <div className="ai-technology-main technology-box-main">

                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic1.svg').default} className="img-fluid  red" alt='img' />
                                      </div>
                                    </div>
                                    <p className="technology-title text-regular  mt-10">
                                      Python
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic2.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Java
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic3.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      MySQL
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic4.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      C++
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic5.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Scala
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic6.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Swift3
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic7.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Typescript
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic8.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      C#
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic9.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      GO
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic10.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      BASH
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic11.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Flask
                                    </p>
                                  </div>
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic12.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <p className="technology-title text-regular mt-10">
                                      Django
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </Col>

                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="mobile-development">
                          <Row className="technology-info">
                            <Col lg={12} md={12}>
                              <div className="ai-technology-main technology-box-main">
                                <h6 className="text-regular mb-25">
                                  Programming language
                                </h6>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic13.svg').default} className="img-fluid" alt='mobile-ic1' />
                                      </div>
                                    </div>
                                    <h6 className="technology-title text-regular  mt-10">
                                      Azure
                                    </h6>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic14.svg').default} className="img-fluid" alt='mobile-ic2' />
                                      </div>

                                    </div>
                                    <h6 className="technology-title text-regular mt-10">
                                      Amazon Web Services
                                    </h6>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic15.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <h6 className="technology-title text-regular mt-10">
                                      Google Cloud
                                    </h6>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic16.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <h6 className="technology-title text-regular mt-10">
                                      SPSS
                                    </h6>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic17.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <h6 className="technology-title text-regular mt-10">
                                      Apache Hadoop
                                    </h6>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic18.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <h6 className="technology-title text-regular mt-10">
                                      Kubernetes
                                    </h6>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic19.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <h6 className="technology-title text-regular mt-10">
                                      Apache Cassandra
                                    </h6>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic20.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <h6 className="technology-title text-regular mt-10">
                                      MongoDB
                                    </h6>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic21.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <h6 className="technology-title text-regular mt-10">
                                      Access
                                    </h6>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic22.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <h6 className="technology-title text-regular mt-10">
                                      Macros
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </Col>


                          </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="design">
                          <Row className="technology-info">
                            <Col lg={6} md={6}>
                              <div className=" technology-box-main">
                                <h6 className="text-regular mb-25">
                                  Convolutional & recurrent neural networks
                                </h6>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic23.svg').default} className="img-fluid" alt='design-ic1' />
                                      </div>
                                    </div>
                                    <h6 className="technology-title text-regular  mt-10">
                                      Long short-term memory
                                    </h6>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic24.svg').default} className="img-fluid" alt='design-ic2' />
                                      </div>

                                    </div>
                                    <h6 className="technology-title text-regular mt-10">
                                      Glavnoye Razvedyvatelnoye<br></br> Upravlenie
                                    </h6>
                                  </div>

                                </div>


                              </div>
                            </Col>
                            <Col lg={6} md={6}>
                              <div className="technology-box-main">
                                <h6 className="text-regular mb-25">
                                  Autoencoders
                                </h6>
                                <div className="ss-technology-box d-flex flex-fill">
                                  <div className="technology-circle-main text-center mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic25.svg').default} className="img-fluid svg-img" alt='design-ic5' />
                                      </div>

                                    </div>
                                    <h6 className="technology-title text-regular  mt-10">
                                      Ventilator-<br></br>associated Event
                                    </h6>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic26.svg').default} className="img-fluid" alt='design-ic6' />
                                      </div>

                                    </div>
                                    <h6 className="technology-title text-regular mt-10">
                                      Digital Asset <br></br>Exchange
                                    </h6>
                                  </div>
                                  <div className="technology-circle-main text-center  mb-20">
                                    <div className="technology-circle">
                                      <div className="ss-technology-img">
                                        <img src={require('../../assets/images/technology/ai-ic27.svg').default} className="img-fluid" alt='design-ic7' />
                                      </div>
                                    </div>
                                    <h6 className="technology-title text-regular mt-10">
                                      SAE
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </div>
              </Tab.Container>
            </div>
          </div>
          {/* SECTION 3 END*/}
          <div className="our-industry ptb-100 bg-multi ptb-100">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      Our Industry <span> Specific Experience </span>
                    </h2>
                    <p className="pt-10 text-regular">
                      Sensussoft has been dedicated to providing mobile app and website solutions with industry specific experience. Whether it is lifestyle, health, fitness, booking, or e-commerce, Sensussoft has worked on various projects tailored to individual businesses' needs.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="pt-50 justify-content-center ">
                <Col lg={12}>
                  <div className="experience-box-main d-flex flex-wrap justify-content-center">
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic8.svg').default} className="img-fluid" alt='experience-ic1' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Restaurant
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic9.svg').default} className="img-fluid" alt='experience-ic2' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Logistics
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic10.svg').default} className="img-fluid" alt='experience-ic3' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Games & Sports
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic5.svg').default} className="img-fluid" alt='experience-ic5' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Aviation
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic11.svg').default} className="img-fluid" alt='experience-ic4' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Real Estate
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic12.svg').default} className="img-fluid" alt='experience-ic6' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          On-Demand
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic13.svg').default} className="img-fluid" alt='experience-ic7' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Government
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic1.svg').default} className="img-fluid" alt='experience-ic1' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Social Media
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic2.svg').default} className="img-fluid" alt='experience-ic2' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          E-commerce
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic3.svg').default} className="img-fluid" alt='experience-ic3' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Entertainment
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic4.svg').default} className="img-fluid" alt='experience-ic4' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Healthcare
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic5.svg').default} className="img-fluid" alt='experience-ic5' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Travel
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic6.svg').default} className="img-fluid" alt='experience-ic6' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Fintech
                        </p>
                      </div>
                    </div>
                    <div className="experience-box text-center mb-sm-20 flex-xl-fill mb-20">
                      <div className="experience-img">
                        <img src={require('../../assets/images/experience-ic7.svg').default} className="img-fluid" alt='experience-ic7' />
                      </div>
                      <div className="experience-text">
                        <p className="lg-text-semibold">
                          Education
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* <our process start> */}
          <div className="our_process_mainblog ptb-80">
            <div className="container">
              <div className="row pb-40">
                <div className="col-lg-12">
                  <div className="ss-heading">
                    <h2> <span>AIML Development </span> Process</h2>
                  </div>
                </div>
              </div>
              <Ourprocess />
            </div>
          </div>
          {/* <our process end> */}
          {/* section  5*/}
          <div className="attractive-main ptb-120">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="attractive-box">
                    <Stack direction="horizontal" className="d-mobile-block">
                      <div className="attractive-text flex-grow-1">
                        <h3 className="ss-title">
                          <span className="text-regular">We Have Rolled Up Our Sleeves To <br></br>Provide You With 360-degree It Support.</span>
                        </h3>
                      </div>
                      <div className="attractive-btn">
                        <ScheduleCall></ScheduleCall>
                      </div>
                    </Stack>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 5 end */}
          {/* section 7 */}
          <div className="ss-faq-main">
            <div className="faq-main pt-120   ">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="faq-title proof-text-main text-center mb-30">
                      <div className="ss-heading proof-text">
                        <h2><span>Frequently Asked<br />
                          Questions </span></h2>
                      </div>
                    </div>

                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <div className="faq-accordion">
                      <Accordion defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>How can I use Artificial Intelligence in my app?
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">

                                <ul>


                                  <li><p>When you are talking about using Artificial Intelligence in your app, the possibilities are countless. Let’s have a look at some of the ways in which AI can benefit you and your business by incorporating it into your app.

                                  </p></li>

                                  <li><p>Automate your business processes using AI tools

                                  </p></li>

                                  <li><p>Customized AI-based mobile apps, web apps, or software

                                  </p></li>

                                  <li><p>AI-powered standalone chatbots for personalized communication

                                  </p></li>

                                  <li><p>Custom software built using Artificial Neural Networks

                                  </p></li>

                                  <li> <p>AI-fueled systems can solve complex problems using automated reasoning

                                  </p></li>

                                  <li><p>AI-powered systems can be used for user behavior analytics

                                  </p></li>
                                </ul>
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Will you provide any document to absolute ownership of my AI and ML project?

                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Yes, You will have 100% ownership of your AI and ML project. It includes NDA, copyright, source code, intellectual property rights, etc.


                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>What specific type of data is required to implement AI and ML?

                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Any data which can be converted as well as represented into numerical representation can be used for AI and ML. Here is the list of such types of data:
                                <ul className='pt-5'>
                                  <li><p>Tabular data</p></li>
                                  <li><p>Text</p></li>
                                  <li><p>Image</p></li>
                                  <li><p>Video</p></li>
                                  <li><p>Graphs</p></li>
                                </ul>
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>How much does it cost to develop an AI-based app?
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular pb-5">
                                The average cost to develop an artificial intelligence app would range somewhere between $40,000 to $300,000. However, this is a very rough estimate. There are a number of factors, such as the features and complexity of your app, that can increase or lower the final price.
                              </p>
                              <p className="text-regular pb-5">
                                However, If you wish to get the exact estimates for your custom app project, a dedicated artificial intelligence solutions provider like Sensussoft can help you out.
                              </p>
                              <p className="text-regular pb-5">
                                Get in touch for the exact cost estimates!</p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>What is the process for keeping me informed during the development stages?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                During the workday, you communicate with the project team via Slack. You will be added to all project boards by our project manager. You are welcome to attend our daily meetings, refinements, planning, and demo meetings where you will receive updates on the project. JIRA is currently our project management tool. If there is anything you would like to discuss, you can also request a meeting with the assigned project manager at any time.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <Faq></Faq>
          </div>
          {/* section 7 end*/}
        </div>
      </main>
    </>
  )
}

export default AlMldevelopment

