import Marquee from "react-fast-marquee";
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
// import { Container, Row } from "react-bootstrap";
// import Workcard from "../components/Workcard";

export default function AboutUs() {

    const [activeYear, setActiveYear] = useState('2014');
    const [transformValue, setTransformValue] = useState('translateY(0%)');

    const handleScroll = () => {
        const timelineItems = document.querySelectorAll('.timeline li');

        timelineItems.forEach((item) => {
            const rect = item.getBoundingClientRect();
            if (rect.top <= 100) {
                setActiveYear(item.dataset.year);
                const scrollPercentage = (window.pageYOffset / window.innerHeight) * 0;
                setTransformValue(`translateY(${scrollPercentage}%)`);
                return;
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <Helmet>
                <title>About Us | Discover Our Story & Mission</title>
                <meta name="title" content="About Us | Discover Our Story & Mission" />
                <meta name="keywords" content="About Us, Our Story, Company Mission, Discover Our Story, Company Vision, Company Values, Our Mission, Company History, About Our Company, Our Background" />
                <meta name="description" content="Learn about Sensussoft, a reliable IT service provider committed to empowering businesses with innovative software solutions. Discover our CEO's message, vision, mission, core values, and journey towards excellence." />
            </Helmet>
            <main>
                <div className="about-us-main">

                    {/* <Workcard/> */}
                    {/* section 2  */}
                    <div className="software-solution ptb-100">
                        <div className="container">
                            <div className="row justify-content-lg-between justify-content-md-center">
                                <div className="col-lg-6 col-md-12 align-self-center">
                                    <div className="ss-software">
                                        <div className="ss-heading solution-title">
                                            <h1>Reliable Software Solution<br />Partner <span>For Your Business</span></h1>
                                            <p className="text-regular ptb-20">
                                                We are a trustworthy service provider IT company that leaves no stone unturned for the success of a client’s project. </p>
                                            <p className="text-regular pb-20">Every day, we master software development challenges and strive to be the best version of ourselves.</p>
                                        </div>
                                        <div className="ss-certified d-flex mb-15">
                                            <div className="bd-highlight align-self-center">
                                                <div className="certificate-img">
                                                    <img className="img-fluid" src={require('../assets/images/certificate.svg').default} alt="certificate" />
                                                </div>

                                            </div>
                                            <div className="certificate-text align-self-center">
                                                <span>
                                                    ISO 9001:2015 Certified Company
                                                </span>
                                                <p className="text-regular">
                                                    We are an ISO 9001:2015 company having expertise in iOS App development/Android App.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="signtue_of_ceo">
                                            <img className="img-fluid" src={require('../assets/images/sign.png')} alt="sign" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-6  col-md-9 text-start align-self-center">
                                    <div className="partner-text-main">
                                        <div className="wrapper">

                                            <img className="img-fluid float-image right" src={require('../assets/images/ceo.jpg')} alt="ceo" />
                                            <p>
                                                Dear Valued Customers, Partners, and Friends , As CEO of Sensussoft, I am delighted to share our vision and accomplishments with you. We are dedicated to empowering businesses with innovative software solutions for growth and digital transformation. Our talented team's expertise and commitment to excellence   have driven our success. We are grateful for the trust and collaboration from our diverse range of clients, and we look forward to continuing to deliver cutting-edge solutions tailored to their needs. With a focus on innovation, quality, and customer satisfaction, we aim to build long-term partnerships based on transparency and integrity. Thank you for your support as we create a remarkable future together.
                                            </p>
                                            <p>Warm regards,</p>
                                            <div className="ss-certified d-flex mt-20">
                                                <div className="ceo-text align-self-center">
                                                    <span>
                                                        Vinod Kalathiya
                                                    </span>
                                                    <p className="text-regular">
                                                        Director & CEO
                                                    </p>
                                                </div>
                                                <div className="ss-border"></div>
                                                <div className='about-social-img align-self-center'>
                                                    <Link to="https://www.vinodkalathiya.com/" target="_blank" >
                                                        <img className="img-fluid" src={require('../assets/images/about-self.svg').default} alt="about-self" />
                                                    </Link>
                                                    <Link to="https://www.linkedin.com/in/vinod-kalathiya/" target="_blank" aria-label="linkedin">
                                                        <img className="img-fluid" src={require('../assets/images/about-linkedin.svg').default} alt="about-linkedin" />
                                                    </Link>
                                                    <Link to="https://medium.com/@vinod.kalathiya" target="_blank" aria-label='medium'>
                                                        <img className="img-fluid" src={require('../assets/images/about-medium.svg').default} alt="about-medium" />
                                                    </Link>
                                                    <Link to="https://twitter.com/vinodkalathiya" target="_blank" aria-label="twitter">
                                                        <img className="img-fluid" src={require('../assets/images/about-twitter.svg').default} alt="about-twitter" />
                                                    </Link>
                                                    <Link to="skype:vin.patel33?" target="_blank" aria-label="skype">
                                                        <img className="img-fluid" src={require('../assets/images/about-skype.svg').default} alt="about-skype" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    {/* section 2  end*/}
                    {/* section 3 */}
                    <div className="about_stikcy_position">
                        <div className="flip_card_about_goal our-vision  about-bg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 align-self-center">
                                        <div className="nurture-creativity-title">
                                            <div className="our-vision-text-main d-flex">
                                                <div className="bd-highlight vision-img-main">
                                                    <div className="our-vision-img ">
                                                        <img className="img-fluid" src={require('../assets/images/vision-ic.svg').default} alt="vision-ic"></img>
                                                    </div>
                                                </div>
                                                <div className="our-vision-text">
                                                    <p>
                                                        our-vision
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="our-vision-heading">
                                                <h2 className="lg-text-light ptb-15">
                                                    We Nurture <span>Creativity</span>  <br />that drives your  <br /> business to <span>Infinity</span>
                                                </h2>
                                                <p className="text-regular">
                                                    This vision statement emphasizes the importance of fostering “creativity” as a means of driving business success and growth. The use of the word "nurture" suggests that creativity is seen as a valuable resource that needs to be cared for and developed over time. The phrase "to infinity" implies that the potential for growth and success is limitless and that “creativity” is a key factor in achieving this.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 text-center">
                                        <div className="change_vision_img">
                                            <img className="img-fluid" src={require('../assets/images/aboutdes1.png')} alt="about1" />
                                        </div>
                                        <div className="flip-card">
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <div className="vision-img">
                                                        <div className="ss-vision-img">
                                                            <img src={require('../assets/images/vision-img.jpg')} className="img-fluid" alt="Avatar" />
                                                            <div className="vision-label-main">
                                                                <div className="vision-label1 vision-box">
                                                                    <p className="lg-text-semibold">
                                                                        Digital transformation
                                                                    </p>
                                                                </div>
                                                                <div className="vision-label2 vision-box">
                                                                    <p className="lg-text-semibold">
                                                                        Empowerment
                                                                    </p>
                                                                </div>
                                                                <div className="vision-label3 vision-box">
                                                                    <p className="lg-text-semibold">
                                                                        Sustainable
                                                                    </p>
                                                                </div>
                                                                <div className="vision-label4 vision-box">
                                                                    <div className="vision-label4-main d-flex">
                                                                        <div className="bd-highlight align-self-center">
                                                                            <div className="focused-img">
                                                                                <img className="img-fluid" src={require('../assets/images/focus.svg').default} alt="focus"></img>
                                                                            </div>
                                                                        </div>
                                                                        <div className="focused-text align-self-center">
                                                                            <p className="lg-text-semibold text-start">
                                                                                Future-focused
                                                                            </p>
                                                                            <p className="text-regular">
                                                                                provide value to clients
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="flip-card-back">
                                                    <div className="change_vision_img1">
                                                        <img className="img-fluid" src={require('../assets/images/vision-img1.jpg')} alt="about1" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* section 3  end*/}
                        {/* section 4*/}
                        <div className="flip_card_about_goal our-Mission  about-bg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 text-center">
                                        <div className="change_vision_img">
                                            <img className="img-fluid" src={require('../assets/images/aboutdes2.png')} alt="about1" />
                                        </div>
                                        <div className="flip-card">
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <div className="vision-img">
                                                        <div className="ss-vision-img ss-mission-img">
                                                            <img src={require('../assets/images/missionbg.jpg')} className="img-fluid" alt="Avatar" />
                                                            <div className="vision-label-main">

                                                                <div className="vision-label4 vision-box">
                                                                    <div className="vision-label4-main d-flex">
                                                                        <div className="bd-highlight align-self-center">
                                                                            <div className="focused-img">
                                                                                <img className="img-fluid" src={require('../assets/images/misson-ic.svg').default} alt="focus"></img>
                                                                            </div>
                                                                        </div>
                                                                        <div className="focused-text align-self-center">
                                                                            <p className="text-semibold text-start">
                                                                                Future-focused
                                                                            </p>
                                                                            <p className="text-regular">
                                                                                provide value to clients
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="vision-label3 vision-box">
                                                                    <p className="lg-text-semibold">
                                                                        Collaboration
                                                                    </p>
                                                                </div>
                                                                <div className="vision-label2 vision-box">
                                                                    <p className="lg-text-semibold text-start">
                                                                        Innovation
                                                                    </p>
                                                                    <p className="text-start text-regular">
                                                                        developing new & innovative solutions
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flip-card-back">
                                                    <div className="change_vision_img1">
                                                        <img className="img-fluid" src={require('../assets/images/missionbg1.jpg')} alt="about1" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-lg-6 align-self-center">
                                        <div className="nurture-creativity-title">
                                            <div className="our-vision-text-main d-flex">
                                                <div className="bd-highlight vision-img-main">
                                                    <div className="our-vision-img">
                                                        <img className="img-fluid" src={require('../assets/images/mission.svg').default} alt="mission-ic"></img>
                                                    </div>
                                                </div>
                                                <div className="our-vision-text">
                                                    <p>
                                                        Our Mission
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="our-vision-heading">
                                                <h2 className="lg-text-light ptb-15">
                                                    Our mission is to make <br />the <span>Impossible</span> into <br /><span>Achievable. </span>
                                                </h2>
                                                <p className="text-regular">
                                                    Our mission for 2025 is to become a leading provider of IT services while also focusing on in-house product development. We want to deliver our projects in time and costs that we can satisfy our Customer and enable ongoing collaboration. We want to strengthen the capabilities of our team to meet the challenges as a service provider efficiently and successfully.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* section 4 end*/}
                        {/* section 5*/}
                        <div className="flip_card_about_goal our-strategy  about-bg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 align-self-center">
                                        <div className="nurture-creativity-title">
                                            <div className="our-vision-text-main d-flex">
                                                <div className="bd-highlight vision-img-main">
                                                    <div className="our-vision-img">
                                                        <img className="img-fluid" src={require('../assets/images/mission.svg').default} alt="mission-ic"></img>
                                                    </div>
                                                </div>
                                                <div className="our-vision-text">
                                                    <p>
                                                        Our Strategy
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="our-vision-heading">
                                                <h2 className="lg-text-light ptb-15">
                                                    Delivering beautiful <br /><span>Digital Products </span>to our <br /><span>Customers</span>
                                                </h2>
                                                <p className="text-regular">
                                                    The combination of great products and expertise, coupled with our practical and caring philosophy, makes our customers very happy.
                                                    This leads to the necessary appreciation of each of us. Our long-term partnership is based on supporting exceptional products that align with people's values and becoming the leading Creative partner for the best digital products out there.                  </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 text-center">
                                        <div className="change_vision_img">
                                            <img className="img-fluid" src={require('../assets/images/aboutdes3.png')} alt="about1" />
                                        </div>
                                        <div className="flip-card">
                                            <div className="flip-card-inner">
                                                <div className="flip-card-front">
                                                    <div className="vision-img">
                                                        <div className="ss-vision-img">
                                                            <img src={require('../assets/images/strategy-bg.jpg')} className="img-fluid" alt="Avatar" />
                                                            <div className="vision-label-main">
                                                                <div className="ss_main_vison_box vision-label1 vision-box">
                                                                    <p className="lg-text-semibold">
                                                                        Continuous Learning and Adaptation
                                                                    </p>
                                                                </div>

                                                                <div className="vision-label3 vision-box">
                                                                    <p className="lg-text-semibold">
                                                                        Build Relationships
                                                                    </p>
                                                                </div>
                                                                <div className="vision-label4 vision-box">
                                                                    <div className="vision-label4-main d-flex">
                                                                        <div className="bd-highlight align-self-center">
                                                                            <div className="focused-img">
                                                                                <img className="img-fluid" src={require('../assets/images/solution-ic.svg').default} alt="solution"></img>
                                                                            </div>
                                                                        </div>
                                                                        <div className="focused-text align-self-center">
                                                                            <p className="lg-text-semibold text-start">
                                                                                Solution Differentiation
                                                                            </p>
                                                                            <p className="text-start text-regular">
                                                                                provide value to clients
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="flip-card-back">
                                                    <div className="change_vision_img1">
                                                        <img className="img-fluid" src={require('../assets/images/strategy-bg1.jpg')} alt="about1" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* section 5 end*/}
                    </div>
                    {/* section 6 */}
                    <div className="our-core-value ptb-80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="ss-heading">
                                        <h2>Our Core <span>Values</span></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row pt-30">
                                <div className="col-lg-6 col-md-6 mb-25">
                                    <div className="core-value-box d-flex">
                                        <div className="bd-highlight align-self-center">
                                            <div className="core-value-img">
                                                <img className="img-fluid" src={require('../assets/images/value-ic1.svg').default} alt="value-ic1" />
                                            </div>
                                        </div>
                                        <div className="core-value-text align-self-center">
                                            <h2>
                                                Creativity
                                            </h2>
                                            <p className="text-regular pt-10">
                                                Creativity is the art of original expression and innovative thinking, and Innovation is implementing it.
                                                <br />By fostering a culture of creativity, the organization can develop innovative solutions, drive growth, and maintain a competitive edge in the industry. The ability to generate new ideas and think outside the box leads to cutting-edge technology and a dynamic work environment that inspires and motivates employees. By embracing creativity, we can build a strong foundation for continued success.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 mb-25">
                                    <div className="core-value-box d-flex">
                                        <div className="bd-highlight align-self-center">
                                            <div className="core-value-img">
                                                <img className="img-fluid" src={require('../assets/images/value-ic2.svg').default} alt="value-ic2" />
                                            </div>
                                        </div>
                                        <div className="core-value-text align-self-center">
                                            <h2>
                                                Authenticity
                                            </h2>
                                            <p className="text-regular pt-10">
                                                Truth and accuracy<br />

                                                Authenticity is the quality of being genuine, true to oneself, and sincere.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 mb-25">
                                    <div className="core-value-box d-flex">
                                        <div className="bd-highlight align-self-center">
                                            <div className="core-value-img">
                                                <img className="img-fluid" src={require('../assets/images/value-ic3.svg').default} alt="value-ic3" />
                                            </div>
                                        </div>
                                        <div className="core-value-text align-self-center">
                                            <h2>
                                                Responsibility
                                            </h2>
                                            <p className="text-regular pt-10">
                                                Responsibility is the accountability for one's actions and duties.<br />At our organization, clear responsibilities are essential to realizing our vision. Every member of our team must take ownership of their role and fulfill it to the best of their ability every day. When we take responsibility for our tasks, we approach them with a focus on efficiency, seeking the most effective way to achieve our goals.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 mb-25">
                                    <div className="core-value-box d-flex">
                                        <div className="bd-highlight align-self-center">
                                            <div className="core-value-img">
                                                <img className="img-fluid" src={require('../assets/images/value-ic4.svg').default} alt="value-ic4" />
                                            </div>
                                        </div>
                                        <div className="core-value-text align-self-center">
                                            <h2>
                                                Professionalism
                                            </h2>
                                            <p className="text-regular pt-10">
                                                Professionalism is the conduct and attitude that reflects competence, ethics, and dedication in a particular profession or workplace<br />As a creative organizational team, we take full responsibility for the IT aspect of our partners' business operations. From communicating with partners and colleagues to implementing solutions, we must always act as professionals to deliver excellent results. Both internally and externally, we strive to maintain a high level of professionalism to ensure professional outcomes.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 mb-25">
                                    <div className="core-value-box d-flex">
                                        <div className="bd-highlight align-self-center">
                                            <div className="core-value-img">
                                                <img className="img-fluid" src={require('../assets/images/value-ic5.svg').default} alt="value-ic5" />
                                            </div>
                                        </div>
                                        <div className="core-value-text align-self-center">
                                            <h2>
                                                Curiosity
                                            </h2>
                                            <p className="text-regular pt-10">
                                                Curiosity is the innate desire to seek knowledge, explore, and discover.<br />Curiosity drives us to take on new challenges and push ourselves to our limits. It gives us the courage to venture into the unknown, learn from our mistakes, and emerge stronger as individuals and as an organizational team. This curiosity is essential to making the impossible into achievable and realizing our vision.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 mb-25">
                                    <div className="core-value-box d-flex">
                                        <div className="bd-highlight align-self-center">
                                            <div className="core-value-img">
                                                <img className="img-fluid" src={require('../assets/images/value-ic6.svg').default} alt="value-ic6" />
                                            </div>
                                        </div>
                                        <div className="core-value-text align-self-center">
                                            <h2>
                                                Integrity
                                            </h2>
                                            <p className="text-regular pt-10">
                                                Integrity is the adherence to moral and ethical principles, and the consistency between words and actions.<br />

                                                Integrity is the cornerstone of our relationships and obligations to our partners, team members, and end-users. We are dedicated to maintaining honesty, unity, and transparency in all our dealings, and, in the event that we cannot fulfill our commitments, we proactively communicate this to those involved. Our commitment to integrity reinforces our belief in keeping our promises and ensures that we uphold the trust placed in us.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* section 6 end*/}
                    {/* section 7 */}
                    <div className="out-story ">
                        <div className="ss-story  container-fluid p-0 pt-20 d-flex">
                            <Marquee speed={50} >
                                <span>
                                    Our story Our story  Our story
                                </span>
                                <span>
                                    Our story Our story  Our story
                                </span>
                                <span>
                                    Our story Our story  Our story
                                </span>
                                <span>
                                    Our story Our story  Our story
                                </span>
                            </Marquee>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 text-lg-center text-md-center mt-10 ">
                                    <div className="story-box">
                                        <h2>Moving Forward to Our Vision</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="abousus-timeline testimonials pb-120">
                            <div className="sticky-span" style={{ transform: transformValue }}>
                                {activeYear}
                            </div>
                            <div className="container ">
                                <div className="row">

                                    <div className="col-lg-12">

                                        <div className="timeline" onScroll={handleScroll}>
                                            <ul>
                                                <li data-year="2025">
                                                    <div className="story-box">
                                                        <h2>Our Story : 2025</h2>
                                                        <p className="text-regular">
                                                            we’re centering our efforts on health and fitness products by launching Healthx—a platform dedicated to providing both health and fitness services as well as related products.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li data-year="2024">
                                                    <div className="story-box">
                                                        <h2>Our Story : 2024</h2>
                                                        <p className="text-regular">
                                                            Launched first MVP of our software product called SuratFit on Google Play Store and app stores, currently enhancing the next version for millions of users.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li data-year="2023">
                                                    <div className="story-box">
                                                        <h2>In-House Product Development</h2>
                                                        <p className="text-regular">
                                                            Separate with German based partner, Commenced in-house product development, streamlined processes, and prioritized learning and training for continued growth.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li data-year="2022">
                                                    <div className="story-box">
                                                        <h2>Company process enhancement</h2>
                                                        <p className="text-regular">
                                                            Continue focus on Company operation and Processes improvement
                                                        </p>
                                                    </div>
                                                </li>
                                                <li data-year="2021">
                                                    <div className="story-box">
                                                        <h2>Company culture enhancement</h2>
                                                        <p className="text-regular">
                                                            Focusing on Culture, Company Process, Changed Company Policy and made it employee-friendly
                                                        </p>
                                                    </div>
                                                </li>
                                                <li data-year="2020">
                                                    <div className="story-box">
                                                        <h2>Collaboration</h2>
                                                        <p className="text-regular">
                                                            Did Collaboration with German-based Company and USA Based Company
                                                        </p>
                                                    </div>
                                                </li>
                                                <li data-year="2019">
                                                    <div className="story-box">
                                                        <h2>Adapted New technologies</h2>
                                                        <p className="text-regular">
                                                            Focusing on Web and backend technology with UI UX Services
                                                        </p>
                                                    </div>
                                                </li>
                                                <li data-year="2018">
                                                    <div className="story-box">
                                                        <h2>Become Pvt Ltd company</h2>
                                                        <p className="text-regular">
                                                            Become a Private Limited Company and continue services
                                                        </p>
                                                    </div>
                                                </li>
                                                <li data-year="2017">
                                                    <div className="story-box">
                                                        <h2>New Partnership</h2>
                                                        <p className="text-regular">
                                                            Separate Partnership and meet a new partner, and start working on Native Mobile app development
                                                        </p>
                                                    </div>
                                                </li>
                                                <li data-year="2016">
                                                    <div className="story-box">
                                                        <h2>Partnership</h2>
                                                        <p className="text-regular">
                                                            Started new office in surat, India with 2 partners
                                                        </p>
                                                    </div>
                                                </li>
                                                <li data-year="2015">
                                                    <div className="story-box">
                                                        <h2>Working towards vision</h2>
                                                        <p className="text-regular">
                                                            Continue work on achieving our Vision and Goals which we created
                                                        </p>
                                                    </div>
                                                </li>
                                                <li data-year="2014">
                                                    <div className="story-box">
                                                        <h2>Foundation</h2>
                                                        <p className="text-regular">
                                                            Founded IT company called Sensussoft
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>

                                        </div>
                                        <div className="col-lg-12 text-lg-center text-md-center mt-10 ">
                                            <div className="story-box">
                                                <h2>Born of Sensussoft</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    {/* section 7 end*/}
                </div>
            </main>
        </>
    )
}


