import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import Combenifits from '../../sliderdata/Combenifits.json';
import Applyjob from '../../components/Applyjob';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Axios from 'axios';
import PageNotFound from '../PageNotFound';

function Careerdetails() {
    const { department_id } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [careeeVacancies, setCareeevacancies] = useState([]);
    const [jobreleted, setreletedJob] = useState([]);
    const [metaData, setMetaData] = useState({
        head_title: '',
        meta_title: '',
        meta_desc: '',
        meta_tag: ''
    });
    useEffect(() => {
        // Fetch data from the API using Axios
        Axios.get(`https://api.sensussoft.com/api/career/${department_id}`)
            .then((response) => {
                // When the API call is successful, the data will be available in the 'response' object
                // console.log("API Response:", response.data);  // Extract the data for single vacancies and related jobs from the API response
                const careeesinglevacancies = response.data.data.singlevacancies[0] || {};
                const careeereletedJob = response.data.data.reletedJob || [];// Update the state variables with the fetched data
                const { head_title, meta_title, meta_desc, meta_tag } = response.data.data;
                setCareeevacancies(careeesinglevacancies);
                setreletedJob(careeereletedJob);// Set loading to false (data has been fetched successfully)
                setMetaData({ head_title, meta_title, meta_desc, meta_tag });
                setLoading(false);// Reset any previous errors (in case there was an error before)
                setError(null);
            })
            .catch((error) => {// If there is an error while fetching data, this block will be executed
                console.log(error);// Set loading to false (data fetching is complete, either success or failure)
                setLoading(false); // Set the error state to display an error message to the user
                setError("Error fetching data");
            });
    }, [department_id]);

    // Conditional rendering based on the loading status, error, and blog post details
    if (loading) {
        return <div className='blog-loader-main'>
            <div className='blog-loader'>
                <div className='loader-img'>
                    <Container>
                        <Row className='justify-content-center ptb-100 text-center'>
                            <Col lg={12}>
                                <div className='loading-content'>
                                    <div style={{ position: 'relative', width: '250px', height: '150px' }}>
                                        {/* SVG Markup */}
                                        <svg width="100%" height="100%" viewBox="0 0 187.3 93.7" preserveAspectRatio="xMidYMid meet" className="svg-animation">
                                            <path
                                                stroke="#001B50"
                                                id="outline"
                                                fill="none"
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeMiterlimit="10"
                                                d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                                            />
                                            <path
                                                id="outline-bg"
                                                opacity="0.05"
                                                fill="none"
                                                stroke="#001B50"
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeMiterlimit="10"
                                                d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                                            />
                                        </svg>

                                        {/* Custom text or content below the SVG */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container></div>
            </div>
        </div>
    }
    if (error) {
        return <p>{error}</p>; // Show error message if there's an error while fetching data
    }
    // Check if careeeVacancies is empty to show "Page Not Found"
    if (Object.keys(careeeVacancies).length === 0) {
        return <div>
            <PageNotFound></PageNotFound>
        </div>;
    }
    return (
        <div>
            <>
                <Helmet>
                    <title>{metaData.head_title}</title>
                    <meta name="title" content={metaData.meta_title} />
                    <meta name="description" content={metaData.meta_desc} />
                    <meta name="keywords" content={metaData.meta_tag} />
                </Helmet>
                {/* Job Search start  */}
                <main>
                    <div className='sens_details_of_subcarrer pt-100' style={{ backgroundImage: `url(${require('../../assets/images/careee-bg.svg').default})` }}>
                        <Container>
                            <Row>
                                <Col lg="8" md='8' className='align-self-center'>
                                    <div className='details_of_software'>
                                        <p className='yellow_text'> Job Search</p>
                                        <h1>{careeeVacancies.role}</h1>
                                        <p>{careeeVacancies.working_type} | {careeeVacancies.location} </p>
                                    </div>
                                </Col>
                                <Col lg="4" md='4' className="text-lg-end text-md-start text-start align-self-center">
                                    <div className="apply_modal_sens_btn">
                                        <button className="learn-more" aria-label='apply job'>
                                            <Applyjob title={careeeVacancies.role} image={require('../../assets/images/btn-arrow.svg').default} />
                                        </button>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className='about_sub_job_desc pb-100'>
                        <Container>
                            <div className="ss_job_details_about">
                                <Row>
                                    <Col lg="8" md="8">
                                        <div className='main_job_description'>
                                            <h2>Job Description</h2>
                                        </div>
                                        <div className='main_job_description_part2'>
                                            <div className='role_of_software'>
                                                <p className='sm-text-semibold'>Role | {careeeVacancies.role}<span></span></p>
                                                <p className='sm-text-semibold mx-2'>Experience |   <span>{careeeVacancies.experience} Years</span></p>
                                                <p className='sm-text-semibold'> Location | <span>{careeeVacancies.location}</span></p>
                                            </div>
                                            <div className='ss_sens_about_comdese'>

                                                {/* <div dangerouslySetInnerHTML={{ __html: careeeVacancies.skills }} /> */}
                                                <span>{careeeVacancies.tab1_title}</span>
                                                {careeeVacancies.tab1_desc[0].split('\r\n\r\n').map((paragraph, index) => (
                                                    <p key={index}>{paragraph}</p>
                                                ))}
                                                <span className='ptb-10'>{careeeVacancies.tab2_title}</span>
                                                {careeeVacancies.tab2_desc[0].split('\r\n\r\n').map((paragraph, index) => (
                                                    <p key={index}>{paragraph}</p>
                                                ))}
                                                <span className='ptb-10'>{careeeVacancies.tab3_title}</span>
                                                <div className='points_of_description'>
                                                    {careeeVacancies.tab3_desc.map((text, index) => (
                                                        <p key={index}>{text.split(',').join('\n')}</p>
                                                    ))}
                                                </div>
                                                <span className='ptb-10'>{careeeVacancies.tab4_title}</span>
                                                <div className='points_of_description'>
                                                    {careeeVacancies.tab4_desc.map((text, index) => (
                                                        <p key={index}>{text.split(',').join('\n')}</p>
                                                    ))}
                                                </div>
                                                <span className='ptb-10'>{careeeVacancies.tab5_title}</span>
                                                <div className='points_of_description'>
                                                    {careeeVacancies.tab5_desc.map((text, index) => (
                                                        <p key={index}>{text.split(',').join('\n')}</p>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className='apply_social_section d-flex ptb-50'>
                                                <div className="job_apply_btn align-self-center ">
                                                    <button className='learn-more arrow-btn-secondary' aria-label='apply job'>
                                                        <Applyjob title={careeeVacancies.role} image={require('../../assets/images/btn-arrow2.svg').default} />
                                                    </button>

                                                </div>
                                                <div className='social_apply_icon ms-auto align-self-center'>
                                                    <span className='sm-text-semibold'>Share opportunity here</span>
                                                    <Link to="https://www.facebook.com/Sensussoft/" aria-label='facebook'>
                                                        <i className="fa fa-facebook"></i>
                                                    </Link>
                                                    <Link to="https://twitter.com/sensussoft" aria-label='twitter'>
                                                        <i className="fa fa-twitter mx-1"></i>
                                                    </Link>
                                                    <Link to="https://www.linkedin.com/company/sensussoft" aria-label='linkedin'>
                                                        <i className="fa fa-linkedin"></i>
                                                    </Link>

                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="4" md="4">
                                        <div className='related_job_opninig'>
                                            <h3>Related Jobs</h3>
                                            {jobreleted.map(jobs => (
                                                <div key={jobs.id}>
                                                    <div className='related_job_opninig_s2'>
                                                        <h4>{jobs.role}</h4>
                                                        <p className='sm-text-semibold py-2'>
                                                            {jobs.location} | 2mo. Ago
                                                        </p>
                                                        <p><span>Design — About Sensussoft: we are global professional services company with...</span></p>
                                                        <div className="apply_dese_btn mt-15">
                                                            <Link to={`/careerdetails/${jobs.app_url}`} aria-label='career detail'>
                                                                <button className="learn-more arrow-btn" aria-label='career detail'>
                                                                    <span className="circle" aria-hidden="true">
                                                                        <span className="icon arrow">
                                                                            <img src={require('../../assets/images/btn-arrow2.svg').default} className="img-fluid" alt='img' />
                                                                        </span>
                                                                    </span>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    {/* <Join us in our journey To the Infinity start  */}
                    <div className='our_company_benifits ptb-80'>
                        <Container>
                            <Row >
                                <Col>
                                    <div className="our-vision-heading">
                                        <h2 className="lg-text-light ptb-15">Join us in our journey
                                            <br /><span>To the Infinity.</span></h2>
                                    </div>
                                </Col>
                            </Row>
                            <div className='ss_benififts_section mt-50'>
                                <Row className='g-0'>
                                    {
                                        Combenifits.map((item, i) => {
                                            const { id, benicon, benheading, description } = item;
                                            return (

                                                <Col lg="4" md="6" key={id}>
                                                    <div className='items' >
                                                        <div className='hiring_description_s1'>
                                                            <div className="our-hiring-igsub_s1 mr-15">
                                                                <div className="our-hiring-img_s1">
                                                                    <img className='img-fluid' src={require(`../../assets/images/${benicon}`)} alt='hiring1' />
                                                                </div>
                                                            </div>
                                                            <div className="our-hiring-text-dese_s1 align-self-center">
                                                                <h3 className=''>{benheading}</h3>
                                                                <p>{description}</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Col>


                                            )
                                        }
                                        )
                                    }
                                </Row>
                            </div>
                        </Container>
                    </div>
                </main>
                {/* <Join us in our journey To the Infinity end  */}
                {/* Job Search end  */}
            </>
        </div>
    )
}

export default Careerdetails
