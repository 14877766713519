import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Container, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function BlogSlider() {
  // blog API calling
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('https://api.sensussoft.com/api/blog?limit=6')
      .then(response => {
        if (response.data && response.data.success) {
          const blogData = response.data.data || []; // Handle empty or invalid data
          setBlogs(blogData);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching blogs:', error);
        setLoading(false);
      });
  }, []);
  const handleFocusManagement = () => {
    // Ensure aria-hidden slides are not focusable
    document.querySelectorAll('.slick-slide[aria-hidden="true"]').forEach(slide => {
      slide.setAttribute('tabindex', '-1');
      slide.querySelectorAll('a, button, input, select, textarea').forEach(elem => {
        elem.setAttribute('tabindex', '-1');
      });
    });
  };

  useEffect(() => {
    handleFocusManagement();
  }, [blogs]); // Reapply focus management when blogs change


  if (loading) {
    return (
      <div className='blog-loader-main'>
        <div className='blog-loader'>
          <div className='loader-img'>
            <Container>
              <Row className='justify-content-center ptb-100 text-center'>
                <Col lg={12}>
                  <div className='loading-content'>
                    <div style={{ position: 'relative', width: '250px', height: '150px' }}>
                      {/* SVG Markup */}
                      <svg width="100%" height="100%" viewBox="0 0 187.3 93.7" preserveAspectRatio="xMidYMid meet" className="svg-animation">
                        <path
                          stroke="#001B50"
                          id="outline"
                          fill="none"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                        />
                        <path
                          id="outline-bg"
                          opacity="0.05"
                          fill="none"
                          stroke="#001B50"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                        />
                      </svg>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }

  // Custom Arrow component for react-slick
  const CustomArrow = ({ direction, onClick }) => {
    const icon = direction === 'next' ? faAngleRight : faAngleLeft;

    return (
      <div
        className={`custom-arrow ${direction}`}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  };

  // Slider settings for react-slick
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    afterChange: handleFocusManagement,
    beforeChange: handleFocusManagement,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false, dots: true
        },
      },
    ],
  };

  // Reapply focus management when blogs change

  return (
    <div className="blog ptb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="testimonials-section blog-section">
              <div className="ss-heading">
                <h2>Latest<span> Blogs</span> </h2>
              </div>
              <div className="testimonials-box-main blog-slider pt-35 pb-0" >
                <Slider {...settings}>
                  {blogs.map(blog => (
                    <div key={blog.id} className="blog-box-s1">
                      <Link to={`/blog/${blog.slug}`} aria-label='blog'>
                        <div className="ss-blog-box-main d-flex flex-column ">
                          <div className="ss-blog-box-img">
                            <img className="img-fluid" src={blog.image} alt="blog1" />
                          </div>
                          <div className="ss-blog-box-title flex-grow-1">
                            <p className="mb-10 text-regular">
                              {blog.category_name}
                            </p>
                            <p>
                              <span
                                style={{
                                  color: "#003adc",
                                  fontSize: "16px",
                                  fontWeight: 400,
                                  lineHeight: "24px"
                                }}
                                className="text-regular ss-blog-title"
                              >
                                {blog.title}
                              </span>
                            </p>
                            <p className="blog-title-info text-regular pt-5">
                              {blog.preview_text}
                            </p>
                          </div>
                          <div className="ss-blog-author-main d-flex ">
                            <div className="bd-highlight align-self-center">
                              <div className="ss-author-img">
                                <img className="img-fluid" src={blog.author_profile} alt={blog.alt_text} />
                              </div>
                            </div>
                            <div className="ss-author-name align-self-center">
                              <p className="text-regular ss-blog-author">
                                {blog.author_name}
                              </p>
                              <p className="ss-blog-date text-regular">
                                {blog.published_date} <span> • {blog.reading_time} Min</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </Slider>

              </div>

              <div className="blog-btn mt-25 text-center">
                <Link to="/blog" aria-label='learn more'>
                  <button className="learn-more arrow-btn-secondary" aria-label='learn more'>
                    <span className="circle" aria-hidden="true">
                      <span className="icon arrow">
                        <img src={require('../assets/images/btn-arrow2.svg').default} className="img-fluid" alt='img' />
                      </span>
                    </span>
                    <span className="button-text ">Learn More</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
