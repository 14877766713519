import React, { useState } from 'react'
import { Container, Accordion, Row, Col, Tab } from 'react-bootstrap';

export default function WhyUsChoose() {
  const [activeTab, setActiveTab] = useState('accordion-1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  return (
    <div>
      <div className="main-functinality-main ptb-80">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="ss-heading proof-text">
                <h2>
                  <span>Why choose us
                  </span>
                </h2>
              </div>
            </Col>
          </Row>
          <Row className="pt-20 justify-content-between">
            <Col lg={5} className="align-self-center">
              <div className="main-functionality-accordion">
                <Accordion flush className="d-block nav nav-tabs" role="tablist" defaultActiveKey="accordion-1">
                  <Accordion.Item eventKey="accordion-1">
                    <Accordion.Header onClick={() => handleTabClick('accordion-1')}> AI-Driven Innovation & Expertise</Accordion.Header>
                    <Accordion.Body>
                      <div className="functionality-content-main">
                        <ul className="main-functionality-content">
                          <li>
                            <p className='light-heading'>We leverage cutting-edge AI technologies to optimize business processes, enhance decision-making, and provide scalable, intelligent solutions that drive success.                            </p>
                          </li>
                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="accordion-2">
                    <Accordion.Header onClick={() => handleTabClick('accordion-2')}>A Strong Foundation
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="functionality-content-main">
                        <ul className="main-functionality-content">
                          <li>
                            <p className='light-heading'>With years of experience and a solid technical backbone, we ensure reliability, security, and long-term sustainability in every solution we deliver.
                            </p>
                          </li>

                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="accordion-3">
                    <Accordion.Header onClick={() => handleTabClick('accordion-3')}> Mastering Challenges & Growing Together
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="functionality-content-main">
                        <ul className="main-functionality-content">

                          <li>
                            <p className='light-heading'>We believe in collaborative problem-solving, turning challenges into opportunities while growing alongside our clients to achieve mutual success.
                            </p>
                          </li>

                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="accordion-4">
                    <Accordion.Header onClick={() => handleTabClick('accordion-4')}> Full-Stack & AI-Powered Expertise
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="functionality-content-main">
                        <ul className="main-functionality-content">

                          <li>
                            <p className='light-heading'>From frontend to backend, our full-stack and AI-driven capabilities ensure high-performance, data-driven solutions tailored to your unique needs.
                            </p>
                          </li>

                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="accordion-5">
                    <Accordion.Header onClick={() => handleTabClick('accordion-5')}>Agile Development Puts You in the Driver’s Seat</Accordion.Header>
                    <Accordion.Body>
                      <div className="functionality-content-main">
                        <ul className="main-functionality-content">

                          <li>
                            <p className='light-heading'>We follow agile methodologies to ensure flexibility, rapid iterations, and client-driven development, delivering solutions that evolve with your business.
                            </p>
                          </li>

                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="accordion-6">
                    <Accordion.Header onClick={() => handleTabClick('accordion-6')}> We Value Client Partnerships Over New Sales
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="functionality-content-main">
                        <ul className="main-functionality-content">

                          <li>
                            <p className='light-heading'>Instead of chasing quick wins, we focus on building long-term relationships, ensuring continuous support, innovation, and growth for our clients.</p>
                          </li>

                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="accordion-7">
                    <Accordion.Header onClick={() => handleTabClick('accordion-7')}>  We Emphasize Employee & AI-Powered Growth
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="functionality-content-main">
                        <ul className="main-functionality-content">

                          <li>
                            <p className='light-heading'>Our team thrives in an environment of continuous learning and AI-driven advancements, ensuring we stay ahead in innovation, technology, and industry trends.
                            Let me know if you need any refinements!</p>
                          </li>

                        </ul>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>

            <Col lg={6} className="align-self-center">
              <div className="tab-content d-flex justify-content-center align-items-center" id="nav-tabContent">
                <div className={`tab-pane fade ${activeTab === 'accordion-1' ? 'show active' : ''}`} role="tabpanel">
                  <div className="functionality-images">
                    <img src={require('../assets/images/choose1.jpg')} className="img-fluid" alt='img' />
                  </div>
                </div>
                <div className={`tab-pane fade ${activeTab === 'accordion-2' ? 'show active' : ''}`} role="tabpanel">
                  <div className="functionality-images">
                    <img src={require('../assets/images/choose2.jpg')} className="img-fluid" alt='img' />
                  </div>
                </div>
                <div className={`tab-pane fade ${activeTab === 'accordion-3' ? 'show active' : ''}`} role="tabpanel">
                  <div className="functionality-images">
                    <img src={require('../assets/images/choose3.jpg')} className="img-fluid" alt='img' />
                  </div>
                </div>
                <div className={`tab-pane fade ${activeTab === 'accordion-4' ? 'show active' : ''}`} role="tabpanel">
                  <div className="functionality-images">
                    <img src={require('../assets/images/choose4.jpg')} className="img-fluid" alt='img' />
                  </div>
                </div>
                <div className={`tab-pane fade ${activeTab === 'accordion-5' ? 'show active' : ''}`} role="tabpanel">
                  <div className="functionality-images">
                    <img src={require('../assets/images/choose5.jpg')} className="img-fluid" alt='img' />
                  </div>
                </div>
                <div className={`tab-pane fade ${activeTab === 'accordion-6' ? 'show active' : ''}`} role="tabpanel">
                  <div className="functionality-images">
                    <img src={require('../assets/images/choose6.jpg')} className="img-fluid" alt='img' />
                  </div>
                </div>
                <div className={`tab-pane fade ${activeTab === 'accordion-7' ? 'show active' : ''}`} role="tabpanel">
                  <div className="functionality-images">
                    <img src={require('../assets/images/choose7.jpg')} className="img-fluid" alt='img' />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  )
}
