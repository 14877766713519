import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Col, Container, Form, Row, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import PageNotFound from '../PageNotFound';

export default function BlogDetails() {

  const { slug } = useParams();
  const [blogDetails, setBlogDetails] = useState(null);
  const [tagsDetails, setTagsDetails] = useState([]); // Initialize as an empty array
  const [commentDetails, setCommentDetails] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');
  const [prevBlog, setPrevBlog] = useState(null);
  const [nextBlog, setNextBlog] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);



  useEffect(() => {
    axios.get(`https://api.sensussoft.com/api/blog/${slug}`) // Use the slug from the URL to fetch blog details
      .then(response => {
        console.log('Blog Details:', response.data);
        // if (response.data && response.data.success) {
        const blogDetailsData = response.data.data.blogDetails[0] || {}; // Assuming the API response has blog details under the 'data' property
        const tagsDetailsData = response.data.data.tagsDetails || [];
        const commentsData = response.data.data.commentDetails || [];
        setPrevBlog(response.data.data.prevBlog);
        setNextBlog(response.data.data.nextBlog);
        setCommentDetails(commentsData);
        setBlogDetails(blogDetailsData);
        setTagsDetails(tagsDetailsData);
        setLoading(false); // Set loading to false when data is fetched successfully
        // }
      })
      .catch(error => {
        setLoading(false); // Set loading to false even if there's an error
        setError(true);
        // console.error('Error fetching blog details:', error);
        // navigate('/404');
      });
  }, [slug, navigate]);

  if (loading) {
    return <div className='blog-loader-main'>
      <div className='blog-loader'>
        <div className='loader-img'>
          <Container>
            <Row className='justify-content-center ptb-100 text-center'>
              <Col lg={12}>
                <div className='loading-content'>
                  <div style={{ position: 'relative', width: '250px', height: '150px' }}>
                    {/* SVG Markup */}
                    <svg width="100%" height="100%" viewBox="0 0 187.3 93.7" preserveAspectRatio="xMidYMid meet" className="svg-animation">
                      <path
                        stroke="#001B50"
                        id="outline"
                        fill="none"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                      />
                      <path
                        id="outline-bg"
                        opacity="0.05"
                        fill="none"
                        stroke="#001B50"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                      />
                    </svg>

                    {/* Custom text or content below the SVG */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container></div>
      </div>
    </div>
  }

  if (error || !blogDetails) {
    return <PageNotFound />;
  }


  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Assuming you have a post API endpoint for submitting comments, adjust the URL accordingly.
    axios.post('https://api.sensussoft.com/api/blogcomment', {
      blog_id: blogDetails.id, // Assuming you have the blog post ID in blogDetails
      name,
      email,
      comment,
    })
      .then(response => {
        // After submitting the comment, you may want to update the commentDetails state with the newly submitted comment as well.
        setCommentDetails(prevComments => [...prevComments, response.data.data]);

        setName('');
        setEmail('');
        setComment('');
      })
      .catch(error => {
        console.error('Error submitting comment:', error);
      });
  };



  // add img tag near  img-fluid class
  const processHTMLContent = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    // Add img-fluid class to all <img> tags
    doc.querySelectorAll('img').forEach(img => {
      img.classList.add('img-fluid');
    });

    // Return updated HTML content
    return doc.body.innerHTML;
  };

  return (
    <main>

      <div>
        <Helmet>
          <title>{blogDetails.head_title}</title>
          <meta name="title" content={blogDetails.meta_title} />
          <meta name="keywords" content={blogDetails.meta_tag} />
          <meta name="description" content={blogDetails.meta_content} />
        </Helmet>
        <div className='blog_sub_mainhead ptb-80'>
          <Container>
            <Row className='next-prev-btn'>
              <Col lg={6} className='col-6'>
                {prevBlog && (
                  <div className="blog-next-btn">
                    <Link to={`/blog/${prevBlog}`} aria-label='previous blog'>

                      <button className="learn-more arrow-btn" aria-label='previous blog'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>

                    </Link>
                  </div>
                )}
              </Col>
              <Col lg={6} className='col-6'>
                <div className='text-end'>
                  {nextBlog && (
                    <Link to={`/blog/${nextBlog}`} aria-label='next blog'>

                      <button className="learn-more arrow-btn" aria-label='next blog'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>

                    </Link>
                  )}
                </div>
              </Col>
            </Row>
            <Row className='justify-content-between '>

              <Col xl='8' lg='8'>
                <div className='Blog_heading_section pt-40'>
                  <h6>  Posted on  {blogDetails.published_date} <span> | </span>  {blogDetails.views} Views  <span> | </span>    {blogDetails.reading_time} Minutes read</h6>
                  <div className="ss-heading proof-text"><h1 style={{ whiteSpace: 'pre-line' }}><span>{blogDetails.title} </span></h1></div>
                  <div className='main_subblog_details d-flex '>

                    <div className="bd-highlight align-self-center me-1">
                      <div className="ss-author-img">
                        <img className='img-fluid' src={blogDetails.author_profile} alt={blogDetails.alt_text}></img>                   </div>
                    </div>
                    <div className="ss-author-name align-self-center">
                      <p className="text-regular ss-blog-author">
                        Author
                      </p>
                      <p className="ss-blog-date text-regular">
                        {blogDetails.author_name}
                      </p>

                    </div>


                  </div>
                </div>
              </Col>
              <Col xl='4' lg='4' className='align-self-center text-lg-end  mt-lg-0 mt-md-4 mt-3 '>
                <div className='blog_social_icon'>
                  <div className='social_apply_icon ms-auto align-self-center'>
                    <Link to="https://www.facebook.com/Sensussoft/" target='_blank' aria-label='facebook'>
                      <i className="fa fa-facebook"></i>
                    </Link>
                    <Link to="https://twitter.com/sensussoft" target='_blank' aria-label='twitter'>
                      <i className="fa fa-twitter"></i>
                    </Link>
                    <Link to="https://www.linkedin.com/company/sensussoft" target='_blank' aria-label='linkedin'>
                      <i className="fa fa-linkedin"></i>
                    </Link>
                    <Link to="https://www.instagram.com/sensussoft/" target='_blank' aria-label='instagram'>
                      <i className="fa fa-instagram"></i>
                    </Link>
                    <Link to="https://www.behance.net/Sensussoft-Software" target='_blank' aria-label='behance'>
                      <i className="fa fa-behance"></i>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className='blog_insider_pages ptb-80'>
          <Container>
            <Row>
              <Col lg={9}>
                <div className='blog_comman_bg_details' >
                  <div className='blog_wrapper_alldet'>
                    <div className='blog_wise_add_images'>
                      {/* <img className="img-fluid" src={blogDetails.image} alt={blogDetails.alt_text} /> */}
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: processHTMLContent(blogDetails.description) }} />
                  </div>

                </div>
                <div className='blog_comments_query mt-20'>
                  <Col>
                    <h5>comment</h5>

                    {commentDetails.map(comment => (
                      <div className="comment_box" key={comment.id}>
                        <div className="about_ceo_box d-flex sub_mobile-block">
                          <div className="sens_image align-self-center">
                            <div className="ceo_of_image">
                              <img className="img-fluid" src={require('../../assets/images/bloguser.svg').default} alt="vinod" />
                            </div>
                          </div>
                          <div>


                          </div>
                          <div className="info_of_ceo align-self-center">
                            <h6> {comment.name} <span>{comment.created_at} </span></h6>
                            <p className='text-regular'>
                              {comment.comment}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}

                  </Col>


                  <div className='comment_box_form mt-20'>
                    <h5>Write A Comment</h5>
                  </div>
                  <div className='comment_box_form mt-20'>
                    <Form onSubmit={handleFormSubmit}>
                      <Row className='mt-20'>

                        <Col lg='6' md='6'>
                          <div className="custom-input mb-20 ">
                            <Form.Group controlId="validationCustom01">
                              <Form.Control type="text" required placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                            </Form.Group>
                          </div>
                        </Col>
                        <Col lg='6' md='6'>
                          <div className="custom-input mb-20 ">
                            <Form.Group controlId="validationCustom02">
                              <Form.Control type="email" required placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>
                          </div>
                        </Col>
                        <Col lg='12'>
                          <div className="custom-input mb-20 ">
                            <Form.Group controlId="validationCustom03">
                              <Form.Control type="text" required as="textarea" placeholder="Your Message" value={comment} onChange={(e) => setComment(e.target.value)} />
                            </Form.Group>
                          </div>
                        </Col>
                        <Col lg="12">
                          <div className="submit_cancel_btn  mt-20">

                            <Button type="submit" className="btn-wrapper btn-wrapper-blue" aria-label='submit'>
                              Submit
                            </Button>

                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
              <Col lg={3}>
                <div className='sub_detaiils_blog_sidepanal blogdetails_page_b1'>

                  <div>
                    <div className='active_of_table'>
                      <h6 className='mb-20'>Recommended Topic</h6>
                    </div>
                    <div className='diff_diff_tecnology'>
                      <div className="tag-cloud">
                        <ul>
                          {tagsDetails.map((tag, index) => (
                            <li key={index}>
                              <Link to={`/blog?tag=${(tag)}`} aria-label='tages'>{tag}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className='blog_social_icon pt-30'>
                      <div className='social_apply_icon ms-auto align-self-center'>
                        <Link to="https://www.facebook.com/Sensussoft/" target='_blank' aria-label='facebook'>
                          <i className="fa fa-facebook"></i>
                        </Link>
                        <Link to="https://twitter.com/sensussoft" target='_blank' aria-label='twitter'>
                          <i className="fa fa-twitter"></i>
                        </Link>
                        <Link to="https://www.linkedin.com/company/sensussoft" target='_blank' aria-label='linkedin' >
                          <i className="fa fa-linkedin"></i>
                        </Link>
                        <Link to="https://www.instagram.com/sensussoft/" target='_blank' aria-label='instagram'>
                          <i className="fa fa-instagram"></i>
                        </Link>
                        <Link to="https://www.behance.net/Sensussoft-Software" target='_blank' aria-label='behance'>
                          <i className="fa fa-behance"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </main>
  )
}
