
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Col, Container, Row, Stack, Tab, Nav } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Requirementjiob() {
  const { department_id } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [jobbackend, setBackendjob] = useState([]);
  const [countData, setCountData] = useState({});

  useEffect(() => {

    // Make an API request with or without department_id based on the URL parameter
    axios.get(`https://api.sensussoft.com/api/career`)
      .then(response => {
        // const careerbackend = response.data.data.allvacancies || [];
        // console.log("API Response:", response.data); // Log the API response

        setBackendjob(response.data.data.allvacancies || []);
        setCountData({
          totalVacancies: response.data.data.totalVacancies || 0,
          developementCount: response.data.data.developementCount || 0,
          designCount: response.data.data.designCount || 0,
          managementCount: response.data.data.managementCount || 0,
          mobile_engineeringCount: response.data.data.mobile_engineeringCount || 0,
          front_endCount: response.data.data.front_endCount || 0,
          buissness_strategyCount: response.data.data.buissness_strategyCount || 0,
          qaCount: response.data.data.qaCount || 0,
          project_managementCount: response.data.data.project_managementCount || 0
        });
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching blogs:', error);
        setLoading(false);
        setError("Error fetching data");
      });
  }, [department_id]);

  const departmentTabs = [
    { eventKey: "first", label: "All", jobs: jobbackend, count: countData.totalVacancies },
    { eventKey: "second", label: "Web Developement", jobs: jobbackend.filter(jobs => jobs.department_id === 1), count: countData.developementCount },
    { eventKey: "third", label: "UI/UX Designer", jobs: jobbackend.filter(jobs => jobs.department_id === 2), count: countData.designCount },
    { eventKey: "fourth", label: "Human Resources", jobs: jobbackend.filter(jobs => jobs.department_id === 3), count: countData.managementCount },
    { eventKey: "fifth", label: "Mobile Development", jobs: jobbackend.filter(jobs => jobs.department_id === 4), count: countData.mobile_engineeringCount },
    { eventKey: "sixth", label: "Frontend Developer", jobs: jobbackend.filter(jobs => jobs.department_id === 5), count: countData.front_endCount },
    { eventKey: "seventh", label: "Business Development", jobs: jobbackend.filter(jobs => jobs.department_id === 6), count: countData.buissness_strategyCount },
    { eventKey: "Eighth", label: "Quality Assurance", jobs: jobbackend.filter(jobs => jobs.department_id === 7), count: countData.qaCount },
    { eventKey: "ninth", label: "Project Management", jobs: jobbackend.filter(jobs => jobs.department_id === 8), count: countData.project_managementCount },
  ];

  if (loading) {
    return (
      <div className='blog-loader-main'>
        <div className='blog-loader'>
          <div className='loader-img'>
            <Container>
              <Row className='justify-content-center ptb-100 text-center'>
                <Col lg={12}>
                  <div className='loading-content'>
                    <div style={{ position: 'relative', width: '250px', height: '150px' }}>
                      {/* SVG Markup */}
                      <svg width="100%" height="100%" viewBox="0 0 187.3 93.7" preserveAspectRatio="xMidYMid meet" className="svg-animation">
                        <path
                          stroke="#001B50"
                          id="outline"
                          fill="none"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                        />
                        <path
                          id="outline-bg"
                          opacity="0.05"
                          fill="none"
                          stroke="#001B50"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                        />
                      </svg>

                      {/* Custom text or content below the SVG */}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container></div>
        </div>
      </div>
    )
  }
  if (error) {
    return <p>{error}</p>; // Show error message if there's an error while fetching data
  }


  return (
    <>
      <div className='job_opninig_ourcom ptb-80'>
        <Container>
          <Row>
            <Col lg="12">
              <div className="nurture-creativity-title pb-20">
                <div className="our-vision-text-main d-flex">
                  <div className="bd-highlight vision-img-main">
                    <div className="our-vision-img">
                      <img className="img-fluid" src={require('../assets/images/vision-ic.svg').default} alt="vision-ic"></img>
                    </div>
                  </div>
                  <div className="our-vision-text">
                    <p>
                      Job Openings
                    </p>
                  </div>
                </div>
                <div className="our-vision-heading">
                  <h2 className="lg-text-light ptb-15">
                    Join with us to build the future.<br />
                    We are <span>waiting for you.</span>
                  </h2>

                </div>
              </div>
            </Col>
            <Col lg="12">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col lg="4" xl="3" md="4" sm={3}>
                    <div className='department_of_job'>
                      <Nav variant="pills" className="flex-column">
                        {departmentTabs.map((tab, index) => (
                          <Nav.Item key={index}>
                            <Nav.Link aria-label='total count' eventKey={tab.eventKey}>{tab.label}<span>{tab.count}</span></Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </div>
                  </Col>
                  <Col lg="8" xl="9" md="8" sm={9}>
                    <div className='job_description_com'>
                      <Tab.Content className='comman_tab_description' id='el'>
                        {departmentTabs.map((department, index) => (
                          <Tab.Pane key={index} eventKey={department.eventKey}>
                            {department.jobs.length === 0 ? (
                              <h5 className='not_open_position' style={{ textAlign: 'center' }}>Currently, we don't have any open positions.</h5>
                            ) : (
                              department.jobs.map(jobs => (
                                <div className='sub_details_of_job' key={jobs.id}>
                                  <h3>{jobs.role}</h3>
                                  <Stack gap={3} direction='horizontal' className='mt-10'>
                                    <div className="content_job_style flex-fill">
                                      <p>Number of Positions</p>
                                      <p>{jobs.position}</p>
                                    </div>
                                    <div className="content_job_style flex-fill">
                                      <p>Experience </p>
                                      <p> {jobs.experience}</p>
                                    </div>
                                    <div className="job_details_btn flex-fill text-end">
                                      <Link aria-label='careerdetail' to={`/careerdetails/${jobs.app_url}`}>
                                        <div className="apply_dese_btn mt-15">
                                          <button className="learn-more arrow-btn" aria-label='career detail'>
                                            <span className="circle" aria-hidden="true">
                                              <span className="icon arrow">
                                                <img src={require('../assets/images/btn-arrow2.svg').default} className="img-fluid" alt='img' />
                                              </span>
                                            </span>
                                          </button>
                                        </div>
                                      </Link>
                                    </div>
                                  </Stack>
                                </div>
                              ))
                            )}
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </div>
                  </Col>
                </Row>



                <div className="HR_contact" >

                  <div className="d-flex bd-highlight d-mobile-block">
                    <div className="p-2 flex-grow-1 bd-highlight align-self-center"><h4>HR Contact</h4></div>
                    <div className="p-2 bd-highlight align-self-center">
                      <Link to="tel:+91-722-782-9705" aria-label='phone number'><p className="mb-0 text-regular">+91-722-782-9705</p></Link>
                    </div>
                    <div className="p-2 bd-highlight align-self-center contact-line2">
                      <p>|</p>
                    </div>
                    <div className="p-2 bd-highlight align-self-center">
                      <Link to="mailto:hr@sensussoft.com" aria-label='mail to hr'><p className="text-regular mb-0">hr@sensussoft.com</p></Link>
                    </div>
                  </div>
                </div>




              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )


}

export default Requirementjiob
