import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import CookieService from './CookieService';
function Cookies() {
    const [consent, setConsent] = useState({
        necessary: true,
        analytics: true,
        preferences: true,
        marketing: true,
        statistical: true,
    });
    const [showBanner, setShowBanner] = useState(true);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const location = useLocation();
    useEffect(() => {
        const savedConsent = JSON.parse(localStorage.getItem('consentMode'));
        const bannerClosed = localStorage.getItem('bannerClosed');
        if (savedConsent || bannerClosed === 'true') {
            setShowBanner(false);
        }
        const statisticalCookiesValue = CookieService.get('statisticalCookies');
        const marketingCookiesValue = CookieService.get('marketingCookies');
        if (statisticalCookiesValue !== undefined) {
            setConsent(prevConsent => ({
                ...prevConsent,
                statistical: statisticalCookiesValue === '1'
            }));
        }
        if (marketingCookiesValue !== undefined) {
            setConsent(prevConsent => ({
                ...prevConsent,
                marketing: marketingCookiesValue === '1'
            }));
        }
        // Load Google Analytics script based on initial consent
        if (savedConsent && savedConsent.analytics) {
            loadGoogleAnalytics();
        }
    }, []);
    useEffect(() => {
        // Trigger banner on route change
        const savedConsent = JSON.parse(localStorage.getItem('consentMode'));
        const bannerClosed = localStorage.getItem('bannerClosed');
        if (!savedConsent && bannerClosed !== 'true') {
            setShowBanner(true);
        }
    }, [location]);
    const updateConsent = (newConsent) => {
        setConsent(newConsent);
        const consentMode = {
            functionality_storage: newConsent.necessary ? 'granted' : 'denied',
            security_storage: newConsent.necessary ? 'granted' : 'denied',
            ad_storage: newConsent.marketing ? 'granted' : 'denied',
            analytics_storage: newConsent.analytics ? 'granted' : 'denied',
            personalization_storage: newConsent.preferences ? 'granted' : 'denied',
            statistical_storage: newConsent.statistical ? 'granted' : 'denied',
        };
        if (newConsent.analytics) {
            loadGoogleAnalytics();
        } else {
            disableGoogleAnalytics();
        }
        if (typeof window.gtag === 'function') {
            window.gtag('consent', 'update', consentMode);
        } else {
            console.warn('gtag is not defined');
        }
        localStorage.setItem('consentMode', JSON.stringify(consentMode));
        setShowBanner(false);
        localStorage.setItem('bannerClosed', 'true');
        CookieService.set('statisticalCookies', newConsent.statistical ? '1' : '0');
        CookieService.set('marketingCookies', newConsent.marketing ? '1' : '0');
    };
    const loadGoogleAnalytics = () => {
        if (!window.ga) {
            const gaScript = document.createElement('script');
            gaScript.type = 'text/javascript';
            gaScript.async = true;
            gaScript.src = 'https://www.google-analytics.com/analytics.js'; // Adjust URL as needed
            document.head.appendChild(gaScript);
            gaScript.onload = () => {
                window.ga('create', 'G-WYLF1PBPR5', 'auto');
                window.ga('send', 'pageview');
            };
        }
    };
    const disableGoogleAnalytics = () => {
        window['ga-disable-G-WYLF1PBPR5'] = true;
        localStorage.removeItem('_ga'); // Optional: Clear GA client ID if stored
    };
    const acceptAll = () => {
        const newConsent = {
            necessary: true,
            analytics: true,
            preferences: true,
            marketing: true,
            statistical: true,
        };
        updateConsent(newConsent);
        setShowSettingsModal(false);
    };
    const declineAll = () => {
        const newConsent = {
            necessary: false,
            analytics: false,
            preferences: false,
            marketing: false,
            statistical: false,
        };
        updateConsent(newConsent);
        setShowSettingsModal(false);
    };
    const handleSaveSettings = () => {
        updateConsent(consent);
        setShowSettingsModal(false);
    };
    const toggleConsent = (key) => {
        setConsent(prevConsent => ({
            ...prevConsent,
            [key]: !prevConsent[key]
        }));
    };
    return (
        <aside className="cookie-info" aria-label="Cookie Consent Information">

            <div className='cookiemodal-main'>
                {showBanner && (
                    <div className='close-btn-banner'>
                        <div className='cookie-consent-banner'>
                            <div className='close-icon text-end'>
                                <Button variant="" onClick={() => { setShowBanner(false); localStorage.setItem('bannerClosed', 'false'); }}>
                                    <img className='img-fluid' src={require('../assets/images/closeblack.svg').default} alt="Close" />
                                </Button>
                            </div>

                            <div className="cookie-consent-banner-main d-flex align-items-center">
                                <div className='cookie-info'>
                                    <p>
                                        By clicking 'Accept All Cookies,' you consent to storing cookies for site navigation, usage analysis, and marketing.
                                    </p>
                                </div>
                                <div className='cookies-button'>
                                    <Button className="modalbtn" onClick={() => setShowSettingsModal(true)}>Settings</Button>
                                    <Button variant="danger" onClick={declineAll}>Reject</Button>
                                    <Button variant="success" onClick={acceptAll}>Accept</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Modal show={showSettingsModal} className='cookie-setting-modal' onHide={() => setShowSettingsModal(false)} centered size='lg' style={{ zIndex: 99999 }}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className='cookie-modal-main text-start'>
                            <div className='cookie-modal'>
                                <h2>Set Up Your Cookies</h2>
                                <p className='pb-10'>
                                    Necessary cookies help make the website user-friendly by allowing basic tasks like navigating pages and accessing secure parts. Without these cookies, the website just wouldn't work right.
                                </p>
                                <div className='cookies-set ptb-10'>
                                    <div className='statistical-cookie-main d-flex justify-content-between align-items-center mb-10'>
                                        <div className='statistical-title'>
                                            <h3>Statistical Cookies</h3>
                                        </div>
                                        <div className='switch-custom'>
                                            <input type="checkbox" className="toggle" id="toggle-statistical" checked={consent.statistical} onChange={() => toggleConsent('statistical')} />
                                            <label htmlFor="toggle-statistical">
                                                <span className="on">Yes</span>
                                                <span className="off">No</span>
                                            </label>
                                        </div>
                                    </div>
                                    <p className='text-start'>
                                        Statistical cookies help us understand how visitors interact with websites by collecting and reporting information anonymously. You can opt-out if you prefer.
                                    </p>
                                </div>
                                <div className='cookies-set ptb-10'>
                                    <div className='statistical-cookie-main d-flex justify-content-between align-items-center mb-10'>
                                        <div className='statistical-title'>
                                            <h3>Marketing Cookies</h3>
                                        </div>
                                        <div className='switch-custom'>
                                            <input type="checkbox" className="toggle" id="toggle-marketing" checked={consent.marketing} onChange={() => toggleConsent('marketing')} />
                                            <label htmlFor="toggle-marketing">
                                                <span className="on">Yes</span>
                                                <span className="off">No</span>
                                            </label>
                                        </div>
                                    </div>
                                    <p className='text-start'>
                                        Our marketing partners, such as Google, Facebook, and LinkedIn, place marketing cookies on our website. These cookies help create a profile of your interests to display relevant advertisements on other websites. They uniquely recognize your browser and device. If you reject these cookies, you will not receive our targeted ads on other websites.
                                    </p>
                                </div>
                            </div>
                            <div className='cookies-buttons-main d-flex justify-content-between align-items-center pt-20'>
                                <div className='privacy-policy-link'>
                                    <Link to="/privacy-policy">Privacy Policy</Link>
                                </div>
                                <div className='cookies-button '>
                                    <Button variant="danger" onClick={declineAll}>Decline</Button>
                                    <Button variant="success" onClick={acceptAll}>Accept</Button>
                                    <Button className="modalbtn" onClick={handleSaveSettings}>Save Settings</Button>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </aside>
    );
}
export default Cookies;












