import React from 'react'
import { Col, Container, Row, Stack } from 'react-bootstrap'
import Portfolio from '../../components/Portfolio';
import Faq from '../../components/Faq';
import Nav from 'react-bootstrap/Nav';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import ScheduleCall from '../../components/ScheduleCall';
import { Helmet } from 'react-helmet-async';
export default function QualityAssurance() {
  return (
    <>
      <Helmet>
        <title>Quality Assurance Services: Ensuring Excellence at Sensussoft</title>
        <meta name="title" content="Quality Assurance Services: Ensuring Excellence at Sensussoft" />
        <meta name="keywords" content="Quality Assurance Services, QA Services, Software Testing, Quality Control, Sensussoft QA Solutions" />

        <meta name="description" content="Ensure top-notch software quality with Sensussoft's comprehensive quality assurance services. From mobile app testing to web application verification, we guarantee high performance, security, and user satisfaction through rigorous testing methodologies and industry-leading tools." />
      </Helmet>
      <main>
        <div className="quality-assurance-main">
          {/* section 1 */}

          <div className="quality-assurance website-development ptb-80 bg-logo">
            <Container>
              <Row className='pb-80 justify-content-end'>
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className=" service-btn-mobile  next-prev-btn comm-btn ">
                    <Link aria-label='productideation' to='/ui-ux-design'>
                      <button className="service-previous-btn learn-more arrow-btn " aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>
                <Col xs={6} sm={6} lg={6} className="align-self-center">
                  <div className="service-btn-mobile next-prev-btn comm-btn text-end ">
                    <Link aria-label='mobileappdevelopment' to='/maintenance-support'>
                      <button className="learn-more arrow-btn" aria-label='learn more'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                      </button>
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={6} className="align-self-center">
                  <div className="website-development-text proof-text-main">
                    <div className="ss-heading proof-text">
                      <h1><span>Quality Assurance</span></h1>
                      <p className="text-regular mt-20">
                        We love finding bugs because it improves the quality of the software, which results in the green light for the release. Ramp up your project with high speed. Get the right team to execute and accomplish your goals. To deliver a robust, high-performance app, we handle front-end and back-end development, along with continual testing.                   </p>
                      <Link to='/get-in-touch-with-us' aria-label='contact us for detail'>
                        <button className="learn-more btn-large mt-25" aria-label='contact us for detail'>
                          <span className="circle" aria-hidden="true">
                            <span className="icon arrow">
                              <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                            </span>
                          </span>
                          <span className="button-text">CONTACT US FOR DETAILS</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="align-self-center text-center">
                  <div className="change_vision_img"><img className="img-fluid" src={require('../../assets/images/serviceimg5.png')} alt='service1' /></div>

                  <div className="website-development-img-main">
                    <div className="website-development-img mt-sm-20 mt-md-20">
                      <img className="img-fluid" src={require('../../assets/images/quality-assurance.jpg')} alt="quality-assurance"></img>
                    </div>
                    <div className="web-build-box">
                      <div className="like-round">
                        <img className="img-fluid " src={require('../../assets/images/blub.svg').default} alt="blub">
                        </img>
                      </div>
                      <div className="build-text">
                        <h2>
                          565+
                        </h2>
                        <p className="text-regular">
                          Quality <br />Assurance
                        </p>
                      </div>
                    </div>
                    <div className="web-build-box2">
                      <p>
                        Your Software, Our Commitment to <br />Quality.
                      </p>
                      <div className="line">
                        <img className="img-fluid" src={require('../../assets/images/linefill.png')} alt="line"></img><br />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* section 1 end*/}
          {/* section 2 */}
          <div className="quality-products ptb-80">
            <Container>
              <Row>
                <Col lg={6} className="align-self-center">
                  <div className="quality-img text-md-center">
                    <img src={require('../../assets/images/rating-img.jpg')} className="img-fluid" alt='rating-img' />
                    <div className="quality-box">
                      <div className="quality-img">
                        <img src={require('../../assets/images/review.jpg')} className="img-fluid" alt='rating-img' />
                      </div>
                      <h3 className="mt-2">
                        185+ Customer Review
                      </h3>
                    </div>
                  </div>
                </Col>
                <Col lg={6} className="align-self-center">
                  <div className="quality-title">
                    <div className="ss-heading ss-portfolio-text">
                      <h2 className="ptb-15">
                        Why Sensussoft Is Your <br /> <span >Smartest Choice</span> For <br /> Quality Products?
                      </h2>
                      <p className="text-regular">
                        Our analysts work with clients to identify, address, and prevent software quality issues through observational, compatibility, and performance testing, as well as automation engineering and continuous integration with End-To-End software testing. We also offer an independent software quality assessment and a managed services suite aimed at managing costs, reducing time-to-market, and enhancing testing applicability.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 2 end*/}
          {/* section 3 */}
          <div className="idea-and-enhance bg-service ">
            <Container>
              <Row>
                <Col lg={6}>
                  <div className="ideation-services">
                    <div className="services-in-action-main mb-md-20">
                      <div className="services-in-action pt-100 pb-200">
                        <div className="our-vision-text-main d-flex">
                          <div className="bd-highlight vision-img-main">
                            <div className="our-vision-img overview-img">
                              <img className="img-fluid" src={require('../../assets/images/overview.svg').default} alt="vision-ic"></img>
                            </div>
                          </div>
                          <div className="our-vision-text">
                            <p>
                              Software Quality
                            </p>
                          </div>
                        </div>
                        <div className="ss-heading ss-portfolio-text">
                          <h2 className="ptb-15">
                            How Do We Ensure Your <br /> <span>Software Quality?</span>
                          </h2>
                          <p className="text-regular">
                            How can mobile apps be highly responsive? Is your website mobile-friendly? Let's take a look at how we define software quality.
                            We make your mobile app, website or web app more secure and responsive so that your users love it. Our end-to-end software testing saves you money, time, and improves customer satisfaction! We will make sure your software solution is just what you ordered (or better yet, what your customers wanted)!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={12} className='align-self-center'>
                  <div className="ideation-content ptb-100">
                    <div className="enhance-content-main " >
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Mobile App Testing</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          We provide functional, non-functional and Automation mobile application testing for a wide range of industries, including travel, banking, retail, health & fitness, entertainment etc.
                        </p>
                        <p className="pt-20 light-heading pt-15">
                          The following are some of the benefits our Partners derive from mobile apps testing:
                        </p>
                        <div className="cms-needed pt-40">
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                The App Testing process is accelerated with a multitude of tools.
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                We produce efficient results at a low cost with modern App Testing facility.                            </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                The highest possible level of quality, user experience, performance, quality, and functionality are improved.                     </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Quick support is available and mobile app is delivered on time.
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                Assists in detecting bugs and discrepancies and resolving them.
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                We partner with you throughout the Android and iOS app development process.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="enhance-content-main">
                      <div className="enhance-content">
                        <h3 className="text-regular">
                          <span className="lg-text-semibold">Web Application & Website Testing</span>
                        </h3>
                        <p className="pt-20 light-heading">
                          Our website and web app testing services cover various domains ranging from customized, e-commerce, an admin server, or an education website backend. We promise to provide you with high qualified software solution features.  </p>
                        <div className="cms-needed pt-40">
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Accelerated Testing: Our highly qualified testing team ensures that the necessary test cases are executed and no errors are repeated multiple times.
                              </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>

                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                Commercial Frameworks: To save you money and ensure seamless testing, we provide premium corporate frameworks, tools, resources, and services.                      </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text ">
                              <p className="light-heading">
                                Customized Web Application Testing: We create testing methods tailored to your specific needs and business goals.                    </p>
                            </div>
                          </div>
                          <div className="right-box d-flex mb-20">
                            <div className="bd-highlight ">
                              <div className="right-img">
                                <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                              </div>
                            </div>
                            <div className="right-text">
                              <p className="light-heading">
                                Risk Mitigation: Depending on the type of web testing, we have a competent QA team who will mitigate and assess risks.
                              </p>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>


                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* secion 3 end */}
          {/* section 4 */}
          <div className="attractive-main ptb-120">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="attractive-box">
                    <Stack direction="horizontal" className="d-mobile-block">
                      <div className="attractive-text flex-grow-1">
                        <h3 className="ss-title">
                          <span className="text-regular">Ready To</span>  Launch Your App?  <span className="text-regular">Did You  <br />Test It Before Launching?</span>

                        </h3>
                      </div>
                      <div className="attractive-btn">
                        <ScheduleCall></ScheduleCall>
                      </div>
                    </Stack>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 4 end*/}
          {/* section 5 */}
          <div className="process-main ptb-80">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      Our Software <span>Testing Life Cycle</span> (STLC)

                    </h2>
                    <p className="pt-10 text-regular">
                      Our software testing cycle's purpose is to structure test cases into repeatable cycles. There are various cycles for testing individual features of an app, key components of the app, website, or web app, and the entire product.
                      <br /><br />
                      Our QA team ensures the quality of a software release is 100% satisfied. A series of tests must be run whenever a new deployment is needed. Testing cycle management must be carried out frequently to ensure the cycle is up to date.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="pt-50">
                <Col lg={12}>
                  <div className="ss-process-tab">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                      <Row className="justify-content-between">
                        <Col lg={4} md={12}>
                          <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                              <Nav.Link eventKey="first" aria-label='Requirement Analysis'>1. Requirement Analysis<span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="second" aria-label='Test Planning'>2.Test Planning<span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="third" aria-label='Test Case Design & Development'>3. Test Case Design & Development<span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="fourth" aria-label='Test Environment Setup'>4. Test Environment Setup <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="fifth" aria-label='Test Execution'>5. Test Execution <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="sixth" aria-label='Test Cycle Closure'>6. Test Cycle Closure <span><i className="fa fa-angle-right" ></i></span></Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col lg={7} md={12}>
                          <Tab.Content className="pt-50">
                            <Tab.Pane eventKey="first">
                              <div className="ss-process-content">

                                <div className="core-value-img">
                                  <img className="img-fluid" src={require('../../assets/images/testing-ic1.svg').default} alt="testing-ic1" />
                                </div>


                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <p className="light-heading">
                                        Identify blind spots and unclear areas in the requirements.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Prioritize assessments and fix project flaws and integrations.                       </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Gather high-level business needs, architectural requirements, and detailed system requirements.             </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <div className="ss-process-content">

                                <div className="core-value-img">
                                  <img className="img-fluid" src={require('../../assets/images/testing-ic2.svg').default} alt="testing-ic1" />
                                </div>


                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <p className="light-heading">
                                        Create a test plan outlining timing, participants, and responsibilities.


                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Determine resources and efforts required for evaluating the release.

                                      </p>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text ">
                                      <p className="light-heading">
                                        Define scope, objectives, and types of functional and non-functional tests.

                                      </p>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                              <div className="ss-process-content">

                                <div className="core-value-img">
                                  <img className="img-fluid" src={require('../../assets/images/testing-ic3.svg').default} alt="testing-ic1" />
                                </div>


                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <h6 className="light-heading">
                                        Design simple and well-understood test cases.


                                      </h6>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <h6 className="light-heading">
                                        Ensure uniqueness among test cases.

                                      </h6>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text ">
                                      <h6 className="light-heading">
                                        Achieve full coverage of requirements using a traceability matrix.           </h6>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                              <div className="ss-process-content">

                                <div className="core-value-img">
                                  <img className="img-fluid" src={require('../../assets/images/testing-ic4.svg').default} alt="testing-ic1" />
                                </div>


                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <h6 className="light-heading">
                                        Install operating systems, virtual machines, and testing tools
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <h6 className="light-heading">
                                        Deploy project's test environment and database.



                                      </h6>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text ">
                                      <h6 className="light-heading">
                                        Define hardware, software, test data, configurations, and network parameters.       </h6>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                              <div className="ss-process-content">

                                <div className="core-value-img">
                                  <img className="img-fluid" src={require('../../assets/images/testing-ic5.svg').default} alt="testing-ic1" />
                                </div>


                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <h6 className="light-heading">
                                        Fully test the product.


                                      </h6>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <h6 className="light-heading">
                                        Identify and report bugs arising from test case execution.
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text ">
                                      <h6 className="light-heading">
                                        Log system performance compared to requirements.

                                      </h6>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="sixth">
                              <div className="ss-process-content">

                                <div className="core-value-img">
                                  <img className="img-fluid" src={require('../../assets/images/testing-ic6.svg').default} alt="testing-ic1" />
                                </div>


                                <div className="cms-needed pt-40">
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text">
                                      <h6 className="light-heading">
                                        Prepare a test closure report summarizing findings.
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>

                                    </div>
                                    <div className="right-text ">
                                      <h6 className="light-heading">
                                        Communicate the report to team members.
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="right-box d-flex mb-20">
                                    <div className="bd-highlight ">
                                      <div className="right-img">
                                        <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                      </div>
                                    </div>
                                    <div className="right-text ">
                                      <h6 className="light-heading">
                                        Include a summary of the testing process and results.
                                      </h6>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </Tab.Pane>

                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {/* section 5 end*/}
          {/* section 6 */}
          <div className="services-portfolio-main ptb-80">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="ss-heading ss-portfolio-text">
                    <h2>
                      <span>Our Portfolio</span>
                    </h2>

                  </div>
                </Col>
              </Row>
            </Container>
            <Portfolio></Portfolio>
          </div>
          {/* section 6 end*/}
          {/* section 7 */}
          <div className="ss-faq-main">
            <div className="faq-main pt-120   ">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="faq-title proof-text-main text-center mb-30">
                      <div className="ss-heading proof-text">
                        <h2><span>Frequently Asked<br />
                          Questions </span></h2>
                      </div>
                    </div>

                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col lg={8}>
                    <div className="faq-accordion">
                      <Accordion defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>How are quality assurance and quality control different in software development?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Quality Assurance is known as a proactive process and is prevention in nature. The purpose of Quality Assurance is to ensure that the approaches, techniques, methods, and processes designed for the projects are implemented effectively. Quality Assurance must be carried out before Quality Control. <br /><br />
                                A quality control process evaluates the final product against specific parameters, ensuring that it is of high quality. Both activities aim to correct any mistakes made during the production process. Thus QA != QC.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Why should quality assurance and software development be separated?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Every software development process includes quality assurance. Quality assurance is meant to reveal any errors, deficiencies, or discrepancies in developers' code. A high-quality end product is made possible by keeping software development and quality assurance separate.
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>How does the mobile app testing process work?</Accordion.Header>
                          <Accordion.Body>
                            <div className="faq-content">
                              <p className="text-regular">
                                Our website building process in a nutshell:
                                <ul>


                                  <li><p>Identify types of testing</p></li>

                                  <li><p>Prepare test cases and scripts</p></li>

                                  <li><p>Setting up an environment for testing</p></li>

                                  <li><p>Testing, both manual and automated</p></li>

                                  <li><p>Beta testing or usability testing</p></li>

                                  <li> <p>Testing performance</p></li>

                                  <li><p>Tests on devices</p></li>
                                  <li><p>Compliance and security testing</p></li>
                                  <li><p>Summary of Test Results</p></li> </ul>
                              </p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        {/* <Accordion.Item eventKey="3">
                    <Accordion.Header>Eu egestas sed sed posuere ultrices ?</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                      minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                      aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item> */}
                      </Accordion>
                    </div>
                  </Col>
                </Row>



              </Container>
            </div>
            <Faq></Faq>
          </div>

          {/* section 7 end*/}
        </div>
      </main>
    </>
  )
}
