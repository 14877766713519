import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import HiringDedication from '../../components/HiringDedication';
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import PricingHire from '../../components/PricingHire';
import HireDedicationContect from '../../components/HireDedicationContect';
import { Helmet } from 'react-helmet-async';

export default function HireUIUXDesigner() {
    const scrollToSection = () => {
        // Scroll to the section containing HireDedicationContect
        const section = document.getElementById('hireDedicationSection');
        if (section) {
            const topOffset = section.offsetTop - 200;
            window.scrollTo({ top: topOffset, behavior: 'smooth' });
        }
    };
    const CustomArrow = ({ direction, onClick }) => {
        const icon = direction === 'next' ? faChevronRight : faChevronLeft;

        return (
            <div
                className={`custom-arrow ${direction}`}
                style={{ zIndex: '1', display: 'block' }}
                onClick={onClick}
            >
                <FontAwesomeIcon icon={icon} />
            </div>
        );
    };

    const hireresource = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        nextArrow: <CustomArrow direction="next" />,
        prevArrow: <CustomArrow direction="prev" />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                },
            },
        ],
    };
    return (
        <>
            <Helmet>
                <title>	Custom UI/UX Design Services | Sensussoft</title>
                <meta name="title" content="Custom UI/UX Design Services | Sensussoft" />
                <meta name="keywords" content="Custom UI/UX Design, UI/UX Design Services, UX Design Company, UI Design Services, Sensussoft UI/UX Solutions" />
                <meta name="description" content="Explore our specialized UI/UX design services tailored for diverse industries. Get custom solutions, user research, wireframing, prototyping, and ongoing support from experienced UI/UX designers."></meta>
            </Helmet>
            <main>
                <div className='hiring_dedication_section'>
                    <section>
                        <div className="mobile-app-development website-development ptb-80 bg-logo">
                            <Container >
                                <Row>
                                    <Col lg={6} className="align-self-center">
                                        <div className="website-development-text proof-text-main">
                                            <div className="ss-heading proof-text">
                                                <h1><span>UI/UX Design </span> <br />
                                                    Company </h1>
                                                <p className="text-regular mt-20">
                                                    At Sensussoft, we specialize in UI/UX design, crafting intuitive and visually appealing interfaces that enhance user experiences. Our UI/UX design services cater to diverse industries, ensuring the creation of engaging and user-centric digital products.
                                                </p>
                                            </div>
                                            {/* <Link to='/get-in-touch-with-us'> */}
                                            <button className="learn-more btn-large mt-25" onClick={scrollToSection} aria-label='contact us for detail'>
                                                <span className="circle" aria-hidden="true">
                                                    <span className="icon arrow">
                                                        <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                                                    </span>
                                                </span>
                                                <span className="button-text">CONTACT US FOR DETAILS</span>
                                            </button>
                                            {/* </Link> */}
                                        </div>
                                    </Col>
                                    <Col lg={6} className="align-self-center text-center">
                                        <div className="change_vision_img"><img className="img-fluid" src={require('../../assets/images/ui-ux-bg.jpg')} alt='service1' /></div>

                                        <div className="website-development-img-main">

                                            <div className="website-development-img mt-sm-20 mt-md-20">
                                                <img className="img-fluid" src={require('../../assets/images/ui-ux-bg.jpg')} alt="mobile-app-development"></img>
                                            </div>


                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>
                    <section>
                        <div className="idea-and-enhance bg-service ">
                            <Container>
                                <Row>
                                    <Col lg={6}>
                                        <div className="ideation-services">
                                            <div className="services-in-action-main mb-md-20">
                                                <div className="services-in-action pt-100 pb-200">
                                                    <div className="our-vision-text-main d-flex">
                                                        <div className="bd-highlight vision-img-main">
                                                            <div className="our-vision-img overview-img">
                                                                <img className="img-fluid" src={require('../../assets/images/overview.svg').default} alt="vision-ic"></img>
                                                            </div>
                                                        </div>
                                                        <div className="our-vision-text">
                                                            <p>
                                                                UI/UX Design Services
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="ss-heading ss-portfolio-text">
                                                        <h2 className="ptb-15">
                                                            {/* See <span>Product  <br /> Ideation Services  </span> <br />in action. */}
                                                            Our expert team leverages modern design principles and user-centered approaches to create delightful and effective user interfaces for web and mobile applications.
                                                        </h2>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} md={12} className='align-self-center'>
                                        <div className="ideation-content ptb-100">
                                            <div className="enhance-content-main " >
                                                <div className="enhance-content">
                                                    <h3 className="text-regular">
                                                        <span className="lg-text-semibold">Custom UI/UX Design</span>
                                                    </h3>
                                                    <p className="pt-10 light-heading">
                                                        Crafting tailored and visually stunning user interfaces that resonate with your brand identity.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="enhance-content-main ">
                                                <div className="enhance-content">
                                                    <h3 className="text-regular">
                                                        <span className="lg-text-semibold">User Research & Persona Development</span>
                                                    </h3>
                                                    <p className="pt-10 light-heading">
                                                        Conducting user research to understand target audiences and creating user personas for informed design decisions.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="enhance-content-main ">
                                                <div className="enhance-content">
                                                    <h3 className="text-regular">
                                                        <span className="lg-text-semibold">Wireframing & Prototyping</span>
                                                    </h3>
                                                    <p className="pt-10 light-heading">
                                                        Developing wireframes and interactive prototypes to visualize the user flow and functionality of the digital product.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="enhance-content-main ">
                                                <div className="enhance-content">
                                                    <h3 className="text-regular">
                                                        <span className="lg-text-semibold">UI Design & Branding</span>
                                                    </h3>
                                                    <p className="pt-10 light-heading">
                                                        Designing aesthetically pleasing interfaces while maintaining brand consistency and identity.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="enhance-content-main ">
                                                <div className="enhance-content">
                                                    <h3 className="text-regular">
                                                        <span className="lg-text-semibold">UX Optimization & Testing</span>
                                                    </h3>
                                                    <p className="pt-10 light-heading">
                                                        Conducting usability testing and iterative refinement to ensure optimal user experiences.
                                                    </p>
                                                </div>
                                            </div>

                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>
                    <section>
                        <div className="tecnology_plus_slider testimonials ptb-80 mtb-80">
                            <div className="container">
                                <div className="col-lg-12">
                                    <div className="testimonials-section">
                                        <div className="ss-heading">
                                            <h2>Most-Preferred<br /><span>UI/UX</span> Combinations We Cater</h2>
                                        </div>
                                        <div className='pt-10'>
                                            <p className='light-heading'>We provide a range of UI/UX combinations to meet diverse project requirements.</p>
                                        </div>
                                        <div className="testimonials-box-main pt-35" >
                                            <Slider {...hireresource} className='ss-testimonial'>
                                                <div className='tecnology_hirededicate'>

                                                    <h3>User-Centered Design</h3>
                                                    <p>lacing the user at the center of the design process to meet their needs and preferences.</p>
                                                </div>
                                                <div className='tecnology_hirededicate'>

                                                    <h3>Consistency & Familiarity</h3>
                                                    <p>Maintaining consistency in design elements and patterns to enhance usability and familiarity.</p>
                                                </div>
                                                <div className='tecnology_hirededicate'>

                                                    <h3>Accessibility & Inclusivity</h3>
                                                    <p>Designing interfaces that are accessible to users of all abilities and inclusive of diverse demographics.</p>
                                                </div>
                                                <div className='tecnology_hirededicate'>

                                                    <h3>Visual Hierarchy & Readability</h3>
                                                    <p>Establishing clear visual hierarchies and ensuring readability for effective communication of content.</p>
                                                </div>
                                                <div className='tecnology_hirededicate'>

                                                    <h3>Simplicity & Minimalism</h3>
                                                    <p>Embracing simplicity and minimalism to reduce cognitive load and streamline user interactions.</p>
                                                </div>


                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <Container>
                            <Row>
                                <Col lg={12} md={12} className='align-self-center'>
                                    <div className="ideation-content pb-80">
                                        <div className="enhance-content-main ">
                                            <div className="enhance-content">
                                                <h3 className="text-regular text-center">
                                                    <span className="lg-text-semibold">Why Choose UI/UX Design Service From Sensussoft</span>
                                                </h3>
                                                <p className="pt-20 light-heading text-center">
                                                    Our commitment is to deliver visually stunning and highly functional UI/UX designs that elevate user experiences and drive business growth.
                                                </p>
                                                <div className="sotfware-info pt-40">
                                                    <Row>
                                                        <Col lg={6}>
                                                            <div className="application-title">
                                                                <div className="right-box d-flex mb-20">
                                                                    <div className="bd-highlight align-self-center">
                                                                        <div className="right-img">
                                                                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                                                        </div>

                                                                    </div>
                                                                    <div className="right-text align-self-center">
                                                                        <p className="light-heading">
                                                                            Strategic Approach to Design
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="right-box d-flex mb-20">
                                                                    <div className="bd-highlight align-self-center">
                                                                        <div className="right-img">
                                                                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                                                        </div>

                                                                    </div>
                                                                    <div className="right-text align-self-center">
                                                                        <p className="light-heading">
                                                                            Creative and Innovative Solutions
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="right-box d-flex mb-20">
                                                                    <div className="bd-highlight align-self-center">
                                                                        <div className="right-img">
                                                                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                                                        </div>

                                                                    </div>
                                                                    <div className="right-text align-self-center">
                                                                        <p className="light-heading">
                                                                            Focus on User Needs and Preferences
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="right-box d-flex mb-20">
                                                                    <div className="bd-highlight align-self-center">
                                                                        <div className="right-img">
                                                                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                                                        </div>

                                                                    </div>
                                                                    <div className="right-text align-self-center">
                                                                        <p className="light-heading">
                                                                            Collaborative Design Process
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <div className="application-title">
                                                                <div className="right-box d-flex mb-20">
                                                                    <div className="bd-highlight align-self-center">
                                                                        <div className="right-img">
                                                                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                                                        </div>

                                                                    </div>
                                                                    <div className="right-text align-self-center">
                                                                        <p className="light-heading">
                                                                            Comprehensive Design Services
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="right-box d-flex mb-20">
                                                                    <div className="bd-highlight align-self-center">
                                                                        <div className="right-img">
                                                                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                                                        </div>

                                                                    </div>
                                                                    <div className="right-text align-self-center">
                                                                        <p className="light-heading">
                                                                            Adherence to Design Standards and Best Practices
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="right-box d-flex mb-20">
                                                                    <div className="bd-highlight align-self-center">
                                                                        <div className="right-img">
                                                                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                                                        </div>

                                                                    </div>
                                                                    <div className="right-text align-self-center">
                                                                        <p className="light-heading">
                                                                            Quality Assurance and Testing
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="right-box d-flex mb-20">
                                                                    <div className="bd-highlight align-self-center">
                                                                        <div className="right-img">
                                                                            <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                                                        </div>

                                                                    </div>
                                                                    <div className="right-text align-self-center">
                                                                        <p className="light-heading">
                                                                            Timely Delivery and Deployment
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section>
                        <div className="solve-the-unsolved">
                            <Container>
                                <Row>
                                    <Col lg={6} className='align-self-center'>
                                        <div className="solve-img text-center">
                                            <img src={require('../../assets/images/solve.jpg')} className="img-fluid" alt='solve' />
                                        </div>
                                    </Col>
                                    <Col lg={6} className='align-self-center'>
                                        <div className="ss-heading ss-portfolio-text">
                                            <h2>
                                                Reasons to Opt for Our <br /><span> Dedicated Resources</span>
                                            </h2>
                                            <ul className='dedicated_resources'>
                                                <li>Specialized team of UI/UX designers with diverse design expertise.</li>
                                                <li>Customized hiring models to align with your project needs.</li>
                                                <li>Access to modern design tools and resources.</li>
                                                <li>Strong commitment to client collaboration and communication.</li>
                                                <li>Adherence to project timelines and milestones.</li>
                                                <li>Transparent pricing with no hidden fees.</li>
                                                <li>Regular design updates and progress reports.</li>
                                                <li>Satisfaction guarantee with a refund policy if expectations are not met.</li>

                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </section>
                    <section>
                        <PricingHire tecnology="UI/UX Designer" click={scrollToSection} />
                    </section>
                    <section>
                        <div className='hiring_process_blog pt-80 pb-80' style={{ backgroundColor: '#F4F5F9' }}>
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <div className='hiring_heading'>
                                            <div className="nurture-creativity-title pb-20">
                                                <div className="our-vision-text-main d-flex">
                                                    <div className="bd-highlight vision-img-main">
                                                        <div className="our-vision-img">
                                                            <img className="img-fluid" src={require('../../assets/images/vision-ic.svg').default} alt="vision-ic"></img>
                                                        </div>
                                                    </div>
                                                    <div className="our-vision-text">
                                                        <p>
                                                            Our Procedure
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="our-vision-heading">
                                                    <h2 className="lg-text-light ptb-15">
                                                        Discover Your Future
                                                        <span> Join Us Today!</span>
                                                    </h2>
                                                    <p style={{ fontWeight: '300' }}>We have simple and smart procedures to get you the best team that gives the best result. To know more about how we build & maintain an exclusive offshore team for you, get in touch with us now.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    {/* <slider of hiring process start  */}
                                    <HiringDedication />
                                    {/* <slider of hiring process end */}
                                </Row>
                            </Container>
                        </div>
                    </section>
                    <section>
                        <div className="ss-faq-main mb-80">
                            <div className="faq-main pt-80 pb-80">
                                <Container>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="faq-title proof-text-main text-center mb-30">
                                                <div className="ss-heading proof-text">
                                                    <h2><span>Frequently Asked<br />
                                                        Questions </span></h2>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col lg={8}>
                                            <div className="faq-accordion">
                                                <Accordion defaultActiveKey="0" flush>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>Why is UI/UX Design Important for Digital Products?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="faq-content">
                                                                <p className="text-regular">
                                                                    UI/UX design enhances user satisfaction, usability, and overall brand perception.
                                                                </p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>What’s the Cost of UI/UX Design Services?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="faq-content">
                                                                <p className="text-regular">
                                                                    Costs vary based on project complexity and scope; contact us for a custom quote.
                                                                </p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="2">
                                                        <Accordion.Header>How Long Does It Take to Complete UI/UX Design?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="faq-content">
                                                                <p className="text-regular">
                                                                    Timelines depend on project requirements and iteration cycles.
                                                                </p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="3">
                                                        <Accordion.Header>How Can I Provide Feedback on Design Concepts?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="faq-content">
                                                                <p className="text-regular">
                                                                    We provide collaborative tools and regular checkpoints for feedback.
                                                                </p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="4">
                                                        <Accordion.Header>Do You Offer Post-Design Support and Maintenance?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="faq-content">
                                                                <p className="text-regular">
                                                                    Yes, we offer ongoing support and design updates as needed.
                                                                </p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="5">
                                                        <Accordion.Header>What Design Tools and Software Do You Use?</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="faq-content">
                                                                <p className="text-regular">
                                                                    We use industry-standard design tools such as Adobe XD, Sketch, and Figma.
                                                                </p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>

                        </div>
                    </section>
                    <section id="hireDedicationSection">
                        <HireDedicationContect />
                    </section>
                </div>
            </main>
        </>
    )
}
