import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, NavLink } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Row, Col } from 'react-bootstrap';
import Header from './Header';


function Cultureheader(props) {

    const [isHeaderFixed, setIsHeaderFixed] = useState(false);
    const [isbannerClicked, setIsBannerClicked] = useState(sessionStorage.getItem('showBanner'));

    useEffect(() => {
        const handleScroll = () => {
            const isFixed = window.pageYOffset > 0;
            setIsHeaderFixed(isFixed);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const handleCloseOffcanvas = () => {
        setShowOffcanvas(false);
    };
    const handleLinkClick = () => {
        if (isbannerClicked === null || isbannerClicked === true) {
            props.setShowBanner(false);
            sessionStorage.setItem('showBanner', false);
        }
        if (showOffcanvas) {
            setShowOffcanvas(false);
        }
    };
    useEffect(() => {
        setIsBannerClicked(sessionStorage.getItem('showBanner'));
    }, [])
    const [showCultureHeader, setShowCultureHeader] = useState(false);

    const handleWorkClick = () => {
        if (isbannerClicked === null || isbannerClicked === true) {
            props.setShowBanner(false);
            sessionStorage.setItem('showBanner', true);
        }

        localStorage.setItem('workClicked', 'true');
        sessionStorage.setItem('activeHeader', 'cultureheader');
        setShowCultureHeader(true);
    };

    useEffect(() => {
        // const isWorkClicked = localStorage.getItem('workClicked');
        const isWorkClicked = sessionStorage.getItem('workClicked');

        if (isWorkClicked === 'true') {
            setShowCultureHeader(true);
            localStorage.removeItem('workClicked');
        }
    }, []);


    return (

        <div className='home-page-main main-functinality-main'>
            {showCultureHeader ? (
                <Header handleCloseCultureHeader={() => setShowCultureHeader(false)} handleWorkClick={handleWorkClick} />
            ) : (

                <header className={isHeaderFixed ? 'fixed-header' : ''}>
                    <Container>
                        <Row>
                            <Col>
                                {['lg'].map((expand) => (
                                    <Navbar key={expand} expand={expand} className="bg-body-tertiary">
                                        {/* <Container> */}
                                        <Navbar.Brand>
                                            <div className="ss-logo">
                                                <Link onClick={handleLinkClick} to="/" aria-label='logo' >
                                                    <img src={require('../assets/images/Sensussoft_Logo.svg').default} className="img-fluid" alt='img' />
                                                </Link>

                                            </div>
                                        </Navbar.Brand>
                                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => setShowOffcanvas(true)} />
                                        <Navbar.Offcanvas
                                            show={showOffcanvas}
                                            onHide={handleCloseOffcanvas}
                                            id={`offcanvasNavbar-expand-${expand}`}
                                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                            placement="start"
                                            className='header_main_wapper'
                                            backdrop="static"
                                        >
                                            <Offcanvas.Header closeButton>
                                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>

                                                    <div className="ss-logo">
                                                        <Link onClick={handleLinkClick} to="/" aria-label='culture' >
                                                            <img src={require('../assets/images/Sensussoft_Logo.svg').default} className="img-fluid" alt='img' />
                                                        </Link>
                                                    </div>
                                                </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            <Offcanvas.Body>


                                                <div className="d-flex nav-section1 me-auto ">
                                                    <div className="about_after_line "></div>
                                                    <div className="ss-link-main-part responsive_header_h1 align-self-center">
                                                        <Link to="/culture" onClick={handleLinkClick} aria-label='our culture'>OUR CULTURE</Link>
                                                    </div>
                                                </div>
                                                <Nav className='nev-section2 m-auto ps-2'>

                                                    <div className="ss-link-main">
                                                        <NavLink className="ss-link nav-link" to="/about-us" onClick={handleLinkClick} aria-label='About Us'>About Us</NavLink>
                                                    </div>
                                                    <div className="ss-link-main">
                                                        <NavLink className="ss-link nav-link" to="/meeting-landscape" onClick={handleLinkClick} aria-label='Company Meetings'>Company Meetings</NavLink>
                                                    </div>
                                                    <div className="ss-link-main">
                                                        <NavLink className="ss-link nav-link" to="/careers" onClick={handleLinkClick} aria-label='Career'>Career</NavLink>
                                                    </div>
                                                    <div className="ss-link-main">
                                                        <NavLink className="ss-link nav-link" to="/team" onClick={handleLinkClick} aria-label='our team'>Our Team</NavLink>
                                                    </div>
                                                    <div className="ss-link-main btn-sewrvice-get align-self-center mt-lg-0 mt-md-2 mt-2">
                                                        <NavLink className="nav-link animate-charcter" to="/newsletter" onClick={handleLinkClick} aria-label='Newsletter'>
                                                            News letter</NavLink>
                                                    </div>
                                                </Nav>
                                                <Nav className="d-flex align-items-center btns_of_comman">
                                                    <div className="get-touch-btn">
                                                        <Link to="/careers#joiningcomp" onClick={handleLinkClick} className="btn-wrapper-get" aria-label='opening'>
                                                            OPENING
                                                        </Link>
                                                    </div>

                                                    <div className="about_after_line "></div>
                                                    <div className="ss-link-main-part responsive_header_h1 align-self-center">
                                                        <Link to="/what-we-do" onClick={handleWorkClick} aria-label='what we do'>WHAT WE DO</Link>
                                                    </div>


                                                </Nav>

                                            </Offcanvas.Body>
                                        </Navbar.Offcanvas>
                                        {/* </Container> */}
                                    </Navbar>
                                ))}
                            </Col>
                        </Row>
                    </Container>

                </header>


            )}

        </div>

    )
}

export default Cultureheader
