import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from "react-router-dom";
import axios from 'axios';

import { Helmet } from 'react-helmet-async';

export default function Team() {
    const baseurl = "https://api.sensussoft.com/api/team";
    const [managementteam, setManagementTeam] = useState([]);
    const [teamleader, setteamleader] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(baseurl)
            .then(response => {
                //   console.log('API Response:', response.data);
                const managementteamData = response.data.data.management || []; // Handle empty or invalid data
                const leaderData = response.data.data.teamleader || []; // Handle empty or invalid data
                setManagementTeam(managementteamData);
                setteamleader(leaderData);

                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching blogs:', error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <div className='blog-loader-main'>
            <div className='blog-loader'>
                <div className='loader-img'>
                    <Container>
                        <Row className='justify-content-center ptb-100 text-center'>
                            <Col lg={12}>
                                <div className='loading-content'>
                                    <div style={{ position: 'relative', width: '250px', height: '150px' }}>
                                        {/* SVG Markup */}
                                        <svg width="100%" height="100%" viewBox="0 0 187.3 93.7" preserveAspectRatio="xMidYMid meet" className="svg-animation">
                                            <path
                                                stroke="#001B50"
                                                id="outline"
                                                fill="none"
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeMiterlimit="10"
                                                d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                                            />
                                            <path
                                                id="outline-bg"
                                                opacity="0.05"
                                                fill="none"
                                                stroke="#001B50"
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeMiterlimit="10"
                                                d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
                                            />
                                        </svg>

                                        {/* Custom text or content below the SVG */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container></div>
            </div>
        </div>
    }
    return (
        <>
            <Helmet>
                <title>Meet Our Team: Experts at Sensussoft</title>
                <meta name="title" content="Meet Our Team: Experts at Sensussoft" />
                <meta name="keywords" content="Meet Our Team, Sensussoft Experts, Team Members, Company Experts, Team Profiles, Meet Our Experts, Expert Team, Professional Team, Company Team, Team Introduction" />
                <meta name="description" content="An exceptional team of wealth management veterans, technology experts, and knowledgeable advisors with proven experience." />        <meta property="og:type" content="Website" />

            </Helmet>
            <main>
                <div className="team-page-main">
                    {/*<div className="our-team-main website-development pt-80 bg-logo">*/}
                    <div className="our-team-main website-development">
                        <Container>
                            <Row>
                                <Col lg={6} className="align-self-center">
                                    <div className="website-development-text proof-text-main">
                                        <div className="ss-heading proof-text">
                                            <h1>Meet Our Key <br />
                                                <span>
                                                    Executives</span></h1>
                                            <p className="text-regular mt-20">
                                                We are Sensussoft, a company full of motivated goal-getters who make the dreams of people all around the globe a reality with innovation, out-of-the-box thinking, dedication, and talent.
                                            </p>
                                            <Link to='/get-in-touch-with-us' aria-label='connect with our team'>
                                                <button className="learn-more btn-large mt-25" aria-label='connect with our team'>
                                                    <span className="circle" aria-hidden="true">
                                                        <span className="icon arrow">
                                                            <img src={require('../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                                                        </span>
                                                    </span>
                                                    <span className="button-text">CONNECT WITH OUR TEAM</span>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} className="align-self-center text-center">
                                    <div className="team-bg-img mt-sm-20 mt-md-20">
                                        <img className="img-fluid" src={require('../assets/images/team-images/teamimg.png')} alt="teamimg"></img>
                                        <div className="web-build-box">
                                            <div className="like-round">
                                                <img className="img-fluid " src={require('../assets/images/team-images/mail-ic.svg').default} alt="blub">
                                                </img>
                                            </div>
                                            <div className="build-text">
                                                <span>
                                                    Congratulation!
                                                </span>
                                                <p className="text-regular">
                                                    You are at the Right Place!
                                                </p>
                                            </div>
                                        </div>
                                        <div className="web-build-box team-box2">
                                            <div className="like-round">
                                                <img className="img-fluid " src={require('../assets/images/team-images/like-ic.svg').default} alt="like">
                                                </img>
                                            </div>
                                            <div className="build-text">
                                                <span>
                                                    40 +
                                                </span>
                                                <p className="text-regular">
                                                    Happy Employee
                                                </p>
                                                <div className="team-img-box pt-5">
                                                    <img className="img-fluid " src={require('../assets/images/team-images/team-grid.png')} alt="blub" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="team-img2 mt-sm-20 mt-md-20">
                                        <img className='img-fluid' src={require('../assets/images/team-images/teamimg2.png')} alt='teamimg'></img>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="team-images-main ptb-60">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="our-vision-text-main d-flex">
                                        <div className="bd-highlight vision-img-main">
                                            <div className="team-title-img">
                                                <img className="img-fluid" src={require('../assets/images/title-ic.svg').default} alt="vision-ic"></img>
                                            </div>
                                        </div>
                                        <div className="our-vision-text">
                                            <h2>
                                                management team
                                            </h2>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pt-40">
                                {managementteam.map(managementmember => (
                                    <div className='col-xl-3 col-lg-4 col-md-6 mb-40' key={managementmember.id}>
                                        <div className='team-member-box-main'>
                                            <div className="team-member-img flex-grow-1">
                                                <img src={managementmember.avatar} className="img-fluid" alt='Team-img' />
                                            </div>
                                            <div className="team-member-info-main d-flex gx-2 mb-5">
                                                <div className="member-name align-self-center flex-grow-1">
                                                    <p className='sm-text-semibold'>
                                                        {managementmember.name}
                                                    </p>
                                                    <p style={{ color: '#001b50cc', fontWeight: 400 }}>
                                                        {managementmember.role}
                                                    </p>
                                                </div>
                                                <div className="social-profiles d-flex align-self-center">
                                                    {managementmember.web && (
                                                        <div className="profiles-link">
                                                            <Link to={managementmember.web} target="_blank" aria-label='web url'>
                                                                <img src={require('../assets/images/team-images/website-ic.svg').default} className="img-fluid" alt='website-ic' />
                                                            </Link>
                                                        </div>
                                                    )}
                                                    {managementmember.linked_in && (
                                                        <div className="profiles-link" >
                                                            <Link to={managementmember.linked_in} target="_blank" aria-label='linkedin'>
                                                                <img src={require('../assets/images/team-images/linkin-ic.svg').default} className="img-fluid" alt='linkin-ic' />
                                                            </Link>
                                                        </div>
                                                    )}
                                                    {managementmember.medium && (
                                                        <div className="profiles-link">
                                                            <Link to={managementmember.medium} target="_blank" aria-label='medium'>
                                                                <img src={require('../assets/images/team-images/medium-ic.svg').default} className="img-fluid" alt='medium-ic' />
                                                            </Link>
                                                        </div>
                                                    )}
                                                    {managementmember.twitter && (
                                                        <div className="profiles-link" >
                                                            <Link to={managementmember.twitter} target="_blank" aria-label='twitter'>
                                                                <img src={require('../assets/images/team-images/twitter-ic.svg').default} className="img-fluid" alt='twitter-ic' />
                                                            </Link>
                                                        </div>
                                                    )}
                                                    {managementmember.skype && (
                                                        <div className="profiles-link">
                                                            <Link to={managementmember.skype} target="_blank" aria-label='skype'>
                                                                <img src={require('../assets/images/team-images/skype-ic.svg').default} className="img-fluid" alt='skype-ic' />
                                                            </Link>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className="our-vision-text-main d-flex">
                                        <div className="bd-highlight vision-img-main">
                                            <div className="team-title-img">
                                                <img className="img-fluid" src={require('../assets/images/title-ic.svg').default} alt="vision-ic"></img>
                                            </div>
                                        </div>
                                        <div className="our-vision-text">
                                            <h2>
                                                Team Leaders
                                            </h2>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pt-40 team-leaders-info">
                                {teamleader.map((teamleaderData) => (
                                    <div className="mb-40 col-xl-3 col-lg-4 col-md-6" key={teamleaderData.id}>
                                        <div className='team-member-box-main  d-flex '>
                                            <div className="team-member-img flex-grow-1">
                                                <img src={teamleaderData.avatar} className="img-fluid" alt='img' />
                                            </div>
                                            <div className="team-member-info-main d-flex gx-2 mb-5">
                                                <div className="member-name align-self-center flex-grow-1">
                                                    <p className='sm-text-semibold'>
                                                        {teamleaderData.name}
                                                    </p>
                                                    <p style={{ color: '#001b50cc', fontWeight: 400 }}>
                                                        {teamleaderData.role}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <Col className="col-xl-3 col-lg-4 col-md-6 mb-40">
                                    <Link to="/careers" aria-label='careers'>
                                        <div className='team-member-box-main team-member-hire  d-flex team-hire-box'>
                                            <div className="team-member-img flex-grow-1">
                                                <img src={require('../assets/images/team-images/smilely.png')} className="img-fluid" alt='img' />
                                                <div className="face-goes-text">
                                                    <h3 className="text-regular">Your Face goes <br />here</h3>
                                                </div>
                                            </div>
                                            <div className="team-member-info-main d-flex gx-2 mb-5">
                                                <div className="member-name align-self-center flex-grow-1">
                                                    <p className='sm-text-semibold'>
                                                        Your name goes here
                                                    </p>
                                                    <p className='text-regular' style={{ color: 'rgba(0, 27, 80, 0.8)', fontWeight: '400' }}>
                                                        Your role goes here
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </Link>
                                </Col>

                            </Row>
                        </Container>
                    </div>
                </div>
            </main>
        </>
    )
}

