// import React from 'react';
import Slider from 'react-slick';
import { Stack } from 'react-bootstrap';
import React, { useRef, useEffect } from 'react';
import hiringdadication from '../sliderdata/hiringdadication.json';


const HiringDedication = () => {
    const sliderRef = useRef(null);

    useEffect(() => {
        const slider = sliderRef.current;

        // Check if slider or slider.innerSlider is not available
        if (!slider || !slider.innerSlider) {
            return;
        }

        const sliderLength = slider.innerSlider.state.slideCount;

        // Attach event listeners
        slider.innerSlider.list.addEventListener("wheel", handleWheel);
        slider.innerSlider.list.addEventListener("beforeChange", setTargetSlide);

        // Define event handler functions
        function setTargetSlide(event, slick, currentSlide, nextSlide) {
            let target = nextSlide + 1;

            // Adjust target slide based on conditions
            if (
                currentSlide - 1 === nextSlide ||
                (nextSlide + 1 === slick.slideCount && currentSlide < nextSlide)
            ) {
                target = nextSlide - 1;
            }

            const targetElement = slider.innerSlider.slides[target];
            targetElement.classList.add("slick-target");
        }

        function handleWheel(event) {
            const deltaY = event.deltaY;
            const currentSlideIndex = slider.innerSlider.state.currentSlide;

            // Check if wheel action is at the edge of the slider
            if (
                (deltaY < 0 && currentSlideIndex === 0) ||
                (deltaY > 0 && currentSlideIndex === sliderLength - 1)
            ) {
                return;
            }

            event.preventDefault();

            // Navigate to previous or next slide based on wheel direction
            if (deltaY < 0) {
                slider.slickPrev();
            } else {
                slider.slickNext();
            }
        }

        // Cleanup function to remove event listeners
        return () => {
            if (slider && slider.innerSlider && slider.innerSlider.list) {
                slider.innerSlider.list.removeEventListener("wheel", handleWheel);
                slider.innerSlider.list.removeEventListener("beforeChange", setTargetSlide);
            }
        };
    }, []);

    // Slider settings
    const settings = {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        vertical: true,
        dots: false,
        arrows: false,
        responsive: [
            { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 1, vertical: true, dots: false } },
            { breakpoint: 766, settings: { slidesToShow: 1, vertical: false, dots: true, adaptiveHeight: false } },
        ],
    };

    return (
        <>

            <div className='core-value-section'>
                <div className="slider-item" >
                    <Slider {...settings} ref={sliderRef}>
                        {
                            hiringdadication.map((item, i) => {
                                const { id, hiringimg, hiringhead, hiringdese } = item;
                                return (
                                    <div className='items' key={id}>
                                        <div className='hiring_description'>
                                            <Stack direction='horizontal' className="our-hiring-text-main   d-mobile-block ">
                                                <div className="our-hiring-igsub">
                                                    <div className="our-hiring-img">
                                                        <img className='img-fluid' src={require(`../assets/images/${hiringimg}`)} alt='hiring1' />
                                                    </div>
                                                </div>
                                                <div className="our-hiring-text-dese align-self-center">
                                                    <h2 className='ss-title'>{hiringhead}</h2>
                                                    <p>{hiringdese}</p>
                                                </div>
                                            </Stack>
                                        </div>
                                    </div>
                                )
                            }
                            )
                        }
                    </Slider>
                </div>
            </div>


        </>

    );
};

export default HiringDedication;
