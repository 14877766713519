import React from 'react'
import { Link } from 'react-router-dom';

function Ourservices() {
  return (
    <>
      <div className="row g-0 pt-30 delivering-section">
        <div className="col-lg-4 col-md-6">
          <Link to="/product-ideation-services" aria-label='product-ideation-services page'>
            <div className="delivering-box border d-flex flex-column">
              <div className="delivering-ic">
                <img src={require('../assets/images/delivering-ic1.svg').default} className="img-fluid" alt='img' />
              </div>
              <div className="delivering-info">
                <h2 className="pt-15">Ideation and planning</h2>
                <h3 className="lg-text-light pt-5">Technology brainstorming is an iterative process of making your product better and better over time. We know how to turn your vision into reality.</h3>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="/mobile-app-development" aria-label='mobile-app-development page'>
            <div className="delivering-box border d-flex flex-column">
              <div className="delivering-ic">
                <img src={require('../assets/images/delivering-ic2.svg').default} className="img-fluid" alt='img' />
              </div>
              <div className="delivering-info">
                <h2 className="pt-15">Mobile App Development</h2>
                <h3 className="lg-text-light pt-5">A feature-rich app with various functions delivers unique value propositions to firms. We have the right team.</h3>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="/web-application-development" aria-label='web application development'>
            <div className="delivering-box border d-flex flex-column">
              <div className="delivering-ic">
                <img src={require('../assets/images/delivering-ic3.svg').default} className="img-fluid" alt='img' />
              </div>
              <div className="delivering-info flex-grow-1">
                <h2 className="pt-15">Website Development</h2>
                <h3 className="lg-text-light pt-5">With our creative team, you can craft the idea for a completely customized website and ensure your website is future-proof.</h3>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="/ui-ux-design" aria-label='ui/ux design'>
            <div className="delivering-box border d-flex flex-column">
              <div className="delivering-ic">
                <img src={require('../assets/images/delivering-ic4.svg').default} className="img-fluid" alt='img' />
              </div>
              <div className="delivering-info flex-grow-1">
                <h2 className="pt-15">UI & UX Design</h2>
                <h3 className="lg-text-light pt-5">Creating a high-quality product interface means solving problems iteratively and making them better with each iteration to meet end users' expectations. .</h3>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="/quality-assurance" aria-label='quality assurance'>
            <div className="delivering-box border d-flex flex-column">
              <div className="delivering-ic">
                <img src={require('../assets/images/delivering-ic5.svg').default} className="img-fluid" alt='img' />
              </div>
              <div className="delivering-info flex-grow-1">
                <h2 className="pt-15">Quality Assurance</h2>
                <h3 className="lg-text-light pt-5">We are not just executers that fulfill requirements. We are a talented team of Engineers that solve highly complex problems in a well-founded and matching.</h3>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="/maintenance-support" aria-label='maintenance-support'>
            <div className="delivering-box border d-flex flex-column">
              <div className="delivering-ic">
                <img src={require('../assets/images/delivering-ic6.svg').default} className="img-fluid" alt='img' />
              </div>
              <div className="delivering-info flex-grow-1">
                <h2 className="pt-15">Maintenance and support</h2>
                <h3 className="lg-text-light pt-5">Collaboration is not finished after the project is developed, delivered, and launched. As a committed Software Development Partner.</h3>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-lg-4 col-md-6">
          <Link to="/ai-ml-development" aria-label='ai-ml-development'>
            <div className="delivering-box border d-flex flex-column">
              <div className="delivering-ic">
                <img src={require('../assets/images/delivering-ic10.svg').default} className="img-fluid" alt='img' />
              </div>
              <div className="delivering-info flex-grow-1">
                <h2 className="pt-15">AI/ML Development</h2>
                <h3 className="lg-text-light pt-5">Comprehensive AI/ML development services for businesses, leveraging cutting-edge tech to enhance user experiences, automate processes, and enable data-driven decisions.
                </h3>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-lg-4 col-md-6">
          <Link to="/blockchain-development" aria-label='blockchain-development'>
            <div className="delivering-box border d-flex flex-column">
              <div className="delivering-ic">
                <img src={require('../assets/images/delivering-ic7.svg').default} className="img-fluid" alt='img' />
              </div>
              <div className="delivering-info flex-grow-1">
                <h2 className="pt-15">Blockchain Development</h2>
                <h3 className="lg-text-light pt-5">Premier blockchain developers empowering businesses with custom solutions, NFTs, Whitelabel, and more. Trust us for a decentralized future.
                </h3>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-lg-4 col-md-6">
          <Link to="/marketplace-apps" aria-label='marketplace-apps'>
            <div className="delivering-box border d-flex flex-column">
              <div className="delivering-ic">
                <img src={require('../assets/images/delivering-ic9.svg').default} className="img-fluid" alt='img' />
              </div>
              <div className="delivering-info flex-grow-1">
                <h2 className="pt-15">Marketplace apps</h2>
                <h3 className="lg-text-light pt-5">we are Experts in profitable marketplace apps, connecting buyers, sellers, and service providers. Custom solutions, user-friendly platforms, and multiple payment options.
                </h3>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}

export default Ourservices
