import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion';
import HiringDedication from '../../components/HiringDedication';
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import PricingHire from '../../components/PricingHire';
import HireDedicationContect from '../../components/HireDedicationContect';
import { Helmet } from 'react-helmet-async';

export default function HireVueJSDevelopers() {
  const scrollToSection = () => {
    // Scroll to the section containing HireDedicationContect
    const section = document.getElementById('hireDedicationSection');
    if (section) {
      const topOffset = section.offsetTop - 200;
      window.scrollTo({ top: topOffset, behavior: 'smooth' });
    }
  };
  const CustomArrow = ({ direction, onClick }) => {
    const icon = direction === 'next' ? faChevronRight : faChevronLeft;

    return (
      <div
        className={`custom-arrow ${direction}`}
        style={{ zIndex: '1', display: 'block' }}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    );
  };

  const hireresource = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <CustomArrow direction="next" />,
    prevArrow: <CustomArrow direction="prev" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <Helmet>
        <title>	Leading Vue.js Development Company | Sensussoft</title>
        <meta name="title" content="Leading Vue.js Development Company | Sensussoft" />
        <meta name="keywords" content="Leading Vue.js Development Company, Vue.js Development Services, Vue.js Development Solutions, Vue.js Company, Sensussoft Vue.js Experts" />
        <meta name="description" content="Explore specialized Vue.js development services for fintech, media, and healthcare sectors. Custom solutions, SPAs, maintenance & support."></meta>
      </Helmet>
      <main>
        <div className='hiring_dedication_section'>
          <section>
            <div className="mobile-app-development website-development ptb-80 bg-logo">
              <Container >
                <Row>
                  <Col lg={6} className="align-self-center">
                    <div className="website-development-text proof-text-main">
                      <div className="ss-heading proof-text">
                        <h1><span>Vue Development </span> <br />
                          Company </h1>
                        <p className="text-regular mt-20">
                          Sensussoft stands at the forefront of Vue.js development, offering specialized services across sectors like fintech, media, and healthcare. Our proficiency in Vue.js ensures that your application is not just current but also future-ready.
                        </p>
                      </div>
                      {/* <Link to='/get-in-touch-with-us'> */}
                      <button className="learn-more btn-large mt-25" onClick={scrollToSection} aria-label='contact us for detail'>
                        <span className="circle" aria-hidden="true">
                          <span className="icon arrow">
                            <img src={require('../../assets/images/btn-arrow.svg').default} className="img-fluid" alt='img' />
                          </span>
                        </span>
                        <span className="button-text">CONTACT US FOR DETAILS</span>
                      </button>
                      {/* </Link> */}
                    </div>
                  </Col>
                  <Col lg={6} className="align-self-center text-center">
                    <div className="change_vision_img"><img className="img-fluid" src={require('../../assets/images/hire-devloper/vuejs_img1.png')} alt='service1' /></div>

                    <div className="website-development-img-main">

                      <div className="website-development-img mt-sm-20 mt-md-20">
                        <img className="img-fluid" src={require('../../assets/images/hire-devloper/vuejs_img1.png')} alt="mobile-app-development"></img>
                      </div>


                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <div className="idea-and-enhance bg-service ">
              <Container>
                <Row>
                  <Col lg={6}>
                    <div className="ideation-services">
                      <div className="services-in-action-main mb-md-20">
                        <div className="services-in-action pt-100 pb-200">
                          <div className="our-vision-text-main d-flex">
                            <div className="bd-highlight vision-img-main">
                              <div className="our-vision-img overview-img">
                                <img className="img-fluid" src={require('../../assets/images/overview.svg').default} alt="vision-ic"></img>
                              </div>
                            </div>
                            <div className="our-vision-text">
                              <h2>
                                Vue Development Services
                              </h2>
                            </div>
                          </div>
                          <div className="ss-heading ss-portfolio-text">
                            <h3 className="ptb-15">
                              {/* See <span>Product  <br /> Ideation Services  </span> <br />in action. */}
                              Harnessing Vue.js, we create lightweight, scalable, and advanced web applications, transforming your business ideas into powerful technical realities.
                            </h3>

                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={12} className='align-self-center'>
                    <div className="ideation-content ptb-100">
                      <div className="enhance-content-main " >
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Custom Vue.js Web Development</span>
                          </h3>
                          <div className='pt-10'><p className="light-heading">
                            Crafting unique solutions that set your business apart.
                          </p></div>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Vue.js SPA (Single Page Application)</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Building fast, responsive, and interactive SPAs.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Maintenance & Support</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Ensuring your Vue.js applications are always up-to-date and running smoothly.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Vue.js Component Development</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Creating reusable and efficient Vue.js components for a seamless user interface.
                          </p>
                        </div>
                      </div>
                      <div className="enhance-content-main ">
                        <div className="enhance-content">
                          <h3 className="text-regular">
                            <span className="lg-text-semibold">Integration & Migration to Vue.js</span>
                          </h3>
                          <p className="pt-10 light-heading">
                            Smooth transition and integration services for upgrading to Vue.js.
                          </p>
                        </div>
                      </div>

                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <div className="tecnology_plus_slider tecnology_plus_slider_nav testimonials ptb-80 mtb-80">
              <div className="container">
                <div className="col-lg-12">
                  <div className="testimonials-section">
                    <div className="ss-heading">
                      <h2>Most-Preferred<br /><span>Vue</span> Combinations We Cater</h2>
                    </div>
                    <p className='pb-10 light-heading'>Delivering a range of Vue.js combinations, specifically designed to meet diverse business needs.</p>
                    <div className="testimonials-box-main pt-35" >
                      <Slider {...hireresource} className='ss-testimonial'>
                        <div className='tecnology_hirededicate'>

                          <h3>Vue.js + Node.js</h3>
                          <p>Utilize Vue.js frontend with Node.js backend for efficient and modern web development.</p>
                        </div>
                        <div className='tecnology_hirededicate'>

                          <h3>Vue.js + Laravel</h3>
                          <p>Pair Vue.js frontend with Laravel backend for robust and scalable web applications.</p>
                        </div>
                        <div className='tecnology_hirededicate'>

                          <h3>Vue.js + Next.js</h3>
                          <p>Combine Vue.js with Nuxt.js for server-side rendering & seamless Vue app development.</p>
                        </div>


                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <Container>
              <Row>
                <Col lg={12} md={12} className='align-self-center'>
                  <div className="ideation-content pb-80">
                    <div className="enhance-content-main ">
                      <div className="enhance-content">
                        <h3 className="text-regular text-center">
                          <span className="lg-text-semibold">Why Choose Vue Development Service From Sensussoft</span>
                        </h3>
                        <p className="pt-20 light-heading text-center">
                          Delivering sophisticated Vue.js solutions that drive impactful results in today’s digital ecosystem.
                        </p>
                        <div className="sotfware-info pt-40">
                          <Row>
                            <Col lg={6}>
                              <div className="application-title">
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Cutting-edge Development Strategies
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Flexible and Custom Engagement Models
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      User-Centric Design Philosophy
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Comprehensive Solutions from Ideation to Deployment
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="application-title">
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Commitment to Optimal Performance
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Adherence to High-Security Standards
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Ongoing Support and Maintenance Post-Deployment
                                    </p>
                                  </div>
                                </div>
                                <div className="right-box d-flex mb-20">
                                  <div className="bd-highlight align-self-center">
                                    <div className="right-img">
                                      <img src={require('../../assets/images/check.svg').default} className="img-fluid" alt='img' />
                                    </div>

                                  </div>
                                  <div className="right-text align-self-center">
                                    <p className="light-heading">
                                      Rapid Deployment and Market-Ready Solutions
                                    </p>
                                  </div>
                                </div>

                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section>
            <div className="solve-the-unsolved">
              <Container>
                <Row>
                  <Col lg={6} className='align-self-center'>
                    <div className="solve-img text-center">
                      <img src={require('../../assets/images/hire-devloper/vuejs_img2.png')} className="img-fluid" alt='solve' />
                    </div>
                  </Col>
                  <Col lg={6} className='align-self-center'>
                    <div className="ss-heading ss-portfolio-text">
                      <h2>
                        Reasons to Opt for Our <br /><span> Dedicated Resources</span>
                      </h2>
                      <ul className='dedicated_resources'>
                        <li>Extensive pool of Vue.js experts with proven expertise.</li>
                        <li>Customized hiring models to meet diverse project needs.</li>
                        <li>Advanced technological infrastructure and tools.</li>
                        <li>Strict confidentiality policies to protect your intellectual property.</li>
                        <li>On-time delivery with adherence to deadlines.</li>
                        <li>Transparent pricing with no surprise costs.</li>
                        <li>Open communication channels for effective collaboration.</li>
                        <li>Regular updates for complete project visibility.</li>
                        <li>24/7 technical support and maintenance services.</li>
                        <li>Assurance of satisfaction with a money-back policy.</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <PricingHire tecnology="VueJs Developers" click={scrollToSection} />
          </section>
          <section>
            <div className='hiring_process_blog pt-80 pb-80' style={{ backgroundColor: '#F4F5F9' }}>
              <Container>
                <Row>
                  <Col lg="12">
                    <div className='hiring_heading'>
                      <div className="nurture-creativity-title pb-20">
                        <div className="our-vision-text-main d-flex">
                          <div className="bd-highlight vision-img-main">
                            <div className="our-vision-img">
                              <img className="img-fluid" src={require('../../assets/images/vision-ic.svg').default} alt="vision-ic"></img>
                            </div>
                          </div>
                          <div className="our-vision-text">
                            <p>
                              Our Procedure
                            </p>
                          </div>
                        </div>
                        <div className="our-vision-heading">
                          <h2 className="lg-text-light ptb-15">
                            Discover Your Future
                            <span> Join Us Today!</span>
                          </h2>
                          <p style={{ fontWeight: '300' }}>We have simple and smart procedures to get you the best team that gives the best result. To know more about how we build & maintain an exclusive offshore team for you, get in touch with us now.</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <slider of hiring process start  */}
                  <HiringDedication />
                  {/* <slider of hiring process end */}
                </Row>
              </Container>
            </div>
          </section>
          <section>
            <div className="ss-faq-main mb-80">
              <div className="faq-main pt-80 pb-80">
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className="faq-title proof-text-main text-center mb-30">
                        <div className="ss-heading proof-text">
                          <h2><span>Frequently Asked<br />
                            Questions </span></h2>
                        </div>
                      </div>

                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg={8}>
                      <div className="faq-accordion">
                        <Accordion defaultActiveKey="0" flush>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Why Choose Vue.js for Web Development?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Vue.js offers a progressive framework for building user-centric and high-performance web interfaces.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Cost Implications for Vue.js Web Development?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Costs depend on project specifics; we provide customized quotes.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>Estimated Time for Developing a Vue.js Web Application? </Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Timelines are project-specific, focused on efficient and quality delivery.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>Tracking the Progress of Vue.js Projects? </Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  We maintain transparency with regular updates and project management tools.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>Post-Development Support in Vue.js?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  Yes, we offer comprehensive maintenance and support services post-launch.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="5">
                            <Accordion.Header>What are the Pricing Models for Vue.js Development?</Accordion.Header>
                            <Accordion.Body>
                              <div className="faq-content">
                                <p className="text-regular">
                                  We offer flexible pricing models, including fixed, hourly, or project-based.
                                </p>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
              <section id="hireDedicationSection">
                <HireDedicationContect />
              </section>
            </div>
          </section>
        </div >
      </main >
    </>
  )
}
